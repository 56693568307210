import axios from 'shared/lib/axios';

interface TrackProps {
  event: string;
  properties: object;
}

export function apiTrackPost(params: TrackProps) {
  const { event, properties } = params;
  return axios.post('/v1/tracks', { event, properties });
}
