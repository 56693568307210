import React, { useContext, useEffect, useState } from 'react';
import { ApiErrorResponse, AuthResponseData, SUPPORT_EMAIL, sendCode } from 'shared';

import Head from 'components/Head';

import { media } from 'context';
import { Button, EmailInput, Layout, Link, Text } from 'ds';
import GoogleButton from 'ds/GoogleButton';
import { AuthFlowType } from 'store/Auth/types';
import { selectUser } from 'store/User/selectors';
import { useAppSelector } from 'store/hooks';

export interface Props {
  handleSuccess: (data: AuthResponseData) => void;
  email: string;
  setEmail: (email: string) => void;
  submitting: boolean;
  setSubmitting: (submitting: boolean) => void;
  setViewState: (viewState: 'verify') => void;
  authFlowType: AuthFlowType;
}

const SignIn: React.FC<Props> = ({
  handleSuccess,
  email,
  setEmail,
  submitting,
  setSubmitting,
  setViewState,
  authFlowType
}) => {
  const { xs } = useContext(media);
  const [errorMessage, setErrorMessage] = useState<JSX.Element | undefined>(undefined);
  const [valid, setValid] = useState<boolean>(false);
  const [emailError, setEmailError] = useState<string>('');
  const alreadyLoggedInUser = useAppSelector(selectUser);

  useEffect(() => {
    setTimeout(() => window.scrollTo(0, 0), 50);
    if (authFlowType !== 'default' && !!email) {
      setValid(true);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (!!alreadyLoggedInUser) return null;

  const handleSubmitEmail = () => {
    if (!email) setEmailError('Email address is required.');
    if (!email || !valid) return;

    setSubmitting(true);
    sendCode({ email })
      .then(() => {
        setSubmitting(false);
        setErrorMessage(undefined);
        setViewState('verify');
        window.scrollTo(0, 0);
      })
      .catch((error: ApiErrorResponse) => {
        setSubmitting(false);
        handleError({ email, error });
      });
  };

  const handleError = ({ error, email }: { error: ApiErrorResponse; email?: string }) => {
    const { type, message } = error;
    if (type === 'HivenException::EmailNotAuthorized') {
      setErrorMessage(
        <Layout
          marginTop={xs ? 34 : 24}
          justify={xs ? 'center' : undefined}
          color="red-200"
          paddingY={8}
          paddingX={12}
          borderRadius={8}
        >
          <Text size="body-xs" color="red-700" align={xs ? 'center' : undefined} wrap>
            We couldn't find an existing user with {email}. Please try again or{' '}
            <Link href={`mailto:${SUPPORT_EMAIL}`}>
              <Text size="body-xs" color="blue-500">
                contact us
              </Text>
            </Link>
            .
          </Text>
        </Layout>
      );
    } else if (type === 'HivenException::AuthenticationError') {
      setErrorMessage(
        <Layout
          marginTop={xs ? 34 : 24}
          justify={xs ? 'center' : undefined}
          color="red-200"
          paddingY={8}
          paddingX={12}
          borderRadius={8}
        >
          <Text size="body-xs" color="red-700" align={xs ? 'center' : undefined} wrap>
            {message}
          </Text>
        </Layout>
      );
    }
  };

  const title = authFlowType === 'default' ? 'Sign in' : 'Create your account.';
  const subtitle =
    authFlowType === 'default'
      ? 'Choose from one of the following options.'
      : 'Choose from one of the following options to create your account.';

  return (
    <>
      <Head title="Sign In" description="Sign in to your Codi account." />
      <Layout marginTop={24} />
      <Text size={xs ? 'h6' : 'headline-lg'}>{title}</Text>
      <Layout marginTop={xs ? 22 : 24} />
      <Text size="body-md" align={xs ? 'center' : undefined}>
        {subtitle}
      </Text>
      {errorMessage && <>{errorMessage}</>}
      <Layout marginTop={xs ? 34 : 24} />
      <Layout width="100%" height={xs ? 56 : 64}>
        <GoogleButton
          handleSuccess={handleSuccess}
          handleError={error => handleError({ error })}
          setSubmitting={setSubmitting}
          submitting={submitting}
          size={xs ? 'sm' : 'md'}
          fullWidth
        />
      </Layout>
      <Layout marginY={xs ? 24 : 32} width="100%" justify="space-evenly" align="center" direction="row">
        <Layout borderBottom borderColor="gray-50" flexGrow={1} />
        <Layout marginX={16}>
          <Text size="body2" color="gray-500">
            OR
          </Text>
        </Layout>
        <Layout borderBottom borderColor="gray-50" flexGrow={1} />
      </Layout>
      {authFlowType === 'default' ? (
        <form
          onSubmit={e => {
            e.preventDefault();
            handleSubmitEmail();
          }}
          style={{ width: '100%' }}
        >
          <Layout width="100%">
            <EmailInput
              label="Work email"
              value={email}
              onChange={({ value }) => {
                setEmailError('');
                setEmail(value.toLowerCase());
              }}
              onValidate={({ valid }) => setValid(valid)}
              size={xs ? 'sm' : 'md'}
              error={emailError}
            />
          </Layout>
          <Layout marginTop={16} />
          <Button
            onClick={handleSubmitEmail}
            type="primary"
            text="Continue"
            size={xs ? 'sm' : 'md'}
            disabled={submitting}
            showSpinner={submitting}
            fullWidth
          />
        </form>
      ) : (
        <Button
          text="Continue with email"
          type="primary"
          size={xs ? 'sm' : 'md'}
          onClick={handleSubmitEmail}
          disabled={submitting}
          showSpinner={submitting}
          fullWidth
        />
      )}
      <Layout marginTop={32} />
      <Layout width="100%" justify={xs ? 'center' : 'flex-start'}>
        <Text size="body-xs" color="gray-500" align={xs ? 'center' : undefined}>
          By continuing, you agree to Codi’s{' '}
          <Link href="/terms">
            <Text color="gray-500" size="body-xs" underline inline>
              Terms of Service
            </Text>
          </Link>{' '}
          and{' '}
          <Link href="/privacy">
            <Text color="gray-500" size="body-xs" underline inline>
              Privacy Policy
            </Text>
          </Link>
          .
        </Text>
      </Layout>
    </>
  );
};

export default SignIn;
