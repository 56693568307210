import { ApiErrorResponse, CheckinInstruction, Workspace } from 'shared/types';
import { EditableCheckinInstruction, EditableWorkspace, SavedWorkspace } from './types';

import { yyyyMmDdToDate } from 'shared/time';

export function workspaceToEditableWorkspace({
  first_hosting_availability,
  access_info,
  ...workspace
}: Workspace): EditableWorkspace {
  return {
    ...workspace,
    first_hosting_availability: transformFirstHostingAvailability(first_hosting_availability),
    access_info: {
      ...access_info,
      checkin_instructions: fillCheckinInstructions(access_info.checkin_instructions),
      vendor_checkin_instructions: fillCheckinInstructions(access_info.vendor_checkin_instructions)
    },
    editableImages: [...workspace.images]
  };
}

export function fillCheckinInstructions(checkinInstructions: CheckinInstruction[]): EditableCheckinInstruction[] {
  return new Array(3)
    .fill(0)
    .map((_, index) => checkinInstructions[index] || { ...EMPTY_INSTRUCTION, sequence: index + 1 })
    .sort((i1, i2) => i1.sequence - i2.sequence);
}

export function transformFirstHostingAvailability(firstHostingAvailability: string | null): string | undefined {
  return firstHostingAvailability ? yyyyMmDdToDate(firstHostingAvailability).toISOString() : undefined;
}

export const EMPTY_INSTRUCTION = {
  description: null,
  photo_url: null
};

export function checkinInstructionValid({ description }: EditableCheckinInstruction): boolean {
  return typeof description === 'string' && description.length > 0;
}

export function workspaceToSavedWorkspace(workspace: Workspace): SavedWorkspace {
  return {
    ...workspace,
    workspace_features: workspace.workspace_features,
    editableImages: [...workspace.images],
    access_info: {
      ...workspace.access_info,
      checkin_instructions: fillCheckinInstructions(workspace.access_info?.checkin_instructions || []),
      vendor_checkin_instructions: fillCheckinInstructions(workspace.access_info?.vendor_checkin_instructions || [])
    }
  };
}

export function isApiErrorResponse(error: any): error is ApiErrorResponse {
  if (typeof error !== 'object') return false;

  const { data, message, type, status } = error;

  return (
    typeof data === 'object' && typeof message === 'string' && typeof type === 'string' && typeof status === 'number'
  );
}
