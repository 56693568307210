import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { WHITE_HEX } from 'shared';

import { logout } from 'helpers/auth';

import { app } from 'context';
import {
  Avatar,
  Clickable,
  Layout,
  NavatarSecondaryItem,
  SIDEBAR_EXPANDED_WIDTH,
  SIDEBAR_PRIMARY_WIDTH,
  Text
} from 'ds';
import { useOnClickOutside } from 'ds/helpers';
import { ADMIN_PATH, APP_ROOT_PATH, PROPERTIES_PATH } from 'routes';
import { selectUIState } from 'store/UI/selectors';
import { selectCustomerAdminPlans, selectHasPropertiesNavItem, selectIsAdmin, selectUser } from 'store/User/selectors';
import { useAppSelector } from 'store/hooks';
import { ACCOUNT_NAV_ITEMS, MEMBERSHIP_NAV_ITEM, RESOURCE_NAV_ITEMS } from 'ux/Layouts/Shared/Navigation/constants';

import NavatarMenuItem from './NavatarMenuItem';
import { getUXSwitcherNavItems } from '../utils';

interface Props {
  avatarUrl: string;
  isExpanded?: boolean;
  menuOpen: boolean;
  setMenuOpen: (menuOpen: boolean) => void;
  setIsExpanded: (isExpanded: boolean) => void;
}

const Navatar: React.FC<Props> = ({ avatarUrl, isExpanded, menuOpen, setMenuOpen, setIsExpanded }) => {
  const { sidebarIsExpanded: contextSidebarIsExpanded } = useContext(app);
  const ref = useRef<HTMLDivElement | null>(null);
  let secondaryItems: NavatarSecondaryItem[] | undefined = undefined;
  const { pathname } = useLocation();
  const isAdmin = useAppSelector(selectIsAdmin);
  const isAppPath = pathname.startsWith(APP_ROOT_PATH);
  const isAdminPath = pathname.startsWith(ADMIN_PATH);
  const resourceItems = [...RESOURCE_NAV_ITEMS.map(({ iconName: _, ...item }) => ({ ...item }))];
  const history = useHistory();
  const user = useAppSelector(selectUser);
  const [animationEnabled, setAnimationEnabled] = useState<boolean>(false);
  const reduxUxType = useAppSelector(selectUIState).uxType;
  const isPropertyUX = pathname.startsWith(PROPERTIES_PATH) || reduxUxType === 'property';
  const isAdminUX = pathname.startsWith(ADMIN_PATH) || reduxUxType === 'admin';
  const hasPropertiesNavItem = useAppSelector(selectHasPropertiesNavItem);
  const isPublicPage = !isAppPath && !isAdminPath;
  const customerAdminPlans = useAppSelector(selectCustomerAdminPlans);
  const hasActivePlans = !!customerAdminPlans?.long_term_plans.filter(p => !!p.active_workspace_id).length;

  let navItems = getUXSwitcherNavItems({
    isPublicPage,
    isAdmin,
    isAdminUX,
    isPropertyUX,
    hasPropertiesNavItem
  });

  secondaryItems = resourceItems;

  if (!isAdminUX) {
    if (hasActivePlans) {
      navItems.push(MEMBERSHIP_NAV_ITEM);
    }

    navItems = navItems.concat(ACCOUNT_NAV_ITEMS);
  }

  useOnClickOutside(ref, () => {
    if (!menuOpen) return;

    setMenuOpen(false);

    if (!contextSidebarIsExpanded) {
      setIsExpanded(false);
    }
  });

  useEffect(() => {
    // This is a hack to prevent the animation from running on page load
    setTimeout(() => setAnimationEnabled(true), 300);
  }, []);

  return (
    <Layout position="relative" width={SIDEBAR_PRIMARY_WIDTH} zIndex={2} __ref={ref}>
      <Clickable
        name="navatar"
        onClick={() => setMenuOpen(!menuOpen)}
        style={{ display: 'flex', paddingLeft: 6, alignItems: 'center' }}
      >
        <Avatar
          avatarUrl={avatarUrl}
          size={24}
          borderWidth={menuOpen ? 2 : 1}
          hoverBorderWidth={2}
          borderColor="white"
          outlineColor={menuOpen ? 'rgba(0, 0, 0, 0.2)' : WHITE_HEX}
        />
        {isExpanded && (
          <Layout
            marginLeft={10}
            __className="NavIcon-label"
            __style={animationEnabled ? { animationDuration: `${120 + 8 * 4}ms` } : {}}
          >
            <Text size="body3" color="gray-400" ellipsis>
              {user?.email}
            </Text>
          </Layout>
        )}
      </Clickable>
      {menuOpen && (
        <Layout
          paddingLeft={16}
          position="absolute"
          left={32 + (isExpanded ? SIDEBAR_EXPANDED_WIDTH : SIDEBAR_PRIMARY_WIDTH) - 2 * 12 - 24}
          bottom={-12}
        >
          <Layout
            transition="left 200ms ease-in-out"
            width={260}
            color="white"
            borderRadius={8}
            boxShadow="2px 0px 22px 0px #57496D1F"
            overflow="hidden"
          >
            {!!navItems.length && (
              <Layout paddingY={8} borderBottom>
                {navItems.map(item => {
                  return (
                    <NavatarMenuItem
                      {...item}
                      paddingY={16}
                      setMenuOpen={setMenuOpen}
                      textSize="body2"
                      key={item.key}
                    />
                  );
                })}
              </Layout>
            )}
            {!!secondaryItems.length && (
              <Layout paddingY={8} borderBottom>
                {secondaryItems.map(item => {
                  return (
                    <NavatarMenuItem {...item} paddingY={8} textSize="body3" setMenuOpen={setMenuOpen} key={item.key} />
                  );
                })}
              </Layout>
            )}
            <Layout paddingY={8}>
              <Clickable
                style={{ display: 'block', width: '100%' }}
                onClick={() => {
                  logout({ history });
                  setMenuOpen(false);
                }}
                context="Navatar Sign Out"
              >
                <Layout paddingY={12} paddingX={12} hoverColor="blue-50">
                  <Text semibold={false} color="gray-900" align="left" size="body3">
                    Sign Out
                  </Text>
                </Layout>
              </Clickable>
            </Layout>
          </Layout>
        </Layout>
      )}
    </Layout>
  );
};

export default Navatar;
