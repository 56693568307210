import { useEffect } from 'react';
import { ValidationResult } from 'shared';

import { ValidateProp } from './types';

interface Params<T> extends ValidateProp {
  value?: T;
  validate: (value?: T) => ValidationResult;
  setErrorMessage: (message?: string) => void;
}

export default function useValidate<T>({ validate, value, setErrorMessage, onValidate }: Params<T>) {
  useEffect(() => {
    const validationResult = validate(value);
    value && setErrorMessage(validationResult.error);
    onValidate && onValidate(validationResult);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
}
