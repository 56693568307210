import React from 'react';
import { START_OF_CODI_EPOCH } from 'shared';
import { Placement } from '@popperjs/core';

import { HEADER_INPUT_SIZE } from './constants';
import Card from '../Card';
import DatePicker from '../DatePicker/DatePicker';
import Layout from '../Layout';
import TextButton from '../TextButton';

interface Props {
  startDate?: Date;
  endDate?: Date;
  reset: VoidFunction;
  setEndDate: (endDate?: Date | null) => void;
  setStartDate: (startDate?: Date | null) => void;
  placement?: Placement;
  setCalendarStartElement: (element: HTMLDivElement | null) => void;
  setCalendarEndElement: (element: HTMLDivElement | null) => void;
}

// TODO: reconcile displayed column date and filter date timezones
const DateRange: React.FC<Props> = ({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  reset,
  placement,
  setCalendarStartElement,
  setCalendarEndElement
}) => {
  return (
    <Card>
      <Layout>
        <DatePicker
          label="From"
          size={HEADER_INPUT_SIZE}
          onChange={date => setStartDate(date)}
          selected={startDate}
          minDate={START_OF_CODI_EPOCH}
          dateTimeFormat="SHORT"
          allowPastDates
          placement={placement}
          setCalendarElement={setCalendarStartElement}
        />
      </Layout>
      <Layout marginTop={8}>
        <DatePicker
          label="To"
          size={HEADER_INPUT_SIZE}
          onChange={date => setEndDate(date)}
          selected={endDate}
          minDate={START_OF_CODI_EPOCH}
          dateTimeFormat="SHORT"
          allowPastDates
          placement={placement}
          setCalendarElement={setCalendarEndElement}
        />
      </Layout>
      <Layout marginTop={8}>
        <TextButton scale textSize="body3" text="Clear" color="gray-600" paddingY={false} onClick={reset} />
      </Layout>
    </Card>
  );
};

export default DateRange;
