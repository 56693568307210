import React, { PropsWithChildren, useContext, useEffect, useRef, useState } from 'react';
import { BLUE_500_HEX, COLORS, DateTimeFormat, GRAY_50_HEX, GRAY_900_HEX } from 'shared';
import { Placement } from '@popperjs/core';
import classNames from 'classnames';
import ReactDOM from 'react-dom';
import { usePopper } from 'react-popper';

import { media } from 'context';
import Button from 'ds/Button';
import Layout from 'ds/Layout';
import Modal from 'ds/Modal';
import TextButton from 'ds/TextButton';
import { BUTTON_TYPE_CONFIG, DS_CLASS_NAME, IS_TOUCH_DEVICE } from 'ds/constants';
import { useOnClickOutside } from 'ds/helpers';
import { ButtonType, FieldSize } from 'ds/types';
import { bgColorClass, getDatePickerPortalElement, hoverBgColorClass } from 'ds/utils';
import useFieldSize from 'hooks/useFieldSize';
import { track } from 'lib/analytics';

import DatePickerDisplay from './DatePickerDisplay';
import { CalendarDisplayState } from './types';
import { formatDates } from './utils';

interface Props {
  selected?: Date | null;
  startDate?: Date | null;
  endDate?: Date | null;
  size?: FieldSize;
  isRange?: boolean;
  required?: boolean;
  hideIcon?: boolean;
  buttonType?: ButtonType;
  onOpen?: VoidFunction;
  onClose?: VoidFunction;
  dateTimeFormat?: DateTimeFormat;
  disabled?: boolean;
  isBottomSheet?: boolean;
  isFixedWidth?: boolean;
  calendarState: CalendarDisplayState;
  setCalendarState: (state: CalendarDisplayState) => void;
  placeholder?: string;
  onClear: VoidFunction;
  calendarClassName: string;
  type?: 'marketing';
  label?: string;
  placement?: Placement;
  setCalendarElement?: (calendar: HTMLDivElement | null) => void;
  bottomSheetZIndexPosition?: number;
  isReadOnly?: boolean;
}

const CLEAR_BUTTON_X_FROM_RIGHT_EDGE = {
  xxs: 36,
  xs: 36,
  sm: 42,
  md: 44,
  lg: 54
};

const DatePickerContainer: React.FC<PropsWithChildren<Props>> = ({
  selected,
  startDate,
  endDate,
  dateTimeFormat,
  isRange,
  required,
  hideIcon,
  disabled = false,
  buttonType,
  onOpen,
  isFixedWidth = true,
  type = 'default' as const,
  children,
  calendarState,
  setCalendarState,
  onClear,
  calendarClassName,
  placement = 'bottom-start',
  setCalendarElement,
  bottomSheetZIndexPosition,
  isReadOnly,
  ...props
}) => {
  const scaledSize = useFieldSize();
  const size = props.size || scaledSize;
  const ref = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const { xs } = useContext(media);
  const isCalendarVisible = calendarState === 'opening' || calendarState === 'visible';
  const isMarketingType = type === 'marketing';
  const isDefaultType = type === 'default';
  const label = isMarketingType ? '' : props.label ? props.label + (required ? ' *' : '') : undefined;
  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
  const { styles, attributes, update } = usePopper(referenceElement, popperElement, {
    placement,
    modifiers: [{ name: 'offset', options: { offset: [0, 8] } }]
  });
  const rootRef = useRef<HTMLElement | null>();
  const portalElement = rootRef.current || getDatePickerPortalElement();

  useEffect(() => {
    rootRef.current = getDatePickerPortalElement();
  }, []);

  const openCalendar = () => {
    if (isBottomSheet) {
      setCalendarState('visible');
    } else {
      setCalendarState('opening');
      update && update();

      setTimeout(() => {
        setCalendarState('visible');
      }, 300);
    }
  };

  const closeCalendar = () => {
    if (isBottomSheet) {
      setCalendarState('hidden');
    } else {
      setCalendarState('closing');

      setTimeout(() => {
        setCalendarState('hidden');
      }, 150);
    }
  };

  const onClose = () => {
    closeCalendar();
    props.onClose && props.onClose();
  };

  useOnClickOutside(ref, event => {
    if (
      (buttonRef && buttonRef.current && buttonRef.current.contains(event.target as HTMLElement)) ||
      (popperElement && popperElement.contains(event.target as HTMLElement))
    ) {
      return;
    }

    !IS_TOUCH_DEVICE && !isBottomSheet && isCalendarVisible && onClose();
  });

  const onButtonClick: React.MouseEventHandler<HTMLButtonElement> = e => {
    const buttonRect = e.currentTarget.getBoundingClientRect();
    const clickRight = buttonRect.x + buttonRect.width - e.pageX;

    if (clickRight <= CLEAR_BUTTON_X_FROM_RIGHT_EDGE[size] && displayValue) {
      onClear();
      track('Element Interacted', { type: 'button', name: 'date picker close' });
      if (!isCalendarVisible) {
        openCalendar();
        onOpen && onOpen();
      }
    } else {
      track('Element Interacted', { type: 'button', name: 'date picker' });
      isCalendarVisible ? closeCalendar() : openCalendar();
      onOpen && onOpen();
    }
  };

  const displayValue = formatDates({
    date: selected,
    startDate,
    endDate,
    format: dateTimeFormat
  });
  const placeholder = props.placeholder || 'Select ' + (isRange ? 'dates' : 'a date');
  const showIcon = !buttonType && !hideIcon;
  const isButtonControl = !!buttonType;
  const isBottomSheet = props.isBottomSheet === undefined ? xs : props.isBottomSheet;
  const classes = ['DatePicker', `is-size-${size}`, isBottomSheet ? 'is-bottom-sheet' : 'is-panel'];
  const buttonClasses = [DS_CLASS_NAME, buttonType, `is-${calendarState}`];
  let style;

  if (buttonType) {
    const { textColor, borderWidth, borderColor } = BUTTON_TYPE_CONFIG[buttonType];
    style = {
      color: textColor,
      borderWidth,
      borderColor: borderColor ? COLORS[borderColor] : undefined,
      borderStyle: 'solid'
    };
  } else if (isDefaultType) {
    style = {
      color: GRAY_900_HEX,
      border: isCalendarVisible ? `2px solid ${BLUE_500_HEX}` : `1px solid ${GRAY_50_HEX}`
    };

    if (!isReadOnly) {
      buttonClasses.push(hoverBgColorClass('gray-50'), bgColorClass('white'));
    }
  }

  return (
    <div ref={setReferenceElement}>
      <div
        className={classNames(DS_CLASS_NAME, classes, {
          'is-button-control': isButtonControl,
          'has-icon': showIcon,
          'is-filled': !!displayValue,
          'is-disabled': disabled,
          'is-fixed-width': isFixedWidth,
          'is-marketing-type': isMarketingType,
          'is-default-type': isDefaultType,
          'is-bottom-sheet': isBottomSheet,
          'has-small-label': label,
          'is-read-only': isReadOnly
        })}
        ref={ref}
      >
        {isReadOnly ? (
          <div
            className={classNames(
              buttonClasses,
              buttonType ? `bg-color-${BUTTON_TYPE_CONFIG[buttonType].color}` : undefined,
              'DatePicker-picker',
              { 'is-marketing-type': isMarketingType, 'is-default-type': isDefaultType, 'is-read-only': isReadOnly }
            )}
            style={style}
          >
            {isButtonControl ? (
              <label className="DatePicker-buttonText">
                {displayValue || label}
                {required ? ' *' : ''}
              </label>
            ) : (
              <DatePickerDisplay
                {...(isMarketingType ? { size: 'sm' } : { size })}
                type={type}
                label={label}
                value={displayValue}
                placeholder={placeholder}
                isCalendarVisible={isCalendarVisible}
                showIcon={showIcon}
                isBottomSheet={isBottomSheet}
                required={required}
                isReadOnly={isReadOnly}
              />
            )}
          </div>
        ) : (
          <button
            onClick={onButtonClick}
            ref={buttonRef}
            className={classNames(
              buttonClasses,
              buttonType ? `bg-color-${BUTTON_TYPE_CONFIG[buttonType].color}` : undefined,
              'DatePicker-picker',
              { 'is-marketing-type': isMarketingType, 'is-default-type': isDefaultType }
            )}
            disabled={disabled}
            style={style}
          >
            {isButtonControl ? (
              <label className="DatePicker-buttonText">
                {displayValue || label}
                {required ? ' *' : ''}
              </label>
            ) : (
              <DatePickerDisplay
                {...(isMarketingType ? { size: 'sm' } : { size })}
                type={type}
                label={label}
                value={displayValue}
                placeholder={placeholder}
                isCalendarVisible={isCalendarVisible}
                showIcon={showIcon}
                isBottomSheet={isBottomSheet}
                required={required}
                isReadOnly={isReadOnly}
              />
            )}
          </button>
        )}
        {isReadOnly ? null : isBottomSheet ? (
          <Modal
            isBottomSheet
            onClose={closeCalendar}
            isVisible={calendarState === 'visible'}
            header=" "
            hasFooterBorder={false}
            bottomSheetZIndexPosition={bottomSheetZIndexPosition}
            footer={
              <>
                <Button text="Apply" size="sm" type="primary" onClick={onClose} fullWidth />
                <Layout marginTop={24} justify="center">
                  <TextButton text="Clear" onClick={onClear} textSize="body2" color="gray-700" />
                </Layout>
              </>
            }
            showCloseButton
          >
            <div className={classNames('CalendarSheet', calendarClassName)}>{children}</div>
          </Modal>
        ) : portalElement ? (
          ReactDOM.createPortal(
            <div
              style={{ ...styles.popper, zIndex: 9998 }}
              {...attributes.popper}
              ref={element => {
                setPopperElement(element);

                if (setCalendarElement) setCalendarElement(element);
              }}
            >
              <div className={classNames('CalendarPanel', calendarClassName, `is-${calendarState}`)}>
                {children}
                <Layout
                  marginTop={12}
                  paddingTop={12}
                  __style={{ borderTop: '1px solid #eff0f3' }}
                  justify={isRange ? 'space-between' : 'flex-end'}
                  width="100%"
                >
                  {isRange &&
                    (startDate ? (
                      <TextButton text="Apply" textSize="body2" paddingY={false} onClick={onClose} color="blue-500" />
                    ) : (
                      <span />
                    ))}
                  <TextButton text="Clear" textSize="body2" paddingY={false} onClick={onClear} color="gray-700" />
                </Layout>
              </div>
            </div>,
            portalElement
          )
        ) : null}
      </div>
    </div>
  );
};

export default DatePickerContainer;
