import React from 'react';
import { Image, Listing } from 'shared';
import pluralize from 'pluralize';
import { ListItem, Offer, SearchResultsPage, WithContext } from 'schema-dts';

import { generateImgSrc } from 'helpers/images';

import { SearchLocation } from './types';

interface Props {
  searchLocation: SearchLocation;
  workspaces: Listing[];
}

function numberWithCommas(x: number) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

const SearchResultsSchema: React.FC<Props> = ({ searchLocation, workspaces }) => {
  let listings: ListItem[] = [];

  workspaces.slice(0, 200).forEach((workspace, index) => {
    listings.push({
      '@type': 'ListItem',
      position: index + 1,
      item: {
        '@type': 'Offer',
        name: workspace.name,
        description: `${numberWithCommas(workspace.square_feet!)} sq. ft. office space offered at $${numberWithCommas(
          workspace.monthly_price!
        )} per month in ${workspace.address.city}, ${workspace.address.region}`,
        category: 'Office',
        url: `${window.location.origin}/listings/${workspace.slug}`,
        price: `$${numberWithCommas(workspace.monthly_price!)} per month`,
        priceCurrency: 'USD',
        image: generateImgSrc({ srcKey: (workspace.images[0] as Image).key, width: 800, height: 600 }),
        availableAtOrFrom: {
          '@type': 'Place',
          address: {
            '@type': 'PostalAddress',
            streetAddress: workspace.address.line1,
            addressLocality: workspace.address.city,
            addressRegion: workspace.address.region,
            postalCode: workspace.address.postal_code,
            addressCountry: workspace.address.country
          }
        }
      } as Offer
    });
  });

  const locationString =
    searchLocation.type === 'Neighborhood'
      ? `${searchLocation.name}, ${searchLocation.submarket?.name}, ${searchLocation.state}`
      : `${searchLocation.name}, ${searchLocation.state}`;

  const description =
    (workspaces.length > 0
      ? `Browse ${workspaces.length} ${pluralize('office space', workspaces.length)} for rent in ${locationString}`
      : `Office spaces for rent in ${locationString}`) +
    '. Move-in ready and furnished, with meeting rooms and flexible terms that grow with your business.';

  const schema: WithContext<SearchResultsPage> = {
    '@context': 'https://schema.org',
    '@type': 'SearchResultsPage',
    description: `${description}`,
    speakable: { '@type': 'SpeakableSpecification', xpath: ["/html/head/meta[@name='description']/@content"] },
    about: listings
  };

  return <script type="application/ld+json">{JSON.stringify(schema)}</script>;
};

export default SearchResultsSchema;
