import React, { useRef } from 'react';
import { EditableImage } from 'shared';

import Clickable from 'ds/Clickable';
import Icon from 'ds/Icon';
import Layout from 'ds/Layout';
import Text from 'ds/Text';

import Grid from './Grid';
import TaskImageUploadThumbnail from './TaskImageUploadThumbnail';
import { Size } from './Text';

interface Props {
  images: EditableImage[];
  onChange: (files: File[]) => void;
  onDeleteClick?: (image: EditableImage) => void;
  disabled?: boolean;
  countPerRow?: number;
  maxImages?: number;
  label?: string;
  labelSize?: Size;
  thumbnailSize?: number;
}

const TaskImageUploader: React.FC<Props> = ({
  onChange,
  onDeleteClick,
  disabled,
  countPerRow,
  maxImages,
  label,
  labelSize = 'body2',
  thumbnailSize,
  ...props
}) => {
  const hiddenFileInputRef = useRef<HTMLInputElement>(null);
  const showFileChooser = () => hiddenFileInputRef.current?.click();

  const images = props.images.filter(i => !i._destroy);

  const input = (
    <input
      ref={hiddenFileInputRef}
      type="file"
      style={{ display: 'none' }}
      accept="image/png, image/jpg, image/heic"
      multiple={maxImages !== 1}
      onChange={event => {
        const files = event.target.files;

        if (!files) return;

        onChange(Array.from(files).slice(0, 12 - images.length));
      }}
    />
  );

  if (!images.length) {
    return (
      <Layout>
        <Clickable onClick={showFileChooser} style={{ display: 'flex', alignItems: 'center' }}>
          <Icon name="attachment" size="xs" color="blue-500" />
          <Text size={labelSize} color="blue-500">
            {label || 'Attach image'}
          </Text>
        </Clickable>
        {input}
      </Layout>
    );
  }

  const addImageThumbnail = (
    <Clickable onClick={showFileChooser} style={{ borderRadius: 8 }} disabled={disabled} fullWidth fullHeight>
      <Layout
        align="center"
        justify="center"
        color="gray-25"
        width={thumbnailSize}
        height={thumbnailSize || 96}
        borderRadius={8}
      >
        <Icon name="plus" size="md" color="gray-200" />
      </Layout>
    </Clickable>
  );

  const imageThumbnails = images.map(image => {
    return (
      <TaskImageUploadThumbnail
        key={image.id || image.url}
        dimension={thumbnailSize || 96}
        disabled={disabled}
        image={image}
        onDeleteClick={!!onDeleteClick ? () => onDeleteClick(image) : undefined}
      />
    );
  });

  return (
    <Layout>
      {countPerRow ? (
        <Grid itemsPerRow={countPerRow} gap={8}>
          {imageThumbnails}
          {images.length < (maxImages || 12) && addImageThumbnail}
        </Grid>
      ) : (
        <Layout gap={6} flex>
          {imageThumbnails}
        </Layout>
      )}
      {input}
    </Layout>
  );
};

export default TaskImageUploader;
