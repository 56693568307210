import React, { FocusEventHandler, forwardRef, useContext } from 'react';
import { GRAY_900_HEX } from 'shared';

import { app, media } from 'context';
import { Animate, Icon, Layout } from 'ds';

interface Props {
  onChange: (value: string) => void;
  value: string;
  placeholder: string;
  handleClose?: VoidFunction;
  onFocus?: FocusEventHandler<HTMLInputElement>;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  paddingBottom?: number;
}

const OfficeRequestInput = forwardRef<HTMLInputElement, Props>(
  ({ onChange, value, onFocus, placeholder, onBlur, paddingBottom }, ref) => {
    const { contentPaddingX } = useContext(app);
    const { xs, sm } = useContext(media);
    const isBottomSheet = xs || sm;
    const Content = (
      <Layout paddingBottom={paddingBottom}>
        <Layout align="center">
          <Icon size={isBottomSheet ? 'md' : 'xl'} name="addressPinOutline" color="gray-900" />
          <input
            onFocus={onFocus}
            autoComplete="off"
            placeholder={placeholder}
            autoFocus={!isBottomSheet}
            ref={ref}
            spellCheck={false}
            style={{
              borderStyle: 'none',
              fontSize: isBottomSheet ? 18 : 24,
              lineHeight: isBottomSheet ? '28px' : '36px',
              outline: 'none',
              background: 'none',
              color: GRAY_900_HEX,
              zIndex: 1,
              width: '100%',
              marginLeft: isBottomSheet ? 8 : 16
            }}
            value={value}
            onChange={({ target: { value } }) => onChange(value)}
            onBlur={onBlur}
          />
        </Layout>
        <Layout
          __style={{
            background: 'linear-gradient(94.99deg, #1AB2D9 28.48%, #0078FF 141.74%)'
          }}
          height={2}
          marginTop={12}
        />
      </Layout>
    );

    return isBottomSheet ? <Layout paddingX={contentPaddingX}>{Content}</Layout> : <Animate>{Content}</Animate>;
  }
);
export default OfficeRequestInput;
