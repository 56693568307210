import React, { useContext } from 'react';
import { IconName } from 'shared';

import { media } from 'context';
import { FullWidthBackground, Icon, Layout, Text } from 'ds';

import PlaybookCTA from './PlaybookCTA';

interface Props {}

const VALUE_PROPS: { icon: IconName; text: string }[] = [
  { icon: 'building', text: 'Three kinds of office leases: traditional, coworking, and flexible' },
  { icon: 'payout', text: 'Lease negotiations: how to get the best deal' },
  { icon: 'search', text: 'Mistakes to avoid when choosing a private office for the first time' },
  { icon: 'scale', text: 'The best way to break a long-term lease' },
  { icon: 'clipboard', text: 'A list of super sneaky lease terms to avoid' },
  { icon: 'starOutline', text: 'Tips on managing your office like a pro' }
];

const Included: React.FC<Props> = () => {
  const { xs } = useContext(media);

  return (
    <FullWidthBackground color="blue-800">
      <Layout paddingTop={xs ? 60 : 144} paddingBottom={xs ? 72 : 144} flexGrow={1} direction="column" align="center">
        <Text size="h4" color="white" scale>
          Included in the Playbook
        </Text>
        <Layout
          color="white"
          width="100%"
          {...(xs
            ? { marginTop: 32, borderRadius: 12, paddingTop: 40, paddingBottom: 28, paddingX: 24 }
            : { maxWidth: 900, marginTop: 44, borderRadius: 24, paddingTop: 40, paddingBottom: 52, paddingX: 40 })}
        >
          {VALUE_PROPS.map(({ icon, text }, index) => (
            <Layout marginTop={index > 0 ? 12 : undefined} direction="row" align="center" key={icon}>
              <Layout justify="center" align="center" width={56} height={56} minWidth={56} minHeight={56}>
                <Icon name={icon} color="blue-gradient" size="xl" />
              </Layout>
              <Layout marginLeft={12} />
              <Text color="gray-700" size={xs ? 'body1' : 'h6'} notBold scale>
                {text}
              </Text>
            </Layout>
          ))}
        </Layout>
        <Layout marginTop={xs ? 32 : 44} width="100%" justify="center">
          <PlaybookCTA />
        </Layout>
      </Layout>
    </FullWidthBackground>
  );
};

export default Included;
