import React, { useRef, useState } from 'react';
import { ValidationResult, validateNumber } from 'shared';
import { usePreviousValue } from 'beautiful-react-hooks';
import { MaskedNumber, MaskedPattern, createMask } from 'imask';

import Input from './Input';
import { BasePropsNumber, InputImageIconProps, InputProps, OnBlurFocus, OnChangeParams, ValidateProp } from './types';
import useMask from './useMask';
import useValidate from './useValidate';
import { ReactInputProps } from '../types';

export type Props = BasePropsNumber &
  InputProps &
  InputImageIconProps &
  ValidateProp & {
    min?: number;
    max?: number;
    step?: number;
    cents?: boolean;
    prefix?: string;
    suffix?: string;
    validate?: (params: { value?: number | null }) => ValidationResult;
  };

const INTEGER_CONFIG = { mask: Number, signed: false, thousandsSeparator: ',', mapToRadix: [''], scale: 0 };

export const WITH_CENTS_CONFIG = {
  mask: 'num',
  blocks: {
    num: {
      mask: Number,
      signed: false,
      thousandsSeparator: ',',
      radix: '.',
      mapToRadix: ['.'],
      scale: 2
    }
  },
  min: 0
};

export const WITHOUT_CENTS_CONFIG = {
  mask: 'num',
  blocks: {
    num: {
      ...INTEGER_CONFIG
    }
  },
  min: 0
};

const MoneyInput: React.FC<Props> = ({
  min = 0,
  max = Number.MAX_SAFE_INTEGER / 100,
  name,
  label,
  placeholder,
  value,
  onChange,
  onBlur,
  error,
  size,
  onValidate,
  validateOnBlur = true,
  required = true,
  step = 1,
  cents = true,
  disabled,
  hasErrorHeight,
  icon,
  suffix,
  hideLabel,
  prefix = '$',
  isReadOnly,
  inputStyle,
  inlineLabelWidth,
  showErrorMessage,
  ...props
}) => {
  const [padFractionalZeros, setPadFractionalZeros] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const imask = createMask(cents ? WITH_CENTS_CONFIG : WITHOUT_CENTS_CONFIG);
  const maskInputValue = numberValueToString({ value, cents });
  const [mask] = useMask({ mask: imask, value: maskInputValue });
  const previousMaskedValue = usePreviousValue(mask.value);
  const [inputValueWithCents, setInputValueWithCents] = useState<string>();
  const inputRef = useRef<HTMLInputElement>(null);
  const maskedValue = getMaskedValue({ mask, value, cents });
  let inputValue = value === undefined ? '' : inputValueWithCents || maskedValue;
  inputValue = !inputValue ? '' : padFractionalZeros && cents ? addTrailingZeros(inputValue) : inputValue;

  const validate = (value?: number): ValidationResult => {
    if (value === undefined) {
      return required ? { valid: false, error: 'Please enter a valid amount' } : { valid: true };
    } else if (props.validate) {
      return props.validate({ value });
    } else {
      return validateNumber({ num: value, min, max, currency: true });
    }
  };

  useValidate<number>({ value: value === null ? undefined : value, onValidate, validate, setErrorMessage });

  const onChangeWrapper = ({ event, ...params }: OnChangeParams) => {
    setPadFractionalZeros(false);
    // Handle case where user pastes value including $ into input with value $0
    let updatedValue = params.value.replaceAll('$', '');

    const wasPasted = updatedValue.length - (inputValue.length || 0) > 2;

    const parts = updatedValue.split('.');

    const centsValue = parts.length >= 1 ? parts[1] : '';

    if (wasPasted) {
      setInputValueWithCents(updatedValue);
    } else {
      // User typed decimal and there was already a decimal
      if (updatedValue.split('').filter(v => v === '.').length > 1) return;

      // User typed decimal and cents should not be shown
      if (updatedValue.slice(-1)[0] === '.' && !cents) return;

      // User typed third decimal
      if (centsValue?.length > 2) return;

      if (
        updatedValue.endsWith('.') &&
        ((previousMaskedValue && !previousMaskedValue.endsWith('.')) ||
          (previousMaskedValue === undefined && !updatedValue.toString().includes('.')))
      ) {
        setInputValueWithCents(updatedValue);
        mask.value = updatedValue;
      } else if (updatedValue.includes('.') && !(previousMaskedValue || '').endsWith('.') && centsValue.length <= 2) {
        setInputValueWithCents(updatedValue);
        mask.value = updatedValue;
      } else {
        setInputValueWithCents(undefined);
      }
    }

    mask.value = updatedValue;
    const unmaskedValue = inputValueToNumber({ value: mask.unmaskedValue });

    if (typeof max === 'number' && typeof unmaskedValue === 'number' && unmaskedValue > max) {
      return;
    }

    if (mask.unmaskedValue === '') {
      onChange({ event, value: undefined });
    } else {
      onChange({ event, value: unmaskedValue });
    }

    const validationResult = validate(unmaskedValue);

    if (validationResult.valid) {
      setErrorMessage(undefined);
    } else if (onValidate) {
      onValidate(validationResult);
    } else if (validationResult.error) {
      setErrorMessage(validationResult.error);
    }
  };

  const handleKeyDown: ReactInputProps['onKeyDown'] = ({ key }) => {
    const input = inputRef.current;

    if (!input) return;

    if (key !== 'ArrowUp' && key !== 'ArrowDown') return;

    const unmaskedValue = mask.unmaskedValue;
    const numberValue = inputValueToNumber({ value: unmaskedValue }) || 0;

    let newValue = key === 'ArrowUp' ? numberValue + step : numberValue - step;
    if (typeof max === 'number' && newValue > max) {
      newValue = max;
    } else if (typeof min === 'number' && newValue < min) {
      newValue = min;
    }

    const unformatted = numberValueToString({ value: newValue, cents });
    mask.resolve(unformatted);
    const newInputValue = mask.value;

    const valueSetter = Object.getOwnPropertyDescriptor(input, 'value')?.set;
    const prototype = Object.getPrototypeOf(input);
    const prototypeValueSetter = Object.getOwnPropertyDescriptor(prototype, 'value')?.set;
    if (valueSetter && valueSetter !== prototypeValueSetter && prototypeValueSetter) {
      prototypeValueSetter.call(input, newInputValue);
    } else if (valueSetter) {
      valueSetter.call(input, newInputValue);
    }

    input.dispatchEvent(new Event('input', { bubbles: true }));
  };

  const onBlurWrapper: OnBlurFocus<HTMLInputElement> = ({ event, value }) => {
    setPadFractionalZeros(true);
    mask.value = value;

    const unmaskedValue = inputValueToNumber({ value: mask.unmaskedValue });

    if (validateOnBlur) setErrorMessage(validate(unmaskedValue).error);

    onBlur && onBlur({ event, value: unmaskedValue });
  };

  return (
    <Input
      inputStyle={inputStyle}
      inlineLabelWidth={inlineLabelWidth}
      name={name}
      value={inputValue}
      type="tel"
      label={label}
      placeholder={placeholder}
      onChange={onChangeWrapper}
      onBlur={onBlurWrapper}
      size={size}
      error={error || errorMessage}
      required={required}
      disabled={disabled}
      step={step}
      ref={inputRef}
      onKeyDown={handleKeyDown}
      inputMode="numeric"
      hasErrorHeight={hasErrorHeight}
      icon={icon}
      suffix={suffix}
      hideLabel={hideLabel}
      prefix={prefix}
      isReadOnly={isReadOnly}
      showErrorMessage={showErrorMessage}
    />
  );
};

const numberValueToString = ({ value, cents }: { value?: number | null; cents?: boolean }) => {
  return typeof value === 'number' ? value.toFixed(cents ? 2 : 0) : '';
};

const inputValueToNumber = ({ value }: { value: string }) => {
  if (value === '') return undefined;

  const numberValue = Number(value);

  return isNaN(numberValue) ? undefined : numberValue;
};

const getMaskedValue = ({
  mask,
  value,
  cents
}: {
  value?: number | null;
  cents?: boolean;
  mask: MaskedPattern<string> | MaskedNumber;
}) => {
  const maskInputValue = numberValueToString({ value, cents });
  mask.resolve(maskInputValue);

  return mask.value;
};

const addTrailingZeros = (value: string) => {
  if (!value.includes('.')) {
    return value + '.00';
  } else if (value.split('.')[1].length === 1) {
    return value + '0';
  } else {
    return value;
  }
};

export default MoneyInput;
