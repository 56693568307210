import React, { useEffect, useState } from 'react';
import { filterByInclusion } from 'shared';

import ComboBox, { ComboxBoxProps } from 'ds/ComboBox';
import NoMatchesItem from 'ds/ComboBox/NoMatchesItem';
import Layout from 'ds/Layout';
import { actions } from 'store/UI';
import { selectDefaultTypeaheadResults } from 'store/UI/selectors';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import WorkspaceResult from './WorkspaceResult';
import { SearchableWorkspace } from './types';
import { Props as TextInputProps } from '../inputs/TextInput';

export interface Props
  extends Pick<TextInputProps, 'size' | 'placeholder' | 'disabled' | 'required' | 'isReadOnly'>,
    ComboxBoxProps {
  label?: string;
  workspaces: SearchableWorkspace[];
  selectedWorkspaceId?: number;
  errorMessage?: string;
  onSelect: (workspace: SearchableWorkspace | undefined) => void;
  clearQueryAfterSelect?: boolean;
  /** Used to show default typeahead results */
  name?: string;
}

const WorkspaceComboBox: React.FC<Props> = ({
  size,
  workspaces,
  selectedWorkspaceId,
  errorMessage,
  onSelect,
  label = 'Workspace',
  placeholder = 'Search by street address, workspace ID, or listing URL',
  disabled,
  required = true,
  clearQueryAfterSelect,
  isReadOnly,
  readOnlyHref,
  name
}) => {
  const defaultIds = useAppSelector(state => selectDefaultTypeaheadResults(state, name)).ids;
  const dispatch = useAppDispatch();
  const workspaceQueryFormatted = (workspace?: SearchableWorkspace) => {
    if (!workspace) return undefined;

    const { street_address, city_region_zip, id } = workspace;
    return `${street_address}, ${city_region_zip} (${id})`;
  };
  const [workspaceQuery, setWorkspaceQuery] = useState<string | undefined>(
    workspaceQueryFormatted(workspaces.find(w => w.id === selectedWorkspaceId))
  );
  const [width, setWidth] = useState<number>(0);

  const selectedWorkspace = workspaces.find(w => w.id === selectedWorkspaceId);
  const formattedWorkspaceQuery = selectedWorkspace ? workspaceQueryFormatted(selectedWorkspace) : undefined;

  useEffect(() => {
    if (!formattedWorkspaceQuery) {
      setWorkspaceQuery(undefined);
      return;
    }

    setWorkspaceQuery(formattedWorkspaceQuery);
  }, [formattedWorkspaceQuery]);

  const resultWorkspaces =
    selectedWorkspace && defaultIds.length
      ? (defaultIds.map(id => workspaces.find(ws => ws.id === id)).filter(Boolean) as SearchableWorkspace[])
      : workspaceQuery
      ? workspaces.filter(
          workspace =>
            filterByInclusion(workspace.id.toString(), workspaceQuery) ||
            filterByInclusion(workspace.street_address, workspaceQuery)
        )
      : workspaces;

  const results = resultWorkspaces.map(workspace => ({
    value: workspace.id,
    element: (
      <WorkspaceResult key={workspace.id} size={size} maxWidth={width} query={workspaceQuery} workspace={workspace} />
    )
  }));

  const image = selectedWorkspace?.image
    ? {
        srcKey: selectedWorkspace.image.key,
        alt: selectedWorkspace.street_address,
        folder: selectedWorkspace.image.folder,
        style: { borderRadius: 4 }
      }
    : undefined;

  const handleSelect = (workspace: SearchableWorkspace) => {
    const { id, street_address, city_region_zip } = workspace;

    onSelect(workspace);
    name && dispatch(actions.addDefaultTypeaheadResults({ name, id: workspace.id }));

    if (clearQueryAfterSelect) {
      setWorkspaceQuery(undefined);
    } else {
      setWorkspaceQuery(`${street_address}, ${city_region_zip} (${id})`);
    }
  };

  return (
    <Layout
      onMeasure={({ width }) => {
        setWidth(width);
      }}
    >
      <ComboBox
        query={workspaceQuery}
        results={results}
        noMatchesItem={!!selectedWorkspaceId ? undefined : <NoMatchesItem size={size} width={width} />}
        error={errorMessage}
        size={size}
        label={label}
        bigLabel={placeholder}
        placeholder={placeholder}
        readOnlyHref={readOnlyHref}
        onChange={({ value }) => {
          if (value.startsWith('http')) {
            const url = new URL(value);
            const slug = url.pathname.split('/').slice(-1)[0];

            const matchedWorkspace = workspaces.find(ws => ws.slug === slug || ws.id === parseInt(slug));

            if (matchedWorkspace) {
              setWorkspaceQuery(workspaceQueryFormatted(matchedWorkspace));
              return;
            }
          }
          setWorkspaceQuery(value);
          onSelect(undefined);
        }}
        onSelect={value => {
          const workspace = workspaces.find(workspace => workspace.id === value);

          if (!workspace) {
            setWorkspaceQuery(undefined);
            return;
          }

          handleSelect(workspace);
        }}
        onClear={() => {
          setWorkspaceQuery(undefined);
          onSelect(undefined);
        }}
        highlightMatch
        required={required}
        showResultsWhenEmpty
        hideLabel
        maxVisibleItems={5}
        maxItems={100}
        disabled={disabled && !isReadOnly}
        isReadOnly={isReadOnly}
        image={image}
      />
    </Layout>
  );
};

export default WorkspaceComboBox;
