import { StartupResponse } from 'shared/types';
import axios from 'shared/lib/axios';

function startupPath() {
  return '/v1/startup';
}

export function getStartup() {
  return axios.get<StartupResponse>(startupPath());
}
