import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FeedbackRequest, castToDateTime } from 'shared';
import qs from 'qs';

import { media } from 'context';
import Button from 'ds/Button';
import Clickable from 'ds/Clickable';
import Icon from 'ds/Icon';
import Layout from 'ds/Layout';
import Modal from 'ds/Modal';
import Text from 'ds/Text';
import TextArea from 'ds/inputs/TextArea';
import { selectUser } from 'store/User/selectors';
import { useAppSelector } from 'store/hooks';

import { TASK_TYPE_TO_ICON } from './constants';
import { submitFeedback } from './requests';
import { EditableFeedback } from './types';

interface Props {
  isVisible: boolean;
  onClose: VoidFunction;
  feedbackRequest: FeedbackRequest;
  feedback: EditableFeedback;
}

const FeedbackModal: React.FC<Props> = ({ isVisible, onClose, feedbackRequest, feedback }) => {
  const { xs } = useContext(media);

  const history = useHistory();
  const queryParams = qs.parse(history.location.search, { ignoreQueryPrefix: true });
  const { thumbs_up, thumbs_down } = queryParams;

  const initialLikedValue = thumbs_up !== undefined ? true : thumbs_down !== undefined ? false : !!feedback.liked;
  const [liked, setLiked] = useState<boolean>(initialLikedValue);
  const [message, setMessage] = useState<string>();
  const [requestInProgress, setRequestInProgress] = useState<boolean>();
  const [viewState, setViewState] = useState<'form' | 'success'>('form');

  const user = useAppSelector(selectUser);
  const userId = user?.id;
  if (!userId) return null;

  const { type, date } = feedbackRequest;

  const iconName = TASK_TYPE_TO_ICON[type];
  const dateTime = castToDateTime(date);

  const isValid = liked || (!liked && !!message);

  const handleSubmit = () => {
    if (!isValid || requestInProgress) return;

    setRequestInProgress(true);
    submitFeedback({ userId, feedback: { ...feedback, liked: liked, message: message } })
      .then(() => {
        setViewState('success');
      })
      .finally(() => {
        setRequestInProgress(false);
      });
  };

  return (
    <Modal
      header=" "
      width={xs ? undefined : 500}
      isVisible={isVisible}
      onClose={onClose}
      hasFooterBorder={false}
      animate={true}
      footer={
        !xs ? undefined : viewState === 'form' ? (
          <Button
            fullWidth
            type="primary"
            text="Submit"
            onClick={handleSubmit}
            disabled={!isValid || requestInProgress}
          />
        ) : (
          <Button fullWidth type="primary" text="Got it" onClick={onClose} />
        )
      }
      {...(xs ? { hasBottomSheetPaddingX: true, isBottomSheet: true, bottomSheetIsRounded: true } : { minHeight: 380 })}
    >
      {viewState === 'success' ? (
        <Layout
          direction="column"
          justify={xs ? 'center' : 'space-between'}
          align="center"
          height={xs ? '100%' : undefined}
          marginTop={xs ? 80 : undefined}
        >
          <Layout width={80} height={80} borderRadius="circular" color="blue-gradient" justify="center" align="center">
            <Icon name="checkmark" size="xl" color="white" />
          </Layout>
          <Layout marginTop={xs ? 32 : 16} />
          <Text size="headline-md" align="center">
            {liked ? 'Thanks' : 'Bummer'}, {user.firstname}!
          </Text>
          <Layout marginTop={16} />
          <Layout maxWidth={380}>
            <Text size="body-md" color="gray-700" align="center">
              {liked
                ? 'Your feedback helps us improve our services.'
                : 'We’re looking into your issue and will be in touch. Thanks for sharing. Your feedback helps us improve our services.'}
            </Text>
          </Layout>
          {!xs && (
            <Layout marginTop={32} paddingY={16} paddingX={24}>
              <Button size="md" type="primary" fullWidth={xs} text="Got it" onClick={onClose} />
            </Layout>
          )}
        </Layout>
      ) : (
        <Layout direction="column" justify="space-between" align="center">
          <Layout direction="column" justify="space-between" align="center">
            {xs && (
              <Layout
                marginBottom={24}
                width={96}
                height={96}
                justify="center"
                align="center"
                color="blue-50"
                borderRadius="circular"
              >
                <Layout transform="rotate(13deg)">
                  <Icon name={iconName} size={64} color="blue-500" />
                </Layout>
              </Layout>
            )}
            <Text size={xs ? 'headline-sm' : 'headline-md'}>How was your {type.toLowerCase()} service?</Text>
            <Layout marginTop={xs ? 4 : 16} />
            <Text size="body-sm" color="gray-400" semibold>
              {dateTime.toLocaleString({ weekday: 'long', month: 'long', day: 'numeric' })}
            </Text>
            <Layout marginTop={xs ? 36 : 16} />
            <Layout direction="row" justify="center" align="center">
              <Clickable
                color={liked ? 'blue-500' : 'gray-25'}
                hoverColor={liked ? 'blue-600' : 'gray-50'}
                style={{
                  borderRadius: '50%',
                  width: 76,
                  height: 76,
                  justifyContent: 'center',
                  alignItems: 'center',
                  display: 'inline-flex'
                }}
                onClick={() => setLiked(true)}
                disabled={liked}
                context="thumbsUp-Modal"
              >
                <Icon
                  name={liked ? 'thumbsUpFilled' : 'thumbsUp'}
                  stroke={liked ? 1.5 : 2}
                  color={liked ? 'white' : 'black'}
                  size="lg"
                />
              </Clickable>
              <Layout marginLeft={20} />
              <Clickable
                color={!liked ? 'blue-500' : 'gray-25'}
                hoverColor={!liked ? 'blue-600' : 'gray-50'}
                style={{
                  borderRadius: '50%',
                  width: 76,
                  height: 76,
                  justifyContent: 'center',
                  alignItems: 'center',
                  display: 'inline-flex'
                }}
                onClick={() => setLiked(false)}
                disabled={!liked}
                context="thumbsDown-Modal"
              >
                <Icon
                  name={!liked ? 'thumbsDownFilled' : 'thumbsDown'}
                  stroke={!liked ? 1.5 : 2}
                  color={!liked ? 'white' : 'black'}
                  size="lg"
                />
              </Clickable>
            </Layout>
            <Layout marginTop={xs ? 36 : 16} />
            <Text size="body-sm" semibold>
              {liked ? 'Tell us why you liked our service' : 'Tell us how we can improve our service *'}
            </Text>
            <Layout marginTop={xs ? 12 : 16} />
            <Layout width="100%">
              <TextArea
                size="md"
                minHeight={112}
                value={message}
                onChange={({ value }) => setMessage(value)}
                label="Comment"
                placeholder="Comment"
                hideLabel
              />
            </Layout>
          </Layout>
          {!xs && (
            <Layout marginTop={32} paddingY={16} paddingX={24}>
              <Button
                size="md"
                type="primary"
                fullWidth={xs}
                text="Submit"
                onClick={handleSubmit}
                disabled={!isValid || requestInProgress}
              />
            </Layout>
          )}
        </Layout>
      )}
    </Modal>
  );
};

export default FeedbackModal;
