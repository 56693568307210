import { Animate, Button, ButtonType, FullWidthBackground, Layout, Text } from 'ds';
import React, { useContext } from 'react';

import { Color } from 'shared';
import { OFFICE_REQUEST_PATH } from 'routes';
import { media } from 'context';

interface Props {
  background_color: Color;
  copy: string;
  button_type: 'primary' | 'secondary' | 'secondary-outline';
  button_text: string;
  action: 'sales-form' | 'link';
  href?: string;
  marginTop?: number;
  hasAnimation?: boolean;
}

const ClientCTA: React.FC<Props> = ({
  background_color,
  copy,
  button_type,
  button_text,
  action,
  href,
  marginTop = 64,
  hasAnimation
}) => {
  const { xs, sm } = useContext(media);

  const buttonType: ButtonType = button_type === 'secondary-outline' ? 'secondaryOutline' : button_type;

  const content = (
    <Layout maxWidth={600}>
      <Text size={xs || sm ? 'h4' : 'h3'} color="blue-700" align="center">
        <span dangerouslySetInnerHTML={{ __html: copy }} />
      </Text>
      <Layout marginTop={36} justify="center">
        <Button
          type={buttonType}
          size={xs || sm ? 'md' : 'lg'}
          text={button_text}
          href={action === 'sales-form' ? OFFICE_REQUEST_PATH : action === 'link' ? href : undefined}
          onClick={undefined}
        />
      </Layout>
    </Layout>
  );

  return (
    <FullWidthBackground color={background_color}>
      <Layout width="100%" marginTop={marginTop} paddingY={xs ? 112 : 164} justify="center">
        {hasAnimation ? <Animate>{content}</Animate> : content}
      </Layout>
    </FullWidthBackground>
  );
};

export default ClientCTA;
