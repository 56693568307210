import React from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { Color } from 'shared';
import { useMediaQuery } from 'beautiful-react-hooks';

import Head from 'components/Head';

import { ButtonType } from 'ds';
import { PLAYBOOK_PATH } from 'routes';

import AboutCodi from './AboutCodi';
import AllInTheDetails from './AllInTheDetails';
import BeforeTeamArrives from './BeforeTeamArrives';
import BrokersTours from './BrokersTours';
import CarvingOutNewSpace from './CarvingOutNewSpace';
import ClosingThoughts from './ClosingThoughts';
import Comparison from './Comparison';
import Coworking from './Coworking';
import CoworkingOffices from './CoworkingOffices';
import DayToDay from './DayToDay';
import DecisionFactors from './DecisionFactors';
import DecisionTree from './DecisionTree';
import DesigningYourSpace from './DesigningYourSpace';
import FlexOffices from './FlexOffices';
import FlexSpace from './FlexSpace';
import FlexibleSpaceCompany from './FlexibleSpaceCompany';
import FlexibleSpaceModel from './FlexibleSpaceModel';
import Forward from './Forward';
import Growth1 from './Growth1';
import Growth2 from './Growth2';
import HandyTools from './HandyTools';
import Intro from './Intro';
import KnowYourOptions from './KnowYourOptions';
import LeaseTermsForNegotiation from './LeaseTermsForNegotiation';
import LeaseTermsToAvoid from './LeaseTermsToAvoid';
import MovingIn from './MovingIn';
import NegotiationsAgreements from './NegotiationsAgreements';
import NowWhat from './NowWhat';
import OrganizingNewOffice from './OrganizingNewOffice';
import PlaybookTemplate, { GradientCloudOrientation } from './PlaybookTemplate';
import RedFlags from './RedFlags';
import ReputableBrokers from './ReputableBrokers';
import ReviewYourOptions from './ReviewYourOptions';
import SearchIntro from './SearchIntro';
import SearchSteps from './SearchSteps';
import SearchingForAFlexibleOffice from './SearchingForAFlexibleOffice';
import SearchingForATraditionalOffice from './SearchingForATraditionalOffice';
import SearchingForCoworking from './SearchingForCoworking';
import SearchingForCoworkingIntro from './SearchingForCoworkingIntro';
import SigningUpFlexOffices from './SigningUpFlexOffices';
import TenantBrokers from './TenantBrokers';
import TermSheetsAndLeases from './TermSheetsAndLeases';
import TheCoworkingOffice from './TheCoworkingOffice';
import TheFlexOffice from './TheFlexOffice';
import TheSearch from './TheSearch';
import TheTraditionalOffice from './TheTraditionalOffice';
import Traditional from './Traditional';
import TraditionalOffices from './TraditionalOffices';
import TransienceOfCoworking from './TransienceOfCoworking';
import UnwantedNegotiation from './UnwantedNegotiation';
import WhatToRemember from './WhatToRemember';
import { WEB_PLAYBOOK_QUERY } from './constants';
import { PLAYBOOK_META } from './meta';

interface Props {}

interface PlaybookRoute {
  path: string;
  Component: React.ComponentClass<any> | React.FC<any>;
  gradientCloudOrientation?: GradientCloudOrientation;
  backgroundColor?: Color | 'transparent';
  step: number;
  buttonType?: ButtonType;
  chapterTitle?: string;
}

const REVIEW_YOUR_OPTIONS_TILE = '01  REVIEW YOUR OPTIONS';
const SEARCHING_FOR_TRADITIONAL_TITLE = '02  THE SEARCH | SEARCHING FOR A TRADITIONAL OFFICE';
const SEARCHING_FOR_COWORKING_TITLE = '02  THE SEARCH | SEARCHING FOR A COWORKING OFFICE';
const SEARCHING_FOR_FLEX_TITLE = '02  THE SEARCH | SEARCHING FOR A FLEX OFFICE';
const NEGOTIATING_TRADITIONAL_TITLE = '03  NEGOTIATIONS & AGREEMENTS | TRADITIONAL OFFICES';
const NEGOTIATING_COWORKING_TITLE = '03  NEGOTIATIONS & AGREEMENTS | COWORKING OFFICES';
const NEGOTIATING_FLEX_TITLE = '03  NEGOTIATIONS & AGREEMENTS | FLEX OFFICES';
const MOVING_IN_TRADITIONAL_TITLE = '04  MOVING IN & MANAGING YOUR OFFICE | THE TRADITIONAL OFFICE';
const MOVING_IN_COWORKING_TITLE = '04  MOVING IN & MANAGING YOUR OFFICE | THE COWORKING OFFICE';
const MOVING_IN_FLEX_TITLE = '04  MOVING IN & MANAGING YOUR OFFICE | THE FLEX OFFICE';
const CLOSING_TITLE = 'CLOSING THOUGHTS & HANDY TOOLS';

const ROUTES: PlaybookRoute[] = [
  { Component: Intro, path: `${PLAYBOOK_PATH}/intro`, gradientCloudOrientation: 'topRight', step: 0 },
  { Component: Forward, path: `${PLAYBOOK_PATH}/forward`, gradientCloudOrientation: 'topLeft', step: 0 },
  { Component: ReviewYourOptions, path: `${PLAYBOOK_PATH}/review-your-options`, backgroundColor: 'blue-800', step: 1 },
  {
    Component: DecisionFactors,
    path: `${PLAYBOOK_PATH}/decision-factors`,
    backgroundColor: 'blue-800',
    step: 1,
    chapterTitle: REVIEW_YOUR_OPTIONS_TILE
  },
  {
    Component: KnowYourOptions,
    path: `${PLAYBOOK_PATH}/know-your-options`,
    backgroundColor: 'blue-800',
    step: 1,
    chapterTitle: REVIEW_YOUR_OPTIONS_TILE
  },
  { Component: Traditional, path: `${PLAYBOOK_PATH}/traditional`, step: 1, chapterTitle: REVIEW_YOUR_OPTIONS_TILE },
  { Component: Coworking, path: `${PLAYBOOK_PATH}/coworking`, step: 1, chapterTitle: REVIEW_YOUR_OPTIONS_TILE },
  { Component: FlexSpace, path: `${PLAYBOOK_PATH}/flex-office`, step: 1, chapterTitle: REVIEW_YOUR_OPTIONS_TILE },
  { Component: TheSearch, path: `${PLAYBOOK_PATH}/the-search`, backgroundColor: 'blue-800', step: 2 },
  {
    Component: SearchingForATraditionalOffice,
    path: `${PLAYBOOK_PATH}/searching-for-traditional-office`,
    gradientCloudOrientation: 'topRight',
    step: 2
  },
  {
    Component: SearchSteps,
    path: `${PLAYBOOK_PATH}/search-steps`,
    backgroundColor: 'blue-800',
    step: 2,
    chapterTitle: SEARCHING_FOR_TRADITIONAL_TITLE
  },
  {
    Component: TenantBrokers,
    path: `${PLAYBOOK_PATH}/tenant-brokers`,
    step: 2,
    chapterTitle: SEARCHING_FOR_TRADITIONAL_TITLE
  },
  {
    Component: ReputableBrokers,
    path: `${PLAYBOOK_PATH}/reputable-brokers`,
    step: 2,
    chapterTitle: SEARCHING_FOR_TRADITIONAL_TITLE
  },
  {
    Component: BrokersTours,
    path: `${PLAYBOOK_PATH}/broker-tours`,
    backgroundColor: 'blue-800',
    step: 2,
    chapterTitle: SEARCHING_FOR_TRADITIONAL_TITLE
  },
  { Component: RedFlags, path: `${PLAYBOOK_PATH}/red-flags`, step: 2, chapterTitle: SEARCHING_FOR_TRADITIONAL_TITLE },
  {
    Component: SearchingForCoworking,
    path: `${PLAYBOOK_PATH}/searching-for-coworking`,
    gradientCloudOrientation: 'topRight',
    step: 2
  },
  {
    Component: SearchingForCoworkingIntro,
    path: `${PLAYBOOK_PATH}/searching-for-coworking-intro`,
    step: 2,
    chapterTitle: SEARCHING_FOR_COWORKING_TITLE
  },
  {
    Component: TransienceOfCoworking,
    path: `${PLAYBOOK_PATH}/transience-of-coworking`,
    step: 2,
    chapterTitle: SEARCHING_FOR_COWORKING_TITLE,
    buttonType: 'primaryInverse',
    backgroundColor: 'transparent'
  },
  {
    Component: SearchingForAFlexibleOffice,
    path: `${PLAYBOOK_PATH}/searching-for-a-flexible-office`,
    gradientCloudOrientation: 'topRight',
    step: 2
  },
  {
    Component: SearchIntro,
    path: `${PLAYBOOK_PATH}/searching-for-a-flexible-office-intro`,
    buttonType: 'primaryInverse',
    step: 2,
    chapterTitle: SEARCHING_FOR_FLEX_TITLE,
    backgroundColor: 'transparent'
  },
  {
    Component: FlexibleSpaceModel,
    path: `${PLAYBOOK_PATH}/flexible-space-model`,
    buttonType: 'primaryInverse',
    step: 2,
    chapterTitle: SEARCHING_FOR_FLEX_TITLE,
    backgroundColor: 'transparent'
  },
  {
    Component: FlexibleSpaceCompany,
    path: `${PLAYBOOK_PATH}/flexible-space-company`,
    step: 2,
    chapterTitle: SEARCHING_FOR_FLEX_TITLE
  },
  {
    Component: NegotiationsAgreements,
    path: `${PLAYBOOK_PATH}/negotiations-agreements`,
    backgroundColor: 'blue-800',
    step: 3
  },
  {
    Component: TraditionalOffices,
    path: `${PLAYBOOK_PATH}/traditional-offices`,
    gradientCloudOrientation: 'topRight',
    step: 3
  },
  {
    Component: TermSheetsAndLeases,
    path: `${PLAYBOOK_PATH}/term-sheets-and-leases`,
    buttonType: 'primaryInverse',
    step: 3,
    chapterTitle: NEGOTIATING_TRADITIONAL_TITLE,
    backgroundColor: 'transparent'
  },
  {
    Component: LeaseTermsForNegotiation,
    path: `${PLAYBOOK_PATH}/lease-terms-for-negotiation`,
    step: 3,
    chapterTitle: NEGOTIATING_TRADITIONAL_TITLE
  },
  {
    Component: LeaseTermsToAvoid,
    path: `${PLAYBOOK_PATH}/lease-terms-to-avoid`,
    step: 3,
    chapterTitle: NEGOTIATING_TRADITIONAL_TITLE
  },
  {
    Component: UnwantedNegotiation,
    path: `${PLAYBOOK_PATH}/unwanted-negotiation`,
    step: 3,
    chapterTitle: NEGOTIATING_TRADITIONAL_TITLE,
    backgroundColor: 'white'
  },
  {
    Component: CoworkingOffices,
    path: `${PLAYBOOK_PATH}/coworking-offices`,
    gradientCloudOrientation: 'topRight',
    step: 3
  },
  {
    Component: CarvingOutNewSpace,
    path: `${PLAYBOOK_PATH}/carving-out-new-space`,
    step: 3,
    chapterTitle: NEGOTIATING_COWORKING_TITLE,
    buttonType: 'primaryInverse',
    backgroundColor: 'transparent'
  },
  {
    Component: WhatToRemember,
    path: `${PLAYBOOK_PATH}/what-to-remember`,
    step: 3,
    chapterTitle: NEGOTIATING_COWORKING_TITLE
  },
  {
    Component: Growth1,
    path: `${PLAYBOOK_PATH}/growth-1`,
    step: 3,
    chapterTitle: NEGOTIATING_COWORKING_TITLE,
    buttonType: 'primaryInverse',
    backgroundColor: 'transparent'
  },
  { Component: FlexOffices, path: `${PLAYBOOK_PATH}/flex-offices`, gradientCloudOrientation: 'topRight', step: 3 },
  {
    Component: SigningUpFlexOffices,
    path: `${PLAYBOOK_PATH}/signing-up-flex-offices`,
    step: 3,
    chapterTitle: NEGOTIATING_FLEX_TITLE
  },
  {
    Component: Growth2,
    path: `${PLAYBOOK_PATH}/growth-2`,
    buttonType: 'primaryInverse',
    step: 3,
    chapterTitle: NEGOTIATING_FLEX_TITLE,
    backgroundColor: 'transparent'
  },
  { Component: MovingIn, path: `${PLAYBOOK_PATH}/moving-in`, backgroundColor: 'blue-800', step: 4 },
  { Component: TheTraditionalOffice, path: `${PLAYBOOK_PATH}/the-traditional-office`, step: 4 },
  {
    Component: NowWhat,
    path: `${PLAYBOOK_PATH}/now-what`,
    chapterTitle: MOVING_IN_TRADITIONAL_TITLE,
    step: 4,
    buttonType: 'primaryInverse',
    backgroundColor: 'transparent'
  },
  {
    Component: BeforeTeamArrives,
    path: `${PLAYBOOK_PATH}/before-team-arrives`,
    chapterTitle: MOVING_IN_TRADITIONAL_TITLE,
    step: 4,
    backgroundColor: 'white'
  },
  {
    Component: TheCoworkingOffice,
    path: `${PLAYBOOK_PATH}/the-coworking-office`,
    gradientCloudOrientation: 'topRight',
    step: 4
  },
  {
    Component: DesigningYourSpace,
    path: `${PLAYBOOK_PATH}/designing-your-space`,
    chapterTitle: MOVING_IN_COWORKING_TITLE,
    step: 4,
    buttonType: 'primaryInverse',
    backgroundColor: 'transparent'
  },
  { Component: TheFlexOffice, path: `${PLAYBOOK_PATH}/the-flex-office`, gradientCloudOrientation: 'topRight', step: 4 },
  {
    Component: OrganizingNewOffice,
    path: `${PLAYBOOK_PATH}/organizing-new-office`,
    step: 4,
    chapterTitle: MOVING_IN_FLEX_TITLE
  },
  {
    Component: DayToDay,
    path: `${PLAYBOOK_PATH}/day-to-day`,
    buttonType: 'primaryInverse',
    step: 4,
    chapterTitle: MOVING_IN_FLEX_TITLE,
    backgroundColor: 'transparent'
  },
  {
    Component: AllInTheDetails,
    path: `${PLAYBOOK_PATH}/all-in-the-details`,
    step: 4,
    chapterTitle: MOVING_IN_FLEX_TITLE
  },
  { Component: HandyTools, path: `${PLAYBOOK_PATH}/handy-tools`, backgroundColor: 'blue-800', step: 5 },
  {
    Component: ClosingThoughts,
    path: `${PLAYBOOK_PATH}/closing-thoughts`,
    gradientCloudOrientation: 'bottomRight',
    chapterTitle: CLOSING_TITLE,
    step: 5
  },
  {
    Component: Comparison,
    path: `${PLAYBOOK_PATH}/comparison`,
    gradientCloudOrientation: 'bottomRight',
    chapterTitle: CLOSING_TITLE,
    step: 5
  },
  {
    Component: DecisionTree,
    path: `${PLAYBOOK_PATH}/decision-tree`,
    gradientCloudOrientation: 'bottomRight',
    chapterTitle: CLOSING_TITLE,
    step: 5
  },
  { Component: AboutCodi, path: `${PLAYBOOK_PATH}/about-codi`, chapterTitle: CLOSING_TITLE, step: 5 }
];

const Playbook: React.FC<Props> = () => {
  const { pathname } = useLocation();
  const pageIndex = ROUTES.findIndex(r => r.path === pathname);
  const backHref = ROUTES[pageIndex - 1]?.path;
  const forwardHref = ROUTES[pageIndex + 1]?.path;
  const isCrawler = /bot|googlebot|crawler|spider|robot|crawling/i.test(navigator.userAgent);
  const isWebPlaybook = useMediaQuery(WEB_PLAYBOOK_QUERY) || isCrawler;

  return (
    <Switch>
      {isWebPlaybook ? (
        <>
          {ROUTES.map(
            ({ Component, path, gradientCloudOrientation, backgroundColor, step, buttonType, chapterTitle }) => {
              const metaTags = PLAYBOOK_META[path] || PLAYBOOK_META['/playbook'];

              return (
                <Route path={path} key={path} exact>
                  <Head title={metaTags.title} description={metaTags.description} />
                  <PlaybookTemplate
                    pageNumber={pageIndex + 1}
                    pageCount={ROUTES.length}
                    backHref={backHref}
                    forwardHref={forwardHref}
                    gradientCloudOrientation={gradientCloudOrientation}
                    backgroundColor={backgroundColor}
                    step={step}
                    buttonType={buttonType}
                    chapterTitle={chapterTitle}
                  >
                    <Component />
                  </PlaybookTemplate>
                </Route>
              );
            }
          )}
          <Redirect to={pathname || `${PLAYBOOK_PATH}/intro`} />
        </>
      ) : (
        <Redirect to={PLAYBOOK_PATH} />
      )}
    </Switch>
  );
};

export default Playbook;
