import React from 'react';
import { Color } from 'shared';
import classNames from 'classnames';

import useFieldSize from 'hooks/useFieldSize';
import { track } from 'lib/analytics';

import Text, { HEADING_TAGS, HeadingTag, Props as TextProps } from './Text';
import { DS_CLASS_NAME, IS_TOUCH_DEVICE } from './constants';
import { FieldSize } from './types';
import { extractText } from './utils';

export interface Props extends Pick<TextProps, 'scale' | 'semibold'> {
  text: string | JSX.Element;
  onClick?: () => void;
  color: Color;
  paddingX?: boolean;
  paddingY?: boolean;
  fullWidth?: boolean;
  disabled?: boolean;
  align?: 'left' | 'center';
  textSize: TextProps['size'];
  size?: FieldSize;
  tabIndex?: number;
  context?: string;
  hoverColor?: Color;
  hoverOpacity?: boolean;
  name?: string;
  underline?: boolean;
  stopPropagation?: boolean;
}

const TextButton: React.FC<Props> = ({
  text,
  color,
  textSize,
  paddingX,
  paddingY = true,
  semibold = true,
  fullWidth,
  align = 'center',
  disabled,
  scale,
  tabIndex,
  context,
  hoverColor,
  name,
  underline = false,
  stopPropagation = false,
  ...props
}) => {
  const scaledSize = useFieldSize();
  const size = props.size || scaledSize;
  const hoverOpacity = props.hoverOpacity === undefined ? !IS_TOUCH_DEVICE : true;
  const onClick = () => {
    props.onClick && props.onClick();
    track('Element Interacted', { context, name: name || extractText(text), type: 'textButton' });
  };

  const isHeadingSize = HEADING_TAGS.includes(textSize as HeadingTag);

  return (
    <button
      className={classNames(
        DS_CLASS_NAME,
        'TextButton',
        `is-size-${size}`,
        `hover-text-color-${hoverColor || color}`,
        {
          'has-paddingX': paddingX,
          'has-paddingY': paddingY,
          'is-fullWidth': fullWidth,
          'is-disabled': disabled,
          'has-hover-opacity': hoverOpacity
        },
        align && `text-align-${align}`
      )}
      disabled={disabled}
      onClick={e => {
        e.preventDefault();
        if (stopPropagation) e.stopPropagation();

        onClick && onClick();
      }}
      tabIndex={tabIndex}
    >
      <Text
        scale={scale}
        size={textSize}
        bold={isHeadingSize ? false : undefined}
        color={color}
        semibold={semibold}
        underline={underline}
        inline
      >
        {text}
      </Text>
    </button>
  );
};

export default TextButton;
