import React from 'react';
import { Color } from 'shared';

import Icon from 'ds/Icon';
import Layout from 'ds/Layout';
import Link from 'ds/Link';
import Text from 'ds/Text';

interface Props {
  href: string;
  label?: string;
  color?: Color;
}

const MobileBackLink: React.FC<Props> = ({ href, label, color = 'gray-900' }) => {
  return (
    <Link href={href}>
      <Layout align="center">
        <Icon name="leftChevron" color={color} size="lg" />
        {label && (
          <Layout marginLeft={2}>
            <Text size="body1" color={color} maxLines={2}>
              {label}
            </Text>
          </Layout>
        )}
      </Layout>
    </Link>
  );
};

export default MobileBackLink;
