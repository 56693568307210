import React, { PropsWithChildren, ReactNode, useContext, useEffect, useRef, useState } from 'react';
import { Color } from 'shared';
import classNames from 'classnames';
import ReactDOM from 'react-dom';

import { app } from 'context';
import { media } from 'context';
import { LayoutBorderRadius, Props as LayoutProps } from 'ds/Layout';
import { ANIMATION_DURATION, MODAL_Z_INDEX, SCROLLABLE_BOX_SHADOW } from 'ds/constants';

import IconButton from './IconButton';
import Layout from './Layout';
import ScrollShadowContainer from './ScrollShadowContainer';
import Spinner from './Spinner';
import Text from './Text';
import { BottomSheetAnimation } from './types';
import { getRootElement } from './utils';

export interface Props {
  isVisible: boolean;
  onClickOutside?: VoidFunction;
  overflowVisible?: boolean;
  scrimBackground?: string;
  gutterTop: number;
  size?: 'md' | 'lg' | 'xl';
  onClose: VoidFunction;
  topLeft?: ReactNode;
  header?: string | ReactNode;
  headerAlign?: 'left' | 'center' | 'right';
  footer?: ReactNode;
  headerProps?: LayoutProps;
  closeDisabled?: boolean;
  isFullScreen: boolean;
  paddingX: number;
  state: 'entering' | 'visible' | 'initialized-visible' | 'hiding' | 'hidden';
  animate: boolean;
  showCloseButton: boolean;
  scrollShadow?: boolean;
  handleKeyboardFocus?: boolean;
  animation: BottomSheetAnimation;
  hasFooterBorder?: boolean;
  isRounded?: boolean;
  reverseClosePosition?: boolean;
  verticallyCenterContent?: boolean;
  zIndexPosition?: number;
  showLoadingState?: boolean;
  containerProps?: LayoutProps;
  borderRadius?: LayoutBorderRadius;
  backgroundColor?: Color;
  containerRef?: React.RefObject<HTMLDivElement>;
  hasFooterPadding?: boolean;
}

const HEADER_HEIGHT_XS = 72;
const MARGIN_X = 24;

const BottomSheet: React.FC<PropsWithChildren<Props>> = ({
  children,
  isVisible,
  onClickOutside,
  overflowVisible = false,
  scrimBackground = 'rgba(0, 0, 0, 0.5)',
  isFullScreen,
  gutterTop,
  state,
  animate,
  animation,
  handleKeyboardFocus = false,
  zIndexPosition = 0,
  borderRadius = 16,
  ...props
}) => {
  const rootRef = useRef<HTMLElement | null>();
  const isRounded = props.isRounded && !isFullScreen;
  const { windowHeight } = useContext(app);
  const ref = useRef<HTMLDivElement>(null);
  const [keyboardIsOpen, setKeyboardIsOpen] = useState<boolean>(false);
  useEffect(() => {
    rootRef.current = getRootElement();
  }, []);

  const handleMobileKeyboardOpen = () => {
    const root = getRootElement();

    if (!root) return;

    root.style.position = 'fixed';
    setKeyboardIsOpen(true);
  };

  const handleMobileKeyboardClose = () => {
    const root = getRootElement();

    if (!root) return;

    root.style.position = 'unset';
    setKeyboardIsOpen(false);
  };

  useEffect(() => {
    const content = ref.current;

    if (!content || !handleKeyboardFocus) return;

    if (isVisible) {
      content.querySelectorAll('input').forEach(input => {
        input.addEventListener('focus', handleMobileKeyboardOpen);
        input.addEventListener('blur', handleMobileKeyboardClose);
      });
    } else {
      content.querySelectorAll('input').forEach(input => {
        input.removeEventListener('focus', handleMobileKeyboardOpen);
        input.removeEventListener('blur', handleMobileKeyboardClose);
      });
    }
  }, [isVisible, handleKeyboardFocus]);

  if (state === 'hidden') return null;

  const portalElement = rootRef.current || getRootElement();

  return portalElement
    ? ReactDOM.createPortal(
        <>
          <div
            onClick={() => {
              if (!isVisible) return;

              onClickOutside && onClickOutside();
            }}
            className={classNames('BottomSheet-scrim', { 'is-hidden': !isVisible }, `is-z-index-${zIndexPosition}`)}
            style={{
              background: zIndexPosition === 0 ? scrimBackground : undefined,
              height: isVisible ? windowHeight : 0
            }}
          />
          <div
            className={classNames(
              'BottomSheet',
              `is-${state}`,
              `is-animation-${animation}`,
              `is-z-index-${zIndexPosition}`,
              {
                'is-overflow-visible': overflowVisible,
                'is-full-screen': isFullScreen,
                'has-animation': animate
              }
            )}
            style={{
              height: windowHeight - gutterTop,
              top: isFullScreen ? undefined : gutterTop,
              borderTopLeftRadius: isRounded ? borderRadius : undefined,
              borderTopRightRadius: isRounded ? borderRadius : undefined
            }}
            ref={ref}
            onScroll={e => e.preventDefault()}
          >
            <Content
              {...props}
              handleKeyboardFocus={handleKeyboardFocus}
              borderRadius={borderRadius}
              keyboardIsOpen={keyboardIsOpen}
              gutterTop={gutterTop}
              isFullScreen={isFullScreen}
            >
              {children}
            </Content>
          </div>
        </>,
        portalElement
      )
    : null;
};

interface ContentProps
  extends Pick<
    Props,
    | 'header'
    | 'headerAlign'
    | 'footer'
    | 'onClose'
    | 'headerProps'
    | 'closeDisabled'
    | 'isFullScreen'
    | 'paddingX'
    | 'gutterTop'
    | 'showCloseButton'
    | 'handleKeyboardFocus'
    | 'scrollShadow'
    | 'hasFooterBorder'
    | 'topLeft'
    | 'reverseClosePosition'
    | 'verticallyCenterContent'
    | 'showLoadingState'
    | 'containerProps'
    | 'borderRadius'
    | 'backgroundColor'
    | 'containerRef'
    | 'hasFooterPadding'
  > {
  keyboardIsOpen: boolean;
}

const Content: React.FC<PropsWithChildren<ContentProps>> = ({
  header,
  headerAlign,
  footer,
  onClose,
  headerProps,
  closeDisabled,
  isFullScreen,
  paddingX,
  children,
  gutterTop,
  showCloseButton,
  keyboardIsOpen,
  scrollShadow,
  handleKeyboardFocus,
  hasFooterBorder,
  topLeft,
  reverseClosePosition,
  verticallyCenterContent,
  showLoadingState,
  containerProps,
  borderRadius = 16,
  backgroundColor,
  containerRef,
  hasFooterPadding = true
}) => {
  const { isMobile } = useContext(media);
  const [footerHeight, setFooterHeight] = useState<number>(0);
  const [hasScrolled, setHasScrolled] = useState<boolean>();
  const footerRef = useRef<HTMLDivElement>(null);
  const contentHeight =
    keyboardIsOpen && handleKeyboardFocus && window.visualViewport
      ? window.visualViewport.height - (header ? HEADER_HEIGHT_XS : 0 + footerHeight + gutterTop)
      : `calc(100vh - ${(header ? HEADER_HEIGHT_XS : 0) + footerHeight + gutterTop}px)`;
  const { windowWidth, windowHeight } = useContext(app);

  const hideBottomSheet = () => {
    if (closeDisabled) return;

    onClose();

    setTimeout(() => {
      onClose();
    }, ANIMATION_DURATION);
  };

  const hasFooter = !!footerRef.current;

  useEffect(() => {
    if (footerRef.current) {
      setFooterHeight(footerRef.current.clientHeight);
    } else {
      setFooterHeight(0);
    }
  }, [hasFooter, footerRef.current?.innerHTML]);

  const isRounded = !isFullScreen;

  return (
    <>
      {header && (
        <Layout
          borderTopLeftRadius={isRounded ? borderRadius : undefined}
          borderTopRightRadius={isRounded ? borderRadius : undefined}
          paddingTop={24}
          color="white"
          width="100%"
          zIndex={MODAL_Z_INDEX + 1}
          height={HEADER_HEIGHT_XS}
          boxShadow={hasScrolled ? SCROLLABLE_BOX_SHADOW : undefined}
          {...headerProps}
        >
          {showCloseButton && (
            <Layout
              position="absolute"
              zIndex={MODAL_Z_INDEX + 1}
              {...(reverseClosePosition ? { left: 24 } : { right: 24 })}
              display="inline-flex"
            >
              <IconButton
                onClick={hideBottomSheet}
                name="close"
                type="gray"
                {...(isMobile ? { size: 'md' } : { size: 'sm' })}
                disabled={closeDisabled}
                stroke={2}
              />
            </Layout>
          )}
          {topLeft && (
            <Layout
              zIndex={MODAL_Z_INDEX + 1}
              {...(reverseClosePosition ? { right: 24 } : { left: 24 })}
              position="absolute"
            >
              {topLeft}
            </Layout>
          )}
          <Layout
            {...(headerAlign === 'left' ? { justify: 'flex-start', paddingLeft: 24 } : { justify: 'center' })}
            align="center"
            /** Used to vertically align with icons */
            height={40}
          >
            {typeof header === 'string' ? (
              <Text size="h5" align="center" ellipsis scale>
                {header}
              </Text>
            ) : (
              header
            )}
          </Layout>
        </Layout>
      )}
      {(isFullScreen || !header) && showCloseButton && (
        <Layout
          zIndex={MODAL_Z_INDEX + 5}
          top={24}
          left={reverseClosePosition ? MARGIN_X : undefined}
          right={reverseClosePosition ? undefined : MARGIN_X}
          position="absolute"
        >
          <IconButton onClick={onClose} size="md" name="close" type="gray" disabled={closeDisabled} />
        </Layout>
      )}
      {header && scrollShadow ? (
        <ScrollShadowContainer
          paddingTop={16}
          paddingX={paddingX}
          paddingBottom={footer ? footerHeight + 48 : 24}
          overflow="auto"
          height={contentHeight}
          color={backgroundColor}
          setHasScrolled={setHasScrolled}
          __ref={containerRef}
          {...containerProps}
        >
          {children}
        </ScrollShadowContainer>
      ) : (
        <Layout
          paddingX={paddingX}
          paddingBottom={footer ? footerHeight + 48 : 24}
          width={windowWidth}
          overflowX="hidden"
          height={contentHeight}
          color={backgroundColor}
          __ref={containerRef}
          {...(verticallyCenterContent ? { direction: 'column', justify: 'center' } : {})}
          {...containerProps}
        >
          {children}
        </Layout>
      )}
      {footer && (
        <Layout
          __ref={footerRef}
          position="sticky"
          zIndex={1}
          bottom={0}
          {...(hasFooterPadding ? { paddingY: 16, paddingX: paddingX || 24 } : {})}
          color="white"
          borderTop={hasFooterBorder}
          borderColor="gray-50"
          boxShadow={hasScrolled ? SCROLLABLE_BOX_SHADOW : undefined}
        >
          {footer}
        </Layout>
      )}
      {showLoadingState && (
        <Layout
          opacity={showLoadingState ? 0.2 : undefined}
          width={windowWidth}
          height={windowHeight}
          position="absolute"
          top={0}
          left={0}
          zIndex={MODAL_Z_INDEX + 20}
          color="black"
        />
      )}
      {showLoadingState && (
        <Layout position="fixed" top="50vh" left="50vw" transform="translateX(-50%) translateY(-50%)">
          <Spinner size="md" />
        </Layout>
      )}
    </>
  );
};

export default BottomSheet;
