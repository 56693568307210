import { Licensor, PayoutAccount, Workspace, safeAssign } from 'shared';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { EditableListing } from 'ux/Properties/Listings/Listing/types';

export type PropertiesState = {
  licensors: Licensor[];
  selectedLicensorId: number | null;
  editableWorkspace: EditableListing | null;
  workspaces: Workspace[] | null;
};

const initialState: PropertiesState = {
  licensors: [],
  selectedLicensorId: null,
  editableWorkspace: null,
  workspaces: [] // DEPRECATED?
};

const propertiesSlice = createSlice({
  name: 'Properties',
  initialState,
  reducers: {
    setLicensors: (state, { payload }: PayloadAction<Licensor[]>) => {
      state.licensors = payload;
    },
    updatePayoutAccount: (state, { payload }: PayloadAction<PayoutAccount>) => {
      if (!state.selectedLicensorId) return;

      const selectedLicensorIndex = state.licensors.findIndex(licensor => licensor.id === state.selectedLicensorId);
      if (selectedLicensorIndex === -1) return;

      state.licensors[selectedLicensorIndex].payout_account = payload;
    },
    updateWorkspace: (state, { payload }: PayloadAction<Workspace>) => {
      const licensor = state.licensors.find(licensor => licensor.id === state.selectedLicensorId);
      if (!!licensor) {
        const workspace = licensor.workspaces.find(workspace => workspace.id === payload.id);
        if (!!workspace) {
          safeAssign(workspace, payload);
        } else {
          safeAssign(licensor.workspaces, [payload, ...licensor.workspaces]);
        }
      }
    },
    setSelectedLicensorId: (state, { payload }: PayloadAction<number | null>) => {
      state.selectedLicensorId = payload;
    },
    setEditableWorkspace: (state, { payload }: PayloadAction<EditableListing | null>) => {
      state.editableWorkspace = payload;
    },
    updateEditableWorkspace: (state, { payload }: PayloadAction<Partial<EditableListing>>) => {
      safeAssign(state.editableWorkspace, payload);
    },
    setWorkspaces: (state, { payload }: PayloadAction<Workspace[]>) => {
      state.workspaces = payload;
    }
  }
});

export const { actions } = propertiesSlice;

export default propertiesSlice.reducer;
