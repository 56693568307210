import React, { useState } from 'react';
import { castToDateTime } from 'shared';
import classNames from 'classnames';
import ReactDatePicker from 'react-datepicker';

import { isSameDate } from 'helpers/time';

import CalendarDay from './CalendarDay';
import CalendarHeader from './CalendarHeader';
import { DateRangePickerProps } from './types';

interface Props extends DateRangePickerProps {}

const DateRangePickerCalendar: React.FC<Props> = ({
  startDate,
  endDate,
  onChange,
  minDate: minDateInput,
  maxDate: maxDateInput,
  excludeDateIntervals = []
}) => {
  const [rangeSelectionEndDate, setRangeSelectionEndDate] = useState<Date>();
  const [isMouseOnCalendar, setIsMouseOnCalendar] = useState<boolean>(false);
  const [minDate, setMinDate] = useState<Date | null | undefined>(minDateInput);
  const [maxDate, setMaxDate] = useState<Date | null | undefined>(maxDateInput);

  return (
    <ReactDatePicker
      excludeDateIntervals={excludeDateIntervals}
      renderCustomHeader={props => <CalendarHeader {...props} />}
      selected={startDate}
      onChange={onChange}
      startDate={startDate}
      minDate={minDate}
      maxDate={maxDate}
      onSelect={date => {
        const isRangeSelectionEndDate = !!rangeSelectionEndDate && !!date && isSameDate(date, rangeSelectionEndDate);

        if (!isRangeSelectionEndDate) {
          excludeDateIntervals.every(edi => {
            if (edi.start > date) {
              setMaxDate(edi.start);
              return false;
            } else {
              return true;
            }
          });
          setMinDate(date);
        }

        if (isRangeSelectionEndDate) {
          setRangeSelectionEndDate(undefined);
        }
      }}
      dayClassName={date => {
        const isRangeSelectionEndDate = !!rangeSelectionEndDate && !!date && isSameDate(date, rangeSelectionEndDate);

        return classNames({
          'is-mouse-on-calendar': isMouseOnCalendar,
          'react-datepicker__day--selecting-range-end': isRangeSelectionEndDate
        });
      }}
      endDate={endDate}
      onMonthMouseLeave={() => {
        setRangeSelectionEndDate(undefined);
        setIsMouseOnCalendar(false);
      }}
      onDayMouseEnter={date => {
        setIsMouseOnCalendar(true);
        if (!startDate) return;

        if (startDate && endDate) {
          setRangeSelectionEndDate(undefined);
          return;
        }

        if (isSameDate(date, startDate)) {
          setRangeSelectionEndDate(undefined);
          return;
        }

        setRangeSelectionEndDate(date);
      }}
      renderDayContents={(dayOfMonth, date) => {
        const isStartDate = !!startDate && !!date && isSameDate(date, startDate);
        const isEndDate = !!endDate && !!date && isSameDate(date, endDate);
        const isBeforeStartDate =
          !!date && !!startDate && castToDateTime(date).startOf('day') < castToDateTime(startDate).startOf('day');

        return (
          <CalendarDay
            dayOfMonth={dayOfMonth}
            isStartDate={isStartDate}
            isEndDate={isEndDate}
            isDisabled={isBeforeStartDate}
          />
        );
      }}
      selectsRange
      inline
    />
  );
};

export default DateRangePickerCalendar;
