import React, { useContext, useState } from 'react';
import { colorClassName } from 'shared';
import { ReactComponent as LogoSvg } from 'shared/assets/logo.svg';
import classNames from 'classnames';

import { media } from 'context';
import { Icon, Layout, Link, Text } from 'ds';
import { formatUsd } from 'helpers';
import { OFFICE_REQUEST_PATH } from 'routes';

import HomePageNumberStepper from './HomePageNumberStepper';

import { ReactComponent as ColumnIcons } from './home-page-column-icons.svg';
import { ReactComponent as PriceColumnArrow } from './price-column-arrow.svg';

interface Props {}

const PriceExplanation: React.FC<Props> = () => {
  const { isMobile, xs, sm, md } = useContext(media);
  const [headCount, setHeadCount] = useState<number | undefined>(20);
  const [columnHeight, setColumnHeight] = useState<number>();
  const codiPrice = headCount ? (headCount * 150 * 45) / 12 : 0;
  const traditionalPrice = codiPrice * 1.2;

  return (
    <Layout
      {...(xs
        ? { direction: 'column', align: 'center' }
        : sm
        ? { direction: 'column', align: 'center' }
        : { flex: true })}
    >
      <Layout {...(isMobile ? {} : { maxWidth: md ? 480 : 520 })}>
        <Text size={isMobile ? 'headline-lg' : 'display-sm'} color="blue-500" align={sm ? 'center' : undefined}>
          Everything you need in one monthly bill
        </Text>
        <Layout marginTop={32}>
          <Text color="gray-400" size={isMobile ? 'body-md' : 'body-md'} align={sm ? 'center' : undefined}>
            With a traditional lease, you’ll be required to manage your own office, costing you an additional 20%
            compared with Codi. Save time and money by letting Codi do it all for you with one simple membership.
          </Text>
        </Layout>
        <Layout {...(isMobile ? { justify: 'center' } : {})} marginTop={32}>
          <Text color="gray-400" size={isMobile ? 'body-md' : 'body-md'} align={isMobile ? 'center' : undefined}>
            Enter your team’s size to compare.
          </Text>
        </Layout>
        <Layout {...(isMobile ? { justify: 'center' } : {})} marginTop={16}>
          <HomePageNumberStepper
            onChange={value => {
              setHeadCount(value);
            }}
            value={headCount}
            label="employees"
            max={10000}
          />
        </Layout>
        <Layout
          {...(xs
            ? { marginTop: 24, justify: 'center' }
            : sm
            ? { marginTop: 24 }
            : { marginTop: 56, justify: 'center', width: md ? 300 : 342 })}
        >
          <Link href={OFFICE_REQUEST_PATH} {...(isMobile ? { fullWidth: true } : {})}>
            <Layout align="center" {...(isMobile ? { justify: 'center' } : { paddingX: 36 })}>
              <Text size="body-sm" color="blue-500" semibold>
                Get started with Codi
              </Text>
              <Layout marginLeft={8} display="inline-flex">
                <Icon name="rightArrow" color="blue-500" size="sm" stroke={2} />
              </Layout>
            </Layout>
          </Link>
        </Layout>
      </Layout>
      <Layout
        {...(xs
          ? { marginTop: 68, maxWidth: 430, width: '100%' }
          : sm
          ? { marginTop: 68, maxWidth: 430, width: '100%' }
          : { marginLeft: md ? 48 : 80 })}
      >
        <Layout {...(isMobile ? {} : { paddingX: 64 })} flex>
          <Layout {...(isMobile ? { equalWidth: true } : { flexGrow: 1 })}>
            <Text size="headline-md" color="gray-400" align="center">
              {formatUsd(traditionalPrice, false)}
            </Text>
            <Layout marginTop={12} {...(isMobile ? { flexGrow: 1 } : {})}>
              <Layout onMeasure={({ height }) => setColumnHeight(height)} {...(isMobile ? {} : { width: 164 })}>
                <Layout borderTopLeftRadius={8} borderTopRightRadius={8} color="blue-800" paddingY={14}>
                  <Text size="body-xs" semibold color="white" align="center">
                    Maintenance
                  </Text>
                </Layout>
                <Layout color="blue-700" paddingY={17}>
                  <Text size="body-xs" semibold color="white" align="center">
                    Office Management
                  </Text>
                </Layout>
                <Layout color="blue-600" paddingY={40}>
                  <Text size="body-xs" semibold color="white" align="center">
                    Furniture
                  </Text>
                </Layout>
                <Layout color="blue-400" paddingY={64}>
                  <Text size="body-xs" semibold color="white" align="center">
                    Rent
                  </Text>
                </Layout>
              </Layout>
            </Layout>
          </Layout>
          <Layout display="inline-flex">
            <Layout>
              <Text size="headline-sm" color="gray-400" align="center">
                vs
              </Text>
              {columnHeight && (
                <Layout marginTop={12}>
                  <PriceColumnArrow
                    style={{ height: columnHeight - 5, position: 'relative', top: 9 }}
                    preserveAspectRatio="none"
                    {...(isMobile ? { width: 40 } : { width: 60 })}
                  />
                </Layout>
              )}
            </Layout>
          </Layout>
          <Layout {...(isMobile ? { equalWidth: true } : {})} marginLeft={4}>
            <Text size="headline-md" color="gray-400" align="center">
              {formatUsd(codiPrice, false)}
            </Text>
            <Layout marginTop={12} {...(isMobile ? { flexGrow: 1 } : { width: 164 })}>
              <Layout
                borderTopLeftRadius={8}
                borderTopRightRadius={8}
                color="blue-100"
                paddingY={22}
                borderStyle="dashed"
                borderColor="blue-300"
              >
                <Text size="body-md" semibold color="blue-500" align="center">
                  Save 20%
                </Text>
              </Layout>
              <Layout
                color="blue-500"
                height={268}
                borderTopLeftRadius={8}
                borderTopRightRadius={8}
                position="relative"
                top={-5}
              >
                <Layout position="relative" top={82}>
                  <Text size="body-xs" color="white" align="center" semibold>
                    ALL-INCLUSIVE
                  </Text>
                </Layout>
                <ColumnIcons />
              </Layout>
            </Layout>
          </Layout>
        </Layout>
        <Layout position="relative" top={isMobile ? -5 : -7}>
          <Layout height={2} color="blue-100" {...(isMobile ? { transform: 'scaleX(1.05)' } : {})} />
          <Layout marginTop={12} align="center" {...(isMobile ? {} : { paddingX: 64 })}>
            <Layout {...(isMobile ? { equalWidth: true } : { width: 164 })}>
              <Text size="body-md" color="gray-400" align="center" semibold wrap={false}>
                Traditional Lease
              </Text>
            </Layout>
            <Layout {...(isMobile ? { width: 40 } : { width: 60 })} />
            <Layout {...(isMobile ? { equalWidth: true } : { width: 164 })} display="inline-flex" justify="center">
              <span className={classNames('Logo', colorClassName('blue-500'))}>
                <LogoSvg style={{ width: 50 }} />
              </span>
            </Layout>
          </Layout>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default PriceExplanation;
