import { Listing } from 'shared';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export type SearchState = {
  workspaces: Listing[];
  lastSearchKey: string | null;
  filters: Filters;
};

type Filters = { daysPerWeek: number };

const initialState: SearchState = {
  workspaces: [],
  filters: {
    daysPerWeek: 5
  },
  lastSearchKey: null
};

const searchSlice = createSlice({
  name: 'Search',
  initialState,
  reducers: {
    setWorkspaces: (state, { payload }: PayloadAction<Listing[]>) => {
      state.workspaces = payload.filter(ws => ws.square_feet && ws.square_feet > 0);
    },
    setLastSearchKey: (state, { payload }: PayloadAction<string | null>) => {
      state.lastSearchKey = payload;
    },

    updateFilters: (state, { payload }: PayloadAction<Partial<Filters>>) => {
      state.filters = { ...state.filters, ...payload };
    }
  }
});

export const { actions } = searchSlice;

export default searchSlice.reducer;
