import React, {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useState
} from 'react';
import { useLocation } from 'react-router-dom';
import { ip2LocationRequest } from 'shared';
import { useThrottledCallback, useWindowResize, useWindowScroll } from 'beautiful-react-hooks';

import { EXPIRY_DURATION, locationMissingOrExpired } from 'helpers/location';
import { getItem, setItem } from 'helpers/storage';
import { IpLocation } from 'helpers/types';

import {
  BOTTOM_NAVIGATION_HEIGHT,
  HEADER_HEIGHT,
  HEADER_HEIGHT_MOBILE,
  MAX_CONTENT_WIDTH,
  MD_PAGE_MARGIN,
  PAGE_MARGIN,
  SM_PAGE_MARGIN,
  XS_PAGE_MARGIN
} from 'ds/constants';
import { LayoutOverflow } from 'ds/types';
import { isGoogleUserAgent } from 'helpers';
import { selectUIState } from 'store/UI/selectors';
import { useAppSelector } from 'store/hooks';

import media from './media';

export interface App {
  width: number;
  maxWidth?: number;
  maxContentWidth?: typeof MAX_CONTENT_WIDTH;
  contentHeight: number;
  pageHeight: number;
  windowHeight: number;
  windowWidth: number;
  contentPaddingX: 24 | 40 | 80;
  appPaddingX: 24 | 40;
  sectionGutterY: 0 | 48 | 96 | 136;
  xsContentWidth: number;
  contentWidth: number;
  sectionGutterX?: 24 | 40 | 48;
  fieldSize: 'sm' | 'md' | 'lg';
  navBarHeight: typeof HEADER_HEIGHT | typeof HEADER_HEIGHT_MOBILE | 0;
  overflowY?: LayoutOverflow;
  setOverflowY?: Dispatch<SetStateAction<LayoutOverflow | undefined>>;
  overflowX?: OverflowX;
  setOverflowX?: Dispatch<SetStateAction<OverflowX>>;
  ipLocation?: IpLocation | null;
  scrollY: number;
  footerMarginTop: number;
  isSmallPhone: boolean;
  sidebarIsExpanded?: boolean;
  setSideBarIsExpanded?: (isExpanded: boolean) => void;
  bottomNavigationHeight: number;
}

type OverflowX = 'visible' | 'hidden';

const initialWidth = window.innerWidth;
const initialHeight = window.innerHeight;
const initialScrollY = window.scrollY;
const initialPageHeight = window.document.body.scrollHeight;
const SMALL_PHONE_MAX_HEIGHT = 690;

const AppContext = createContext<App>({
  width: initialWidth,
  windowWidth: initialWidth,
  xsContentWidth: initialWidth - XS_PAGE_MARGIN * 2,
  contentPaddingX: PAGE_MARGIN,
  appPaddingX: 40,
  contentWidth: initialWidth - PAGE_MARGIN * 2,
  fieldSize: 'md',
  navBarHeight: HEADER_HEIGHT,
  sectionGutterY: 0,
  contentHeight: initialHeight,
  pageHeight: initialPageHeight,
  windowHeight: initialHeight,
  scrollY: initialScrollY,
  footerMarginTop: 0,
  isSmallPhone: window.outerHeight < SMALL_PHONE_MAX_HEIGHT,
  bottomNavigationHeight: BOTTOM_NAVIGATION_HEIGHT
});

export const AppProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { body } = window.document;
  const [width, setWidth] = useState(initialWidth);
  const [height, setHeight] = useState(initialHeight);
  const [windowHeight, setWindowHeight] = useState<number>(initialHeight);
  const [pageHeight, setPageHeight] = useState<number>(initialPageHeight);
  const [scrollY, setScrollY] = useState(initialScrollY);
  const [overflowY, setOverflowY] = useState<LayoutOverflow | undefined>();
  const [overflowX, setOverflowX] = useState<OverflowX>('hidden');
  const [ipLocation, setIpLocation] = useState<IpLocation | null>(getItem('ipLocation'));
  const { pathname } = useLocation();
  const isAppFrame = pathname.startsWith('/app/');
  const { bottomNavigationIsVisible } = useAppSelector(selectUIState);
  const { xs, sm, md, lg, xl } = useContext(media);
  const isSmallPhone = window.outerHeight < SMALL_PHONE_MAX_HEIGHT;
  const maxContentWidth = lg ? MAX_CONTENT_WIDTH : undefined;
  const maxWidth = lg ? MAX_CONTENT_WIDTH + PAGE_MARGIN * 2 : undefined;
  const contentPaddingX = lg
    ? isAppFrame
      ? 40
      : PAGE_MARGIN
    : md
    ? MD_PAGE_MARGIN
    : sm
    ? SM_PAGE_MARGIN
    : XS_PAGE_MARGIN;
  const xsContentWidth = width - contentPaddingX * 2;
  const contentWidth = maxWidth ? Math.min(width, maxWidth) - contentPaddingX * 2 : xsContentWidth;
  const sectionGutterX = xl ? 48 : lg ? 40 : md || sm ? 24 : undefined;
  const sectionGutterY = lg ? 136 : md ? 96 : 48;
  const fieldSize = lg ? 'lg' : md || sm ? 'md' : 'sm';
  const navBarHeight = xs ? HEADER_HEIGHT_MOBILE : HEADER_HEIGHT;
  const contentHeight = height - navBarHeight;
  const footerMarginTop = xs ? 64 : sm || md ? 80 : 96;
  const appPaddingX = xs ? 24 : 40;
  const onWindowScroll = useWindowScroll();
  onWindowScroll(
    useThrottledCallback(_event => {
      setScrollY(window.scrollY);
    })
  );

  const onWindowResize = useWindowResize();
  onWindowResize(
    useThrottledCallback(_event => {
      setWidth(body.clientWidth);
      setHeight(body.clientHeight);
      setWindowHeight(window.innerHeight);
    })
  );

  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => setPageHeight(document.body.scrollHeight));

    resizeObserver.observe(document.body);
  }, []);

  // measure 100ms after first render to measure with scroll bar present
  useEffect(() => {
    setTimeout(() => {
      setWidth(body.clientWidth);
    }, 100);
  }, [body.clientWidth]);

  useEffect(() => {
    if (isGoogleUserAgent()) {
      setIpLocation(null);
      return;
    }

    async function setLocation() {
      try {
        if (locationMissingOrExpired()) {
          const response = await ip2LocationRequest().catch(() => {
            return null;
          });

          if (!response) return;

          const data = { ...response, expiry: Date.now() + EXPIRY_DURATION };

          setItem('ipLocation', data);
          setIpLocation(data);
        } else {
          const localStorageIpLocation = getItem('ipLocation');
          setIpLocation(localStorageIpLocation);
        }
      } catch {
        setIpLocation(null);
      }
    }
    setLocation();
  }, []);

  return (
    <AppContext.Provider
      value={{
        width,
        windowWidth: width,
        maxContentWidth,
        contentPaddingX,
        appPaddingX,
        xsContentWidth,
        contentWidth,
        scrollY,
        isSmallPhone,
        sectionGutterY,
        sectionGutterX,
        fieldSize,
        maxWidth,
        navBarHeight,
        overflowY,
        setOverflowY,
        overflowX,
        setOverflowX,
        ipLocation,
        contentHeight,
        pageHeight,
        windowHeight,
        footerMarginTop,
        bottomNavigationHeight: bottomNavigationIsVisible ? BOTTOM_NAVIGATION_HEIGHT : 0
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppContext;
