import React from 'react';
import { useHistory } from 'react-router-dom';
import { Bounds, LatLng, Listing } from 'shared';
import { MapOptions, Props as GoogleMapReactProps } from 'google-map-react';

import { Map } from 'ds';
import { LIGHT_MAP_STYLES } from 'ds/map/constants';
import { OnClusterClick, OnMapChange, OnMarkerClick, OnMarkerClickCenterChange } from 'ds/map/types';
import { selectUser } from 'store/User/selectors';
import { useAppSelector } from 'store/hooks';

interface Props extends Pick<GoogleMapReactProps, 'onGoogleApiLoaded'> {
  workspaces: Listing[];
  selectedWorkspaceId: number | null;
  setSelectedWorkspaceId: (id: number | null) => void;
  splitMapView: boolean;
  center: LatLng;
  zoom: number;
  mapRef: React.MutableRefObject<google.maps.Map | null>;
  bounds?: Bounds;
  daysPerWeek: number;
  offsitesOnly?: boolean;
  options?: MapOptions;
  onMarkerClickCenterChange?: OnMarkerClickCenterChange;
  onClusterClick?: OnClusterClick;
  onMarkerClick?: OnMarkerClick;
  onChange: OnMapChange;
  updateSearch?: boolean;
}

const MapView: React.FC<Props> = ({
  workspaces,
  selectedWorkspaceId,
  setSelectedWorkspaceId,
  splitMapView,
  center,
  zoom,
  mapRef,
  bounds,
  daysPerWeek,
  offsitesOnly,
  options,
  onMarkerClick,
  onClusterClick,
  onChange,
  onGoogleApiLoaded,
  updateSearch = true
}) => {
  const history = useHistory();
  const isLoggedIn = !!useAppSelector(selectUser);

  return (
    <Map
      zoom={zoom}
      onChange={onChange}
      onDragEnd={() => {
        if (!updateSearch) return;

        setTimeout(() => {
          const searchParams = new URLSearchParams(window.location.search);
          searchParams.set('location', 'Map Area');

          history.replace({ search: searchParams.toString() });
        }, 300);
      }}
      center={center}
      bounds={bounds}
      workspaces={workspaces}
      selectedWorkspaceId={selectedWorkspaceId}
      setSelectedWorkspaceId={setSelectedWorkspaceId}
      mapRef={mapRef}
      splitMapView={splitMapView}
      daysPerWeek={daysPerWeek}
      offsitesOnly={offsitesOnly}
      useClusters
      workspaceCardType="search"
      markerType={isLoggedIn ? 'price' : undefined}
      options={{ ...options, styles: LIGHT_MAP_STYLES, maxZoom: 19 }}
      onMarkerClick={onMarkerClick}
      onClusterClick={onClusterClick}
      onGoogleApiLoaded={onGoogleApiLoaded}
    />
  );
};

export default MapView;
