import React, { PropsWithChildren, useContext } from 'react';
import { Color } from 'shared';

import { media } from 'context';
import { Animate, Text } from 'ds';

interface Props {
  color?: Color;
}

const StepDescription: React.FC<PropsWithChildren<Props>> = ({ children, color = 'white' }) => {
  const { xs, sm } = useContext(media);
  const isMobile = xs || sm;

  return isMobile ? (
    <Animate delay={20}>
      <Text size="body1" color={color} bold={false}>
        {children}
      </Text>
    </Animate>
  ) : (
    <Text size="body-md" color={color}>
      {children}
    </Text>
  );
};

export default StepDescription;
