import React, { useContext } from 'react';
import { DateTime } from 'luxon';

import { media } from 'context';
import { BadgeCount, Clickable, Grid, Icon, Layout, PurpleGradientCircle, Text } from 'ds';
import { actions } from 'store/Chatbot';
import { selectChatbotThreads } from 'store/Chatbot/selectors';
import { selectUser } from 'store/User/selectors';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import ChatbotBottomNavigation from './ChatbotBottomNavigation';
import ChatbotNavigationHeader from './ChatbotNavigationHeader';
import { CHATBOT_BOTTOM_NAVIGATION_HEIGHT, CHATBOT_HOME_SCREEN, CHATBOT_NAVIGATION_HEADER_HEIGHT } from './constants';
import useChatbotHeight from './useChatbotHeight';

interface Props {}

const ChatbotThreads: React.FC<Props> = () => {
  const user = useAppSelector(selectUser);
  // TODO: filter
  const threads = useAppSelector(selectChatbotThreads).filter(t => t.chatbot_interactions.length);
  const dispatch = useAppDispatch();
  const height = useChatbotHeight();
  const { isMobile } = useContext(media);

  if (!user) return null;

  return (
    <Layout>
      <ChatbotNavigationHeader backScreenValue={CHATBOT_HOME_SCREEN} showBackButton={false}>
        <Layout flexGrow={1}>
          <Text size="headline-xs" align="center" color="white">
            Messages
          </Text>
        </Layout>
      </ChatbotNavigationHeader>
      <Layout
        {...(isMobile ? {} : { height: height - CHATBOT_NAVIGATION_HEADER_HEIGHT - CHATBOT_BOTTOM_NAVIGATION_HEIGHT })}
        color="white"
        overflowY="auto"
      >
        <Grid itemsPerRow={1}>
          {threads.map(({ id: threadId, chatbot_interactions }) => {
            const lastInteraction = chatbot_interactions[chatbot_interactions.length - 1];
            const codiAvatar = <PurpleGradientCircle dimension={40} />;

            if (!lastInteraction) return '';

            const isSupportTicket = !!lastInteraction.chatbot_intents
              .map(ci => ci.support_ticket?.messages.length || 0)
              .reduce((a, b) => a + b, 0);

            const messagePreview = isSupportTicket
              ? lastInteraction.chatbot_intents
                  .map(ci => ci.support_ticket?.messages.slice(-1)[0].body || '')
                  .reduce((a, b) => a + b, '')
              : lastInteraction.input;

            const systemMessages = isSupportTicket
              ? lastInteraction.chatbot_intents
                  .map(ci => ci.support_ticket?.messages.filter(m => m.author.is_admin) || [])
                  .reduce((a, b) => a.concat(b), [])
              : [];
            const supportTicketView = lastInteraction.chatbot_intents
              .map(ci => ci.support_ticket?.views.find(v => v.user.id === user.id))
              .find(v => v);
            const unviewedSystemMessages = supportTicketView
              ? systemMessages.filter(
                  m => DateTime.fromISO(m.created_at) > DateTime.fromISO(supportTicketView.last_viewed_at)
                )
              : systemMessages;

            return (
              <Clickable
                onClick={e => {
                  e.stopPropagation();
                  dispatch(actions.pushScreen({ value: `threads/${threadId}` }));
                }}
                key={threadId}
              >
                <Layout color="white" paddingX={24}>
                  <Layout borderBottom paddingY={20} align="center" justify="space-between" gap={12}>
                    {codiAvatar}
                    <Layout flexGrow={1} minWidth={0}>
                      <Text align="left" size="body-sm" color="black" ellipsis>
                        {messagePreview}
                      </Text>
                      <Text align="left" size="body-xs" color="gray-500" italic>
                        {DateTime.fromISO(lastInteraction.created_at).toRelative()}
                      </Text>
                    </Layout>
                    {!!unviewedSystemMessages.length && <BadgeCount count={unviewedSystemMessages.length} />}
                    <Icon name="rightChevron" size="xs" />
                  </Layout>
                </Layout>
              </Clickable>
            );
          })}
        </Grid>
      </Layout>
      {!isMobile && <ChatbotBottomNavigation />}
    </Layout>
  );
};

export default ChatbotThreads;
