import { User } from 'shared';

import { userHasRole } from 'store/User/selectors';
import { store } from 'store/store';

import {
  ADMIN_PATH,
  DASHBOARD_PATH,
  HOME_PATH,
  PROPERTIES_LISTINGS_PATH,
  PROPERTIES_PAYOUTS_PATH,
  ROOT_PATH,
  VENDOR_TASKS_PATH
} from './paths';
import { UserRole } from './types';

export function validUserForRole({ user, role }: { user: User; role: UserRole }): boolean {
  const isProspect = !!user.office_leads.length;

  switch (role) {
    case 'user':
      return !!user.id;
    case 'employee':
      return !!user.customer;
    case 'superAdmin':
      return !!user.is_admin;
    case 'vendorAdmin':
      return !!user.vendor?.admin;
    case 'hasWorkspaceRoles':
      return !!user.licensor_roles.length || !!user.workspace_roles.length;
    case 'hasCustomerAdminPlans':
      return !!user.customer_admin_plans;
    case 'hasCustomerAdminPlansOrIsProspect':
      return !!user.customer_admin_plans || isProspect;
    case 'isProspect':
      return !!user.office_leads.length;
    default:
      return false;
  }
}

export function defaultLoginRedirectPath(user: User) {
  if (userHasRole(user, 'admin')) {
    return ADMIN_PATH;
  } else return defaultListingPath(user);
}

export function defaultListingPath(user: User) {
  if (userHasRole(user, 'listing_admin')) {
    return PROPERTIES_LISTINGS_PATH;
  } else if (userHasRole(user, 'payout_admin') || userHasRole(user, 'payout_viewer')) {
    return PROPERTIES_PAYOUTS_PATH;
  } else if (!!user.vendor) {
    return VENDOR_TASKS_PATH;
  } else if (userHasRole(user, 'customer')) {
    return DASHBOARD_PATH;
  } else {
    return HOME_PATH;
  }
}

export function homePath() {
  const isLoggedIn = !!store.getState().User.user;

  return isLoggedIn ? HOME_PATH : ROOT_PATH;
}
