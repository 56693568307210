import React, { useContext } from 'react';
import { Color, ListingWorkspace } from 'shared';

import { getCityString } from 'helpers/bookings';

import { media } from 'context';
import { Icon, Layout, Link, Text, WorkspaceImg } from 'ds';

interface Props {
  workspace: Pick<ListingWorkspace, 'images' | 'name' | 'address' | 'location_info' | 'short_name'>;
  addressColor?: Color;
  isProspectVersion?: boolean;
}

const MiniWorkspaceInfo: React.FC<Props> = ({
  workspace: {
    images,
    name,
    address,
    location_info: { neighborhood, submarket, market },
    short_name
  },
  isProspectVersion,
  addressColor = 'gray-600'
}) => {
  const { isMobile } = useContext(media);

  if (isMobile) {
    return (
      <Layout>
        <Layout>
          <Layout direction="row" align="center" wrap>
            {!market && !submarket && !neighborhood && (
              <Text size="body-sm" color={addressColor} scale>
                {getCityString(address)}
              </Text>
            )}
            {!!market && (
              <Layout direction="row" align="center">
                {!!market.url ? (
                  <Link href={market.url} target="_blank">
                    <Text size="body-sm" color={addressColor} scale>
                      {market.name}
                    </Text>
                  </Link>
                ) : (
                  <Text size="body-sm" color={addressColor} scale>
                    {market.name}
                  </Text>
                )}
                {!!(submarket || neighborhood) && (
                  <Layout paddingX={4} align="center">
                    <Icon name="rightChevron" size="xxs" color={addressColor} />
                  </Layout>
                )}
              </Layout>
            )}
            {!!submarket && (
              <Layout direction="row" align="center">
                {!!submarket.url ? (
                  <Link href={submarket.url} target="_blank">
                    <Text size="body-sm" color={addressColor} scale>
                      {submarket.name}
                    </Text>
                  </Link>
                ) : (
                  <Text size="body-sm" color={addressColor} scale>
                    {submarket.name}
                  </Text>
                )}
                {!!neighborhood && (
                  <Layout paddingX={4} align="center">
                    <Icon name="rightChevron" size="xxs" color={addressColor} />
                  </Layout>
                )}
              </Layout>
            )}
            {!!neighborhood && (
              <Layout direction="row" align="center">
                {!!neighborhood.url ? (
                  <Link href={neighborhood.url} target="_blank">
                    <Text size="body-sm" color={addressColor} scale>
                      {neighborhood.name}
                    </Text>
                  </Link>
                ) : (
                  <Text size="body-sm" color={addressColor} scale>
                    {neighborhood.name}
                  </Text>
                )}
              </Layout>
            )}
          </Layout>
        </Layout>
        <Layout
          {...(isMobile ? { marginTop: 24 } : { marginLeft: 12 })}
          minWidth={0}
          direction="column"
          justify="space-between"
        >
          <Text size="headline-xs" color="gray-900" maxLines={2} bold>
            {isProspectVersion ? short_name : name}
          </Text>
        </Layout>
      </Layout>
    );
  }
  return (
    <Layout align="center">
      <WorkspaceImg
        style={{ width: 64, height: 64, objectFit: 'cover', borderRadius: 8, flexShrink: 0 }}
        srcKey={images[0].key}
        alt="Workspace"
        size="thumbnail"
      />
      <Layout marginLeft={12}>
        <Layout minWidth={0} direction="column" justify="space-between">
          <Text size="headline-xs" color="gray-900" maxLines={2} bold>
            {isProspectVersion ? short_name : name}
          </Text>
        </Layout>
        <Layout marginTop={4}>
          <Text size="body-sm" color={addressColor} scale>
            {getCityString(address)}
          </Text>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default MiniWorkspaceInfo;
