import React, { useContext } from 'react';
import { DEFAULT_LAT_LNG } from 'shared';
import GoogleMapReact from 'google-map-react';

import { app, media } from 'context';
import { Button, Layout, Logo, PinMarker, Text, TextButton } from 'ds';
import { actions } from 'store/AddProperty';
import { selectAddPropertyExitModalIsVisible, selectAddPropertyWorkspace } from 'store/AddProperty/selectors';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import AddPropertyExitModal from './AddPropertyExitModal';
import AddPropertyForm from './AddPropertyForm';

interface Props {}

const AddPropertyStep0: React.FC<Props> = () => {
  const dispatch = useAppDispatch();
  const { isMobile } = useContext(media);
  const { ipLocation, contentPaddingX } = useContext(app);
  const { address } = useAppSelector(selectAddPropertyWorkspace);
  const mapCenter =
    address?.lat && address?.lon ? { lat: address.lat, lng: address.lon } : ipLocation || DEFAULT_LAT_LNG;
  const modalIsVisible = useAppSelector(selectAddPropertyExitModalIsVisible);

  return (
    <>
      {isMobile ? (
        <Layout paddingX={contentPaddingX}>
          <Layout height={72} justify="space-between" align="center">
            <Logo color="blue-500" />
            <TextButton
              textSize="body1"
              color="gray-900"
              onClick={() => dispatch(actions.setExitModalIsVisible(true))}
              text="Exit"
              semibold={false}
            />
          </Layout>
          <Layout paddingTop={36}>
            <Text size="h5" color="blue-700">
              Add a property.
            </Text>
            <Layout marginTop={24}>
              <Text size="body2" color="gray-700">
                Tell us some basic information about your space.
              </Text>
            </Layout>
            <Layout marginTop={24}>
              <AddPropertyForm />
            </Layout>
          </Layout>
        </Layout>
      ) : (
        <Layout flex height="100vh">
          <Layout equalWidth align="center" justify="center" position="relative">
            <Layout maxWidth={500} flexGrow={1} marginX={contentPaddingX}>
              <Text size="h5" color="blue-700">
                Add a property.
              </Text>
              <Layout marginTop={12}>
                <Text size="body2" color="gray-700">
                  Tell us some basic information about your space.
                </Text>
              </Layout>
              <Layout marginTop={36}>
                <AddPropertyForm />
              </Layout>
            </Layout>
            <Layout position="absolute" top={24} left={40}>
              <Logo color="blue-500" />
            </Layout>
          </Layout>
          <Layout height="100%" position="relative" equalWidth>
            <GoogleMapReact
              bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_API }}
              defaultZoom={14}
              options={{ disableDefaultUI: true }}
              center={mapCenter}
            >
              <PinMarker lat={mapCenter.lat} lng={mapCenter.lng} isActive={false} id={0} />
            </GoogleMapReact>
            <Layout position="absolute" top={24} right={40}>
              <Button
                text="Exit"
                size="xs"
                type="primaryInverse"
                onClick={() => dispatch(actions.setExitModalIsVisible(true))}
              />
            </Layout>
          </Layout>
        </Layout>
      )}
      <AddPropertyExitModal isVisible={modalIsVisible} />
    </>
  );
};

export default AddPropertyStep0;
