import { Address, WorkspaceAddressPublic } from './types';

export type FormattableAddress = Pick<
  Address,
  'line1' | 'line2' | 'neighborhood' | 'city' | 'region' | 'postal_code' | 'country'
>;

export function formatPublicAddress({
  neighborhood,
  city,
  region
}: Pick<WorkspaceAddressPublic, 'neighborhood' | 'city' | 'region'>): string {
  return [neighborhood, city, region].filter(Boolean).join(', ');
}

interface FormatIncludes {
  line1?: boolean;
  line2?: boolean;
  neighborhood?: boolean;
  city?: boolean;
  region?: boolean;
  postal_code?: boolean;
}

const ADDRESS_PARTS = ['line1', 'line2', 'neighborhood', 'city', 'region', 'postal_code'] as const;
const DEFAULT_INCLUDE = {
  line1: true,
  line2: true,
  neighborhood: false,
  city: true,
  region: true,
  postal_code: true
} as const;

export function formatAddress(address: Partial<FormattableAddress>, include?: FormatIncludes): string {
  const mergedIncludes = { ...DEFAULT_INCLUDE, ...include };
  const postalCodeInclude = mergedIncludes['postal_code'] ? ` ${address.postal_code}` : '';
  return `${ADDRESS_PARTS.map(part => (mergedIncludes[part] && part !== 'postal_code' ? address[part] : undefined))
    .filter(Boolean)
    .join(', ')}${postalCodeInclude}`;
}

export function formatAddressShort(address: FormattableAddress): string {
  return formatAddress(address, { line1: true, line2: true, city: false, region: false, postal_code: false });
}

export function formatAddressNeighborhoodCity(address: FormattableAddress): string {
  return formatAddress(address, {
    line1: false,
    line2: false,
    neighborhood: true,
    city: true,
    region: true,
    postal_code: false
  });
}

export function stripeUrl({ accountId, isProduction }: { accountId: string; isProduction: boolean }): string {
  return `https://dashboard.stripe.com${isProduction ? '' : '/test'}/connect/accounts/${accountId}`;
}

export function camelize(string: string): string {
  return string
    .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    })
    .replace(/\s+/g, '');
}

export function pascalize(string: string): string {
  const camelized = camelize(string);

  return camelized.slice(0, 1).toUpperCase() + camelized.slice(1);
}

export function escapeRegExp(value: string) {
  return value.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}

export function titleize(string: string): string {
  const stringArray = string.toLowerCase().split(' ');

  for (var i = 0; i < stringArray.length; i++) {
    stringArray[i] = stringArray[i].charAt(0).toUpperCase() + stringArray[i].slice(1);
  }
  return stringArray.join(' ');
}

export function joinWithAnd(strings: string[], useAmpersand = false): string {
  return joinWith(strings, useAmpersand ? '&' : 'and');
}

export function joinWith(strings: string[], connector: string) {
  if (!strings.length) return '';

  if (strings.length === 1) {
    return strings[0];
  }
  if (strings.length === 2) {
    return `${strings[0]} ${connector} ${strings[1]}`;
  }
  return `${strings.slice(0, strings.length - 1).join(', ')}, ${connector} ${strings.slice(-1)[0]}`;
}

export function camelToKebabCase(camelCase: string): string {
  return camelCase.replace(/([a-z0-9]|(?=[A-Z]))([A-Z])/g, '$1-$2').toLowerCase();
}
