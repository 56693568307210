import React from 'react';

import { Layout } from 'ds';
import ChatbotThreadCustomerMessage from 'ux/Chatbot/ChatbotThreadCustomerMessage';
import ChatbotThreadMessage from 'ux/Chatbot/ChatbotThreadMessage';
import { ChatbotIntentProps } from 'ux/Chatbot/types';

import ChatbotViewSupportTicket from '../ChatbotViewSupportTicket';
import ChatbotWidget from '../ChatbotWidget';

interface Props extends Omit<ChatbotIntentProps, 'chatbotIntent'> {}

const Conversation: React.FC<Props> = ({ chatbotInteraction, threadId }) => {
  const { llm_conversation } = chatbotInteraction;

  if (!llm_conversation) {
    return null;
  }

  const { llm_messages } = llm_conversation;

  return (
    <Layout>
      {llm_messages.map((message, index) => (
        <React.Fragment key={message.id}>
          {message.role === 'user' && index > 0 && (
            <Layout marginLeft={24} justify="flex-end">
              <ChatbotThreadCustomerMessage>
                <div dangerouslySetInnerHTML={{ __html: message.content }} />
              </ChatbotThreadCustomerMessage>
            </Layout>
          )}
          {message.role === 'assistant' && (
            <Layout marginTop={16}>
              <ChatbotThreadMessage>
                <div dangerouslySetInnerHTML={{ __html: message.content }} />
              </ChatbotThreadMessage>
            </Layout>
          )}
          {message.role === 'metadata' && message.metadata.resolution?.type === 'support_ticket' && (
            <Layout marginTop={16}>
              <ChatbotViewSupportTicket supportTicket={message.metadata.resolution?.support_ticket} />
            </Layout>
          )}
          {message.role === 'metadata' && message.metadata.resolution?.type === 'widget' && (
            <Layout marginTop={16}>
              <ChatbotWidget
                chatbotInteraction={chatbotInteraction}
                threadId={threadId}
                chatbotIntentId={message.chatbot_intent_id}
              />
            </Layout>
          )}
        </React.Fragment>
      ))}
    </Layout>
  );
};

export default Conversation;
