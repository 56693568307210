import { CreateableWorkspace, SaveableWorkspace, WorkspaceAddress } from 'shared';

import { EditableListing } from './types';

export const pickedListingFields = ({
  id,
  square_feet,
  external_listing_url,
  workspace_features,
  editableImages,
  address,
  first_hosting_availability,
  building_class,
  workspace_price
}: EditableListing) => ({
  id,
  address,
  square_feet,
  external_listing_url,
  workspace_features,
  editableImages,
  first_hosting_availability,
  building_class,
  workspace_price
});

export function isCreatableWorkspace(workspace: Partial<CreateableWorkspace>): workspace is CreateableWorkspace {
  const { address, workspace_price } = workspace;
  const hasAddress = !!address?.line1 && !!address?.city && !!address?.region && !!address?.postal_code;
  const hasAskingRent = !!workspace_price?.asking_rent;

  return (
    hasAddress &&
    hasAskingRent &&
    !!workspace.square_feet &&
    !!workspace.first_hosting_availability &&
    !!workspace.square_feet &&
    !!workspace.building_class
  );
}

export function isSaveableWorkspace(workspace: Partial<SaveableWorkspace>): workspace is SaveableWorkspace {
  return !!workspace.id;
}

export function isWorkspaceAddress(address: Partial<WorkspaceAddress>): address is WorkspaceAddress {
  return !!address.line1 && !!address.city && !!address.region && !!address.postal_code;
}
