import { ReactComponent as Checkmark } from 'shared/icons/Checkmark.svg';
import { ReactComponent as Minus } from 'shared/icons/Minus.svg';
import classNames from 'classnames';

import Layout from 'ds/Layout';
import Text from 'ds/Text';
import useFieldSize from 'hooks/useFieldSize';
import { track } from 'lib/analytics';

import useUniqueId from './helpers/useUniqueId';
import { FieldSize } from './types';

export interface Props {
  value?: CheckboxState;
  disabled?: boolean;
  size?: FieldSize;
  label: string;
  onChange: CheckboxOnChange;
  context?: string;
}

export type CheckboxState = 'checked' | 'indeterminate' | 'off';

export interface OnChangeParams {
  event: React.ChangeEvent<HTMLInputElement>;
  value: CheckboxState;
}
export type CheckboxOnChange = (params: OnChangeParams) => void;

const Checkbox: React.FC<Props> = ({ value = 'off', disabled, label, onChange, context, ...props }) => {
  const scaledSize = useFieldSize();
  const size = props.size || scaledSize;
  const id = useUniqueId();

  return (
    <Layout
      __className={classNames('Checkbox', `is-size-${size}`, {
        'is-checked': ['checked', 'indeterminate'].includes(value),
        'is-disabled': disabled || value === 'off'
      })}
      align="center"
    >
      <input
        id={id}
        className="Checkbox-input"
        onChange={e => {
          const { checked } = e.target;
          const value = checked ? 'checked' : 'off';
          onChange({ event: e, value });
          track('Element Interacted', {
            context,
            name: label,
            type: 'checkBox',
            value: checked ? 'checked' : 'unchecked'
          });
        }}
        checked={['checked'].includes(value)}
        disabled={disabled}
        type="checkbox"
      />
      <div className="Checkbox-box">
        {value === 'checked' && (
          <div className="Checkbox-checkmark">
            <Checkmark />
          </div>
        )}
        {value === 'indeterminate' && (
          <div className="Checkbox-checkmark">
            <Minus />
          </div>
        )}
      </div>
      <label className="Checkbox-label" htmlFor={id}>
        <Text wrap={false} inline scale>
          {label}
        </Text>
      </label>
    </Layout>
  );
};

export default Checkbox;
