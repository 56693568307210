import { ProductKey } from 'shared';
import { createSelector } from '@reduxjs/toolkit';

import { CLEANING_PRODUCT_KEYS, DEEP_CLEANING, PHONE_BOOTH, WEEKLY_CLEANING, WEEKLY_TOUCH_UP } from 'helpers/constants';

import { RootState } from 'store/store';

export const selectCountries = (state: RootState) => state.App.countries;
export const selectAnonymousId = (state: RootState) => state.App.anonymousId;
export const selectCmsPage = (state: RootState, slug: string) => state.App.cmsPages.find(page => page.slug === slug);
export const selectCmsPages = (state: RootState) => state.App.cmsPages;
export const selectLocations = (state: RootState) => state.App.locations;
export const selectDynamicCmsPages = (state: RootState) =>
  state.App.cmsPages.filter(p => p.page_type === 'public_page' && !!p.published);
export const selectAppState = (state: RootState) => state.App.state;
export const selectCities = (state: RootState) => state.App.cities;
export const selectMarkets = (state: RootState) => state.App.markets;
export const selectGoogleOAuthLoaded = (state: RootState) => state.App.googleOAuthLoaded;
export const selectGoogleMapsLoaded = (state: RootState) => state.App.googleMapsLoaded;
export const selectProducts = (state: RootState) => state.App.products;
export const selectConsumableCategories = (state: RootState) => state.App.consumableCategories;
export const selectConsumables = (state: RootState) => state.App.consumables;
export const selectDeepCleaningProduct = (state: RootState) => state.App.products.find(p => p.key === DEEP_CLEANING);
export const selectPhoneBoothProduct = (state: RootState) => state.App.products.find(p => p.key === PHONE_BOOTH);
export const selectPublishedNonServiceProducts = createSelector(selectProducts, products =>
  products.filter(p => p.key !== DEEP_CLEANING && p.is_published === true && p.category !== 'services')
);
export const selectCleaningProductIds = createSelector(selectProducts, products =>
  products.filter(p => CLEANING_PRODUCT_KEYS.includes(p.key as ProductKey)).map(p => p.id)
);

export const selectWeeklyCleaningProduct = (state: RootState) =>
  state.App.products.find(p => p.key === WEEKLY_CLEANING);
export const selectWeeklyTouchUpProduct = (state: RootState) => state.App.products.find(p => p.key === WEEKLY_TOUCH_UP);
