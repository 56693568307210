import React, { useContext } from 'react';

import { media } from 'context';
import { Icon, Img, Layout, Link, Text } from 'ds';
import { CASE_STUDIES_PATH } from 'routes';

interface Props {}

const CaseStudy: React.FC<Props> = () => {
  const { isMobile, md, xs } = useContext(media);

  return (
    <Layout {...(isMobile ? {} : { flex: true })}>
      <Layout {...(isMobile ? {} : { maxWidth: md ? 460 : 520 })}>
        <Text size={isMobile ? 'overline-md' : 'overline-lg'} color="blue-500" semibold>
          SEE WHAT CLIENTS SAY
        </Text>
        <Layout marginTop={12}>
          <Text size={isMobile ? 'headline-md' : 'headline-lg'} color="blue-700">
            Taskrabbit improves collaboration for over 200 employees
          </Text>
        </Layout>
        <Layout marginTop={32}>
          <Text size="body-md" color="gray-400">
            Codi helped TaskRabbit host 8 offsites across the span of 12 months. Discover how Codi's workspaces and
            managed services helped drive this success.
          </Text>
        </Layout>
        <Layout marginTop={32}>
          <Link href={`${CASE_STUDIES_PATH}/taskrabbit`}>
            <Layout align="center">
              <Text size={isMobile ? 'body-md' : 'body-sm'} color="blue-500" semibold>
                Read full story
              </Text>
              <Layout marginLeft={8} display="inline-flex">
                <Icon name="rightArrow" color="blue-500" size="sm" stroke={2} />
              </Layout>
            </Layout>
          </Link>
        </Layout>
      </Layout>
      <Layout {...(isMobile ? { marginTop: 48 } : { marginLeft: 80 })}>
        <Img
          srcKey={
            isMobile ? 'mobile_taskRabbit_case_study_image_x6sTTaBDl.jpeg' : 'home-page-case-study_NC6bLcu5G.jpeg'
          }
          alt="office"
          {...(isMobile ? { width: 1226, height: 611 } : { width: 1380, height: 780 })}
          folder="public"
          style={{
            borderRadius: 16,
            height: xs ? 237 : 360,
            objectFit: 'cover'
          }}
        />
      </Layout>
    </Layout>
  );
};

export default CaseStudy;
