import React, { useEffect } from 'react';
import { OfficeLeadTour } from 'shared';

import { APP_BANNER_HEIGHT, HEADER_Z_INDEX, Icon, Layout, Link, Text } from 'ds';
import { CUSTOMER_OFFICE_REQUEST_PATH } from 'routes';
import { actions } from 'store/UI';
import { selectUIState } from 'store/UI/selectors';
import { selectOfficeLeads, selectRecommendedWorkspaces, selectUser, selectUserId } from 'store/User/selectors';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getMostRecentCompletedTour } from 'ux/Customer/OfficeRequest/utils';

interface Props {}

const CustomerBanner: React.FC<Props> = () => {
  const officeLeads = useAppSelector(selectOfficeLeads);
  const recommendedWorkspaces = useAppSelector(selectRecommendedWorkspaces);
  const userId = useAppSelector(selectUserId);
  const user = useAppSelector(selectUser);
  const { appBannerType } = useAppSelector(selectUIState);
  const dispatch = useAppDispatch();

  const getBannerAttributes = () => {
    if (!user) return;

    let lastCompletedTourWithWorkspacesToReview: OfficeLeadTour | null = null;
    let officeLeadIdWithWorkspacesToReview: number | null = null;

    for (const officeLead of officeLeads) {
      const tour = getMostRecentCompletedTour(officeLead.tours);

      if (tour && !!tour.guests.find(g => g.id === userId) && tour.destinations.some(d => !d.review)) {
        lastCompletedTourWithWorkspacesToReview = tour;
        officeLeadIdWithWorkspacesToReview = officeLead.id;
        break;
      }
    }

    if (lastCompletedTourWithWorkspacesToReview) {
      if (officeLeadIdWithWorkspacesToReview) {
        const destinationsToReview = lastCompletedTourWithWorkspacesToReview.destinations.filter(d => !d.review);

        if (destinationsToReview.length) {
          return {
            lastCompletedTourWithWorkspacesToReview,
            officeLeadIdWithWorkspacesToReview,
            destinationsToReview,
            type: 'review'
          } as const;
        }
      }
    }

    for (const officeLead of officeLeads) {
      const recommendedWorkspaces = officeLead.workspaces.filter(
        ws => !!ws.user_interactions.find(ui => ui.status === 'proposed' && ui.user.id === user.id && !ui.added_at)
      );

      if (recommendedWorkspaces.length) {
        const lastUserInteraction = recommendedWorkspaces
          .slice(-1)[0]
          .user_interactions.find(u => u.user.id === userId);
        const lastProposer = lastUserInteraction?.proposer;

        return {
          lastProposer,
          officeLeadId: officeLead.id,
          type: 'recommendations'
        } as const;
      }
    }
  };

  const bannerAttributes = getBannerAttributes();

  useEffect(() => {
    if (!officeLeads.length || !userId) {
      return;
    }

    if (!bannerAttributes) {
      dispatch(actions.updateUIState({ appBannerType: null }));
    } else {
      dispatch(actions.updateUIState({ appBannerType: bannerAttributes.type }));
    }
  }, [bannerAttributes?.type]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!appBannerType) return null;

  if (!bannerAttributes) return null;

  let content: JSX.Element | null = null;

  if (bannerAttributes.type === 'review') {
    content = (
      <Link
        color="white"
        href={`${CUSTOMER_OFFICE_REQUEST_PATH}/${bannerAttributes.officeLeadIdWithWorkspacesToReview}`}
        search="?review=1"
        opacity={false}
        fullWidth
      >
        <Layout color="red-100" height={APP_BANNER_HEIGHT} align="center" justify="center">
          <Icon name="heartOutline" stroke={2} color="red-700" size="xs" />
          <Layout marginLeft={8}>
            <Text size="body-xs" color="red-700" semibold>
              Rate the offices you toured
            </Text>
          </Layout>
          <Layout marginLeft={20} align="center" paddingX={8}>
            <Text size="body-xs" color="red-700" semibold>
              {bannerAttributes.destinationsToReview.length}
            </Text>
            <Layout marginLeft={4} display="inline-flex">
              <Icon name="rightChevron" size="xs" color="red-700" />
            </Layout>
          </Layout>
        </Layout>
      </Link>
    );
  }

  if (bannerAttributes.type === 'recommendations') {
    content = (
      <Link
        color="white"
        href={`${CUSTOMER_OFFICE_REQUEST_PATH}/${bannerAttributes.officeLeadId}`}
        search="?recommendations=1"
        opacity={false}
        fullWidth
      >
        <Layout color="blue-700" height={APP_BANNER_HEIGHT} align="center" justify="center">
          <Icon name="notification" stroke={2} color="white" size="xs" />
          <Layout marginLeft={8}>
            <Text size="body-xs" color="white" semibold>
              {bannerAttributes.lastProposer
                ? `${bannerAttributes.lastProposer.firstname} sent you offices to review`
                : 'You have offices to review'}
            </Text>
          </Layout>
          <Layout align="center" marginLeft={20} paddingX={8}>
            <Text size="body-xs" color="white" semibold>
              {recommendedWorkspaces.length}
            </Text>
            <Layout marginLeft={4} display="inline-flex">
              <Icon name="rightChevron" size="xs" color="white" />
            </Layout>
          </Layout>
        </Layout>
      </Link>
    );
  }

  if (!content) return null;

  return (
    <Layout position="sticky" top={0} zIndex={HEADER_Z_INDEX}>
      {content}
    </Layout>
  );
};

export default CustomerBanner;
