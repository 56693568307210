import { EditableImage } from 'shared/types';
import axios from 'shared/lib/axios';
import { getBlob } from 'shared/file';

export function setAuthorizationHeader(token: string) {
  setHeaders({ Authorization: 'Bearer ' + token });
}

export function setAnonymousIdHeader(anonymousId: string) {
  setHeaders({ AnonymousId: anonymousId });
}

interface Headers {
  Authorization?: string;
  AnonymousId?: string;
}

function setHeaders(headers: Headers) {
  return (axios.defaults.headers = { ...axios.defaults.headers, ...headers });
}

export function setBaseUrl(url: string) {
  axios.defaults.baseURL = url;
}

interface ImageUploadParams {
  existingImageIds?: number[];
  images?: EditableImage[];
}

export async function imageUploadPrep({ existingImageIds = [], images = [] }: ImageUploadParams) {
  const formData = new FormData();
  const imageIds = images.map(image => image.id).filter(id => id !== undefined);
  const newImages: { id: string; url?: string; key?: string }[] = [];

  let newImageIdx = 1;
  const order = images.map(image => {
    if (image.id) {
      return image.id;
    } else {
      const imageId = `image${newImageIdx}`;

      newImages.push({ id: imageId, url: image.url, key: image.key });

      newImageIdx++;
      return imageId;
    }
  });

  await Promise.all(
    newImages.map(async image => {
      if (image.url) {
        const blob = await getBlob(image.url);

        formData.append(image.id, blob);
      }
    })
  );

  formData.append('order', order.join(','));

  const deleted_image_ids = existingImageIds.filter(id => !imageIds.includes(id));

  if (deleted_image_ids.length) {
    formData.append('deleted_image_ids', deleted_image_ids.join(','));
  }

  return formData;
}
