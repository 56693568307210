import { Updatable, VendorTask, updateInList } from 'shared';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { VendorUser } from 'types';
import { VendorTaskSchedule } from 'ux/Vendor/TaskSchedules/types';

type VendorState = {
  vendorUsers: VendorUser[];
  tasks: VendorTask[];
  taskSchedules: VendorTaskSchedule[];
};

export const VENDOR_INITIAL_STATE: VendorState = {
  vendorUsers: [],
  tasks: [],
  taskSchedules: []
};

const vendorSlice = createSlice({
  name: 'Vendor',
  initialState: VENDOR_INITIAL_STATE,
  reducers: {
    setVendorUsers: (state, action: PayloadAction<VendorUser[]>) => {
      state.vendorUsers = action.payload;
    },
    addVendorUser: (state, action: PayloadAction<VendorUser>) => {
      state.vendorUsers.push(action.payload);
    },
    updateVendorUser: (state, action: PayloadAction<Partial<VendorUser> & { id: number }>) => {
      const index = state.vendorUsers.findIndex(user => user.id === action.payload.id);
      if (index !== -1) {
        state.vendorUsers[index] = { ...state.vendorUsers[index], ...action.payload };
      }
    },
    removeVendorUser: (state, action: PayloadAction<{ id: number }>) => {
      state.vendorUsers = state.vendorUsers.filter(user => user.id !== action.payload.id);
    },
    setTasks: (state, action: PayloadAction<VendorTask[]>) => {
      state.tasks = action.payload;
    },
    updateTask: (state, { payload }: PayloadAction<Updatable<VendorTask>>) => {
      updateInList(state.tasks, payload);
    },
    setTaskSchedules: (state, action: PayloadAction<VendorTaskSchedule[]>) => {
      state.taskSchedules = action.payload;
    },
    updateTaskSchedule: (state, { payload }: PayloadAction<Updatable<VendorTaskSchedule>>) => {
      updateInList(state.taskSchedules, payload);
    }
  }
});

export const { actions } = vendorSlice;

export default vendorSlice.reducer;
