import React, { useContext, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { PRODUCTION_URL, colorClassName, formatDate } from 'shared';
import classNames from 'classnames';
import { DateTime } from 'luxon';
import qs from 'qs';
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton } from 'react-share';
import { BlogPosting, WithContext } from 'schema-dts';

import Head from 'components/Head';
import HeroImage from 'components/HeroImage';

import { app, media } from 'context';
import { Avatar, Icon, Layout, Text } from 'ds';
import butter, { handleError } from 'lib/butter';
import Page404 from 'ux/Page/404';

import Facebook from './share/facebook.svg';
import LinkedIn from './share/linkedin.svg';
import { ReactComponent as X } from 'assets/images/footer/x-logo.svg';

interface RouteMatchProps {
  slug?: string;
}

interface Props extends RouteComponentProps<RouteMatchProps> {}

const Article: React.FC<Props> = ({ match, history }) => {
  const [blogResponse, setBlogResponse] = useState<any>();
  const [is404, setIs404] = useState<boolean>();

  const { maxWidth, contentPaddingX } = useContext(app);
  const { xs } = useContext(media);
  const { preview } = qs.parse(history.location.search, { ignoreQueryPrefix: true });
  const slug = match.params.slug;

  useEffect(() => {
    if (!slug) {
      setIs404(true);
      return;
    }
    butter.post
      .retrieve(slug)
      .then(function (resp) {
        if (resp.data?.data.status !== 'published' && preview !== '1') {
          setIs404(true);
        } else {
          setBlogResponse(resp.data);
        }
      })
      .catch(function (error) {
        handleError({ slug, error });
        setIs404(true);
      });
  }, [preview, slug]);

  if (is404) {
    return <Page404 />;
  }

  if (!blogResponse) return <Layout minHeight="100vh" />;

  const getArticleLink = (slug: string) => {
    const previewSuffix = preview === '1' ? '?preview=1' : '';
    return `/blog/${slug}${previewSuffix}`;
  };

  const { meta, data } = blogResponse;
  const { author } = data;

  const schema: WithContext<BlogPosting> = {
    '@context': 'https://schema.org',
    '@type': 'BlogPosting',
    '@id': `${window.location.origin}/blog/${data.slug}`,
    author: {
      '@type': 'Person',
      name: `${data.author.first_name} ${data.author.last_name}`,
      image: data.author.profile_image
    },
    headline: data.title,
    url: `${window.location.origin}/blog/${data.slug}`,
    datePublished: data.published,
    dateModified: data.updated,
    image: data.featured_image,
    thumbnailUrl: data.featured_image,
    articleBody: data.body.replace(/<\/?[^>]+>/gi, '')
  };

  return (
    <>
      <Head title={data.seo_title} description={data.meta_description} />
      <script type="application/ld+json">{JSON.stringify(schema)}</script>
      <HeroImage img={data.featured_image} height={xs ? 408 : 500}>
        <Layout position="absolute" width="100%" height="100%" color="black" opacity={0.6} zIndex={1} />
        <Layout position="relative" width="100%" height="100%" justify="center" align="center" zIndex={2}>
          <Layout width="100%" __style={{ maxWidth }} paddingX={contentPaddingX}>
            <Text size="body2" color="white" scale>
              {formatDate({ date: DateTime.fromISO(data.published), format: 'FULL_WITH_YEAR' })}
            </Text>
            <Layout marginY={16}>
              <Text tag="h2" color="white" scale>
                {data.title}
              </Text>
            </Layout>
            <Layout direction="row" align="center">
              <Avatar size={24} avatarUrl={author.profile_image} />
              <Layout marginLeft={8} align="center">
                <Text size="body2" color="white" scale>
                  Written by {author.first_name} {author.last_name}
                </Text>
              </Layout>
            </Layout>
          </Layout>
        </Layout>
      </HeroImage>
      <Layout justify="center" width="100%">
        <Layout justify="flex-start" width="100%" maxWidth={744} marginTop={64} __className="CmsContent">
          <div dangerouslySetInnerHTML={{ __html: data.body }} />
        </Layout>
      </Layout>
      <Layout align="center" direction="row" marginY={48} paddingY={16} borderTop borderBottom borderColor="gray-600">
        <Text size="body2" scale>
          Share:
        </Text>
        <Layout marginLeft={32} cursor="pointer" display="inline-flex">
          <TwitterShareButton url={window.location.href} title={data.title} style={{ display: 'inline-flex' }}>
            <span className={classNames('Logo', colorClassName('gray-700'))} style={{ display: 'inline-flex' }}>
              <X width={24} height={24} />
            </span>
          </TwitterShareButton>
        </Layout>{' '}
        <Layout marginLeft={32} cursor="pointer" display="inline-flex">
          <LinkedinShareButton
            url={window.location.href}
            title={data.title}
            summary={data.summary}
            source={PRODUCTION_URL}
            style={{ display: 'inline-flex' }}
          >
            <img src={LinkedIn} width={24} height={24} alt="Share on LinkedIn" />
          </LinkedinShareButton>
        </Layout>{' '}
        <Layout marginLeft={32} cursor="pointer" display="inline-flex">
          <FacebookShareButton url={window.location.href} quote={data.summary} style={{ display: 'inline-flex' }}>
            <img src={Facebook} width={24} height={24} alt="Share on Facebook" />
          </FacebookShareButton>
        </Layout>
      </Layout>
      <Layout direction="row" justify="space-between" width="100%">
        {meta.previous_post && (
          <a href={getArticleLink(meta.previous_post.slug)} title={meta.previous_post.title}>
            <Layout direction="row" align="center">
              <Layout width={48} height={48} color="blue-500" borderRadius="circular" justify="center" align="center">
                <Icon name="leftArrow" color="white" size="xs" />
              </Layout>
              <Layout marginLeft={24}>
                <Text color="blue-500" size="body2" bold scale>
                  Previous
                </Text>
              </Layout>
            </Layout>
          </a>
        )}
        {meta.next_post && (
          <a href={getArticleLink(meta.next_post.slug)} title={meta.next_post.title}>
            <Layout direction="row" align="center">
              <Layout marginRight={24}>
                <Text color="blue-500" size="body2" bold scale>
                  Next
                </Text>
              </Layout>
              <Layout width={48} height={48} color="blue-500" borderRadius="circular" justify="center" align="center">
                <Icon name="rightArrow" color="white" size="xs" />
              </Layout>
            </Layout>
          </a>
        )}
      </Layout>
    </>
  );
};

export default Article;
