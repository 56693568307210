import React, { CSSProperties, PropsWithChildren, useContext, useState } from 'react';
import { Color } from 'shared';
import classNames from 'classnames';

import { app, media } from 'context';
import { ButtonType, Layout } from 'ds';
import StickyHeader from 'ux/Layouts/Shared/StickyHeader';

import Footer, { Props as FooterProps } from './Footer';
import Header from './Header';
import Menu from './Menu';
import { PLAYBOOK_CONTENT_MAX_WIDTH, PLAYBOOK_FOOTER_HEIGHT } from './constants';

import { ReactComponent as GradientCloud } from './gradient-cloud.svg';

interface Props extends FooterProps {
  gradientCloudOrientation?: GradientCloudOrientation;
  backgroundColor?: Color | 'transparent';
  step: number;
  buttonType?: ButtonType;
  chapterTitle?: string;
}

export type GradientCloudOrientation = 'topRight' | 'topLeft' | 'bottomRight';

const PlaybookTemplate: React.FC<PropsWithChildren<Props>> = ({
  children,
  backHref,
  forwardHref,
  pageNumber,
  pageCount,
  gradientCloudOrientation,
  backgroundColor,
  step,
  buttonType,
  chapterTitle
}) => {
  const { xs } = useContext(media);
  const [menuClass, setMenuClass] = useState<string>();
  const { contentPaddingX, width, windowHeight } = useContext(app);

  const closeMenu = () => setMenuClass('is-closed');

  let gradientCloudStyle: CSSProperties = {};

  if (gradientCloudOrientation === 'topLeft') {
    gradientCloudStyle = { transform: 'scaleX(-1)' };
  } else if (gradientCloudOrientation === 'bottomRight') {
    gradientCloudStyle = { transform: 'scaleY(-1) translateX(400px)' };
  }

  return (
    <>
      {gradientCloudOrientation && (
        <Layout position="absolute" width={width} height={windowHeight} overflow="hidden" top={0} left={0} zIndex={-1}>
          <Layout __style={gradientCloudStyle}>
            <GradientCloud width={width} />
          </Layout>
        </Layout>
      )}
      <StickyHeader
        top={0}
        zIndex={2}
        backgroundColor={
          backgroundColor ||
          ((gradientCloudOrientation === 'bottomRight' || !gradientCloudOrientation) && !backgroundColor
            ? 'white'
            : 'transparent')
        }
      >
        <Layout justify="center">
          <Layout
            paddingX={contentPaddingX}
            flexGrow={1}
            maxWidth={xs ? width : PLAYBOOK_CONTENT_MAX_WIDTH}
            position="relative"
          >
            <Header openMenu={() => setMenuClass('is-open')} buttonType={buttonType} chapterTitle={chapterTitle} />
          </Layout>
        </Layout>
      </StickyHeader>
      <Layout __className={classNames('Playbook-Menu', menuClass)}>
        <Menu closeMenu={closeMenu} step={step} />
      </Layout>
      <Layout
        justify="center"
        flexGrow={1}
        color={backgroundColor === 'transparent' ? undefined : backgroundColor}
        paddingBottom={PLAYBOOK_FOOTER_HEIGHT + 24}
        zIndex={1}
      >
        <Layout
          paddingX={contentPaddingX}
          flexGrow={1}
          maxWidth={xs ? width : PLAYBOOK_CONTENT_MAX_WIDTH}
          position="relative"
        >
          {children}
        </Layout>
      </Layout>
      <Layout position="fixed" bottom={0} left={0} width={xs ? width : width} justify="center" color="white">
        <Layout paddingX={contentPaddingX} flexGrow={1} maxWidth={xs ? width : PLAYBOOK_CONTENT_MAX_WIDTH}>
          <Footer backHref={backHref} forwardHref={forwardHref} pageNumber={pageNumber} pageCount={pageCount} />
        </Layout>
      </Layout>
    </>
  );
};

export default PlaybookTemplate;
