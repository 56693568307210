import React, { useContext, useEffect, useState } from 'react';
import { Listing } from 'shared';

import { app, media } from 'context';
import { Button, Layout, Text, WorkspacePromoCarousel } from 'ds';
import { getPage } from 'lib/butter';
import { OFFSITES_PATH } from 'routes';

import { findWorkspaces } from '../requests';

interface Props {}

const SFTechWeekWorkspaces: React.FC<Props> = () => {
  const { xs, md } = useContext(media);
  const { contentPaddingX, contentWidth, windowWidth } = useContext(app);
  const [workspaces, setWorkspaces] = useState<Listing[]>();

  useEffect(() => {
    getPage({ slug: 'offsites' }).then(page => {
      if (!page) return;

      const ids = (page.fields as any).sections
        .find((s: { type: string }) => s.type === 'workspaces')
        .fields.ids.map((obj: { id: number }) => obj.id);
      findWorkspaces({ workspaceIds: ids }).then(({ data }) => {
        setWorkspaces(data);
      });
    });
  }, []);

  if (!workspaces) return null;

  if (xs) {
    return (
      <>
        <Layout paddingTop={108} paddingX={contentPaddingX}>
          <Text size="h5" color="blue-700">
            Private Workspaces
          </Text>
          <Layout marginTop={12}>
            <Text size="h6" color="blue-400">
              Join your TechWeek cohort in a Codi space in downtown SF. Location TBD.
            </Text>
          </Layout>
          <Layout marginTop={12}>
            <Text size="h6" color="blue-400">
              Host an event or collaborate with your team.
            </Text>
          </Layout>
        </Layout>
        <Layout marginTop={36} paddingX={contentPaddingX} width={windowWidth} overflow="hidden">
          <WorkspacePromoCarousel
            isStb
            workspaces={workspaces.map(w => ({
              ...w,
              image: w.images[0]
            }))}
            key={contentWidth}
          />
        </Layout>
        <Layout marginTop={36} justify="center" align="center" paddingX={contentPaddingX}>
          <Button text="Book your space" size="lg" type="secondary" href={OFFSITES_PATH} />
        </Layout>
      </>
    );
  }

  return (
    <Layout justify="center" paddingY={108}>
      <Layout maxWidth={md ? contentWidth : 1200}>
        <Layout align="center" direction="column">
          <Text size="h4" color="blue-700">
            Private Workspaces
          </Text>
          <Layout marginTop={12}>
            <Text size="h6" color="blue-400">
              Host an event or collaborate with your team.
            </Text>
          </Layout>
        </Layout>
        <Layout marginTop={36}>
          <WorkspacePromoCarousel
            isStb
            workspaces={workspaces
              .filter(w => !!w.images.length)
              .map(w => ({
                ...w,
                image: w.images[0]
              }))}
          />
        </Layout>
        <Layout marginTop={36} align="center" direction="column">
          <Button text="Book your space" size="lg" type="secondary" href={OFFSITES_PATH} />
        </Layout>
      </Layout>
    </Layout>
  );
};

export default SFTechWeekWorkspaces;
