import {
  CreateableVendorPayoutAccount,
  CreateableVendorPayoutMethodAddress,
  CreateableVendorPayoutMethodBank
} from './types';

import { VendorPayoutAccount } from 'shared/types';
import axios from 'shared/lib/axios';

type VendorIdParam = { id: number };

function vendorPayoutAccountsPath({ id }: VendorIdParam) {
  return `/v1/vendors/${id}/payout_accounts`;
}

function vendorPayoutAccountPath({ vendorId, payoutAccountId }: { vendorId: number; payoutAccountId: number }) {
  return `/v1/vendors/${vendorId}/payout_accounts/${payoutAccountId}`;
}

function createVendorPayoutMethodPath({ vendorId, payoutAccountId }: { vendorId: number; payoutAccountId: number }) {
  return `/v1/vendors/${vendorId}/payout_accounts/${payoutAccountId}/payout_methods`;
}

function updateVendorPayoutMethodPath({
  vendorId,
  payoutAccountId,
  payoutMethodId
}: {
  vendorId: number;
  payoutAccountId: number;
  payoutMethodId: string;
}) {
  return `/v1/vendors/${vendorId}/payout_accounts/${payoutAccountId}/payout_methods/${payoutMethodId}`;
}

export function getVendorPayoutAccount({ id }: VendorIdParam) {
  return axios.get<VendorPayoutAccount>(vendorPayoutAccountsPath({ id }));
}

export function createVendorPayoutAccount({
  vendorId,
  payoutAccount
}: {
  vendorId: number;
  payoutAccount: CreateableVendorPayoutAccount;
}) {
  return axios.post<VendorPayoutAccount>(vendorPayoutAccountsPath({ id: vendorId }), {
    ...payoutAccount
  });
}

export function updateVendorPayoutAccount({
  vendorId,
  payoutAccountId,
  payoutDay,
  defaultRevSharePercentage,
  payoutUpcomingMonth
}: {
  vendorId: number;
  payoutAccountId: number;
  payoutDay?: number;
  defaultRevSharePercentage?: number;
  payoutUpcomingMonth?: boolean;
}) {
  return axios.patch<VendorPayoutAccount>(vendorPayoutAccountPath({ vendorId, payoutAccountId }), {
    payout_day: payoutDay,
    default_rev_share_percentage: defaultRevSharePercentage,
    payout_upcoming_month: payoutUpcomingMonth
  });
}

export function createVendorPayoutMethodBank({
  vendorId,
  payoutAccountId,
  payoutMethod
}: {
  vendorId: number;
  payoutAccountId: number;
  payoutMethod: CreateableVendorPayoutMethodBank;
}) {
  return axios.post<VendorPayoutAccount>(createVendorPayoutMethodPath({ vendorId, payoutAccountId }), {
    type: 'bank',
    bank: { ...payoutMethod }
  });
}

export function createVendorPayoutMethodAddress({
  vendorId,
  payoutAccountId,
  payoutMethod
}: {
  vendorId: number;
  payoutAccountId: number;
  payoutMethod: CreateableVendorPayoutMethodAddress;
}) {
  return axios.post<VendorPayoutAccount>(createVendorPayoutMethodPath({ vendorId, payoutAccountId }), {
    type: 'address',
    address: { ...payoutMethod }
  });
}

export function updateVendorPayoutMethod({
  vendorId,
  payoutAccountId,
  payoutMethodId
}: {
  vendorId: number;
  payoutAccountId: number;
  payoutMethodId: string;
}) {
  return axios.put<VendorPayoutAccount>(
    updateVendorPayoutMethodPath({ vendorId, payoutAccountId, payoutMethodId }),
    {}
  );
}
