import { IconName, IconStroke } from 'shared';
import { ReactComponent as AddReaction } from 'shared/icons/AddReaction.svg';
import { ReactComponent as AddReactionX2 } from 'shared/icons/AddReactionX2.svg';
import { ReactComponent as AddressPin } from 'shared/icons/AddressPin.svg';
import { ReactComponent as AddressPinOutline } from 'shared/icons/AddressPinOutline.svg';
import { ReactComponent as AddressPinOutlineX2 } from 'shared/icons/AddressPinOutlineX2.svg';
import { ReactComponent as Archive } from 'shared/icons/Archive.svg';
import { ReactComponent as ArchiveX2 } from 'shared/icons/ArchiveX2.svg';
import { ReactComponent as Arrow } from 'shared/icons/Arrow.svg';
import { ReactComponent as ArrowX2 } from 'shared/icons/ArrowX2.svg';
import { ReactComponent as Attachment } from 'shared/icons/Attachment.svg';
import { ReactComponent as Avatar } from 'shared/icons/Avatar.svg';
import { ReactComponent as Beverage } from 'shared/icons/Beverage.svg';
import { ReactComponent as BeverageSupplies } from 'shared/icons/BeverageSupplies.svg';
import { ReactComponent as BeverageSuppliesX2 } from 'shared/icons/BeverageSuppliesX2.svg';
import { ReactComponent as Bike } from 'shared/icons/Bike.svg';
import { ReactComponent as BikeX2 } from 'shared/icons/BikeX2.svg';
import { ReactComponent as Building } from 'shared/icons/Building.svg';
import { ReactComponent as BuildingNew } from 'shared/icons/BuildingNew.svg';
import { ReactComponent as BuildingRetail } from 'shared/icons/BuildingRetail.svg';
import { ReactComponent as BuildingRetailX2 } from 'shared/icons/BuildingRetailX2.svg';
import { ReactComponent as BuildingX2 } from 'shared/icons/BuildingX2.svg';
import { ReactComponent as Calendar } from 'shared/icons/Calendar.svg';
import { ReactComponent as CalendarEdit } from 'shared/icons/CalendarEdit.svg';
import { ReactComponent as CalendarEditX2 } from 'shared/icons/CalendarEditX2.svg';
import { ReactComponent as CalendarX2 } from 'shared/icons/CalendarX2.svg';
import { ReactComponent as Callout } from 'shared/icons/Callout.svg';
import { ReactComponent as Camera } from 'shared/icons/Camera.svg';
import { ReactComponent as Caret } from 'shared/icons/Caret.svg';
import { ReactComponent as CaretX2 } from 'shared/icons/CaretX2.svg';
import { ReactComponent as CenterMap } from 'shared/icons/CenterMap.svg';
import { ReactComponent as CenterMapX2 } from 'shared/icons/CenterMapX2.svg';
import { ReactComponent as Chair } from 'shared/icons/Chair.svg';
import { ReactComponent as Check } from 'shared/icons/Check.svg';
import { ReactComponent as Checkmark } from 'shared/icons/Checkmark.svg';
import { ReactComponent as CheckmarkX2 } from 'shared/icons/CheckmarkX2.svg';
import { ReactComponent as Chevron } from 'shared/icons/Chevron.svg';
import { ReactComponent as ChevronX2 } from 'shared/icons/ChevronX2.svg';
import { ReactComponent as Cleaning } from 'shared/icons/Cleaning.svg';
import { ReactComponent as CleaningDeep } from 'shared/icons/CleaningDeep.svg';
import { ReactComponent as CleaningDeepX2 } from 'shared/icons/CleaningDeepX2.svg';
import { ReactComponent as CleaningLight } from 'shared/icons/CleaningLight.svg';
import { ReactComponent as CleaningLightX2 } from 'shared/icons/CleaningLightX2.svg';
import { ReactComponent as Clear } from 'shared/icons/Clear.svg';
import { ReactComponent as ClipboardNoPageFold } from 'shared/icons/ClipBoardNoPageFold.svg';
import { ReactComponent as Clipboard } from 'shared/icons/Clipboard.svg';
import { ReactComponent as Clock } from 'shared/icons/Clock.svg';
import { ReactComponent as ClockX2 } from 'shared/icons/ClockX2.svg';
import { ReactComponent as Close } from 'shared/icons/Close.svg';
import { ReactComponent as CloseX2 } from 'shared/icons/CloseX2.svg';
import { ReactComponent as Coffee } from 'shared/icons/Coffee.svg';
import { ReactComponent as Comment } from 'shared/icons/Comment.svg';
import { ReactComponent as Copy } from 'shared/icons/Copy.svg';
import { ReactComponent as CreditCard } from 'shared/icons/CreditCard.svg';
import { ReactComponent as Dashboard } from 'shared/icons/Dashboard.svg';
import { ReactComponent as Desk } from 'shared/icons/Desk.svg';
import { ReactComponent as DeskStanding } from 'shared/icons/DeskStanding.svg';
import { ReactComponent as Detector } from 'shared/icons/Detector.svg';
import { ReactComponent as DollarSign } from 'shared/icons/DollarSign.svg';
import { ReactComponent as DollarSignX2 } from 'shared/icons/DollarSignX2.svg';
import { ReactComponent as Download } from 'shared/icons/Download.svg';
import { ReactComponent as EditPen } from 'shared/icons/EditPen.svg';
import { ReactComponent as EditPenX2 } from 'shared/icons/EditPenX2.svg';
import { ReactComponent as Ellipsis } from 'shared/icons/Ellipsis.svg';
import { ReactComponent as Envelope } from 'shared/icons/Envelope.svg';
import { ReactComponent as EnvelopeX2 } from 'shared/icons/EnvelopeX2.svg';
import { ReactComponent as Exclamation } from 'shared/icons/Exclamation.svg';
import { ReactComponent as ExclamationX2 } from 'shared/icons/ExclamationX2.svg';
import { ReactComponent as ExternalLink } from 'shared/icons/ExternalLink.svg';
import { ReactComponent as ExternalLinkX2 } from 'shared/icons/ExternalLinkX2.svg';
import { ReactComponent as Fan } from 'shared/icons/Fan.svg';
import { ReactComponent as FeatherDuster } from 'shared/icons/FeatherDuster.svg';
import { ReactComponent as FeatherDusterX2 } from 'shared/icons/FeatherDusterX2.svg';
import { ReactComponent as Filter } from 'shared/icons/Filter.svg';
import { ReactComponent as Flag } from 'shared/icons/Flag.svg';
import { ReactComponent as FlagX2 } from 'shared/icons/FlagX2.svg';
import { ReactComponent as Flame } from 'shared/icons/Flame.svg';
import { ReactComponent as FlameOutline } from 'shared/icons/FlameOutline.svg';
import { ReactComponent as FlameOutlineX2 } from 'shared/icons/FlameOutlineX2.svg';
import { ReactComponent as Flipchart } from 'shared/icons/Flipchart.svg';
import { ReactComponent as Gift } from 'shared/icons/Gift.svg';
import { ReactComponent as GiftX2 } from 'shared/icons/GiftX2.svg';
import { ReactComponent as Hamburger } from 'shared/icons/Hamburger.svg';
import { ReactComponent as HamburgerX2 } from 'shared/icons/HamburgerX2.svg';
import { ReactComponent as Handle } from 'shared/icons/Handle.svg';
import { ReactComponent as Heart } from 'shared/icons/Heart.svg';
import { ReactComponent as HeartOutline } from 'shared/icons/HeartOutline.svg';
import { ReactComponent as HeartOutlineX2 } from 'shared/icons/HeartOutlineX2.svg';
import { ReactComponent as House } from 'shared/icons/House.svg';
import { ReactComponent as Image } from 'shared/icons/Image.svg';
import { ReactComponent as Images } from 'shared/icons/Images.svg';
import { ReactComponent as ImagesX2 } from 'shared/icons/ImagesX2.svg';
import { ReactComponent as Info } from 'shared/icons/Info.svg';
import { ReactComponent as KeyCard } from 'shared/icons/KeyCard.svg';
import { ReactComponent as KeyCardX2 } from 'shared/icons/KeyCardX2.svg';
import { ReactComponent as Kitchen } from 'shared/icons/Kitchen.svg';
import { ReactComponent as List } from 'shared/icons/List.svg';
import { ReactComponent as ListX2 } from 'shared/icons/ListX2.svg';
import { ReactComponent as LocationArrow } from 'shared/icons/LocationArrow.svg';
import { ReactComponent as LocationArrowOutline } from 'shared/icons/LocationArrowOutline.svg';
import { ReactComponent as Lock } from 'shared/icons/Lock.svg';
import { ReactComponent as LockX2 } from 'shared/icons/LockX2.svg';
import { ReactComponent as Map } from 'shared/icons/Map.svg';
import { ReactComponent as MapX2 } from 'shared/icons/MapX2.svg';
import { ReactComponent as MeetingRoom } from 'shared/icons/MeetingRoom.svg';
import { ReactComponent as Membership } from 'shared/icons/Membership.svg';
import { ReactComponent as Membership2 } from 'shared/icons/Membership2.svg';
import { ReactComponent as Microwave } from 'shared/icons/Microwave.svg';
import { ReactComponent as Minus } from 'shared/icons/Minus.svg';
import { ReactComponent as MinusX2 } from 'shared/icons/MinusX2.svg';
import { ReactComponent as Miscellaneous } from 'shared/icons/Miscellaneous.svg';
import { ReactComponent as MiscellaneousX2 } from 'shared/icons/Miscellaneous.svg';
import { ReactComponent as MobilePhone } from 'shared/icons/MobilePhone.svg';
import { ReactComponent as Monitor } from 'shared/icons/Monitor.svg';
import { ReactComponent as Music } from 'shared/icons/Music.svg';
import { ReactComponent as Newspaper } from 'shared/icons/Newspaper.svg';
import { ReactComponent as NoSymbol } from 'shared/icons/NoSymbol.svg';
import { ReactComponent as NoSymbolX2 } from 'shared/icons/NoSymbolX2.svg';
import { ReactComponent as Noise } from 'shared/icons/Noise.svg';
import { ReactComponent as Notification } from 'shared/icons/Notification.svg';
import { ReactComponent as NotificationX2 } from 'shared/icons/NotificationX2.svg';
import { ReactComponent as Outdoors } from 'shared/icons/Outdoors.svg';
import { ReactComponent as Outdoors2 } from 'shared/icons/Outdoors2.svg';
import { ReactComponent as OutdoorsFlower } from 'shared/icons/OutdoorsFlower.svg';
import { ReactComponent as Page } from 'shared/icons/Page.svg';
import { ReactComponent as PageBlank } from 'shared/icons/PageBlank.svg';
import { ReactComponent as Parking } from 'shared/icons/Parking.svg';
import { ReactComponent as Payout } from 'shared/icons/Payout.svg';
import { ReactComponent as PayoutX2 } from 'shared/icons/PayoutX2.svg';
import { ReactComponent as Pets } from 'shared/icons/Pets.svg';
import { ReactComponent as Phone } from 'shared/icons/Phone.svg';
import { ReactComponent as Plus } from 'shared/icons/Plus.svg';
import { ReactComponent as PlusX2 } from 'shared/icons/PlusX2.svg';
import { ReactComponent as Printer } from 'shared/icons/Printer.svg';
import { ReactComponent as Privacy } from 'shared/icons/Privacy.svg';
import { ReactComponent as Projector } from 'shared/icons/Projector.svg';
import { ReactComponent as QuestionCircle } from 'shared/icons/QuestionCircle.svg';
import { ReactComponent as QuestionCircleX2 } from 'shared/icons/QuestionCircleX2.svg';
import { ReactComponent as QuestionMark } from 'shared/icons/QuestionMark.svg';
import { ReactComponent as QuestionMarkX2 } from 'shared/icons/QuestionMarkX2.svg';
import { ReactComponent as Refresh } from 'shared/icons/Refresh.svg';
import { ReactComponent as Reservation } from 'shared/icons/Reservation.svg';
import { ReactComponent as ReservationX2 } from 'shared/icons/ReservationX2.svg';
import { ReactComponent as Ruler } from 'shared/icons/Ruler.svg';
import { ReactComponent as Scale } from 'shared/icons/Scale.svg';
import { ReactComponent as Search } from 'shared/icons/Search.svg';
import { ReactComponent as SearchX2 } from 'shared/icons/SearchX2.svg';
import { ReactComponent as Settings } from 'shared/icons/Settings.svg';
import { ReactComponent as SettingsX2 } from 'shared/icons/SettingsX2.svg';
import { ReactComponent as Share } from 'shared/icons/Share.svg';
import { ReactComponent as ShoppingCart } from 'shared/icons/ShoppingCart.svg';
import { ReactComponent as ShoppingCartX2 } from 'shared/icons/ShoppingCartX2.svg';
import { ReactComponent as SmartLock } from 'shared/icons/SmartLock.svg';
import { ReactComponent as Snack } from 'shared/icons/Snacks.svg';
import { ReactComponent as Sort } from 'shared/icons/Sort.svg';
import { ReactComponent as SortAsc } from 'shared/icons/SortAsc.svg';
import { ReactComponent as SortControl } from 'shared/icons/SortControl.svg';
import { ReactComponent as SortDesc } from 'shared/icons/SortDesc.svg';
import { ReactComponent as Sparkle } from 'shared/icons/Sparkle.svg';
import { ReactComponent as SparklingWater } from 'shared/icons/SparklingWater.svg';
import { ReactComponent as SparklingWaterX2 } from 'shared/icons/SparklingWaterX2.svg';
import { ReactComponent as Star } from 'shared/icons/Star.svg';
import { ReactComponent as StarOutline } from 'shared/icons/StarOutline.svg';
import { ReactComponent as StreetParking } from 'shared/icons/StreetParking.svg';
import { ReactComponent as Support } from 'shared/icons/Support.svg';
import { ReactComponent as Surveillance } from 'shared/icons/Surveillance.svg';
import { ReactComponent as TV } from 'shared/icons/TV.svg';
import { ReactComponent as TableLarge } from 'shared/icons/TableLarge.svg';
import { ReactComponent as TableSmall } from 'shared/icons/TableSmall.svg';
import { ReactComponent as ThumbsDown } from 'shared/icons/ThumbsDown.svg';
import { ReactComponent as ThumbsDownFilled } from 'shared/icons/ThumbsDownFilled.svg';
import { ReactComponent as ThumbsDownFilledX2 } from 'shared/icons/ThumbsDownFilledX2.svg';
import { ReactComponent as ThumbsDownX2 } from 'shared/icons/ThumbsDownX2.svg';
import { ReactComponent as ThumbsUp } from 'shared/icons/ThumbsUp.svg';
import { ReactComponent as ThumbsUpFilled } from 'shared/icons/ThumbsUpFilled.svg';
import { ReactComponent as ThumbsUpFilledX2 } from 'shared/icons/ThumbsUpFilledX2.svg';
import { ReactComponent as ThumbsUpX2 } from 'shared/icons/ThumbsUpX2.svg';
import { ReactComponent as Ticket } from 'shared/icons/Ticket.svg';
import { ReactComponent as TicketX2 } from 'shared/icons/TicketX2.svg';
import { ReactComponent as Transit } from 'shared/icons/Transit.svg';
import { ReactComponent as Transportation } from 'shared/icons/Transportation.svg';
import { ReactComponent as TransportationX2 } from 'shared/icons/TransportationX2.svg';
import { ReactComponent as Trashcan } from 'shared/icons/Trashcan.svg';
import { ReactComponent as TrashcanX2 } from 'shared/icons/TrashcanX2.svg';
import { ReactComponent as Upload } from 'shared/icons/Upload.svg';
import { ReactComponent as UploadCloud } from 'shared/icons/UploadCloud.svg';
import { ReactComponent as UploadCloudX2 } from 'shared/icons/UploadCloudX2.svg';
import { ReactComponent as UserStar } from 'shared/icons/UserStar.svg';
import { ReactComponent as UserStarX2 } from 'shared/icons/UserStarX2.svg';
import { ReactComponent as Users } from 'shared/icons/Users.svg';
import { ReactComponent as UsersX2 } from 'shared/icons/UsersX2.svg';
import { ReactComponent as Walk } from 'shared/icons/Walk.svg';
import { ReactComponent as WalkX2 } from 'shared/icons/WalkX2.svg';
import { ReactComponent as Watch } from 'shared/icons/Watch.svg';
import { ReactComponent as WatchX2 } from 'shared/icons/WatchX2.svg';
import { ReactComponent as Watching } from 'shared/icons/Watching.svg';
import { ReactComponent as WatchingX2 } from 'shared/icons/WatchingX2.svg';
import { ReactComponent as Whiteboard } from 'shared/icons/Whiteboard.svg';
import { ReactComponent as WiFi } from 'shared/icons/WiFi.svg';

export const ICON_NAME_TO_SVG: Record<IconStroke, Partial<Record<IconName, React.FunctionComponent>>> = {
  1.5: {
    addReaction: AddReaction,
    addressPin: AddressPin,
    addressPinOutline: AddressPinOutline,
    archive: Archive,
    attachment: Attachment,
    avatar: Avatar,
    beverage: Beverage,
    beverageSupplies: BeverageSupplies,
    bike: Bike,
    building: Building,
    buildingNew: BuildingNew,
    buildingRetail: BuildingRetail,
    calendar: Calendar,
    callout: Callout,
    camera: Camera,
    centerMap: CenterMap,
    chair: Chair,
    check: Check,
    checkmark: Checkmark,
    cleaning: Cleaning,
    cleaningDeep: CleaningDeep,
    cleaningLight: CleaningLight,
    clear: Clear,
    clipboardNoPageFold: ClipboardNoPageFold,
    clipboard: Clipboard,
    clock: Clock,
    close: Close,
    coffee_tea: Coffee,
    coffee: Coffee,
    comfortable_chairs: Chair, // TODO: fix in db and remove
    comment: Comment,
    copy: Copy,
    creditCard: CreditCard,
    dashboard: Dashboard,
    desk: Desk,
    detector: Detector,
    dollarSign: DollarSign,
    downArrow: Arrow,
    downCaret: Caret,
    downChevron: Chevron,
    download: Download,
    editPen: EditPen,
    ellipsisH: Ellipsis,
    ellipsisV: Ellipsis,
    envelope: Envelope,
    exclamation: Exclamation,
    externalLink: ExternalLink,
    fan: Fan,
    featherDuster: FeatherDuster,
    filter: Filter,
    flag: Flag,
    flame: Flame,
    flameOutline: FlameOutline,
    flipchart: Flipchart,
    gift: Gift,
    hamburger: Hamburger,
    handle: Handle,
    heart: Heart,
    heartOutline: HeartOutline,
    house: House,
    image: Image,
    images: Images,
    info: Info,
    keyCard: KeyCard,
    kitchen: Kitchen,
    leftArrow: Arrow,
    leftCaret: Caret,
    leftChevron: Chevron,
    list: List,
    locationArrow: LocationArrow,
    locationArrowOutline: LocationArrowOutline,
    lock: Lock,
    map: Map,
    meeting_room: MeetingRoom, // TODO: fix in db and remove
    meetingRoom: MeetingRoom,
    membership: Membership,
    membership2: Membership2,
    microwave: Microwave,
    minus: Minus,
    mobilePhone: MobilePhone,
    monitor: Monitor,
    music: Music,
    newspaper: Newspaper,
    noise: Noise,
    notification: Notification,
    outdoors: Outdoors,
    outdoors2: Outdoors2,
    outdoorsFlower: OutdoorsFlower,
    page: Page,
    pageBlank: PageBlank,
    parking: StreetParking,
    payout: Payout,
    pets: Pets,
    phone_call_area: Phone,
    phone: Phone,
    plus: Plus,
    printer: Printer,
    privacy: Privacy,
    private_parking: Parking,
    projector: Projector,
    questionCircle: QuestionCircle,
    questionMark: QuestionMark,
    refresh: Refresh,
    reservation: Reservation,
    rightArrow: Arrow,
    rightCaret: Caret,
    rightChevron: Chevron,
    ruler: Ruler,
    scale: Scale,
    search: Search,
    settings: Settings,
    share: Share,
    shoppingCart: ShoppingCart,
    smart_lock: SmartLock,
    snack: Snack,
    sort: Sort,
    sortAsc: SortAsc,
    sortControl: SortControl,
    sortDesc: SortDesc,
    sparkle: Sparkle,
    sparklingWater: SparklingWater,
    standing_desk: DeskStanding,
    star: Star,
    starOutline: StarOutline,
    support: Support,
    surveillance: Surveillance,
    tableLarge: TableLarge,
    tableSmall: TableSmall,
    thumbsDown: ThumbsDown,
    thumbsDownFilled: ThumbsDownFilled,
    thumbsUp: ThumbsUp,
    thumbsUpFilled: ThumbsUpFilled,
    ticket: Ticket,
    transit: Transit,
    transportation: Transportation,
    trashcan: Trashcan,
    tv: TV,
    users: Users,
    userStar: UserStar,
    upArrow: Arrow,
    upCaret: Caret,
    upChevron: Chevron,
    upload: Upload,
    uploadCloud: UploadCloud,
    whiteboard: Whiteboard,
    walk: Walk,
    wifi: WiFi,
    miscellaneous: Miscellaneous,
    noSymbol: NoSymbol,
    calendarEdit: CalendarEdit,
    watch: Watch,
    watching: Watching
  },
  2: {
    addReaction: AddReactionX2,
    addressPinOutline: AddressPinOutlineX2,
    archive: ArchiveX2,
    beverageSupplies: BeverageSuppliesX2,
    buildingRetail: BuildingRetailX2,
    bike: BikeX2,
    building: BuildingX2,
    calendar: CalendarX2,
    centerMap: CenterMapX2,
    checkmark: CheckmarkX2,
    cleaningDeep: CleaningDeepX2,
    cleaningLight: CleaningLightX2,
    clock: ClockX2,
    close: CloseX2,
    dollarSign: DollarSignX2,
    downArrow: ArrowX2,
    downCaret: CaretX2,
    downChevron: ChevronX2,
    editPen: EditPenX2,
    envelope: EnvelopeX2,
    exclamation: ExclamationX2,
    externalLink: ExternalLinkX2,
    featherDuster: FeatherDusterX2,
    flag: FlagX2,
    flameOutline: FlameOutlineX2,
    hamburger: HamburgerX2,
    heartOutline: HeartOutlineX2,
    gift: GiftX2,
    images: ImagesX2,
    keyCard: KeyCardX2,
    leftArrow: ArrowX2,
    leftCaret: CaretX2,
    leftChevron: ChevronX2,
    list: ListX2,
    lock: LockX2,
    map: MapX2,
    minus: MinusX2,
    notification: NotificationX2,
    payout: PayoutX2,
    plus: PlusX2,
    questionCircle: QuestionCircleX2,
    questionMark: QuestionMarkX2,
    reservation: ReservationX2,
    rightArrow: ArrowX2,
    rightCaret: CaretX2,
    rightChevron: ChevronX2,
    search: SearchX2,
    settings: SettingsX2,
    shoppingCart: ShoppingCartX2,
    sparklingWater: SparklingWaterX2,
    thumbsDown: ThumbsDownX2,
    thumbsDownFilled: ThumbsDownFilledX2,
    thumbsUp: ThumbsUpX2,
    thumbsUpFilled: ThumbsUpFilledX2,
    ticket: TicketX2,
    transportation: TransportationX2,
    trashcan: TrashcanX2,
    users: UsersX2,
    upArrow: ArrowX2,
    upCaret: CaretX2,
    upChevron: ChevronX2,
    uploadCloud: UploadCloudX2,
    userStar: UserStarX2,
    walk: WalkX2,
    watching: WatchingX2,
    watch: WatchX2,
    miscellaneous: MiscellaneousX2,
    noSymbol: NoSymbolX2,
    calendarEdit: CalendarEditX2
  }
};
