import React from 'react';
import { ReactComponent as ClearSvg } from 'shared/icons/Clear.svg';
import classNames from 'classnames';

import Icon from '../Icon';
import Text from '../Text';
import { FieldSize } from '../types';

interface Props {
  size: FieldSize;
  label?: string;
  value?: string;
  placeholder: string;
  isCalendarVisible: boolean;
  showIcon?: boolean;
  type: 'marketing' | 'default';
  isBottomSheet: boolean;
  required?: boolean;
  isReadOnly?: boolean;
}

export const DatePickerDisplay: React.FC<Props> = ({
  size,
  label,
  value,
  placeholder,
  isCalendarVisible,
  showIcon = true,
  type,
  isBottomSheet,
  required = false,
  isReadOnly
}) => {
  const isMarketingType = type === 'marketing';
  const contents = (
    <>
      {showIcon && (
        <span className="DatePicker-icon">
          <Icon
            name="calendar"
            size={isMarketingType ? (isBottomSheet ? 'md' : 'lg') : size}
            color={isCalendarVisible && type === 'default' ? 'blue-500' : 'gray-900'}
          />
        </span>
      )}
      {value ? (
        isMarketingType ? (
          <Text size={isBottomSheet ? 'body1' : 'h5'} bold={false} inline>
            {value}
          </Text>
        ) : (
          <>
            <label className="DatePicker-label">{label}</label>
            <span className={classNames('DatePicker-value', { 'has-small-label': label })}>{value}</span>
          </>
        )
      ) : (
        <span className="DatePicker-placeholder">
          {placeholder}
          {required ? ' *' : ''}
        </span>
      )}
      {isReadOnly || isMarketingType ? (
        <span />
      ) : (
        !!value && (
          <span className="DatePicker-closeButton">
            <span className={classNames('Icon', 'DatePicker-closeSvg', 'is-color-gray-200')}>
              <ClearSvg />
            </span>
          </span>
        )
      )}
    </>
  );

  return isMarketingType ? (
    <span
      style={{
        display: 'flex',
        alignItems: 'center',
        flexGrow: isBottomSheet ? undefined : 1,
        justifyContent: 'space-between',
        width: 334
      }}
    >
      {contents}
    </span>
  ) : (
    contents
  );
};

export default DatePickerDisplay;
