import React, { useContext } from 'react';
import { WHITE } from 'shared';

import { app, media } from 'context';
import { Animate, Icon, Layout, Link, Text } from 'ds';
import { PRESS_PATH } from 'routes';

import { ReactComponent as FastCompany } from 'ds/fast-company-logo.svg';
import { ReactComponent as Forbes } from 'ds/forbes-logo.svg';
import { ReactComponent as NewYorkTimes } from 'ds/new-york-times-logo.svg';
import { ReactComponent as WallStreetJournal } from 'ds/wall-street-journal-logo.svg';

interface Props {}

const Press: React.FC<Props> = () => {
  const { contentPaddingX } = useContext(app);
  const { xs, sm, md } = useContext(media);

  const outlets = [
    <NewYorkTimes width={xs ? 53 : sm ? 53 * 1.3 : 117} />,
    <WallStreetJournal width={xs ? 44 : sm ? 44 * 1.3 : 100} />,
    <Forbes width={xs ? 88 : sm ? 88 * 1.3 : 208} />,
    <FastCompany width={xs ? 68 : sm ? 68 * 1.3 : 152} />
  ];

  return (
    <Layout
      color="blue-700"
      {...(xs ? { paddingY: 32, paddingX: contentPaddingX } : { paddingY: 48, borderRadius: 16 })}
    >
      <Layout align="center" direction="column">
        <Layout marginBottom={36}>
          <Text size={xs ? 'body-sm' : 'body-md'} color="blue-300" semibold>
            AS SEEN IN
          </Text>
        </Layout>
        <Layout {...(xs ? { width: '100%' } : {})} justify="space-around" align="center" direction="row">
          {outlets.map((outlet, index) => (
            <Animate marginX={xs ? undefined : sm || md ? 32 : 48} key={index} delay={index * 50}>
              <span className={`Icon is-color-${WHITE}`}>{outlet}</span>
            </Animate>
          ))}
        </Layout>
        <Layout marginTop={36}>
          <Link href={PRESS_PATH} color="white">
            <Animate inline align="center" delay={50}>
              <Text size={xs ? 'body-sm' : 'body-md'} color="white" semibold>
                View all press
              </Text>
              <Layout marginLeft={8} display="inline-flex">
                <Icon name="rightArrow" stroke={2} size="sm" color="white" />
              </Layout>
            </Animate>
          </Link>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default Press;
