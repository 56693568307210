import { Listing } from 'shared';
import React, { useContext, useEffect, useState } from 'react';

import { app, media } from 'context';
import { Animate, Layout, Text, WorkspacePromoCarousel } from 'ds';
import { findWorkspaces } from '../requests';

interface Props {
  title: string;
  ids: { id: number }[];
}

const Workspaces: React.FC<Props> = ({ title, ids }) => {
  const { xs, media500, sm } = useContext(media);
  const { contentWidth } = useContext(app);
  const [workspaces, setWorkspaces] = useState<Listing[]>([]);

  const workspaceIds = ids.map(id => id.id);

  useEffect(() => {
    findWorkspaces({ workspaceIds: workspaceIds }).then(({ data }) => {
      setWorkspaces(data);
    });
  }, [ids.join(',')]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!workspaces.length) return null;

  return xs || sm ? (
    <Layout height={media500 ? 526 : 700}>
      <Layout position="relative" top="50%" left="50%" __style={{ transform: 'translate(-50%,-50%)' }}>
        <Animate>
          <Text size="h4" color="blue-700">
            {title}
          </Text>
        </Animate>
        <Animate marginTop={48}>
          <WorkspacePromoCarousel
            isStb
            workspaces={workspaces.map(w => ({
              ...w,
              image: w.images[0]
            }))}
            key={contentWidth}
          />
        </Animate>
      </Layout>
    </Layout>
  ) : (
    <Layout height={900} position="relative">
      <Layout position="relative" top="50%" left="50%" __style={{ transform: 'translate(-50%,-50%)' }}>
        <Animate>
          <Text size="h3" color="blue-700" align="center">
            {title}
          </Text>
        </Animate>
        <Animate>
          <Layout marginTop={48}>
            <WorkspacePromoCarousel
              isStb
              workspaces={workspaces
                .filter(w => !!w.images.length)
                .map(w => ({
                  ...w,
                  image: w.images[0]
                }))}
            />
          </Layout>
        </Animate>
      </Layout>
    </Layout>
  );
};

export default Workspaces;
