import React, { useContext } from 'react';
import { GRAY_400_HEX } from 'shared';

import { app, media } from 'context';
import { Img, Layout, Text } from 'ds';

interface Props {}

const Commission: React.FC<Props> = () => {
  const { isMobile, xs } = useContext(media);
  const { contentPaddingX, windowWidth } = useContext(app);

  return (
    <Layout {...(isMobile ? {} : { justify: 'space-between' })}>
      <Layout>
        <Layout {...(isMobile ? { paddingX: contentPaddingX } : { width: 490 })}>
          <Text size={isMobile ? 'headline-lg' : 'display-sm'} color="blue-500">
            The commission model
          </Text>
          <Layout {...(isMobile ? { marginTop: 32 } : { marginTop: 32 })}>
            <Text size={isMobile ? 'body-sm' : 'headline-xs'} color="gray-400" bold={false}>
              Earn up to a{' '}
              <Text inline bold size={isMobile ? 'body-sm' : 'headline-xs'}>
                $6,000
              </Text>{' '}
              referral bonus plus an additional tenant rep commission from the landlord{' '}
            </Text>
            <ul style={{ color: GRAY_400_HEX, marginTop: 8 }}>
              <li>
                <Text size={isMobile ? 'body-sm' : 'headline-xs'} color="gray-400" bold={false}>
                  <Text inline bold size={isMobile ? 'body-sm' : 'headline-xs'}>
                    $5,000
                  </Text>{' '}
                  for a 12 month term
                </Text>
              </li>
              <li>
                <Text size={isMobile ? 'body-sm' : 'headline-xs'} color="gray-400" bold={false}>
                  <Text inline bold size={isMobile ? 'body-sm' : 'headline-xs'}>
                    $6,000
                  </Text>{' '}
                  for a 24 month term
                </Text>
              </li>
            </ul>
          </Layout>
        </Layout>
        <Layout
          {...(isMobile
            ? {
                width: xs ? windowWidth : undefined,
                overflow: 'scroll'
              }
            : { marginTop: 36 })}
          position="relative"
        >
          <Layout {...(isMobile ? { marginY: 32, marginX: contentPaddingX } : { position: 'absolute' })}>
            <Layout
              color="white"
              borderRadius={16}
              boxShadow="2.7988576889038086px 0 30.78743553161621px 0 rgba(34.1176, 28.6275, 42.7451, 0.1200)"
              paddingX={40}
              paddingY={24}
              width={624}
            >
              <Layout paddingY={10} flex>
                <Layout width={264}></Layout>
                <Layout flexGrow={1} marginLeft={16} flex>
                  <Layout justify="flex-end" equalWidth>
                    <Text size="body-md" color="blue-600" semibold>
                      1 Year Term
                    </Text>
                  </Layout>
                  <Layout marginLeft={16} justify="flex-end" equalWidth>
                    <Text size="body-md" color="blue-600" semibold>
                      2 Year Term
                    </Text>
                  </Layout>
                </Layout>
              </Layout>
              <Layout paddingY={10} flex>
                <Layout width={264}>
                  <Text size="body-md" color="blue-600" bold>
                    Deal value
                  </Text>
                </Layout>
                <Layout flexGrow={1} marginLeft={16} flex>
                  <Layout justify="flex-end" equalWidth>
                    <Text size="body-md" color="gray-700">
                      $120,000
                    </Text>
                  </Layout>
                  <Layout marginLeft={16} justify="flex-end" equalWidth>
                    <Text size="body-md" color="gray-700">
                      $240,000
                    </Text>
                  </Layout>
                </Layout>
              </Layout>
              <Layout paddingY={10} flex>
                <Layout width={264}>
                  <Text size="body-md" color="blue-600" bold>
                    Your time
                  </Text>
                </Layout>
                <Layout flexGrow={1} marginLeft={16} flex>
                  <Layout justify="flex-end" equalWidth>
                    <Text size="body-md" color="gray-700">
                      ~2.5 hrs
                    </Text>
                  </Layout>
                  <Layout marginLeft={16} justify="flex-end" equalWidth>
                    <Text size="body-md" color="gray-700">
                      ~2.5 hrs
                    </Text>
                  </Layout>
                </Layout>
              </Layout>
              <Layout paddingY={10} flex>
                <Layout width={264}>
                  <Text size="body-md" color="blue-600" bold>
                    Est. Commission from landlord
                  </Text>
                </Layout>
                <Layout flexGrow={1} marginLeft={16} flex>
                  <Layout justify="flex-end" equalWidth>
                    <Text size="body-md" color="gray-700">
                      $6,000
                    </Text>
                  </Layout>
                  <Layout marginLeft={16} justify="flex-end" equalWidth>
                    <Text size="body-md" color="gray-700">
                      $12,000
                    </Text>
                  </Layout>
                </Layout>
              </Layout>
              <Layout paddingY={10} flex>
                <Layout width={264}>
                  <Text size="body-md" color="blue-600" bold>
                    CodiConnect Referral Bonus
                  </Text>
                </Layout>
                <Layout flexGrow={1} marginLeft={16} flex>
                  <Layout justify="flex-end" equalWidth>
                    <Text size="body-md" color="gray-700">
                      $5,000
                    </Text>
                  </Layout>
                  <Layout marginLeft={16} justify="flex-end" equalWidth>
                    <Text size="body-md" color="gray-700">
                      $6,000
                    </Text>
                  </Layout>
                </Layout>
              </Layout>
              <Layout paddingY={10} flex>
                <Layout width={264}>
                  <Text size="body-md" color="blue-600" bold>
                    TOTAL EARNINGS
                  </Text>
                </Layout>
                <Layout flexGrow={1} marginLeft={16} flex>
                  <Layout justify="flex-end" equalWidth>
                    <Text size="body-md" color="blue-600" bold>
                      $11,000
                    </Text>
                  </Layout>
                  <Layout marginLeft={16} justify="flex-end" equalWidth>
                    <Text size="body-md" color="blue-600" bold>
                      $18,000
                    </Text>
                  </Layout>
                </Layout>
              </Layout>
            </Layout>
            <Layout marginTop={isMobile ? 24 : 12}>
              <Text size="body-xs" color="gray-400">
                *Model uses estimated commission of 5% for illustrative purposes. Actual commissions vary by market.
              </Text>
            </Layout>
          </Layout>
        </Layout>
      </Layout>
      {!isMobile && (
        <Img
          folder="public"
          srcKey="kearny-brokers_NHpSiYpQE.jpeg"
          alt="kearny office"
          width={1600}
          style={{ height: 500, width: 514, borderRadius: 16, objectFit: 'cover' }}
        />
      )}
    </Layout>
  );
};

export default Commission;
