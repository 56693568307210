import React, { useEffect, useState } from 'react';
import { filterByInclusion } from 'shared';

import ComboBox from 'ds/ComboBox';
import NoMatchesItem from 'ds/ComboBox/NoMatchesItem';
import Layout from 'ds/Layout';
import { InputImageIconProps } from 'ds/inputs/types';
import { actions } from 'store/UI';
import { selectDefaultTypeaheadResults } from 'store/UI/selectors';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import UserResult from './UserResult';
import { SearchableUser } from './types';
import { Props as TextInputProps } from '../inputs/TextInput';

export interface Props
  extends Pick<TextInputProps, 'size' | 'placeholder' | 'disabled' | 'required' | 'onBlur' | 'autoFocus'> {
  label?: string;
  users: SearchableUser[];
  selectedUserId?: number;
  errorMessage?: string;
  onSelect: (user: SearchableUser | undefined) => void;
  clearQueryAfterSelect?: boolean;
  /** Used to show default typeahead results */
  name?: string;
  onCreateNew?: (query?: string) => void;
  showId?: boolean;
  onChange?: TextInputProps['onChange'];
  onSelectDisplay?: 'email';
  hideNoMatchesItem?: (query?: string) => boolean;
}

const UserComboBox: React.FC<Props> = ({
  size,
  users,
  label = 'User',
  selectedUserId,
  errorMessage,
  onSelect,
  placeholder = 'Search by name, email, or user ID',
  disabled,
  required = true,
  name,
  clearQueryAfterSelect,
  onCreateNew,
  onChange,
  onBlur,
  onSelectDisplay,
  hideNoMatchesItem = () => false,
  autoFocus
}) => {
  const defaultIds = useAppSelector(state => selectDefaultTypeaheadResults(state, name)).ids;
  const dispatch = useAppDispatch();
  const formatQuery = (user?: SearchableUser) => {
    if (!user) return undefined;

    const { first, last } = user;
    return `${first} ${last}`;
  };

  const [query, setQuery] = useState<string | undefined>(formatQuery(users.find(w => w.id === selectedUserId)));
  const [width, setWidth] = useState<number>(0);

  const selectedUser = users.find(w => w.id === selectedUserId);
  const formattedQuery = selectedUser ? formatQuery(selectedUser) : undefined;

  useEffect(() => {
    if (!formattedQuery) {
      setQuery(undefined);
      return;
    }

    setQuery(formattedQuery);
  }, [formattedQuery]);

  const resultUsers =
    selectedUser && defaultIds.length
      ? (defaultIds.map(id => users.find(u => u.id === id)).filter(Boolean) as SearchableUser[])
      : query
      ? users.filter(
          user =>
            filterByInclusion(user.id.toString(), query) ||
            filterByInclusion(`${user.first} ${user.last}`, query) ||
            filterByInclusion(user.email, query)
        )
      : users;

  const results = resultUsers.map(user => ({
    value: user.id,
    disabled: user.disabled,
    element: (
      <UserResult
        key={user.id}
        size={size}
        maxWidth={width}
        query={query}
        user={user}
        rightElement={user.rightElement}
      />
    )
  }));

  const image: InputImageIconProps['image'] = selectedUser?.avatar_url
    ? {
        src: selectedUser.avatar_url,
        alt: `${selectedUser.first} ${selectedUser.last}`,
        style: { borderRadius: '50%' }
      }
    : selectedUser?.image
    ? {
        ...selectedUser?.image,
        srcKey: selectedUser?.image.key,
        style: { borderRadius: '50%' },
        alt: `${selectedUser.first} ${selectedUser.last}`
      }
    : undefined;

  return (
    <Layout
      onMeasure={({ width }) => {
        setWidth(width);
      }}
    >
      <ComboBox
        query={query}
        results={results}
        noMatchesItem={
          !!selectedUserId || hideNoMatchesItem(query) ? undefined : (
            <NoMatchesItem size={size} width={width} isCreatable={!!onCreateNew} />
          )
        }
        error={errorMessage}
        size={size}
        label={label}
        bigLabel={placeholder}
        placeholder={placeholder}
        onChange={params => {
          setQuery(params.value);
          onSelect(undefined);
          onChange && onChange(params);
        }}
        onCreateNew={
          onCreateNew
            ? () => {
                onCreateNew(query);
              }
            : undefined
        }
        onSelect={value => {
          const user = users.find(user => user.id === value);

          if (!user) {
            setQuery(undefined);
            return;
          }

          if (user.disabled) return;

          const { first, last, email } = user;

          onSelect(user);
          name && dispatch(actions.addDefaultTypeaheadResults({ name, id: user.id }));

          if (clearQueryAfterSelect) {
            setQuery(undefined);
          } else {
            if (onSelectDisplay === 'email') {
              setQuery(email);
            } else {
              setQuery(`${first} ${last}`);
            }
          }
        }}
        onClear={() => {
          setQuery(undefined);
          onSelect(undefined);
        }}
        onBlur={onBlur}
        highlightMatch
        required={required}
        showResultsWhenEmpty
        hideLabel
        maxItems={5}
        disabled={disabled}
        autoFocus={autoFocus}
        image={image}
      />
    </Layout>
  );
};

export default UserComboBox;
