import React, { useContext } from 'react';
import { BLUE_600_HEX } from 'shared';

import { media } from 'context';
import { Icon, Layout, Text } from 'ds';

import PlaybookContentContainer from './PlaybookContentContainer';

interface Props {}

const TheTraditionalOffice: React.FC<Props> = () => {
  const { xs } = useContext(media);

  return (
    <PlaybookContentContainer>
      <Layout justify="space-between" flexGrow={1} align="center" overflow="hidden">
        <Layout>
          <p
            style={{ fontSize: 16, fontWeight: 700, lineHeight: '20px', letterSpacing: '0.08em', color: BLUE_600_HEX }}
          >
            04 Moving In & Managing Your Office
          </p>
          <Layout marginTop={20}>
            <Text size="h2" color="blue-gradient" scale={xs}>
              The Traditional Office
            </Text>
          </Layout>
        </Layout>
        {!xs && (
          <Layout
            width={224}
            height={224}
            borderColor="blue-gradient"
            borderWidth={8}
            justify="center"
            align="center"
            borderRadius="circular"
          >
            <Icon size={104} color="blue-gradient" name="building" />
          </Layout>
        )}
      </Layout>
    </PlaybookContentContainer>
  );
};

export default TheTraditionalOffice;
