import React, { FocusEvent, FocusEventHandler, useContext, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { DEFAULT_LAT_LNG, GRAY_900_HEX, getDistance } from 'shared';
import classNames from 'classnames';

import { LOCATION_TYPES, SUGGESTED_CITIES, filteredResults } from 'helpers/map';

import LocationBottomSheet from 'components/LocationBottomSheet';

import { app, media } from 'context';
import { Icon, Layout } from 'ds';
import { useOnClickOutside } from 'ds/helpers';
import { track } from 'lib/analytics';
import { getLocations, getPlaceDetails } from 'lib/location';
import { SEARCH_PATH } from 'routes';
import { selectMarkets } from 'store/App/selectors';
import { actions as officeRequestActions } from 'store/OfficeRequest';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import SearchResults from './SearchResults';
import { Result } from './types';
import { generateStepPath, marketNameFromLatLng } from '../OfficeRequest/utils';

interface Props {}

const SearchInput: React.FC<Props> = () => {
  const { xs, sm } = useContext(media);
  const { ipLocation } = useContext(app);
  const origin = ipLocation || undefined;
  const [searchValue, setSearchValue] = useState<string>('');
  const ref = useRef<HTMLDivElement>(null);
  const [_results, setResults] = useState<Result[]>([]);
  const history = useHistory();
  const { pathname } = useLocation();
  const [selectedResult, setSelectedResult] = useState<Result>();
  const [isBottomSheetOpen, setIsBottomSheetOpen] = useState<boolean>(false);
  const [isInputFocused, setIsInputFocused] = useState<boolean>(false);
  const markets = useAppSelector(selectMarkets);
  const dispatch = useAppDispatch();

  const hideResults = () => {
    setIsInputFocused(false);
  };

  const onLocationSelect = async (result: Result | null) => {
    if (!result) {
      history.push(SEARCH_PATH);
      return;
    }

    const { neighborhood, city, region, country, place_id } = result;

    dispatch(officeRequestActions.clearLocations());
    dispatch(officeRequestActions.setEntry('home-page-search'));
    dispatch(officeRequestActions.setLaunchPath(pathname));

    getPlaceDetails({
      place_id,
      callback: place => {
        if (!place || !place.geometry || !place.geometry.location) return;

        const lat = place.geometry.location.lat();
        const lng = place.geometry.location.lng();
        const loc = { lat, lng };
        const market = marketNameFromLatLng(loc, markets);
        dispatch(officeRequestActions.setMarket(market));
        dispatch(officeRequestActions.addLocation({ neighborhood, city, region, country, place_id, loc }));

        history.push(generateStepPath({ step: 1 }));
      }
    });
  };

  const userLoc = ipLocation ? { lat: ipLocation.lat, lng: ipLocation.lng } : DEFAULT_LAT_LNG;

  const results =
    searchValue && searchValue.length >= 3
      ? _results
      : [...SUGGESTED_CITIES].sort((c1, c2) => {
          const dist1 = getDistance(userLoc, c1.loc);
          const dist2 = getDistance(userLoc, c2.loc);

          return dist1 - dist2;
        });

  const isBottomSheet = xs || sm;

  const handleChange = (value: string) => {
    setSearchValue(value);

    if (value.length < 3) {
      return;
    }

    getLocations({
      input: value,
      origin,
      types: [...LOCATION_TYPES],
      country: ['us'],
      callback: results => {
        const filtered = filteredResults(results);
        setResults(filtered.slice(0, isBottomSheet ? 5 : 3));

        if (results.length === 0) {
          setSelectedResult(undefined);
        }
      }
    });
  };

  useOnClickOutside(ref, hideResults);

  return (
    <Layout __id="SearchInput">
      {isBottomSheet && (
        <LocationBottomSheet
          isVisible={isBottomSheetOpen}
          results={results}
          isFullScreen={false}
          onClose={() => setIsBottomSheetOpen(false)}
          handleChange={handleChange}
          onLocationSelect={onLocationSelect}
          scrimBackground="linear-gradient(91.27deg, #0078FF 13.34%, #2AD6C1 82.85%)"
          searchValue={searchValue}
        />
      )}
      <div
        style={{ position: 'relative' }}
        onKeyDown={e => {
          if (e.key === 'Tab') {
            setIsInputFocused(false);
          }
        }}
        ref={ref}
      >
        {isBottomSheet ? (
          <button
            onClick={() => {
              track('Element Interacted', { type: 'button', name: 'home search input open' });
              setIsBottomSheetOpen(true);
            }}
            style={{ width: '100%' }}
          >
            <Input
              onChange={handleChange}
              value=""
              handleClick={() => {
                if (!selectedResult) return;

                onLocationSelect(selectedResult);
              }}
              isFocused={false}
            />
          </button>
        ) : (
          <Input
            onChange={handleChange}
            value={searchValue}
            handleClick={() => {
              if (!selectedResult) return;

              onLocationSelect(selectedResult);
            }}
            onFocus={() => setIsInputFocused(true)}
            isFocused={isInputFocused}
          />
        )}
        {isInputFocused && (
          <SearchResults results={results} onLocationSelect={onLocationSelect} searchValue={searchValue} />
        )}
      </div>
    </Layout>
  );
};

interface InputProps {
  onChange: (value: string) => void;
  value: string;
  handleClick?: VoidFunction;
  handleClose?: VoidFunction;
  onFocus?: FocusEventHandler<HTMLInputElement>;
  isFocused: boolean;
}

const Input: React.FC<InputProps> = ({ onChange, value, onFocus, isFocused }) => {
  const { xs, sm } = useContext(media);
  const isBottomSheet = xs || sm;
  const name = 'SearchInput';

  return (
    <Layout
      height={60}
      flexGrow={1}
      align="center"
      position="relative"
      __className={classNames('HomeSearchInput', { 'is-focused': isFocused })}
    >
      <Layout
        zIndex={1}
        position="relative"
        borderRadius={80}
        __className="HomeSearchInput-container"
        color={isFocused ? 'white' : 'blue-50'}
        overflow="hidden"
      >
        <Layout paddingX={24} align="center" width="100%" height="100%" position="relative">
          <Icon size="xl" name="search" color="blue-500" stroke={2} />
          <form onSubmit={e => e.preventDefault()} style={{ width: '100%' }}>
            <input
              onFocus={(e: FocusEvent<HTMLInputElement>) => {
                track('Element Interacted', {
                  type: 'input',
                  name,
                  value: e.target.value || undefined,
                  action: 'onFocus'
                });
                onFocus && onFocus(e);
              }}
              onBlur={(e: FocusEvent<HTMLInputElement>) => {
                track('Element Interacted', {
                  type: 'input',
                  name,
                  value: e.target.value || undefined,
                  action: 'onBlur'
                });
              }}
              autoComplete="off"
              placeholder="Enter a city or neighborhood"
              style={{
                borderStyle: 'none',
                fontSize: isBottomSheet ? 16 : 18,
                lineHeight: isBottomSheet ? '24px' : '28px',
                padding: '14px 0',
                outline: 'none',
                background: 'none',
                color: GRAY_900_HEX,
                letterSpacing: 0.36,
                zIndex: 1,
                width: '100%',
                marginLeft: 16
              }}
              value={value}
              onChange={({ target: { value } }) => onChange(value)}
            />
          </form>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default SearchInput;
