import React, { useContext } from 'react';
import { ListingWorkspace, formatPublicAddress } from 'shared';

import { media } from 'context';
import { Layout, Text, WorkspaceImg } from 'ds';
import { SaveableShortTermBooking } from 'ux/Admin/ShortTermBookings/types';

import STBRequestCardInfo from './STBRequestCardInfo';
import MiniWorkspaceInfo from '../MiniWorkspaceInfo';

interface Props {
  workspace: ListingWorkspace;
  stbRequest: Partial<SaveableShortTermBooking>;
}

const STBRequestCard: React.FC<Props> = ({
  workspace,
  workspace: { name, address, images },
  stbRequest: { start_date, end_date, amount }
}) => {
  const { xs } = useContext(media);

  if (!start_date || !end_date || !amount) return null;

  if (xs) {
    return (
      <Layout padding={24} borderWidth={1} borderRadius={8}>
        <MiniWorkspaceInfo workspace={workspace} addressColor="gray-900" />
        <STBRequestCardInfo start_date={start_date} end_date={end_date} amount={amount} />
      </Layout>
    );
  }

  return (
    <Layout>
      <WorkspaceImg
        srcKey={images[0].key}
        size="sm"
        style={{ objectFit: 'cover', height: 184, borderTopLeftRadius: 8, borderTopRightRadius: 8 }}
      />
      <Layout padding={24} borderBottomLeftRadius={8} borderBottomRightRadius={8} borderRight borderBottom borderLeft>
        <Text size="h6">{name}</Text>
        <Text size="body2">{formatPublicAddress(address)}</Text>
        <STBRequestCardInfo start_date={start_date} end_date={end_date} amount={amount} />
      </Layout>
    </Layout>
  );
};

export default STBRequestCard;
