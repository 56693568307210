import React, { useContext, useRef } from 'react';
import { GRAY_400_HEX } from 'shared';

import { app } from 'context';
import IconButton from 'ds/IconButton';
import Layout from 'ds/Layout';
import Modal from 'ds/Modal';
import Pressable from 'ds/Pressable';
import Text from 'ds/Text';
import { Props as TextInputProps } from 'ds/inputs/TextInput';
import StickyHeader from 'ux/Layouts/Shared/StickyHeader';

import { HEADER_Z_INDEX } from './constants';
import useKeyDown from './helpers/useKeyDown';
import { highlightMatches } from './utils';

interface Props extends Pick<TextInputProps, 'label' | 'placeholder'> {
  isVisible: boolean;
  onClose: VoidFunction;
  highlightMatch?: boolean;
  results: Result[];
  query: string;
  selections: string[];
  handleChange: (value: string) => void;
  handleSelect: (value: string) => void;
  onDelete: (value: string) => void;
}

interface Result {
  label: string;
  value: string;
}

const MultiSelectBottomSheet: React.FC<Props> = ({
  isVisible,
  onClose,
  highlightMatch,
  onDelete,
  results,
  label,
  placeholder,
  query,
  selections,
  handleSelect,
  handleChange
}) => {
  const { appPaddingX } = useContext(app);
  const inputRef = useRef<HTMLInputElement>(null);

  const renderResult = ({ label, value }: Result) => {
    const content = highlightMatch
      ? highlightMatches({
          label,
          query,
          MatchedWrapper: ({ children }) => <span className="MultiSelectBottomSheet-matchedText">{children}</span>
        })
      : label;

    return (
      <Pressable
        key={value}
        onPress={() => {
          handleSelect(value);
          inputRef.current?.focus();
        }}
        fullWidth
      >
        <Layout paddingY={12} paddingX={appPaddingX} justify="flex-start">
          <Text size="body1">{content}</Text>
        </Layout>
      </Pressable>
    );
  };

  useKeyDown((e: KeyboardEvent) => {
    if (query.length) return;

    switch (e.key) {
      case 'Delete':
      case 'Backspace':
        onDelete(selections.slice(-1)[0]);
        break;
      default:
        return;
    }
  });

  return (
    <Modal isFullScreen onClose={onClose} isVisible={isVisible} showCloseButton={false}>
      <StickyHeader top={0} zIndex={HEADER_Z_INDEX}>
        <Layout paddingX={appPaddingX} height={84} align="center" color="white">
          <IconButton size="lg" type="noBackground" onClick={onClose} name="leftChevron" />
          <Layout marginLeft={4}>
            <Text size="body1" semibold>
              Add {label}
            </Text>
          </Layout>
        </Layout>
      </StickyHeader>
      <Layout paddingY={20} align="center" paddingX={appPaddingX} borderBottom wrap>
        {selections.map(value => {
          return (
            <Layout paddingX={6} paddingY={1} marginY={4} color="gray-50" borderRadius={2} key={value} marginRight={8}>
              <Text size="body2" wrap={false}>
                {value}
              </Text>
            </Layout>
          );
        })}
        <input
          autoComplete="off"
          placeholder={placeholder}
          autoFocus
          spellCheck={false}
          style={{
            borderStyle: 'none',
            fontSize: 14,
            lineHeight: '20px',
            outline: 'none',
            background: 'none',
            color: GRAY_400_HEX,
            zIndex: 1,
            minWidth: 64,
            display: 'inline-flex'
          }}
          value={query}
          onChange={({ target: { value } }) => handleChange(value)}
          ref={inputRef}
        />
      </Layout>
      <Layout paddingTop={12}>{results.map(renderResult)}</Layout>
    </Modal>
  );
};

export default MultiSelectBottomSheet;
