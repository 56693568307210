import React, { useContext, useEffect } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { usePreviousValue } from 'beautiful-react-hooks';
import qs from 'qs';

import { featureEnabled } from 'helpers/features';

import { media } from 'context';
import { isGoogleUserAgent } from 'helpers';
import useScript from 'hooks/useScript';
import { page } from 'lib/analytics';
import { getAllPages } from 'lib/butter';
import { initializeGoogleMapsAutocompleteService, initializeGoogleMapsPlacesService } from 'lib/location';
import { actions as appActions } from 'store/App';
import { selectCmsPages, selectGoogleMapsLoaded } from 'store/App/selectors';
import { actions as uiActions } from 'store/UI';
import { selectUser } from 'store/User/selectors';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import Page404 from 'ux/Page/404';
import CmsPage from 'ux/Public/CmsPage';
import Sitemap from 'ux/Public/Sitemap';

import AuthenticatedRoute from './AuthenticatedRoute';
import PublicRoute from './PublicRoute';
import { ADMIN_PATH, PROPERTIES_PATH, REDIRECTS, SEARCH_ENTRY_PATH, SITEMAP_PATH, VENDOR_TASKS_PATH } from './paths';
import { pathsToRedirect } from './redirects';
import { ROUTES } from './routes';

interface Props {
  isReady: boolean;
  analyticsLoaded: boolean;
}

const Router: React.FC<Props> = ({ isReady, analyticsLoaded }) => {
  const cmsPages = useAppSelector(selectCmsPages);
  const { preview } = qs.parse(window.location.search, { ignoreQueryPrefix: true });
  const dynamicCmsPages = cmsPages.filter(p => p.page_type === 'public_page' && (preview || !!p.published));
  const dispatch = useAppDispatch();
  const { xs } = useContext(media);
  const googleMapsLoaded = useAppSelector(selectGoogleMapsLoaded);
  const { pathname, search } = useLocation();
  const prevPathname = usePreviousValue(pathname);
  const user = useAppSelector(selectUser);

  const determineUxType = () => {
    if (!user) return;

    const uxType = pathname.startsWith(PROPERTIES_PATH)
      ? 'property'
      : pathname.startsWith(VENDOR_TASKS_PATH)
      ? 'vendor'
      : pathname.startsWith(ADMIN_PATH)
      ? 'admin'
      : featureEnabled({ name: 'tenancyManagement', user })
      ? 'tenant'
      : 'customer';

    dispatch(
      uiActions.updateUIState({
        uxType,
        prevPathname
      })
    );
  };

  useEffect(() => {
    determineUxType();
  }, [user?.id]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    page(pathname);

    determineUxType();
  }, [pathname, dispatch]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    getAllPages().then(pages => dispatch(appActions.setCmsPages(pages)));
  }, [dispatch]);

  const googleMapsScriptStatus = useScript(
    `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API}&v=3.exp&libraries=places,geometry`,
    {
      disabled: isGoogleUserAgent() && pathname.startsWith(SEARCH_ENTRY_PATH) && xs,
      id: 'codi-google-maps-script'
    }
  );

  useEffect(() => {
    if (googleMapsScriptStatus === 'ready') {
      initializeGoogleMapsAutocompleteService();
      initializeGoogleMapsPlacesService();
      dispatch(appActions.setGoogleMapsLoaded(true));
    }
  }, [googleMapsScriptStatus, dispatch]);

  const redirectPath = pathsToRedirect.find(pathToRedirect => {
    if (pathToRedirect.exact) {
      return pathToRedirect.from === pathname;
    } else {
      return pathname.startsWith(pathToRedirect.from);
    }
  });

  if (!!redirectPath) {
    const newPath = redirectPath.redirectId ? pathname.replace(redirectPath.from, redirectPath.to) : redirectPath.to;
    return (
      <Redirect
        to={{
          pathname: newPath,
          search
        }}
      />
    );
  }

  const publicRoutes = ROUTES.filter(({ requiredRole }) => !requiredRole);
  const authenticatedRoutes = ROUTES.filter(({ requiredRole }) => !!requiredRole);

  return (
    <Switch>
      {authenticatedRoutes.map(
        ({
          path,
          requiredRole,
          redirect,
          Component,
          scrollToTop,
          exact,
          customLayout,
          sideBarIsExpandable,
          showMobileBottomNav
        }) => (
          <Route
            path={path}
            key={path}
            exact={exact}
            render={props => {
              return (
                <AuthenticatedRoute
                  {...props}
                  showMobileBottomNav={showMobileBottomNav}
                  requiredRole={requiredRole}
                  redirect={redirect}
                  scrollToTop={scrollToTop}
                  isReady={isReady && googleMapsLoaded && analyticsLoaded}
                  customLayout={customLayout}
                  sideBarIsExpandable={sideBarIsExpandable}
                >
                  <Component {...props} />
                </AuthenticatedRoute>
              );
            }}
          />
        )
      )}
      {publicRoutes.map(
        ({
          path,
          customLayout,
          requiredRole,
          redirect,
          Component,
          hideHeader,
          hideFooter,
          hasFooterMargin,
          hasHeaderCta,
          scrollToTop,
          exact,
          cmsSlug,
          waitForUserLoad,
          waitForAnalyticsLoad,
          waitForGoogleMapsLoad
        }) => (
          <Route
            path={path}
            key={path}
            exact={exact}
            render={props => {
              return (
                <PublicRoute
                  {...props}
                  customLayout={customLayout}
                  requiredRole={requiredRole}
                  redirect={redirect}
                  hideHeader={hideHeader}
                  hideFooter={hideFooter}
                  hasFooterMargin={hasFooterMargin}
                  hasHeaderCta={hasHeaderCta}
                  scrollToTop={scrollToTop}
                  isReady={isReady}
                  analyticsLoaded={analyticsLoaded}
                  waitForUserLoad={waitForUserLoad}
                  waitForAnalyticsLoad={waitForAnalyticsLoad}
                  waitForGoogleMapsLoad={waitForGoogleMapsLoad}
                >
                  <Component {...props} cmsSlug={cmsSlug} />
                </PublicRoute>
              );
            }}
          />
        )
      )}
      {dynamicCmsPages.map(page => {
        const { path } = page.fields;

        return (
          <Route
            path={path}
            key={path}
            render={props => {
              return (
                <PublicRoute
                  {...props}
                  hasFooterMargin={false}
                  hasHeaderCta={true}
                  isReady={isReady}
                  analyticsLoaded={analyticsLoaded}
                >
                  <CmsPage {...props} {...page} />
                </PublicRoute>
              );
            }}
            exact
          />
        );
      })}
      {/* Sitemap route is included here instead of publicRoutes to avoid a circular dependency */}
      <Route
        path={SITEMAP_PATH}
        render={props => {
          return (
            <PublicRoute {...props} analyticsLoaded={analyticsLoaded} isReady={isReady} waitForAnalyticsLoad={false}>
              <Sitemap />
            </PublicRoute>
          );
        }}
      />
      {REDIRECTS.map(({ from, to }) => (
        <Redirect key={from} from={from} to={to} />
      ))}
      {cmsPages.length && (
        <Route
          render={props => {
            return (
              <PublicRoute {...props} analyticsLoaded={analyticsLoaded} isReady={isReady}>
                <Page404 />
              </PublicRoute>
            );
          }}
        />
      )}
    </Switch>
  );
};

export default Router;
