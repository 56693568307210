import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { GRAY_900_HEX, User, WHITE_HEX } from 'shared';

import { app, media } from 'context';
import { Animate, Avatar, Button, HEADER_PORTAL_ID, Icon, Layout, Link, Logo, Pressable, TextButton } from 'ds';
import { EnterpriseNavigationPage } from 'lib/butter/types';
import { OFFICE_REQUEST_PATH, ROOT_PATH } from 'routes';
import { selectCmsPage } from 'store/App/selectors';
import { useAppSelector } from 'store/hooks';

import AccountMenu from './AccountMenu';
import PublicMenu from './PublicMenu';

interface Props {
  showCta?: boolean;
  isSearchPage?: boolean;
  user?: User | null;
  isTransparentMode?: boolean;
  textOpacity?: number;
  isPublicNavOpen: boolean;
  setIsPublicNavOpen: (isOpen: boolean) => void;
  isAccountNavOpen: boolean;
  setIsAccountNavOpen: (isOpen: boolean) => void;
}

const Mobile: React.FC<Props> = ({
  showCta,
  isSearchPage,
  user,
  isTransparentMode,
  textOpacity,
  isAccountNavOpen,
  isPublicNavOpen,
  setIsAccountNavOpen,
  setIsPublicNavOpen
}) => {
  const { contentPaddingX, navBarHeight } = useContext(app);
  const { xs } = useContext(media);
  const history = useHistory();

  const page = useAppSelector(s => selectCmsPage(s, 'enterprise-navigation'));
  const closePublicNavMenu = () => setIsPublicNavOpen(false);
  const closeAccountNavMenu = () => setIsAccountNavOpen(false);

  useEffect(() => {
    return () => {
      closePublicNavMenu();
      closeAccountNavMenu();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const accountMenuButton = (
    <Pressable
      onPress={() => {
        setIsAccountNavOpen(true);
      }}
    >
      <Avatar
        size={32}
        avatarUrl={user?.avatar_url}
        opacity={user ? 1 : textOpacity ? textOpacity : undefined}
        hexColor={isTransparentMode && !textOpacity ? WHITE_HEX : GRAY_900_HEX}
      />
    </Pressable>
  );

  return (
    <>
      <Layout
        justify="space-between"
        zIndex={100}
        marginX={contentPaddingX}
        height={navBarHeight}
        align="center"
        opacity={textOpacity ? textOpacity : undefined}
      >
        <Animate
          animation="fade-in"
          visible={!isPublicNavOpen && !isAccountNavOpen}
          {...(isSearchPage ? { flexGrow: 1 } : {})}
          fadeOut
        >
          <Layout align="center">
            <Layout width={40} height={40} justify="center" align="center">
              <TextButton
                scale
                color="white"
                textSize="body1"
                text={
                  <Icon
                    name="hamburger"
                    size="sm"
                    color={isTransparentMode && !textOpacity ? 'white' : 'blue-500'}
                    stroke={2}
                  />
                }
                onClick={() => setIsPublicNavOpen(true)}
              />
            </Layout>
            <Link href={ROOT_PATH}>
              <Logo size="sm" color={isTransparentMode && !textOpacity ? 'white' : 'blue-500'} />
            </Link>
            <Layout flexGrow={1} __id={HEADER_PORTAL_ID} />
          </Layout>
        </Animate>
        <Animate animation="fade-in" visible={!isPublicNavOpen && !isAccountNavOpen} fadeOut>
          <Layout align="center">
            {!xs && showCta && !isSearchPage && (
              <Layout marginRight={8}>
                <Button size="sm" type="primary" text="Get started" href={OFFICE_REQUEST_PATH} />
              </Layout>
            )}
            {!user && (
              <Layout marginLeft={24} width={40} height={40} justify="center" align="center">
                <TextButton
                  scale
                  color="gray-900"
                  textSize="body1"
                  text={
                    <Layout width={40} height={40} justify="center" align="center">
                      <Icon name="avatar" size="sm" color={isTransparentMode && !textOpacity ? 'white' : 'gray-900'} />
                    </Layout>
                  }
                  onClick={() => {
                    history.push('/sign-in');
                  }}
                />
              </Layout>
            )}
            {user && (
              <Layout marginLeft={16} width={40} height={40} justify="center" align="center">
                {accountMenuButton}
              </Layout>
            )}
          </Layout>
        </Animate>
      </Layout>
      {isPublicNavOpen && page && (
        <PublicMenu
          menus={(page as EnterpriseNavigationPage).fields.menus}
          closeMenu={closePublicNavMenu}
          user={user}
        />
      )}
      {isAccountNavOpen && !!user && <AccountMenu user={user} closeMenu={closeAccountNavMenu} />}
    </>
  );
};

export default Mobile;
