import React, { useContext, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import {
  NotificationPreferences as NotificationPreferencesType,
  getNotificationPreferences,
  updateNotificationPreferences
} from 'shared';

import { showSnackbar } from 'helpers/snackbar';

import Head from 'components/Head';

import { media } from 'context';
import { Layout, SAVE_MESSAGE, Text } from 'ds';
import Sections from 'ux/Customer/Account/Notifications/Sections';

interface MatchProps {
  token: string;
}

interface Props extends RouteComponentProps<MatchProps> {}

const NotificationPreferences: React.FC<Props> = ({ match: { params } }) => {
  const token = params.token.replace(/[=]/g, '');
  const { xs } = useContext(media);
  const [notificationPreferences, setNotificationPreferences] = useState<
    Pick<NotificationPreferencesType, 'community' | 'reminders' | 'support'>
  >();

  useEffect(() => {
    getNotificationPreferences({ token }).then(({ data }) => setNotificationPreferences(data));
  }, [token]);

  if (!notificationPreferences) return null;

  return (
    <Layout direction="column" align={'center'} paddingBottom={96}>
      <Head title="Notification Preferences" description="Update your notification preferences." />
      <Layout marginBottom={56}>
        <Text size="h3" align={xs ? 'center' : undefined} scale>
          <Layout inline __style={{ whiteSpace: xs ? undefined : 'nowrap' }}>
            <Text align="left" inline scale>
              Notification{' '}
            </Text>
            <Text align="left" color="blue-500" inline scale>
              Preferences
            </Text>
          </Layout>
        </Text>
      </Layout>
      <Layout maxWidth={768}>
        <Sections
          notificationPreferences={notificationPreferences}
          updatePreference={async ({ notificationCategory, notificationType, sectionValues, value }) => {
            updateNotificationPreferences({
              token,
              [notificationCategory]: { ...sectionValues, [notificationType]: value }
            }).then(({ data }) => {
              setNotificationPreferences(data);
              showSnackbar({ message: SAVE_MESSAGE, duration: 1500 });
            });
          }}
        />
      </Layout>
    </Layout>
  );
};

export default NotificationPreferences;
