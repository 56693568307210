import React, { useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Color } from 'shared';

import { app, media } from 'context';
import { Layout, Link, LinkTarget, Text, TextButton } from 'ds';
import { OFFICE_REQUEST_PATH } from 'routes';
import { actions as officeRequestActions } from 'store/OfficeRequest';
import { useAppDispatch } from 'store/hooks';

interface Props extends InformationProps {
  Visual: JSX.Element;
  reverse?: boolean;
  reverseVisual?: boolean;
}

interface LinkProps {
  context?: string;
  href?: string;
  color?: Color;
  text: string | JSX.Element;
  target?: LinkTarget;
  showForm?: boolean;
  addArrow?: boolean;
}

export const VISUAL_MAX_WIDTH = 648;

const Section: React.FC<Props> = ({
  links,
  heading,
  headingText,
  subHeadingText,
  subHeading,
  Visual,
  reverse = false,
  reverseVisual = false
}) => {
  const { xs, sm, md, lg } = useContext(media);
  const { sectionGutterX } = useContext(app);

  return (
    <Layout
      align="center"
      direction={xs ? (reverseVisual ? 'column-reverse' : 'column') : reverse ? 'row-reverse' : 'row'}
      flexGrow={1}
    >
      <Layout justify={reverse ? 'flex-end' : undefined} equalWidth={lg || md || sm} width={xs ? '100%' : undefined}>
        <Layout maxWidth={xs ? undefined : VISUAL_MAX_WIDTH}>{Visual}</Layout>
      </Layout>
      <Layout
        marginLeft={reverse ? 0 : sectionGutterX}
        marginRight={reverse ? sectionGutterX : 0}
        width={xs ? '100%' : undefined}
        equalWidth
      >
        <Information {...{ links, heading, headingText, subHeading, subHeadingText, reverse }} />
      </Layout>
    </Layout>
  );
};

interface InformationProps {
  headingText?: string;
  heading?: JSX.Element | null;
  subHeadingText?: string | JSX.Element;
  subHeading?: JSX.Element;
  links: LinkProps[];
}

export const Information: React.FC<InformationProps> = ({
  links,
  heading = null,
  headingText,
  subHeadingText,
  subHeading
}) => {
  const { xs } = useContext(media);

  return (
    <Layout marginTop={xs ? 48 : undefined}>
      {heading || (
        <Text scale tag="h3">
          {headingText}
        </Text>
      )}
      {subHeading || (
        <Layout marginTop={24}>
          <Text size="body1" scale>
            {subHeadingText}
          </Text>
        </Layout>
      )}
      {links.map((linkProps, index) => (
        <SectionLink key={index} {...linkProps} />
      ))}
    </Layout>
  );
};

const SectionLink: React.FC<LinkProps> = ({ href, context, color, text, showForm, target, addArrow }) => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();

  return (
    <Layout marginTop={24}>
      {!!href ? (
        <Link href={href} context={context} target={target} size="large-link" color={color}>
          {addArrow ? <>&rarr; {text}</> : text}
        </Link>
      ) : (
        <TextButton
          scale
          onClick={
            showForm
              ? () => {
                  dispatch(officeRequestActions.setLaunchPath(pathname));
                  history.push(OFFICE_REQUEST_PATH);
                }
              : () => {}
          }
          context={context}
          textSize="large-link"
          color={color || 'blue-500'}
          text={addArrow ? <>&rarr; {text}</> : text}
        />
      )}
    </Layout>
  );
};

export default Section;
