export const DAYS_OF_WEEK = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'] as const;

export const DEFAULT_LAT_LNG = { lat: 37.7749, lng: -122.4194 };

export const WORKSPACE_NAME_MAX_LENGTH = 60;
export const WORKSPACE_DESCRIPTION_MAX_LENGTH = 500;
export const WORKSPACE_ADDITIONAL_RULES_MAX_LENGTH = 500;

export const SUPPORT_EMAIL = 'support@codi.com';

export const START_OF_CODI_EPOCH = new Date('2018-12-01');

export const BOUNDS = ['ne', 'sw'] as const;
export const COORDS = ['lat', 'lng'] as const;

export const STORAGE_NAMESPACE = '__codi__' as const;
export const AUTH_STORAGE_NAMESPACE = `${STORAGE_NAMESPACE}.auth` as const;
export const AUTH_TOKEN_KEY = `${AUTH_STORAGE_NAMESPACE}.token` as const;

export const STATE_NAME_TO_ABBREVIATION = {
  Arizona: 'AZ',
  Alabama: 'AL',
  Alaska: 'AK',
  Arkansas: 'AR',
  California: 'CA',
  Colorado: 'CO',
  Connecticut: 'CT',
  Delaware: 'DE',
  Florida: 'FL',
  Georgia: 'GA',
  Hawaii: 'HI',
  Idaho: 'ID',
  Illinois: 'IL',
  Indiana: 'IN',
  Iowa: 'IA',
  Kansas: 'KS',
  Kentucky: 'KY',
  Louisiana: 'LA',
  Maine: 'ME',
  Maryland: 'MD',
  Massachusetts: 'MA',
  Michigan: 'MI',
  Minnesota: 'MN',
  Mississippi: 'MS',
  Missouri: 'MO',
  Montana: 'MT',
  Nebraska: 'NE',
  Nevada: 'NV',
  'New Hampshire': 'NH',
  'New Jersey': 'NJ',
  'New Mexico': 'NM',
  'New York': 'NY',
  'North Carolina': 'NC',
  'North Dakota': 'ND',
  Ohio: 'OH',
  Oklahoma: 'OK',
  Oregon: 'OR',
  Pennsylvania: 'PA',
  'Rhode Island': 'RI',
  'South Carolina': 'SC',
  'South Dakota': 'SD',
  Tennessee: 'TN',
  Texas: 'TX',
  Utah: 'UT',
  Vermont: 'VT',
  Virginia: 'VA',
  Washington: 'WA',
  'West Virginia': 'WV',
  Wisconsin: 'WI',
  Wyomin: 'WY'
};

export const STATE_ABBREVIATION_TO_NAME = {
  AZ: 'Arizona',
  AL: 'Alabama',
  AK: 'Alaska',
  AR: 'Arkansas',
  CA: 'California',
  CO: 'Colorado',
  CT: 'Connecticut',
  DE: 'Delaware',
  FL: 'Florida',
  GA: 'Georgia',
  HI: 'Hawaii',
  ID: 'Idaho',
  IL: 'Illinois',
  IN: 'Indiana',
  IA: 'Iowa',
  KS: 'Kansas',
  KY: 'Kentucky',
  LA: 'Louisiana',
  ME: 'Maine',
  MD: 'Maryland',
  MA: 'Massachusetts',
  MI: 'Michigan',
  MN: 'Minnesota',
  MS: 'Mississippi',
  MO: 'Missouri',
  MT: 'Montana',
  NE: 'Nebraska',
  NV: 'Nevada',
  NH: 'New Hampshire',
  NJ: 'New Jersey',
  NM: 'New Mexico',
  NY: 'New York',
  NC: 'North Carolina',
  ND: 'North Dakota',
  OH: 'Ohio',
  OK: 'Oklahoma',
  OR: 'Oregon',
  PA: 'Pennsylvania',
  RI: 'Rhode Island',
  SC: 'South Carolina',
  SD: 'South Dakota',
  TN: 'Tennessee',
  TX: 'Texas',
  UT: 'Utah',
  VT: 'Vermont',
  VA: 'Virginia',
  WA: 'Washington',
  WV: 'West Virginia',
  WI: 'Wisconsin',
  WY: 'Wyoming'
};

export const REGION_ABBREVIATION_TO_NAME = {
  US: STATE_ABBREVIATION_TO_NAME,
  CA: {
    AB: 'Alberta',
    BC: 'British Columbia',
    MB: 'Manitoba',
    NB: 'New Brunswick',
    NL: 'Newfoundland and Labrador',
    NT: 'Northwest Territories',
    NS: 'Nova Scotia',
    NU: 'Nunavut',
    ON: 'Ontario',
    PE: 'Prince Edward Island',
    QC: 'Quebec',
    SK: 'Saskatchewan',
    YT: 'Yukon'
  }
};

export const COUNTRY_3_ALPHA_TO_2_ALPHA = {
  CAN: 'CA',
  USA: 'US'
};

export const ADD_ON_TASK_TYPE = 'upgrades/add-ons' as const;
export const CLEANING_TASK_TYPE = 'cleaning' as const;
export const DEACTIVATION_TASK_TYPE = 'deactivation' as const;
export const MARKETING_TASK_TYPE = 'marketing' as const;
export const TOUCH_UP_TASK_TYPE = 'touch-up' as const;
export const RESTOCKING_TASK_TYPE = 'restocking' as const;
export const MAINTENANCE_TASK_TYPE = 'maintenance' as const;
export const SOURCING_TASK_TYPE = 'sourcing' as const;
export const STBS_TASK_TYPE = 'stbs' as const;
export const MISCELLANEOUS_TASK_TYPE = 'miscellaneous' as const;
export const INTERNET_ACTIVATION_TASK_TYPE = 'internet activation' as const;
export const INTERNET_DEACTIVATION_TASK_TYPE = 'internet deactivation' as const;
export const INTERNET_IT_TASK_TYPE = 'internet/it' as const;
export const UTILITIES_TASK_TYPE = 'utilities' as const;
export const FURNITURE_TASK_TYPE = 'furniture' as const;
export const PHONE_BOOTH_TASK_TYPE = 'phone booth' as const;
export const ACCESS_TASK_TYPE = 'access' as const;

export const TASK_TYPES = [
  ADD_ON_TASK_TYPE,
  CLEANING_TASK_TYPE,
  DEACTIVATION_TASK_TYPE,
  TOUCH_UP_TASK_TYPE,
  RESTOCKING_TASK_TYPE,
  MAINTENANCE_TASK_TYPE,
  SOURCING_TASK_TYPE,
  STBS_TASK_TYPE,
  MARKETING_TASK_TYPE,
  MISCELLANEOUS_TASK_TYPE,
  INTERNET_ACTIVATION_TASK_TYPE,
  INTERNET_DEACTIVATION_TASK_TYPE,
  INTERNET_IT_TASK_TYPE,
  UTILITIES_TASK_TYPE,
  FURNITURE_TASK_TYPE,
  PHONE_BOOTH_TASK_TYPE,
  ACCESS_TASK_TYPE
] as const;
