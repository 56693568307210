import React, { useContext } from 'react';
import { BLUE_400_HEX } from 'shared';

import { media } from 'context';
import { Button, ButtonType, Clickable, Icon, Layout } from 'ds';
import { OFFICE_REQUEST_PATH } from 'routes';

import { PLAYBOOK_HEADER_HEIGHT } from './constants';

interface Props {
  openMenu: VoidFunction;
  buttonType?: ButtonType;
  chapterTitle?: string;
}

const Header: React.FC<Props> = ({ openMenu, buttonType = 'primary', chapterTitle }) => {
  const { xs } = useContext(media);

  return (
    <Layout paddingY={36} justify="space-between" height={PLAYBOOK_HEADER_HEIGHT}>
      <Layout display="inline-flex" align="center">
        <Clickable
          style={{ width: 48, height: 48, display: 'flex', justifyContent: 'center', alignItems: 'center' }}
          onClick={openMenu}
        >
          <Icon size="lg" name="hamburger" color="blue-500" />
        </Clickable>
        {chapterTitle && (
          <Layout marginLeft={16} visibility={xs ? 'hidden' : undefined} position={xs ? 'absolute' : undefined}>
            <p
              style={{
                color: BLUE_400_HEX,
                fontSize: 12,
                letterSpacing: '2px',
                fontWeight: 600,
                lineHeight: '24px'
              }}
            >
              {chapterTitle}
            </p>
          </Layout>
        )}
      </Layout>
      <Button
        text="Request an office"
        size="sm"
        type={buttonType}
        href={OFFICE_REQUEST_PATH}
        icon="envelope"
        iconStroke={2}
        iconPosition="left"
      />
    </Layout>
  );
};

export default Header;
