import { CreateablePayoutAccount, CreateablePayoutMethodAddress, CreateablePayoutMethodBank } from './types';
import { Payout, PayoutAccount } from 'shared/types';

import axios from 'shared/lib/axios';

type LicensorIdParam = { id: number };

function payoutAccountsPath({ id }: LicensorIdParam) {
  return `/v1/licensors/${id}/payout_accounts`;
}

function payoutAccountPath({ licensorId, payoutAccountId }: { licensorId: number; payoutAccountId: number }) {
  return `/v1/licensors/${licensorId}/payout_accounts/${payoutAccountId}`;
}

function createPayoutMethodPath({ licensorId, payoutAccountId }: { licensorId: number; payoutAccountId: number }) {
  return `/v1/licensors/${licensorId}/payout_accounts/${payoutAccountId}/payout_methods`;
}

function updatePayoutMethodPath({
  licensorId,
  payoutAccountId,
  payoutMethodId
}: {
  licensorId: number;
  payoutAccountId: number;
  payoutMethodId: string;
}) {
  return `/v1/licensors/${licensorId}/payout_accounts/${payoutAccountId}/payout_methods/${payoutMethodId}`;
}

function viewPayoutsPath({ licensorId }: { licensorId: number }) {
  return `/v1/licensors/${licensorId}/view_payouts`;
}

function payoutsPath({ licensorId, payoutAccountId }: { licensorId: number; payoutAccountId: number }) {
  return `/v1/licensors/${licensorId}/payout_accounts/${payoutAccountId}/payouts`;
}

export function getPayoutAccount({ id }: LicensorIdParam) {
  return axios.get<PayoutAccount>(payoutAccountsPath({ id }));
}

export function createPayoutAccount({
  licensorId,
  payoutAccount
}: {
  licensorId: number;
  payoutAccount: CreateablePayoutAccount;
}) {
  return axios.post<PayoutAccount>(payoutAccountsPath({ id: licensorId }), {
    ...payoutAccount
  });
}

export function updatePayoutAccount({
  licensorId,
  payoutAccountId,
  payoutDay,
  defaultRevSharePercentage,
  payoutUpcomingMonth
}: {
  licensorId: number;
  payoutAccountId: number;
  payoutDay?: number;
  defaultRevSharePercentage?: number;
  payoutUpcomingMonth?: boolean;
}) {
  return axios.patch<PayoutAccount>(payoutAccountPath({ licensorId, payoutAccountId }), {
    payout_day: payoutDay,
    default_rev_share_percentage: defaultRevSharePercentage,
    payout_upcoming_month: payoutUpcomingMonth
  });
}

export function createPayoutMethodBank({
  licensorId,
  payoutAccountId,
  payoutMethod
}: {
  licensorId: number;
  payoutAccountId: number;
  payoutMethod: CreateablePayoutMethodBank;
}) {
  return axios.post<PayoutAccount>(createPayoutMethodPath({ licensorId, payoutAccountId }), {
    type: 'bank',
    bank: { ...payoutMethod }
  });
}

export function createPayoutMethodAddress({
  licensorId,
  payoutAccountId,
  payoutMethod
}: {
  licensorId: number;
  payoutAccountId: number;
  payoutMethod: CreateablePayoutMethodAddress;
}) {
  return axios.post<PayoutAccount>(createPayoutMethodPath({ licensorId, payoutAccountId }), {
    type: 'address',
    address: { ...payoutMethod }
  });
}

export function updatePayoutMethod({
  licensorId,
  payoutAccountId,
  payoutMethodId
}: {
  licensorId: number;
  payoutAccountId: number;
  payoutMethodId: string;
}) {
  return axios.put<PayoutAccount>(updatePayoutMethodPath({ licensorId, payoutAccountId, payoutMethodId }), {});
}

export function getPayoutViewerPayouts({ licensorId }: { licensorId: number }) {
  return axios.get<Payout[]>(viewPayoutsPath({ licensorId }));
}

export function getPayouts({ licensorId, payoutAccountId }: { licensorId: number; payoutAccountId: number }) {
  return axios.get<Payout[]>(payoutsPath({ licensorId, payoutAccountId }));
}
