import React, { useContext } from 'react';
import { GRAY_900_HEX, ListingWorkspace } from 'shared';

import { media } from 'context';
import { Clickable, Icon, Layout, Text, Tooltip } from 'ds';
import { selectMarkets } from 'store/App/selectors';
import { selectUser } from 'store/User/selectors';
import { useAppSelector } from 'store/hooks';

import MiniWorkspaceInfo from './MiniWorkspaceInfo';
import TourCTA from './TourCTA';
import { LISTING_CTA_BOX_WIDTH, LISTING_CTA_BOX_WIDTH_SM } from './constants';
import { marketFromLatLng } from '../OfficeRequest/utils';

interface Props {
  workspace: ListingWorkspace;
  selectedDaysRate?: number;
  daysPerWeek: number;
  setDaysPerWeek: (daysPerWeek: number) => void;
  setPriceExplanationModalIsVisible: (isVisible: boolean) => void;
}

const CtaBox: React.FC<Props> = ({
  workspace,
  selectedDaysRate,
  daysPerWeek,
  setDaysPerWeek,
  setPriceExplanationModalIsVisible
}) => {
  const { xs, sm } = useContext(media);
  const isMobileResolution = xs;
  const boxWidth = xs ? '100%' : sm ? LISTING_CTA_BOX_WIDTH_SM : LISTING_CTA_BOX_WIDTH;

  const user = useAppSelector(selectUser);
  const isProspectVersion = !!user;

  const markets = useAppSelector(selectMarkets);
  const market = marketFromLatLng({ lat: workspace.address.lat, lng: workspace.address.lon }, markets);
  const hybridAvailable = !market || market?.hybrid_available;

  const isLoggedIn = !!useAppSelector(selectUser);

  return (
    <Layout>
      <Layout
        paddingX={isMobileResolution ? undefined : 32}
        paddingY={isMobileResolution ? 24 : 32}
        width={boxWidth}
        borderRadius={isMobileResolution ? undefined : 12}
        __style={isMobileResolution ? {} : { boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)' }}
        color="white"
      >
        <MiniWorkspaceInfo workspace={workspace} isProspectVersion={isProspectVersion} />
        {(!xs || isLoggedIn) && <Layout paddingTop={24} marginBottom={24} borderBottom borderColor="gray-50" />}
        {!!hybridAvailable && isLoggedIn && (
          <>
            <Layout direction="row" align="center">
              <Text size="body1" color="gray-900" semibold>
                Select days per week needed
              </Text>
              <Layout marginLeft={8} />
              <Tooltip
                size="lg"
                text={
                  <>
                    <Text color="white" size="body3">
                      Only pay for the days you need. Full-time includes
                      <br />
                      Monday – Friday (and weekends when available).
                    </Text>
                  </>
                }
                placement={xs ? 'top' : 'top-end'}
              >
                <Layout height="100%" align="center">
                  <Icon name="info" color="gray-900" size="xs" />
                </Layout>
              </Tooltip>
            </Layout>
            <Layout
              marginY={24}
              width="100%"
              direction="row"
              height={xs || sm ? 44 : 56}
              paddingX={xs ? 12 : undefined}
              maxWidth={342}
            >
              <button style={{ height: '100%' }} onClick={() => setDaysPerWeek(5)}>
                <Layout
                  hoverColor={!daysPerWeek || daysPerWeek === 5 ? undefined : 'blue-100'}
                  width="100%"
                  borderRadius={100}
                  justify="center"
                  align="center"
                  color={!daysPerWeek || daysPerWeek === 5 ? 'blue-500' : 'white'}
                  borderWidth={1}
                  borderColor={!daysPerWeek || daysPerWeek === 5 ? 'blue-500' : 'blue-300'}
                  height="100%"
                  paddingX={xs ? 20 : 24}
                >
                  <Text
                    size="body2"
                    semibold
                    color={!daysPerWeek || daysPerWeek === 5 ? 'white' : 'blue-500'}
                    wrap={false}
                  >
                    Full-time
                  </Text>
                </Layout>
              </button>
              <button style={{ marginLeft: 12, height: '100%' }} onClick={() => setDaysPerWeek(3)}>
                <Layout
                  hoverColor={daysPerWeek === 3 ? undefined : 'blue-100'}
                  width={xs || sm ? 44 : 56}
                  height={xs || sm ? 44 : 56}
                  borderRadius="circular"
                  justify="center"
                  align="center"
                  color={daysPerWeek === 3 ? 'blue-500' : 'white'}
                  borderWidth={1}
                  borderColor={daysPerWeek === 3 ? 'blue-500' : 'blue-300'}
                >
                  <Text size="body2" semibold color={!daysPerWeek || daysPerWeek === 3 ? 'white' : 'blue-500'}>
                    3
                  </Text>
                </Layout>
              </button>
              <button style={{ marginLeft: 12, height: '100%' }} onClick={() => setDaysPerWeek(2)}>
                <Layout
                  hoverColor={daysPerWeek === 2 ? undefined : 'blue-100'}
                  width={xs || sm ? 44 : 56}
                  height={xs || sm ? 44 : 56}
                  borderRadius="circular"
                  justify="center"
                  align="center"
                  color={daysPerWeek === 2 ? 'blue-500' : 'white'}
                  borderWidth={1}
                  borderColor={daysPerWeek === 2 ? 'blue-500' : 'blue-300'}
                >
                  <Text size="body2" semibold color={!daysPerWeek || daysPerWeek === 2 ? 'white' : 'blue-500'}>
                    2
                  </Text>
                </Layout>
              </button>
              <button style={{ marginLeft: 12, height: '100%' }} onClick={() => setDaysPerWeek(1)}>
                <Layout
                  hoverColor={daysPerWeek === 1 ? undefined : 'blue-100'}
                  width={xs || sm ? 44 : 56}
                  height={xs || sm ? 44 : 56}
                  borderRadius="circular"
                  justify="center"
                  align="center"
                  color={daysPerWeek === 1 ? 'blue-500' : 'white'}
                  borderWidth={1}
                  borderColor={daysPerWeek === 1 ? 'blue-500' : 'blue-300'}
                >
                  <Text size="body2" semibold color={!daysPerWeek || daysPerWeek === 1 ? 'white' : 'blue-500'}>
                    1
                  </Text>
                </Layout>
              </button>
            </Layout>
          </>
        )}
        {isLoggedIn && !!selectedDaysRate && !!daysPerWeek && (
          <Clickable onClick={() => setPriceExplanationModalIsVisible(true)}>
            <Layout direction="row" align="flex-end" __style={{ borderBottom: `1px solid ${GRAY_900_HEX}` }}>
              <Layout justify="flex-start" align="stretch">
                <Text size="h6" inline>
                  $
                </Text>
                <Text size="h4" color="gray-900" inline>
                  {selectedDaysRate.toLocaleString()}
                </Text>
                <Layout marginLeft={8} marginBottom={6} align="flex-end">
                  <Text size="body3" semibold inline color="gray-900">
                    /month
                  </Text>
                </Layout>
              </Layout>
            </Layout>
          </Clickable>
        )}
        <Layout marginTop={24} />
        <TourCTA workspace={workspace} />
      </Layout>
      {xs && <Layout marginTop={8} marginBottom={24} borderBottom borderColor="gray-50" />}
    </Layout>
  );
};

export default CtaBox;
