import React, { useContext, useEffect, useState } from 'react';
import { Image } from 'shared';

import { app, media } from 'context';
import { useKeyPress } from 'ds/helpers';
import { SwiperSlide } from 'swiper/react';
import { Swiper } from 'swiper/types';

import Icon from '../Icon';
import Layout from '../Layout';
import Modal from '../Modal';
import SwiperCarousel from '../SwiperCarousel';
import Text from '../Text';
import WorkspaceImg from '../WorkspaceImg';
import { WORKSPACE_PHOTO_W_TO_H_RATIO } from '../constants';
import { lockScreenScroll, unlockScreenScroll } from '../utils';

interface Props {
  images: Image[];
  startingIndex: number;
  onClose: VoidFunction;
  padResize?: boolean;
}

const PADDING_X = 166;
const PADDING_Y = 112;
const IMAGE_MAX_WIDTH = 1200;
const IMAGE_MAX_HEIGHT = 800;

const Lightbox: React.FC<Props> = ({ images, onClose, startingIndex, padResize = false }) => {
  const { xs } = useContext(media);
  const { windowWidth } = useContext(app);
  const [activeIndex, setActiveIndex] = useState<number>(startingIndex);
  const [swiperInstance, setSwiperInstance] = useState<Swiper | null>(null);

  useEffect(() => {
    lockScreenScroll();

    return () => {
      unlockScreenScroll();
    };
  }, []);

  useKeyPress(e => {
    if (e.key === 'ArrowLeft') {
      swiperInstance?.slidePrev();
    } else if (e.key === 'ArrowRight') {
      swiperInstance?.slideNext();
    }
  });

  const imageViewportWidth = window.innerWidth - PADDING_X * 2;
  const imageViewportHeight = window.innerHeight - PADDING_Y * 2;

  const [imageWidth, imageHeight] = xs
    ? [windowWidth, windowWidth / WORKSPACE_PHOTO_W_TO_H_RATIO]
    : imageViewportWidth / imageViewportHeight >= WORKSPACE_PHOTO_W_TO_H_RATIO
    ? [imageViewportHeight * WORKSPACE_PHOTO_W_TO_H_RATIO, imageViewportHeight]
    : [imageViewportHeight, imageViewportWidth / WORKSPACE_PHOTO_W_TO_H_RATIO];

  if (!images.length || !imageWidth) return null;

  const imageCount = images.length;

  return (
    <Modal isFullScreen size="lg" onClose={onClose} isVisible>
      <Layout width="100%" height="100vh" position="relative" justify="center" align="center" color="black">
        <Layout position="absolute" height={72} top={0} width="100%" justify="center" align="center">
          <Text color="white" size="body1">
            {activeIndex + 1} / {imageCount}
          </Text>
        </Layout>
        <Layout
          justify="center"
          align="center"
          width={imageWidth}
          height={imageHeight}
          marginLeft={PADDING_X}
          marginRight={PADDING_X}
          maxWidth={IMAGE_MAX_WIDTH}
          maxHeight={IMAGE_MAX_HEIGHT}
          marginY={PADDING_Y}
        >
          <SwiperCarousel
            onSwiper={setSwiperInstance}
            config={{ md: { slidesPerView: 1, spaceBetween: 0 } }}
            controlLayoutProps={{ width: 48, height: 48, left: -120, right: -120 }}
            leftArrow={<LeftArrow />}
            rightArrow={<RightArrow />}
            width={imageWidth}
            onSlideChange={({ realIndex }) => setActiveIndex(realIndex)}
            initialSlide={startingIndex}
            virtual={false}
            lazy
            loop
          >
            {images.map((image, index) => {
              return (
                <SwiperSlide key={image.key}>
                  <Layout direction="column" justify="center" align="center">
                    <WorkspaceImg
                      srcKey={image.key}
                      size="lg"
                      alt={`workspace ${index + 1}`}
                      style={{ userSelect: 'none' }}
                      padResize={padResize}
                    />
                  </Layout>
                </SwiperSlide>
              );
            })}
          </SwiperCarousel>
        </Layout>
      </Layout>
    </Modal>
  );
};

const LeftArrow: React.FC = () => (
  <Layout
    position="absolute"
    cursor="pointer"
    width={48}
    height={48}
    borderRadius="circular"
    borderWidth={1}
    borderColor="white"
    justify="center"
    align="center"
    top={0}
    left={0}
  >
    <Icon size="lg" color="white" name="leftChevron" />
  </Layout>
);

const RightArrow: React.FC = () => {
  return (
    <Layout
      position="absolute"
      cursor="pointer"
      width={48}
      height={48}
      borderRadius="circular"
      borderWidth={1}
      borderColor="white"
      justify="center"
      align="center"
      top={0}
      left={0}
    >
      <Icon size="lg" color="white" name="rightChevron" />
    </Layout>
  );
};

export default Lightbox;
