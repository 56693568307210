import React, { useState } from 'react';
import { EditableImage } from 'shared';
import Dropzone from 'react-dropzone';

import Grid from 'ds/Grid';
import Icon from 'ds/Icon';
import IconButton from 'ds/IconButton';
import Layout from 'ds/Layout';
import Text from 'ds/Text';

import { OnImageChange } from './types';

export interface Props {
  onChange: OnImageChange;
  images: EditableImage[];
  photosPerRow: number;
  thumbnailHeight?: number;
  gap?: number;
}

const ImageUploader: React.FC<Props> = ({ images, onChange, photosPerRow, gap = 16, thumbnailHeight = 96 }) => {
  const [_isDragging, setIsDragging] = useState<boolean>();

  return (
    <Layout>
      <Layout borderRadius={12} style={{ cursor: 'pointer' }}>
        <Dropzone
          onDragEnter={() => {
            setIsDragging(true);
          }}
          onDragLeave={() => {
            setIsDragging(false);
          }}
          onDrop={async files => {
            setIsDragging(false);

            onChange({
              images: [...images, ...files.map(file => ({ url: URL.createObjectURL(file), file }))]
            });
          }}
          accept="image/png, image/jpeg, image/heic"
          style={{}}
        >
          <Layout
            paddingTop={24}
            paddingBottom={36}
            borderRadius={12}
            borderColor="gray-300"
            borderWidth={2}
            borderStyle="dashed"
            direction="column"
            align="center"
            paddingX={24}
            gap={12}
          >
            <Icon name="uploadCloud" size={64} />
            <Text size="body-lg" bold>
              Drag and drop photos
            </Text>
            <Layout color="blue-500" paddingY={10} borderRadius={8} paddingX={12}>
              <Layout paddingX={4}>
                <Text size="body-xs" color="white" semibold>
                  Select files
                </Text>
              </Layout>
            </Layout>
          </Layout>
        </Dropzone>
      </Layout>
      <Layout marginTop={40}>
        <Grid itemsPerRow={photosPerRow} gap={gap}>
          {images.map(image => (
            <Layout key={image.id || image.filename} position="relative">
              <img
                src={image.url}
                alt={image.filename}
                style={{ height: thumbnailHeight, objectFit: 'cover', borderRadius: 8 }}
              />
              <Layout position="absolute" top={4} right={4}>
                <IconButton
                  name="close"
                  size="xs"
                  type="white"
                  onClick={() => {
                    onChange({ images: images.filter(img => img.url !== image.url) });
                  }}
                />
              </Layout>
            </Layout>
          ))}
        </Grid>
      </Layout>
    </Layout>
  );
};

export default ImageUploader;
