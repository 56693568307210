import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { colorClassName } from 'shared';
import classNames from 'classnames';

import { media } from 'context';
import { IconButton, Layout, Link, Text } from 'ds';
import useKeyDown from 'ds/helpers/useKeyDown';
import { ROOT_PATH } from 'routes';

import { PLAYBOOK_FOOTER_HEIGHT } from './constants';

import { ReactComponent as Facebook } from 'assets/images/footer/facebook-logo.svg';
import { ReactComponent as Instagram } from 'assets/images/footer/instagram-logo.svg';
import { ReactComponent as LinkedIn } from 'assets/images/footer/linkedin-logo.svg';
import { ReactComponent as X } from 'assets/images/footer/x-logo.svg';
import { ReactComponent as Logo } from 'ds/LogoResizable.svg';

export interface Props {
  backHref?: string;
  forwardHref?: string;
  pageNumber: number;
  pageCount: number;
}

const Footer: React.FC<Props> = ({ backHref, forwardHref, pageNumber, pageCount }) => {
  const history = useHistory();
  const { xs } = useContext(media);

  useKeyDown((e: KeyboardEvent) => {
    switch (e.key) {
      case 'ArrowLeft':
        goBack();
        break;
      case 'ArrowRight':
        goForward();
        break;
      default:
        return;
    }
  });

  const goBack = () => {
    if (!backHref) return;

    history.push(backHref);
  };

  const goForward = () => {
    if (!forwardHref) return;

    history.push(forwardHref);
  };

  return (
    <Layout justify="space-between" height={PLAYBOOK_FOOTER_HEIGHT} align="center">
      <Layout equalWidth>
        <Link href={ROOT_PATH}>
          <span className={classNames('Logo', colorClassName('blue-500'))}>
            <Logo style={{ width: 72 }} />
          </span>
        </Link>
      </Layout>
      <Layout align="center" justify="center" equalWidth>
        <Layout display="inline-flex" visibility={backHref ? undefined : 'hidden'}>
          <IconButton size="lg" type="noBackground" href={backHref} name="leftChevron" />
        </Layout>
        <Layout marginLeft={xs ? undefined : 24} width={60}>
          <Text size="body1" color="gray-700" align="center" semibold>
            {pageNumber} / {pageCount}
          </Text>
        </Layout>
        <Layout display="inline-flex" visibility={forwardHref ? undefined : 'hidden'} marginLeft={xs ? undefined : 24}>
          <IconButton size="lg" type="noBackground" href={forwardHref} name="rightChevron" />
        </Layout>
      </Layout>
      {!xs && (
        <Layout align="center" justify="flex-end" equalWidth>
          <Layout display="inline-flex">
            <Link href="https://www.linkedin.com/company/codiwork/about" target="_blank">
              <span className="Icon is-color-blue-500">
                <LinkedIn width={24} height={24} />
              </span>
            </Link>
          </Layout>
          <Layout marginLeft={24} display="inline-flex">
            <Link href="https://twitter.com/codiwork" target="_blank">
              <span className="Icon is-color-blue-500">
                <X width={24} height={24} />
              </span>
            </Link>
          </Layout>
          <Layout marginLeft={24} display="inline-flex">
            <Link href="https://www.facebook.com/codicommunity" target="_blank">
              <span className="Icon is-color-blue-500">
                <Facebook width={24} height={24} />
              </span>
            </Link>
          </Layout>
          <Layout marginLeft={24} display="inline-flex">
            <Link href="https://www.instagram.com/codiwork" target="_blank">
              <span className="Icon is-color-blue-500">
                <Instagram width={24} height={24} />
              </span>
            </Link>
          </Layout>
        </Layout>
      )}
    </Layout>
  );
};

export default Footer;
