import React, { useContext, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ListingWorkspace, getListing } from 'shared';

import Head from 'components/Head';

import { app, media } from 'context';
import { Button, Layout, Link, LoadingScreen, MAX_CONTENT_WIDTH, Text } from 'ds';
import { SEARCH_PATH } from 'routes';
import MobileCarousel from 'ux/Public/Listing/MobileCarousel';

import RequestBookingBox from './RequestBookingBox';
import About from '../About';
import CustomerSuccess from '../CustomerSuccess';
import Divider from '../Divider';
import Hero from '../Hero';
import Highlights from '../Highlights';
import Location from '../Location';
import PhotoCarousel from '../PhotoCarousel';

interface Props {}

const STBListing: React.FC<Props> = () => {
  const params = useParams<{ id: string }>();
  const id = parseInt(params.id);
  const [workspace, setWorkspace] = useState<ListingWorkspace | null>();
  const { xs } = useContext(media);
  const { navBarHeight, contentWidth, setOverflowX } = useContext(app);
  const maxContentWidth = Math.min(contentWidth, MAX_CONTENT_WIDTH);
  const bookingBoxRef = useRef<HTMLDivElement>(null);

  const scrollToBookingBox = () => {
    if (!bookingBoxRef.current) return;

    window.scrollTo({ top: bookingBoxRef.current.offsetTop - 120, behavior: 'smooth' });
  };

  useEffect(() => {
    if (!setOverflowX) return;

    setOverflowX('visible');

    return () => {
      setOverflowX && setOverflowX('hidden');
    };
  }, [!!setOverflowX]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    getListing({
      slug: id,
      available_short_term: true
    })
      .then(({ data: workspace }) => {
        setWorkspace(workspace);
      })
      .catch(() => setWorkspace(null));
  }, [id]);

  if (workspace === undefined) {
    return <LoadingScreen />;
  } else if (workspace === null) {
    return (
      <Layout direction="column" justify="center" align="center" marginTop={48}>
        <Text size="body1" bold align="center" scale>
          Workspace not found or no longer available
        </Text>
        <Link href={SEARCH_PATH}>Go back</Link>
      </Layout>
    );
  }

  const { address, name, description, images, floor_plans, tour_video, matterport_id } = workspace;
  const tourVideoKey = tour_video?.key || null;

  return (
    <>
      <Head
        title={`${address.city} Office Space${!!address.neighborhood ? ` in ${address.neighborhood}` : ''} | ${name}`}
        description={description}
      >
        <meta name="robots" content="noindex" />
      </Head>
      {!xs && (
        <Hero
          workspace={workspace}
          isProspectVersion={false}
          isSTB
          CTA={<Button type="primaryInverse" onClick={scrollToBookingBox} text="Request booking" context="Hero" />}
        />
      )}
      {xs && (
        <MobileCarousel
          backLabel={name}
          images={images}
          floorPlans={floor_plans}
          matterportId={matterport_id}
          tourVideoKey={tourVideoKey}
          onClick={scrollToBookingBox}
          workspace={workspace}
          isOffsite
        />
      )}
      {xs && (
        <>
          <RequestBookingBox workspace={workspace} />
          <Layout borderBottom borderColor="gray-50" />
        </>
      )}
      <Layout justify={xs ? undefined : 'center'}>
        <Layout marginTop={xs ? 60 : 32} __style={{ maxWidth: maxContentWidth }} direction="column">
          <Highlights workspace={workspace} />
          {!xs && (
            <>
              <Layout paddingTop={images.length > 1 ? 44 : undefined} justify="center">
                {images.length > 1 && (
                  <Layout width={Math.min(contentWidth, 1080)}>
                    <PhotoCarousel
                      images={images}
                      floorPlans={floor_plans}
                      matterportId={matterport_id}
                      tourVideoKey={tourVideoKey}
                      CTA={{ onClick: scrollToBookingBox, text: 'Request booking' }}
                    />
                  </Layout>
                )}
              </Layout>
              <Divider />
            </>
          )}
          <Layout {...(xs ? {} : { justify: 'space-between', width: Math.min(contentWidth, 1120) })}>
            <Layout marginTop={xs ? 36 : undefined}>
              <About workspace={workspace} />
              <Divider />
              <Location workspace={workspace} />
              <Divider />
              <CustomerSuccess name="Uma" srcKey="Uma_0z8SLth3n.png" />
            </Layout>
            {!xs && (
              <Layout
                marginLeft={40}
                __ref={bookingBoxRef}
                position="sticky"
                top={navBarHeight + 36}
                __style={{ alignSelf: 'flex-start' }}
              >
                <RequestBookingBox workspace={workspace} />
              </Layout>
            )}
          </Layout>
          <Divider />
          <Layout paddingTop={8}></Layout>
        </Layout>
      </Layout>
    </>
  );
};

export default STBListing;
