import React, { useEffect, useState } from 'react';
import { filterByInclusion } from 'shared';

import ComboBox, { ComboxBoxProps } from 'ds/ComboBox';
import NoMatchesItem from 'ds/ComboBox/NoMatchesItem';
import Layout from 'ds/Layout';
import { InputImageIconProps } from 'ds/inputs/types';
import { actions } from 'store/UI';
import { selectDefaultTypeaheadResults } from 'store/UI/selectors';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import CustomerResult from './CustomerResult';
import { SearchableCustomer } from './types';
import { Props as TextInputProps } from '../inputs/TextInput';

export interface Props
  extends Pick<TextInputProps, 'size' | 'placeholder' | 'disabled' | 'required' | 'isReadOnly'>,
    ComboxBoxProps {
  label?: string;
  customers: SearchableCustomer[];
  selectedCustomerId?: number;
  errorMessage?: string;
  onSelect: (customer: SearchableCustomer | undefined) => void;
  clearQueryAfterSelect?: boolean;
  /** Used to show default typeahead results */
  name?: string;
  onCreateNew?: (query?: string) => void;
}

const CustomerComboBox: React.FC<Props> = ({
  size,
  customers,
  label = 'Customer',
  selectedCustomerId,
  errorMessage,
  onSelect,
  placeholder = 'Search by name, email, or customer ID',
  disabled,
  required = true,
  name,
  clearQueryAfterSelect,
  onCreateNew,
  isReadOnly,
  readOnlyHref
}) => {
  const defaultIds = useAppSelector(state => selectDefaultTypeaheadResults(state, name)).ids;
  const dispatch = useAppDispatch();
  const formatQuery = (customer?: SearchableCustomer) => {
    if (!customer) return undefined;

    return customer.name;
  };

  const [query, setQuery] = useState<string | undefined>(formatQuery(customers.find(w => w.id === selectedCustomerId)));
  const [width, setWidth] = useState<number>(0);

  const selectedCustomer = customers.find(w => w.id === selectedCustomerId);
  const formattedQuery = selectedCustomer ? formatQuery(selectedCustomer) : undefined;

  useEffect(() => {
    if (!formattedQuery) {
      setQuery(undefined);
      return;
    }

    setQuery(formattedQuery);
  }, [formattedQuery]);

  const extractDomain = (email: string | undefined): string => {
    if (!email) return '';

    return email.split('@').pop() || '';
  };

  const queryDomain = extractDomain(query);

  const resultCustomers =
    selectedCustomer && defaultIds.length
      ? (defaultIds.map(id => customers.find(u => u.id === id)).filter(Boolean) as SearchableCustomer[])
      : query
      ? customers.filter(
          customer =>
            filterByInclusion(customer.id.toString(), query) ||
            filterByInclusion(customer.name, query) ||
            filterByInclusion(customer.email, query) ||
            (customer.is_domain_unique &&
              filterByInclusion(extractDomain(customer.email), queryDomain) &&
              extractDomain(customer.email).startsWith(queryDomain))
        )
      : customers;

  const forceShowNoMatchesItem =
    !!onCreateNew &&
    (!query ||
      !!customers.filter(customer => !customer.is_domain_unique && filterByInclusion(customer.email, query)).length ||
      !!customers.filter(
        customer =>
          !!customer.is_domain_unique &&
          filterByInclusion(extractDomain(customer.email), queryDomain) &&
          extractDomain(customer.email) !== queryDomain
      ).length ||
      !!customers.filter(customer => filterByInclusion(customer.email, query) && customer.email !== query).length ||
      !!customers.filter(customer => filterByInclusion(customer.name, query)).length);

  const results = resultCustomers.map(customer => ({
    value: customer.id,
    element: <CustomerResult key={customer.id} size={size} maxWidth={width} query={query} customer={customer} />
  }));

  const image: InputImageIconProps['image'] = selectedCustomer?.logo_url
    ? {
        src: selectedCustomer.logo_url,
        alt: selectedCustomer.name,
        style: { borderRadius: '50%' }
      }
    : undefined;

  return (
    <Layout
      onMeasure={({ width }) => {
        setWidth(width);
      }}
    >
      <ComboBox
        query={query}
        results={results}
        noMatchesItem={
          !!selectedCustomerId ? undefined : <NoMatchesItem size={size} width={width} isCreatable={!!onCreateNew} />
        }
        forceShowNoMatchesItem={forceShowNoMatchesItem}
        error={errorMessage}
        size={size}
        label={label}
        bigLabel={placeholder}
        placeholder={placeholder}
        readOnlyHref={readOnlyHref}
        onChange={({ value }) => {
          setQuery(value);
          onSelect(undefined);
        }}
        onCreateNew={
          onCreateNew
            ? () => {
                onCreateNew(query);
              }
            : undefined
        }
        onSelect={value => {
          const customer = customers.find(customer => customer.id === value);

          if (!customer) {
            setQuery(undefined);
            return;
          }

          onSelect(customer);
          name && dispatch(actions.addDefaultTypeaheadResults({ name, id: customer.id }));
          if (clearQueryAfterSelect) {
            setQuery(undefined);
          } else {
            setQuery(customer.name);
          }
        }}
        onClear={() => {
          setQuery(undefined);
          onSelect(undefined);
        }}
        highlightMatch
        required={required}
        showResultsWhenEmpty
        hideLabel
        maxItems={5}
        isReadOnly={isReadOnly}
        disabled={disabled && !isReadOnly}
        image={image}
      />
    </Layout>
  );
};

export default CustomerComboBox;
