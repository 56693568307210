import { COUNTRY_3_ALPHA_TO_2_ALPHA, REGION_ABBREVIATION_TO_NAME } from 'shared/constants';
import { Ip2LocationResponse, LatLng } from 'shared/types';

import axios from 'axios';

// Convert Degress to Radians
const deg2Rad = (deg: number) => {
  return (deg * Math.PI) / 180;
};

export const pythagorasEquirectangular = (lat1: number, lon1: number, lat2: number, lon2: number): number => {
  lat1 = deg2Rad(lat1);
  lat2 = deg2Rad(lat2);
  lon1 = deg2Rad(lon1);
  lon2 = deg2Rad(lon2);
  var R = 6371; // km
  var x = (lon2 - lon1) * Math.cos((lat1 + lat2) / 2);
  var y = lat2 - lat1;
  var d = Math.sqrt(x * x + y * y) * R;

  return d;
};

export const getDistance = (loc1: LatLng, loc2: LatLng): number => {
  return pythagorasEquirectangular(loc1.lat, loc1.lng, Number(loc2.lat), Number(loc2.lng));
};

export function nearestLoc<T extends LatLng>(latLng: LatLng, locs: T[]): T {
  let closestDistance: number | undefined;
  let closestIndex: number = 0;

  for (let index = 0; index < locs.length; ++index) {
    const loc = locs[index];
    const distance: number = pythagorasEquirectangular(latLng.lat, latLng.lng, Number(loc.lat), Number(loc.lng));

    if (closestDistance === undefined || distance < closestDistance) {
      closestIndex = index;
      closestDistance = distance;
    }
  }

  return locs[closestIndex];
}

export function isAddress(place: google.maps.places.PlaceResult): boolean {
  return !!place.address_components?.find(component => !!component.types.find(t => t === 'street_number'));
}

export async function ip2LocationRequest() {
  try {
    const ipifyResponse = await axios.get<{ ip: string }>('https://api.ipify.org');
    const ipAddress = ipifyResponse.data;
    const rawIp2LocationResponse = await axios.get<Ip2LocationResponse>(
      `https://api.ipbase.com/v2/info?ip=${ipAddress}&apikey=2ioO87cClN22DrjSmX3EM0JSXeVb1RTYmFNktgBv`
    );

    const ip2LocationResponse = rawIp2LocationResponse.data.data;

    const { ip, location, timezone: _timezone } = ip2LocationResponse;

    return Promise.resolve({
      ip: ip,
      lat: location.latitude,
      lng: location.longitude,
      country_code: location.country.alpha2,
      region: location.region.name,
      city: location.city.name
    });
  } catch (error) {
    return Promise.reject(error);
  }
}

type SupportedCountries = keyof typeof REGION_ABBREVIATION_TO_NAME;

/** e.g. California -> CA */
export function regionNameToAbbreviation({ region, country }: { region: string; country?: string }) {
  if (!country) return null;

  if (!Object.keys(REGION_ABBREVIATION_TO_NAME).includes(country)) return null;

  const countryRegions = REGION_ABBREVIATION_TO_NAME[country as SupportedCountries];

  for (const abbreviation in countryRegions) {
    const mappedRegion = countryRegions[abbreviation as keyof typeof countryRegions];

    if (mappedRegion === region) {
      return abbreviation;
    }
  }

  return null;
}

export function country3to2Alpha(alpha3: string) {
  return COUNTRY_3_ALPHA_TO_2_ALPHA[alpha3.toUpperCase() as keyof typeof COUNTRY_3_ALPHA_TO_2_ALPHA] || alpha3;
}
