import React, { useContext, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Listing } from 'shared';

import Head from 'components/Head';

import { app } from 'context';
import { Icon, Layout, Link, PriceExplanationModal, Text } from 'ds';
import { OFFICE_REQUEST_PATH, SEARCH_PATH } from 'routes';
import { actions as officeRequestActions } from 'store/OfficeRequest';
import { useAppDispatch } from 'store/hooks';
import Footer from 'ux/Layouts/Shared/Footer';
import Header from 'ux/Layouts/Shared/Header';

import HeaderTags from './HeaderTags';
import MarketNavigationTree from './MarketNavigationTree';
import SearchResultsSchema from './SearchResultsSchema';
import SeoContent from './SeoContent';
import { SearchLocation } from './types';
import { transformStateToSearch } from './utils';
import NoResults from '../NoResults';
import RequestOfficePrompt from '../RequestOfficePrompt';
import WorkspaceListCard from '../WorkspaceListCard';

interface Props {
  backPathname: string;
  searchLocation: SearchLocation;
  workspaces: Listing[];
}

const MobileUIGoogle: React.FC<Props> = ({ searchLocation, workspaces }) => {
  const { windowWidth, contentPaddingX } = useContext(app);
  const listWorkspaces = workspaces.slice(0, 5);
  const cardHeight = 366;
  const history = useHistory();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const [priceExplanationModalIsVisible, setPriceExplanationModalIsVisible] = useState<boolean>(false);

  return (
    <>
      <Header />
      <Head title={searchLocation.seo_title} description={searchLocation.seo_description} />
      <SearchResultsSchema searchLocation={searchLocation} workspaces={workspaces} />
      <PriceExplanationModal
        isVisible={priceExplanationModalIsVisible}
        onClose={() => setPriceExplanationModalIsVisible(false)}
      />
      <Layout position="relative" paddingBottom={90}>
        {workspaces.length > 0 ? (
          <>
            <Layout padding={contentPaddingX}>
              <HeaderTags numResults={workspaces.length} searchLocation={searchLocation} />
            </Layout>
            {listWorkspaces.map((ws, index) => (
              <React.Fragment key={ws.id}>
                <Layout
                  paddingBottom={40}
                  height={cardHeight + 24}
                  width={windowWidth}
                  paddingX={contentPaddingX}
                  key={ws.id}
                >
                  <WorkspaceListCard
                    workspace={ws}
                    daysPerWeek={5}
                    offsitesOnly={false}
                    setPriceExplanationModalIsVisible={setPriceExplanationModalIsVisible}
                    useCarousel={false}
                  />
                </Layout>
                {index === 0 && (
                  <Layout paddingBottom={40} width={windowWidth} paddingX={contentPaddingX} key={ws.id}>
                    <RequestOfficePrompt
                      onClick={() => {
                        dispatch(officeRequestActions.setLaunchPath(location.pathname));
                        history.push(OFFICE_REQUEST_PATH);
                      }}
                    />
                  </Layout>
                )}
              </React.Fragment>
            ))}
            {workspaces.length > listWorkspaces.length && (
              <Layout paddingX={contentPaddingX} width="100%" justify="flex-end">
                <Link
                  href={SEARCH_PATH}
                  search={transformStateToSearch({ location: searchLocation })}
                  text={
                    <Layout direction="row" align="center">
                      <Text size="body2">
                        View all {workspaces.length} offices in {searchLocation.name}
                      </Text>
                      <Layout marginLeft={4} />
                      <Icon name="rightArrow" size="xs" color="blue-500" />
                    </Layout>
                  }
                />
              </Layout>
            )}
          </>
        ) : (
          <>
            <Layout padding={contentPaddingX}>
              <HeaderTags numResults={workspaces.length} searchLocation={searchLocation} />
            </Layout>
            <Layout justify="center">
              <NoResults searchLocation={searchLocation} />
            </Layout>
          </>
        )}
        <SeoContent searchLocation={searchLocation} />
        <MarketNavigationTree market={searchLocation.type === 'Market' ? searchLocation : searchLocation.market!} />
      </Layout>
      <Footer />
    </>
  );
};

export default MobileUIGoogle;
