import { Booking } from 'shared/types';
import axios from 'shared/lib/axios';

function validateBookingPath({ userId }: { userId: number }) {
  return `/v1/users/${userId}/validate_booking`;
}

function bookingPath({ userId, bookingId }: { userId: number; bookingId: number }) {
  return `/v1/users/${userId}/bookings/${bookingId}`;
}

function createBookingPath({ userId }: { userId: number }) {
  return `/v1/users/${userId}/bookings`;
}

interface BookingParams {
  userId: number;
  workspaceId: number;
  dates: string[];
}

export function validateBooking({ userId, workspaceId: workspace_id, dates }: BookingParams) {
  return axios.post(validateBookingPath({ userId }), { booking: { workspace_id, dates } });
}

export function createBookings({
  userId,
  workspaceId: workspace_id,
  dates,
  platform
}: BookingParams & { platform: 'web' | 'android' | 'ios'; isReferral?: boolean }) {
  return axios.post<Booking[]>(createBookingPath({ userId }), {
    booking: { workspace_id, dates, platform }
  });
}

export function cancelBooking({ userId, bookingId }: { userId: number; bookingId: number }) {
  return axios.delete(bookingPath({ userId, bookingId }));
}
