import React, { CSSProperties, MouseEvent, ReactNode, forwardRef } from 'react';
import { Color } from 'shared';
import classNames from 'classnames';

import { track } from 'lib/analytics';

import { extractText } from './utils';

export interface Props {
  onPress: (event: MouseEvent<HTMLButtonElement>) => void;
  activeColor?: Color;
  hoverColor?: Color;
  color?: Color;
  context?: string;
  style?: CSSProperties;
  fullWidth?: boolean;
  children: ReactNode;
  name?: string;
  value?: string | number;
  className?: string;
}

const Pressable = forwardRef<HTMLButtonElement, Props>(
  (
    {
      onPress,
      activeColor = 'gray-50',
      children,
      context,
      style,
      fullWidth,
      hoverColor,
      color,
      name,
      value,
      className
    },
    ref
  ) => {
    return (
      <button
        onClick={e => {
          onPress(e);
          track('Element Interacted', {
            context,
            value,
            name: name || extractText(children as JSX.Element),
            type: 'pressable'
          });
        }}
        ref={ref}
        className={classNames('Pressable', className, `active-bg-color-${activeColor}`, {
          [`hover-bg-color-${hoverColor}`]: !!hoverColor,
          [`bg-color-${color}`]: !!color
        })}
        style={{ ...(fullWidth ? { display: 'block', width: '100%' } : undefined), ...style }}
      >
        {children}
      </button>
    );
  }
);

export default Pressable;
