import React, { useRef, useState } from 'react';
import classNames from 'classnames';
import ReactQuill, { ReactQuillProps } from 'react-quill';

export interface Props
  extends Pick<
    ReactQuillProps,
    'onChange' | 'value' | 'modules' | 'formats' | 'style' | 'readOnly' | 'placeholder' | 'onFocus' | 'onBlur'
  > {}

const MODULES = {
  toolbar: [
    ['bold', 'italic', 'underline', 'strike'],
    [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
    ['link']
  ]
};

const FORMATS = ['bold', 'italic', 'underline', 'strike', 'list', 'bullet', 'link', 'indent'];

const RichTextEditor = React.forwardRef<ReactQuill, Props>(
  ({ value, onChange, modules, formats, readOnly, placeholder, onFocus, onBlur }, ref) => {
    const [isFocused, setIsFocused] = useState(false);
    const containerRef = useRef<HTMLDivElement>(null);
    return (
      <div className={classNames('RichTextEditor', { 'is-focused': isFocused })} ref={containerRef}>
        <ReactQuill
          ref={ref}
          theme="snow"
          value={value}
          onChange={onChange}
          modules={modules || MODULES}
          formats={formats || FORMATS}
          bounds=".RichTextEditor"
          readOnly={readOnly}
          placeholder={placeholder}
          onFocus={(...args) => {
            setIsFocused(true);
            onFocus?.(...args);
          }}
          onBlur={(quill, source, ...rest) => {
            const activeElement = document.activeElement;

            if (containerRef?.current?.contains(activeElement)) {
              return;
            }

            setIsFocused(false);
            onBlur?.(quill, source, ...rest);
          }}
        />
      </div>
    );
  }
);

export default RichTextEditor;
