import React, { useContext } from 'react';
import { Color } from 'shared';

import { app } from 'context';
import Layout from 'ds/Layout';
import Text from 'ds/Text';
import { HEADER_Z_INDEX, ICON_SIZE_LG } from 'ds/constants';
import StickyHeader from 'ux/Layouts/Shared/StickyHeader';

import MobileBackLink from './MobileBackLink';
import { APP_PAGE_TITLE_HEIGHT_MOBILE } from './constants';
import { MobileLink } from './types';

interface Props {
  backLink?: MobileLink;
  title?: string;
  CTA?: JSX.Element;
  color?: Color;
  hasPaddingX?: boolean;
  type?: 'condensed';
  titleColor?: Color;
}

const AppMobileNavBar: React.FC<Props> = ({ backLink, title, CTA, color, hasPaddingX, type, titleColor }) => {
  const { appPaddingX } = useContext(app);

  if (!backLink && !CTA && !title) return null;

  if (type === 'condensed') {
    return (
      <StickyHeader top={0} zIndex={HEADER_Z_INDEX}>
        <Layout
          paddingX={hasPaddingX === false ? undefined : appPaddingX}
          paddingBottom={10}
          height={APP_PAGE_TITLE_HEIGHT_MOBILE}
          align="flex-end"
          justify="space-between"
          color={color}
        >
          {backLink ? <MobileBackLink {...backLink} /> : <Layout width={ICON_SIZE_LG}></Layout>}
          <Text size="body-md" semibold color={titleColor}>
            {title}
          </Text>
          {CTA ? (
            <Layout align="center" height={48} display="inline-flex">
              {CTA}
            </Layout>
          ) : (
            <Layout width={ICON_SIZE_LG}></Layout>
          )}
        </Layout>
      </StickyHeader>
    );
  }

  if (CTA && title && !backLink) {
    return (
      <StickyHeader top={0} zIndex={HEADER_Z_INDEX}>
        <Layout
          paddingX={hasPaddingX === false ? undefined : appPaddingX}
          height={APP_PAGE_TITLE_HEIGHT_MOBILE}
          align="center"
          justify="space-between"
          color={color}
        >
          <Text size="h5" color={titleColor}>
            {title}
          </Text>
          {CTA}
        </Layout>
      </StickyHeader>
    );
  }

  return (
    <StickyHeader top={0} zIndex={HEADER_Z_INDEX} shadow={false}>
      {(backLink || CTA) && (
        <Layout
          paddingX={hasPaddingX === false ? undefined : appPaddingX}
          height={APP_PAGE_TITLE_HEIGHT_MOBILE}
          align="flex-end"
          justify="space-between"
          color={color}
          borderColor="gray-50"
          paddingBottom={10}
        >
          {backLink && <MobileBackLink {...backLink} />}
          {CTA && (
            <Layout flexGrow={backLink ? undefined : 1} align="center" height={48} display="inline-flex">
              {CTA}
            </Layout>
          )}
        </Layout>
      )}
      {title && (
        <Layout
          height={APP_PAGE_TITLE_HEIGHT_MOBILE}
          paddingY={16}
          align="center"
          paddingX={hasPaddingX === false ? undefined : appPaddingX}
          {...(color ? { color } : { borderColor: 'gray-50' })}
        >
          <Text size="h5" color={titleColor}>
            {title}
          </Text>
        </Layout>
      )}
    </StickyHeader>
  );
};

export default AppMobileNavBar;
