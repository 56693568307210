import React, { useContext } from 'react';
import { IconName } from 'shared';

import { media } from 'context';
import { Clickable, Icon, Layout, Pressable, Text } from 'ds';
import { Props as LayoutProps } from 'ds/Layout';

interface Props {
  isSelected: boolean;
  iconName: IconName;
  label: string;
  onClick: VoidFunction;
  align?: 'flex-start';
}

const AdditionalAmenity: React.FC<Props> = ({ isSelected, iconName, onClick, label, align }) => {
  const { xs, sm } = useContext(media);
  const isMobile = xs || sm;
  const containerStyle: LayoutProps = isSelected
    ? {
        borderColor: 'blue-500',
        borderWidth: 2,
        color: 'blue-50',
        paddingX: isMobile ? 23 : 15,
        paddingTop: 23,
        paddingBottom: isMobile ? 23 : undefined
      }
    : {
        borderColor: 'gray-50',
        borderWidth: 1,
        paddingX: isMobile ? 24 : 16,
        paddingTop: 24,
        paddingBottom: isMobile ? 24 : undefined,
        hoverColor: isMobile ? undefined : 'blue-50'
      };

  return isMobile ? (
    <Pressable onPress={onClick} activeColor="blue-50" color="white">
      <Layout borderRadius={8} height={72} {...containerStyle}>
        <Layout align="center" justify="flex-start">
          <Layout justify="center" display="inline-flex">
            <Icon name={iconName} size="lg" color="gray-900" />
          </Layout>
          <Layout marginLeft={24}>
            <Text size="body-sm">{label}</Text>
          </Layout>
        </Layout>
      </Layout>
    </Pressable>
  ) : (
    <Clickable onClick={onClick} fullWidth>
      <Layout borderRadius={8} height={136} align="center" direction="column" {...containerStyle}>
        <Layout justify="center" display="inline-flex">
          <Icon name={iconName} size="lg" color="gray-900" />
        </Layout>
        <Layout marginTop={8} height={48} align={align || 'center'}>
          <Text size="body-md">{label}</Text>
        </Layout>
      </Layout>
    </Clickable>
  );
};

export default AdditionalAmenity;
