import React from 'react';

import Avatar from 'ds/Avatar';
import { FORMATTED_TYPEAHEAD_RESULT_SIZE_CONFIG } from 'ds/ComboBox/constants';
import Layout from 'ds/Layout';
import Text from 'ds/Text';
import { FieldSize } from 'ds/types';
import { useFieldSize } from 'hooks';

import { SearchableCustomer } from './types';
import { getHighlightedPartsOfText } from 'ds/utils';

interface Props {
  size?: FieldSize;
  query?: string;
  customer: SearchableCustomer;
  maxWidth: number;
}

const getHighlightedText = ({ text, query }: { text: string; query?: string }) => {
  if (!query) return text;

  const parts = getHighlightedPartsOfText({ text, query });

  return (
    <span>
      {parts.map((part, index) =>
        part.toLowerCase() === query.toLowerCase() ? <b key={part + index}>{part}</b> : part
      )}
    </span>
  );
};

const CustomerResult: React.FC<Props> = ({ maxWidth, query, customer: { id, email, name, logo_url }, ...props }) => {
  const scaledSize = useFieldSize();
  const size = props.size || scaledSize;

  const highlightedName = getHighlightedText({ text: name, query });
  const highlightedEmail = getHighlightedText({ text: email, query });
  const highlightedId = getHighlightedText({ text: id.toString(), query });
  const { height, imageSize, labelFontSize } = FORMATTED_TYPEAHEAD_RESULT_SIZE_CONFIG[size];

  return (
    <Layout
      height={height}
      paddingX={16}
      paddingY={8}
      direction="row"
      justify="space-between"
      flexGrow={1}
      maxWidth={maxWidth}
    >
      <Layout
        minWidth={imageSize}
        width={imageSize}
        height={imageSize}
        borderRadius={4}
        overflow="hidden"
        marginBottom={2}
        flex
      >
        <Avatar size={imageSize} avatarUrl={logo_url} />
      </Layout>
      <Layout minWidth={12} width={12} />
      <Layout direction="column" justify="center" align="flex-start" display="inline-block" flexGrow={1}>
        <Layout display="grid">
          <Text size={labelFontSize} ellipsis>
            {highlightedName}
          </Text>
        </Layout>
        <Layout marginTop={2} />
        <Layout display="grid">
          <Text size="body3" color="gray-400" ellipsis>
            {highlightedEmail}
          </Text>
        </Layout>
      </Layout>
      <Layout minWidth={12} width={12} />
      <Text size="body3" color="gray-700">
        {highlightedId}
      </Text>
    </Layout>
  );
};

export default CustomerResult;
