import React, { useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { ApiErrorResponse } from 'shared';

import { showSnackbar } from 'helpers/snackbar';

import { media } from 'context';
import { Button, Layout, Link, Text } from 'ds';
import { Field, FormInput, formValid, getValues } from 'ds/forms';
import { PRIVACY_PATH, TERMS_PATH } from 'routes';
import { selectUser } from 'store/User/selectors';
import { postOffsiteRequest } from 'ux/Public/Offsites/requests';
import { HOW_HEARD_OPTIONS } from 'ux/Public/utils';

interface Props {
  displayedRate?: number;
  daysPerWeek?: number;
  onSubmit: () => void;
}

const TRACK_CONTEXT = 'enterprise form';

const Form: React.FC<Props> = ({ onSubmit }) => {
  const user = useSelector(selectUser);
  const { xs, sm, xl } = useContext(media);
  const cta = 'Submit';

  const FIELDS = [
    {
      name: 'firstname',
      label: 'First name',
      required: true,
      value: user?.firstname || '',
      type: 'text'
    },
    {
      name: 'lastname',
      label: 'Last name',
      required: true,
      value: user?.lastname || '',
      type: 'text'
    },
    { name: 'email', label: 'Work email', required: true, value: user?.email || '', type: 'workEmail' },
    { name: 'phone', value: user?.phone || '', type: 'phone', required: false },
    { name: 'calling_code', value: user?.calling_code || '1', type: 'callingCode', required: false },
    { name: 'company_name', label: 'Company name', type: 'text', required: false },
    { name: 'location', label: 'Offsite location', type: 'location', required: false },
    {
      name: 'how_heard',
      label: 'How you heard about us',
      required: true,
      type: 'select',
      options: HOW_HEARD_OPTIONS
    }
  ] as const;

  const FIELD_NAME_TO_WIDTH = {
    firstname: xs ? '100%' : sm ? '49%' : '48%',
    lastname: xs ? '100%' : sm ? '49%' : '48%',
    email: xs ? '100%' : sm ? '49%' : '48%',
    calling_code: '0%',
    phone: xs ? '100%' : sm ? '49%' : '48%',
    company_name: '100%',
    location: '100%',
    how_heard: '100%'
  };

  const [fields, setFields] = useState<Field<typeof FIELDS[number]['name']>[]>([...FIELDS]);

  const [requestLoading, setRequestLoading] = useState<boolean>(false);

  const paddingX = xs ? undefined : undefined;

  const disabled = !formValid(fields) || !!requestLoading;

  return (
    <Layout color="white">
      <Layout flex={!xs} wrap={!xs} justify={!xs || sm ? 'space-between' : undefined}>
        {fields.map(field => (
          <FormInput
            {...field}
            key={field.name}
            inputContainerProps={{
              width: FIELD_NAME_TO_WIDTH[field.name],
              paddingX,
              paddingY: 8
            }}
            size={xs ? 'sm' : xl ? 'lg' : 'md'}
            fields={fields}
            setFields={setFields}
            disabled={!!requestLoading}
          />
        ))}
      </Layout>
      <Layout marginTop={xs ? 24 : 8} paddingX={paddingX} width={undefined}>
        <Button
          type="primary"
          context={TRACK_CONTEXT}
          onClick={() => {
            setRequestLoading(true);

            postOffsiteRequest({ ...getValues(fields) })
              .then(() => {
                onSubmit();
              })
              .catch((error: ApiErrorResponse) => {
                showSnackbar({
                  message: `An error occurred: ${error.message}`,
                  negative: true
                });
              })
              .finally(() => setRequestLoading(false));
          }}
          target={!disabled ? '_blank' : undefined}
          disabled={disabled}
          showSpinner={!!requestLoading}
          text={cta}
          size={xs ? 'sm' : 'lg'}
          fullWidth
        />
        <Layout marginY={8} paddingBottom={xs ? 12 : undefined}>
          <Text size="body3" inline>
            By clicking the button, you agree to Codi’s{' '}
          </Text>
          <Link context={TRACK_CONTEXT} href={TERMS_PATH} target="_blank">
            <Text size="body3" inline color="blue-500">
              Terms of Service
            </Text>
          </Link>{' '}
          <Text size="body3" inline>
            and{' '}
          </Text>
          <Link context={TRACK_CONTEXT} href={PRIVACY_PATH} target="_blank">
            <Text size="body3" inline color="blue-500">
              Privacy Policy
            </Text>
          </Link>
          <Text size="body3" inline>
            .
          </Text>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default Form;
