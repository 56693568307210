import React from 'react';
import { useLocation } from 'react-router-dom';

import { CONTENT_PADDING_X_XS, Icon, Layout, Link, Text } from 'ds';
import { ADMIN_PATH, APP_ROOT_PATH, PROPERTIES_PATH } from 'routes';
import { selectUIState } from 'store/UI/selectors';
import {
  selectCustomerAdminPlans,
  selectCustomerRequests,
  selectHasCustomerAdminRole,
  selectHasListingAdminRole,
  selectHasPayoutAdminRole,
  selectHasPayoutViewerRole,
  selectHasPropertiesNavItem,
  selectHasSignedPlans,
  selectIsAdmin,
  selectIsCustomerUser,
  selectIsProspect,
  selectIsVendor,
  selectIsVendorAdmin,
  selectOffices,
  selectSelectedOfficeInCustomerAdminOffices,
  selectUser,
  selectUserHasOffsites
} from 'store/User/selectors';
import { useAppSelector } from 'store/hooks';
import { showCalendarNavItem } from 'ux/Calendar/utils';
import { RESOURCE_NAV_ITEMS } from 'ux/Layouts/Shared/Navigation/constants';
import {
  getAdminMoreNavItems,
  getMobileAccountItems,
  getMoreNavItems,
  getUXSwitcherNavItems
} from 'ux/Layouts/Shared/utils';

import CollapsableSection from './CollapsableSection';
import MenuItem from './MenuItem';

interface Props {}

const MenuItems: React.FC<Props> = () => {
  const resourceItems = [...RESOURCE_NAV_ITEMS];
  const { pathname } = useLocation<{ prevPath?: string }>();
  const isAppPath = pathname.startsWith(APP_ROOT_PATH);
  const isAdminPath = pathname.startsWith(ADMIN_PATH);
  const isPublicPage = !isAppPath && !isAdminPath;
  const user = useAppSelector(selectUser);
  const customerAdminPlans = useAppSelector(selectCustomerAdminPlans);
  const hasPropertiesNavItem = useAppSelector(selectHasPropertiesNavItem);
  const hasListingRole = useAppSelector(selectHasListingAdminRole);
  const hasPayoutAdminRole = useAppSelector(selectHasPayoutAdminRole);
  const hasPayoutViewerRole = useAppSelector(selectHasPayoutViewerRole);
  const isAdmin = useAppSelector(selectIsAdmin);
  const hasCustomerAdminRole = useAppSelector(selectHasCustomerAdminRole);
  const hasCalendarNavItem = showCalendarNavItem({ user });
  const reduxUxType = useAppSelector(selectUIState).uxType;
  const isPropertyUX = pathname.startsWith(PROPERTIES_PATH) || reduxUxType === 'property';
  const isVendor = useAppSelector(selectIsVendor);
  const isVendorAdmin = useAppSelector(selectIsVendorAdmin);
  const isAdminUX = pathname.startsWith(ADMIN_PATH) || reduxUxType === 'admin';
  const isCustomerUx = reduxUxType === 'customer';
  const offices = useAppSelector(selectOffices);
  const hasOffices = !!offices.length && hasCalendarNavItem;
  const hasOffsites = useAppSelector(selectUserHasOffsites);
  const isCustomerUser = useAppSelector(selectIsCustomerUser);
  const isProspect = useAppSelector(selectIsProspect);
  const hasSignedPlans = useAppSelector(selectHasSignedPlans);
  const requests = useAppSelector(selectCustomerRequests);
  const openRequests = requests.filter(request => request.status === 'open');
  const hasOpenRequests = !!openRequests.length;
  const selectedOfficeInCustomerAdminOffices = useAppSelector(selectSelectedOfficeInCustomerAdminOffices);
  const hasActivePlans = !!customerAdminPlans?.long_term_plans.filter(p => !!p.active_workspace_id).length;

  const shouldDisplayDot = (label: string) => {
    return (label === 'Requests' && hasOpenRequests) || label === 'Favorites';
  };

  const accountItems = getMobileAccountItems({
    hasActivePlans,
    hasPropertiesNavItem,
    isAdmin
  });

  const userSpecificNavItems =
    isAdmin && isAdminUX
      ? getAdminMoreNavItems()
      : getMoreNavItems({
          user: user || null,
          hasCalendarNavItem,
          hasCustomerAdminRole,
          hasPayoutAdminRole,
          hasPayoutViewerRole,
          isPropertyUX,
          isVendor,
          hasListingRole,
          hasOffices,
          hasOffsites,
          isCustomerUser,
          isProspect,
          hasSignedPlans,
          selectedOfficeInCustomerAdminOffices,
          hasActivePlans,
          isVendorAdmin
        });

  const uxSwitcherNavItems = getUXSwitcherNavItems({
    isPublicPage,
    isAdmin,
    isAdminUX,
    isPropertyUX,
    hasPropertiesNavItem
  });

  return (
    <Layout>
      {!!uxSwitcherNavItems.length && (
        <Layout paddingX={CONTENT_PADDING_X_XS} paddingBottom={24} flex>
          {uxSwitcherNavItems.map((item, index) => {
            return (
              <Layout
                color="gray-25"
                borderRadius={8}
                marginLeft={index ? 12 : undefined}
                justify="center"
                paddingY={16}
                key={item.label}
                equalWidth
              >
                <Link color="gray-900" href={item.href}>
                  <Layout direction="column" justify="center" align="center">
                    <Icon size="xl" name={item.iconName} />
                    <Layout marginTop={12}>
                      <Text size="body1" scale semibold>
                        {item.label}
                      </Text>
                    </Layout>
                  </Layout>
                </Link>
              </Layout>
            );
          })}
        </Layout>
      )}
      {isCustomerUx ? (
        <>
          <Layout paddingTop={hasOffices ? undefined : 18} paddingBottom={18}>
            {userSpecificNavItems.map(item => (
              <MenuItem {...item} key={item.label} displayDot={shouldDisplayDot(item.label)} />
            ))}
          </Layout>
        </>
      ) : (
        !!userSpecificNavItems.length && (
          <>
            <Layout height={8} color="gray-25" />
            <Layout>
              {userSpecificNavItems.map(item => (
                <MenuItem {...item} key={item.label} displayDot={shouldDisplayDot(item.label)} />
              ))}
            </Layout>
          </>
        )
      )}

      <Layout height={8} color="gray-25" />
      <Layout paddingY={18}>
        {accountItems.map(item => {
          return <MenuItem {...item} key={item.label} />;
        })}
      </Layout>
      <Layout height={1} color="gray-50" marginX={CONTENT_PADDING_X_XS} />
      <CollapsableSection title="Resources" items={resourceItems} />
    </Layout>
  );
};

export default MenuItems;
