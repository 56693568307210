import React, { useContext, useState } from 'react';
import { Image } from 'shared';

import { app } from 'context';
import Img from 'ds/Img';
import Layout from 'ds/Layout';
import useKeyDown from 'ds/helpers/useKeyDown';

import { HEADER_HEIGHT } from './constants';

interface Props {
  floorPlans: Image[];
}

const WIDTH_TO_HEIGHT = 1.5;

const FloorPlans: React.FC<Props> = ({ floorPlans }) => {
  const { windowWidth } = useContext(app);
  const [activeIndex, _setActiveIndex] = useState<number>(0);
  const [inputIndex, setInputIndex] = useState<number>(0);

  useKeyDown((e: KeyboardEvent) => {
    if (e.key === 'ArrowRight') {
      if (inputIndex === floorPlans.length - 1) setInputIndex(0);
      else setInputIndex(activeIndex + 1);
    } else if (e.key === 'ArrowLeft') {
      if (inputIndex === 0) setInputIndex(floorPlans.length - 1);
      else setInputIndex(activeIndex - 1);
    }
  });

  if (!floorPlans.length) return null;

  const floorPlansArray: (Image | string)[] = [...floorPlans];

  return (
    <Layout>
      <Layout position="relative" marginTop={HEADER_HEIGHT} wrap __style={{ gap: 4 }}>
        {floorPlansArray.map((floorPlan, index) => {
          return (
            <Layout
              color="gray-50"
              minHeight={windowWidth / WIDTH_TO_HEIGHT}
              minWidth={windowWidth}
              position="relative"
              overflow="hidden"
              key={`thumbnail${index}`}
              direction="column"
            >
              <Img
                width={windowWidth}
                height={windowWidth / WIDTH_TO_HEIGHT}
                alt={`workspace floorPlan ${index}`}
                srcKey={(floorPlan as Image).key}
                padResize
              />
            </Layout>
          );
        })}
      </Layout>
    </Layout>
  );
};

export default FloorPlans;
