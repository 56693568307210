export function sortByDate(date1: string | null | undefined, date2: string | null | undefined): number {
  if (date1 && date2) {
    return new Date(date1).getTime() - new Date(date2).getTime();
  } else {
    return presenceSort(date1, date2);
  }
}

export function sortByAlphabetical(string1: string | null, string2: string | null): number {
  if (string1 !== null) {
    return string1.localeCompare(string2 || '', 'en', { sensitivity: 'base' });
  } else {
    return presenceSort(string1, string2);
  }
}

export function sortByNumerical(number1: number | null, number2: number | null): number {
  if (number1 !== null && number2 !== null) {
    return number1 - number2;
  } else if (number1) {
    return 1;
  } else if (number2) {
    return -1;
  } else {
    return 0;
  }
}

export function presenceSort(
  value1?: string | number | null | boolean,
  value2?: string | number | null | boolean
): number {
  if (!value1 && !value2) {
    return 0;
  }
  if (!value1 && value2) {
    return -1;
  }
  if (value1 && !value2) {
    return 1;
  }

  return 0;
}
