import React, { useContext } from 'react';
import { IconName } from 'shared';

import { media } from 'context';
import { Animate, Layout, Text } from 'ds';
import { ICON_NAME_TO_SVG } from 'ds/Icons';
import { kebabToCamelCase } from 'ds/utils';

import { CenteredSection } from './types';

interface Props extends CenteredSection {}

const Centered: React.FC<Props> = ({ icon, title, subtitle }) => {
  const mediaContext = useContext(media);
  const { xs } = mediaContext;
  const Svg = ICON_NAME_TO_SVG[1.5][kebabToCamelCase(icon) as IconName];

  return (
    <Animate paddingTop={24} paddingBottom={40} justify="center" delay={50}>
      <Layout maxWidth={800}>
        <Layout justify="center">
          <span className="Icon" style={{ width: 56, minWidth: 56, height: 56 }}>
            {Svg && <Svg />}
          </span>
        </Layout>
        <Layout marginTop={32} justify="center">
          <Text size="h4" scale={!xs} align="center">
            <span dangerouslySetInnerHTML={{ __html: title }}></span>
          </Text>
        </Layout>
        <Layout marginTop={32} justify="center">
          <Text size="h5" bold={false} align="center" scale>
            <span dangerouslySetInnerHTML={{ __html: subtitle }}></span>
          </Text>
        </Layout>
      </Layout>
    </Animate>
  );
};

export default Centered;
