import React, { useContext, useEffect, useState } from 'react';
import { ListingWorkspace, dateToYyyyMmDd, yyyyMmDdToDate } from 'shared';
import { DateTime } from 'luxon';

import { media } from 'context';
import { Button, DateRangePicker, Layout, Text } from 'ds';
import { formatUsd } from 'helpers';
import { STB_REQUEST_PATH } from 'routes';
import { actions } from 'store/STBRequest';
import { selectStbRequest } from 'store/STBRequest/selectors';
import { selectUserId } from 'store/User/selectors';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import { getWorkspaceUnavailableIntervals } from './requests';
import { ShortTermBookingRequest } from './types';
import { numDays, stbIsReadyForForm } from './utils';
import MiniWorkspaceInfo from '../MiniWorkspaceInfo';

interface Props {
  workspace: ListingWorkspace;
}

const RequestBookingBox: React.FC<Props> = ({ workspace, workspace: { daily_rate, id } }) => {
  const [excludeDateIntervals, setExcludeDateIntervals] = useState<{ start: Date; end: Date }[]>([]);
  const { xs } = useContext(media);
  const stbRequest = useAppSelector(selectStbRequest);
  const dispatch = useAppDispatch();
  const boxWidth = xs ? undefined : 352;
  const { start_date, end_date, amount } = stbRequest;
  const userId = useAppSelector(selectUserId);

  useEffect(() => {
    dispatch(actions.updateStbRequest({ workspace_id: id }));
  }, [dispatch, id, userId]);

  useEffect(() => {
    getWorkspaceUnavailableIntervals({
      id
    }).then(({ data: intervals }) => {
      setExcludeDateIntervals(
        intervals.map(interval => ({
          start: new Date(`${interval.start} 00:00`),
          end: new Date(`${interval.end} 23:59`)
        }))
      );
    });
  }, [id, dispatch]);

  if (!daily_rate) return null;

  return (
    <Layout
      paddingX={xs ? undefined : 32}
      paddingY={xs ? 24 : 32}
      width={boxWidth}
      borderRadius={xs ? undefined : 12}
      __style={xs ? {} : { boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)' }}
      color="white"
    >
      <MiniWorkspaceInfo workspace={workspace} />
      <Layout marginTop={24} flex>
        <Layout equalWidth align="center">
          <Text size="h6">{formatUsd(daily_rate, false)}</Text>
          <Layout marginLeft={4} paddingTop={9} paddingBottom={3}>
            <Text size="body3">/day</Text>
          </Layout>
        </Layout>
      </Layout>
      <Layout marginTop={24}>
        <DateRangePicker
          excludeDateIntervals={excludeDateIntervals}
          minDate={DateTime.now().plus({ days: 1 }).toJSDate()}
          maxDate={DateTime.now().plus({ years: 1 }).toJSDate()}
          startDate={start_date ? yyyyMmDdToDate(start_date) : null}
          endDate={end_date ? yyyyMmDdToDate(end_date) : null}
          onChange={values => {
            const [startDate, endDate] = values;
            const startDateYyyyMmDd = startDate ? dateToYyyyMmDd(startDate) : undefined;
            const endDateYyyyMmDd = endDate ? dateToYyyyMmDd(endDate) : undefined;

            const attrs: Partial<ShortTermBookingRequest> = {
              start_date: startDateYyyyMmDd,
              end_date: endDateYyyyMmDd
            };

            if (startDateYyyyMmDd && endDateYyyyMmDd) {
              attrs.amount = daily_rate * numDays({ startDate: startDateYyyyMmDd, endDate: endDateYyyyMmDd });
            }

            dispatch(actions.updateStbRequest(attrs));
          }}
          size="sm"
        />
      </Layout>
      {amount && (
        <Layout marginTop={24} paddingTop={24} justify="space-between" borderTop>
          <Text size="h6">Total</Text>
          <Text size="h5">{formatUsd(amount)}</Text>
        </Layout>
      )}
      <Layout marginTop={24}>
        <Button
          size="md"
          href={STB_REQUEST_PATH}
          state={{ workspace }}
          type="primary"
          text="Request booking"
          context="CtaBox"
          disabled={!stbIsReadyForForm(stbRequest)}
          fullWidth
        />
      </Layout>
    </Layout>
  );
};

export default RequestBookingBox;
