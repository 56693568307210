import React, { useRef, useState } from 'react';
import { ValidationResult } from 'shared';
import classNames from 'classnames';
import IMask from 'imask';
import { CountryCode, isPossiblePhoneNumber } from 'libphonenumber-js';

import Input from './Input';
import PhonePrefix from './PhonePrefix';
import { BaseProps, OnChangeParams, ValidateProp } from './types';
import useValidate from './useValidate';
import { DS_CLASS_NAME } from '../constants';
import { COUNTRY_DATA } from '../constants';
import { formatsFromPhoneNumber, getCountryFromCallingCode } from '../utils';

export interface Props extends BaseProps, ValidateProp {
  label?: string;
  callingCode: string;
  onCallingCodeChange: (value: string) => void;
}

export const validateNumber = ({
  country,
  callingCode,
  phone,
  required
}: {
  country: string;
  callingCode: string;
  phone: string | undefined;
  required: boolean;
}): ValidationResult => {
  if (!phone) {
    return required ? { error: 'Phone number is required.', valid: false } : { valid: true };
  } else if (phone === '' && required) {
    return { error: 'Phone number is required.', valid: false };
  } else if (!isPossiblePhoneNumber(`+${callingCode}${phone}`, country as CountryCode)) {
    // TODO: allow 555 numbers in develop and staging environments
    return { error: 'Please enter a valid phone number.', valid: false };
  }

  return { valid: true };
};

interface CallingCodeOption {
  value: string;
  label: string;
  display: string;
}

const CALLING_CODE_OPTIONS: CallingCodeOption[] = COUNTRY_DATA.map(country => ({
  value: country.callingCode,
  label: `+${country.callingCode} ${country.countryName}`,
  display: `+${country.callingCode}`
}));

const PhoneInput: React.FC<Props> = ({
  label,
  onChange,
  onValidate,
  size,
  error,
  required = false,
  onBlur,
  callingCode = '1',
  onCallingCodeChange,
  onFocus,
  ...props
}) => {
  const [errorMessage, setErrorMessage] = useState<string | undefined>(error);
  const [inputContainerHasFocus, setInputContainerHasFocus] = useState<boolean>(false);
  const [prefixWidth, setPrefixWidth] = useState<number>(0);
  const inputRef = useRef<HTMLInputElement>(null);
  const ref = useRef<HTMLDivElement>(null);
  const country = getCountryFromCallingCode(callingCode);
  const { placeholder, maskFormat } = formatsFromPhoneNumber({ callingCode });

  const mask = IMask.createMask({
    mask: maskFormat // '(000) 000-0000'
  });

  const maskValue = (val: string) => {
    return mask.resolve(val);
  };

  const onChangeWrapper = ({ event, value }: OnChangeParams) => {
    const unmaskedValue = unmaskValue(value);
    onChange({ event, value: unmaskedValue });
    const validationResult = validate(unmaskedValue);

    if (validationResult.valid) {
      setErrorMessage(undefined);
    }

    onValidate && onValidate(validationResult);
  };

  const unmaskValue = (value: string) => {
    maskValue(value);
    return mask.unmaskedValue;
  };

  const validate = (value?: string) => {
    return validateNumber({ country, callingCode, phone: value, required });
  };

  useValidate({ value: props.value || undefined, onValidate, validate, setErrorMessage });
  const maskedValue = maskValue(props.value || '');

  const value = inputContainerHasFocus ? maskedValue : !props.value ? '' : `+${callingCode} ${maskedValue}`;

  return (
    <div
      className={classNames(DS_CLASS_NAME, 'PhoneInput', `is-size-${size}`)}
      ref={ref}
      onBlur={() => {
        const container = ref.current;
        if (!container) return;

        // This timeout is necessary because without it the activeElement at this moment is body
        setTimeout(() => {
          if (!container.contains(document.activeElement)) {
            setInputContainerHasFocus(false);

            const value = props.value || '';
            setErrorMessage(validate(value).error);
          }
        }, 20);
      }}
    >
      <Input
        {...props}
        onFocus={params => {
          setInputContainerHasFocus(true);
          onFocus && onFocus(params);
        }}
        onBlur={e => {
          const value = unmaskValue(e.value);

          onBlur && onBlur({ ...e, value });
        }}
        ref={inputRef}
        name="phone"
        type="tel"
        value={value}
        label={label || 'Phone number'}
        placeholder={placeholder}
        onChange={onChangeWrapper}
        size={size}
        error={error || errorMessage}
        required={required}
        prefixWidth={prefixWidth}
        hasFocusClass={inputContainerHasFocus}
        prefix={
          inputContainerHasFocus ? (
            <PhonePrefix
              options={CALLING_CODE_OPTIONS}
              value={callingCode}
              onChange={({ value: countryCodeValue }) => {
                onCallingCodeChange(countryCodeValue);

                const input = inputRef.current;

                if (!input) return;

                input.focus();
              }}
              size={size}
              onMeasure={width => setPrefixWidth(width)}
              hasError={!!(error || errorMessage)}
            />
          ) : undefined
        }
      />
    </div>
  );
};

export default PhoneInput;
