import React, { MutableRefObject, PropsWithChildren, UIEventHandler, useContext, useEffect, useRef } from 'react';
import { Color } from 'shared';
import classNames from 'classnames';

import { app } from 'context';

import { DEFAULT_BORDER_COLOR } from './constants';
import { LayoutOverflow, OnMeasure } from './types';
import { assignProp, camelToKebabCase } from './utils';

type Align = 'flex-start' | 'flex-end' | 'center' | 'baseline' | 'stretch';
type Direction = 'column' | 'column-reverse' | 'row' | 'row-reverse';
type Justify = 'flex-start' | 'flex-end' | 'center' | 'space-between' | 'space-around' | 'space-evenly';
interface FlexProps {
  flex?: boolean;
  wrap?: boolean;
  wrapReverse?: boolean;
  align?: Align;
  direction?: Direction;
  justify?: Justify;
  gap?: number | string;
}
type FlexPropKey = keyof FlexProps;
const FLEX_CONTAINER_PROPS: FlexPropKey[] = ['direction', 'align', 'justify', 'wrap', 'wrapReverse', 'gap'];
const FLEX_PROPS: FlexPropKey[] = ['flex', ...FLEX_CONTAINER_PROPS];

type Cursor = 'default' | 'pointer';

interface InlineProps {
  background?: string;
  margin?: number | string;
  marginTop?: number;
  marginRight?: number;
  marginBottom?: number;
  marginLeft?: number;
  marginX?: number;
  marginY?: number;
  padding?: number;
  paddingTop?: number;
  paddingRight?: number;
  paddingBottom?: number;
  paddingLeft?: number;
  paddingX?: number;
  paddingY?: number;
  width?: number | string;
  minWidth?: number | string;
  maxWidth?: number | string;
  height?: number | string;
  minHeight?: number | string;
  maxHeight?: number | string;
  top?: number | string;
  right?: number | string;
  bottom?: number | string;
  left?: number | string;
  flexGrow?: number;
  flexShrink?: number;
  flexBasis?: number | string;
  zIndex?: number;
  transition?: string;
  cursor?: Cursor;
  opacity?: number;
  boxShadow?: string;
  transform?: string;
  gap?: number | string;
}
type InlinePropKey = keyof InlineProps;
const INLINE_PROPS: InlinePropKey[] = [
  'background',
  'gap',
  'margin',
  'marginTop',
  'marginRight',
  'marginBottom',
  'marginLeft',
  'marginX',
  'marginY',
  'padding',
  'paddingTop',
  'paddingRight',
  'paddingBottom',
  'paddingLeft',
  'paddingX',
  'paddingY',
  'width',
  'minWidth',
  'maxWidth',
  'height',
  'minHeight',
  'maxHeight',
  'top',
  'right',
  'bottom',
  'left',
  'flexGrow',
  'flexShrink',
  'flexBasis',
  'zIndex',
  'transition',
  'transform',
  'cursor',
  'opacity',
  'boxShadow'
];

type Position = 'static' | 'absolute' | 'relative' | 'fixed' | 'sticky';

interface BorderProps {
  borderTop?: boolean;
  borderRight?: boolean;
  borderBottom?: boolean;
  borderLeft?: boolean;
}

type BorderPropKey = keyof BorderProps;
// TODO: Make these props and other border like borderWidth and borderColor work together.
const BORDER_PROPS: BorderPropKey[] = ['borderTop', 'borderRight', 'borderBottom', 'borderLeft'];

interface OverflowProps {
  overflow?: LayoutOverflow;
  overflowX?: LayoutOverflow;
  overflowY?: LayoutOverflow;
}
type OverflowPropKey = keyof OverflowProps;
const OVERFLOW_PROPS: OverflowPropKey[] = ['overflow', 'overflowX', 'overflowY'];
export type LayoutBorderRadius = 2 | 4 | 8 | 12 | 16 | 20 | 24 | 36 | 40 | 56 | 80 | 100 | 1000 | 'circular';

export interface Props extends FlexProps, InlineProps, BorderProps, OverflowProps {
  position?: Position;
  display?: 'inline' | 'inline-block' | 'block' | 'none' | 'inline-flex' | 'grid';
  visibility?: 'visible' | 'hidden' | 'collapse';
  color?: Color | 'blue-gradient' | 'purple-gradient';
  hoverColor?: Color;
  hoverTextColor?: Color;
  hoverIconColor?: Color;
  equalWidth?: boolean;
  borderWidth?: 0 | 1 | 2 | 4 | 8;
  borderColor?: Color | 'transparent' | 'blue-gradient' | 'purple-gradient';
  borderRadius?: LayoutBorderRadius;
  borderTopLeftRadius?: LayoutBorderRadius;
  borderTopRightRadius?: LayoutBorderRadius;
  borderBottomRightRadius?: LayoutBorderRadius;
  borderBottomLeftRadius?: LayoutBorderRadius;
  borderStyle?: 'dashed' | 'dotted' | 'solid';
  tabIndex?: number;
  onScroll?: UIEventHandler<HTMLDivElement>;
  onMouseDown?: UIEventHandler<HTMLDivElement>;
  onMouseUp?: UIEventHandler<HTMLDivElement>;
  onMouseEnter?: UIEventHandler<HTMLDivElement>;
  onMouseLeave?: UIEventHandler<HTMLDivElement>;
  onMeasure?: OnMeasure;
  userSelect?: 'none';
  /** Renders a <span> */
  inline?: boolean;

  style?: React.CSSProperties;
  /** Use with caution */
  className?: string;
  /** Use with caution */
  id?: string;
  /** Use with caution */
  __ref?: MutableRefObject<HTMLDivElement | null>;

  // DEPRECATED: these double underscore props are deprecated in favor of the new props
  // That do not use the double underscore prefix.
  /** Use with caution */
  __style?: React.CSSProperties;
  /** Use with caution */
  __className?: string;
  /** Use with caution */
  __id?: string;
}

function isInlineProp(key: string): key is InlinePropKey {
  return INLINE_PROPS.includes(key as InlinePropKey);
}

function isFlexProp(key: string): key is FlexPropKey {
  return FLEX_PROPS.includes(key as FlexPropKey);
}

function isBorderProp(key: string): key is BorderPropKey {
  return BORDER_PROPS.includes(key as BorderPropKey);
}

function isPositionProp(key: string): key is 'position' {
  return key === 'position';
}

function isDisplayProp(key: string): key is 'display' {
  return key === 'display';
}

function isVisibilityProp(key: string): key is 'visibility' {
  return key === 'visibility';
}

function isColorProp(key: string): key is 'color' {
  return key === 'color';
}

function isHoverColorProp(key: string): key is 'hoverColor' {
  return key === 'hoverColor';
}

function isHoverTextColorProp(key: string): key is 'hoverTextColor' {
  return key === 'hoverTextColor';
}

function isHoverIconColorProp(key: string): key is 'hoverIconColor' {
  return key === 'hoverIconColor';
}

function isOverflowProp(key: string): key is OverflowPropKey {
  return OVERFLOW_PROPS.includes(key as OverflowPropKey);
}

function addFlexClasses(classes: string[], props: FlexProps): string[] {
  const keys = Object.keys(props) as FlexPropKey[];

  keys.forEach(key => {
    const value = props[key];
    if (key === 'flex' && value) {
      classes.push('layout-flex');
    } else if (key === 'wrap' && value) {
      classes.push('layout-flex-wrap');
    } else if (key === 'wrapReverse' && value) {
      classes.push('layout-flex-wrap-reverse');
    } else if (typeof value === 'string') {
      classes.push(`layout-${key}-${value}`);
    }
  });

  // Add layout-flex as a convenience if the flex prop was not provided
  // and flex container rules were used.
  if (!classes.includes('layout-flex') && hasFlexContainerProp(props)) {
    classes.push('layout-flex');
  }

  return classes;
}
function hasFlexContainerProp(props: FlexProps): boolean {
  return FLEX_CONTAINER_PROPS.some(prop => !!props[prop]);
}

const Layout: React.FC<PropsWithChildren<Props>> = ({
  children,
  onScroll,
  onMouseDown,
  onMouseUp,
  onMouseEnter,
  onMouseLeave,
  ...props
}) => {
  const { contentWidth, contentHeight } = useContext(app);
  const inlineStyleProps: InlineProps = {};
  const flexProps: FlexProps = {};

  const className = props.className || props.__className;
  const classes: string[] = !!className ? [className] : [];
  const localRef = useRef<HTMLDivElement | null>(null);
  const ref: MutableRefObject<HTMLDivElement | null> = props.__ref || localRef;

  const { onMeasure } = props;

  useEffect(() => {
    if (!onMeasure) return;

    const element = ref.current;

    if (!element) return;

    onMeasure(element.getBoundingClientRect());
  }, [contentWidth, contentHeight]); // eslint-disable-line react-hooks/exhaustive-deps

  for (const key in props) {
    if (isInlineProp(key)) {
      if (key === 'gap') {
        assignProp(flexProps, key, props[key]);
      }
      if (key === 'paddingX') {
        inlineStyleProps.paddingRight = props[key];
        inlineStyleProps.paddingLeft = props[key];
      } else if (key === 'paddingY') {
        inlineStyleProps.paddingTop = props[key];
        inlineStyleProps.paddingBottom = props[key];
      } else if (key === 'marginX') {
        inlineStyleProps.marginRight = props[key];
        inlineStyleProps.marginLeft = props[key];
      } else if (key === 'marginY') {
        inlineStyleProps.marginTop = props[key];
        inlineStyleProps.marginBottom = props[key];
      } else {
        assignProp(inlineStyleProps, key, props[key]);
      }
    } else if (isFlexProp(key)) {
      assignProp(flexProps, key, props[key]);
    } else if (isPositionProp(key)) {
      const value = props[key];
      value && classes.push(`layout-position-${value}`);
    } else if (isDisplayProp(key)) {
      const value = props[key];
      value && classes.push(`layout-display-${value}`);
    } else if (isVisibilityProp(key)) {
      const value = props[key];
      value && classes.push(`layout-visibility-${value}`);
    } else if (isColorProp(key)) {
      const value = props[key];
      value && classes.push(`bg-color-${camelToKebabCase(value)}`);
    } else if (isHoverColorProp(key)) {
      const value = props[key];
      value && classes.push(`hover-bg-color-${camelToKebabCase(value)}`);
    } else if (isHoverTextColorProp(key)) {
      const value = props[key];
      value && classes.push(`hover-text-color-${camelToKebabCase(value)}`);
    } else if (isHoverIconColorProp(key)) {
      const value = props[key];
      value && classes.push(`hover-icon-color-${camelToKebabCase(value)}`);
    } else if (isBorderProp(key)) {
      const value = props[key];
      if (props.borderColor && value) {
        classes.push(`layout-has-${camelToKebabCase(key)}-${props.borderColor}`);
      } else if (value) {
        classes.push(`layout-has-${camelToKebabCase(key)}`);
        classes.push(`is-border-color-${DEFAULT_BORDER_COLOR}`);
      }
    } else if (isOverflowProp(key)) {
      const value = props[key];
      value && classes.push(`layout-${camelToKebabCase(key)}-${value}`);
    } else if (key === 'userSelect') {
      const value = props[key];
      value && classes.push(`user-select-${value}`);
    } else if (key === 'equalWidth' && props[key]) {
      classes.push('layout-flex-equal-width');
    } else if (key === 'borderStyle' && props[key]) {
      classes.push(`layout-border-style-${props[key]}`);
    } else if (key === 'borderWidth' && props[key]) {
      classes.push(`is-border-width-${props[key]}`);
      if (!props.borderColor) {
        classes.push(`is-border-color-${DEFAULT_BORDER_COLOR}`);
      }
    } else if (key === 'borderRadius' && props[key]) {
      classes.push(`is-border-radius-${props[key]}`);
    } else if (key === 'borderTopLeftRadius' && props[key]) {
      classes.push(`is-border-top-left-radius-${props[key]}`);
    } else if (key === 'borderTopRightRadius' && props[key]) {
      classes.push(`is-border-top-right-radius-${props[key]}`);
    } else if (key === 'borderBottomRightRadius' && props[key]) {
      classes.push(`is-border-bottom-right-radius-${props[key]}`);
    } else if (key === 'borderBottomLeftRadius' && props[key]) {
      classes.push(`is-border-bottom-left-radius-${props[key]}`);
    } else if (key === 'borderColor' && props[key]) {
      classes.push(`is-border-color-${props[key]}`);
    }
  }

  const style = { ...inlineStyleProps, ...(props.style || props.__style || {}) };
  addFlexClasses(classes, flexProps);
  const combinedClasses = classNames(classes);

  const Tag = !!props.inline ? 'span' : 'div';

  const id = props.id || props.__id;

  return (
    <Tag
      style={Object.keys(style).length > 0 ? style : undefined}
      className={combinedClasses.length > 0 ? combinedClasses : undefined}
      tabIndex={props.tabIndex}
      ref={ref}
      id={id}
      onScroll={onScroll}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {children}
    </Tag>
  );
};

export default Layout;
