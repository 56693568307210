import React from 'react';
import { Color } from 'shared';

import Icon from 'ds/Icon';
import Layout from 'ds/Layout';
import Text from 'ds/Text';

interface Props {
  dotColor: Color;
  color: Color;
  text: string;
  dotPosition?: 'left' | 'right';
  textColor?: Color;
  includeCaret?: boolean;
  size?: 'sm' | 'lg';
}

const StatusDisplay: React.FC<Props> = ({
  text,
  dotColor,
  color,
  dotPosition = 'left',
  textColor = 'gray-900',
  includeCaret = false,
  size = 'lg'
}) => {
  return (
    <Layout
      color={color}
      {...(size === 'sm' ? { paddingLeft: 10, paddingRight: 12, paddingY: 2 } : { paddingX: 12, paddingY: 6 })}
      borderRadius={4}
      align="center"
      direction={dotPosition === 'right' ? 'row-reverse' : undefined}
      justify="center"
    >
      <Layout width={8} height={8} borderRadius="circular" color={dotColor} />
      <Layout marginLeft={6}>
        <Text size={size === 'sm' ? 'tag' : 'body-sm'} color={textColor} semibold>
          {text}
        </Text>
      </Layout>
      {includeCaret && <Icon name="downCaret" size={16} color={dotColor} />}
    </Layout>
  );
};

export default StatusDisplay;
