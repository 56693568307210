import axios from 'shared/lib/axios';

interface Interval {
  start: string; //YYYY-mm-dd
  end: string; //YYYY-mm-dd
}

export function getWorkspaceUnavailableIntervals({ id }: { id: number }) {
  return axios.get<Interval[]>(`/v1/workspaces/${id}/unavailable_intervals`);
}
