import React, { useContext, useEffect } from 'react';
import { User } from 'shared';

import { app } from 'context';
import { Animate, IconButton, Layout } from 'ds';
import { APP_CONTENT_GUTTER_BOTTOM } from 'ds/PageLayout/constants';
import { lockScreenScroll, unlockScreenScroll } from 'ds/utils';
import MobileAccount from 'ux/Customer/Account/MobileAccount';

interface Props {
  closeMenu: VoidFunction;
  user: User;
}

const AccountMenu: React.FC<Props> = ({ closeMenu, user }) => {
  const { windowHeight, contentPaddingX, navBarHeight } = useContext(app);

  useEffect(() => {
    lockScreenScroll();

    return () => {
      unlockScreenScroll();
    };
  }, []);

  return (
    <>
      <Layout
        height={navBarHeight}
        width={40}
        justify="center"
        align="center"
        right={contentPaddingX}
        top={0}
        position="absolute"
      >
        <IconButton name="close" onClick={closeMenu} size="sm" type="primary" stroke={2} />
      </Layout>
      <Animate animation="fade-in-from-right">
        <Layout
          position="absolute"
          color="white"
          width="100vw"
          height={windowHeight - navBarHeight}
          __className="Navigation-mobileMenuContainer"
          overflow="auto"
          paddingBottom={APP_CONTENT_GUTTER_BOTTOM}
        >
          <MobileAccount user={user} closeMenu={closeMenu} />
        </Layout>
      </Animate>
    </>
  );
};

export default AccountMenu;
