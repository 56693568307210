import React, { useRef } from 'react';
import { determineIsNew, formatPublicAddress } from 'shared';
import { useWindowResize } from 'beautiful-react-hooks';

import { formatPriceAdjustedForDaysPerWeek } from 'helpers/price';

import Layout from 'ds/Layout';
import Link from 'ds/Link';
import NewTag from 'ds/NewTag';
import Text from 'ds/Text';
import TextButton from 'ds/TextButton';
import WorkspaceImg from 'ds/WorkspaceImg';
import { OnMeasure } from 'ds/types';
import { formatUsd } from 'helpers';
import { AUTH_PATH, LISTING_PATH, STB_REQUEST_PATH } from 'routes';
import { selectUser } from 'store/User/selectors';
import { useAppSelector } from 'store/hooks';

import { PromoWorkspace } from './types';

interface Props {
  workspace: PromoWorkspace;
  onImageMeasure?: OnMeasure;
  isStb?: boolean;
  setPriceExplanationModalIsVisible: (isVisible: boolean) => void;
  showSeePricing: boolean;
}

const PromoCard: React.FC<Props> = ({
  workspace: { id, address, monthly_price, name, slug, first_listed_at, image, daily_rate },
  onImageMeasure,
  isStb = false,
  setPriceExplanationModalIsVisible,
  showSeePricing
}) => {
  const imageContainerRef = useRef<HTMLDivElement>(null);
  const isNew = determineIsNew(first_listed_at);
  const isLoggedIn = !!useAppSelector(selectUser);

  const measureImage = () => {
    const imageContainer = imageContainerRef.current;

    if (!imageContainer || !onImageMeasure) return;

    onImageMeasure(imageContainer.getBoundingClientRect());
  };

  const onWindowResize = useWindowResize();
  onWindowResize(measureImage);
  const price =
    isStb && daily_rate
      ? formatUsd(daily_rate, false)
      : isLoggedIn && monthly_price
      ? formatPriceAdjustedForDaysPerWeek({ monthlyPrice: monthly_price })
      : null;
  const linkPath = isStb ? `${STB_REQUEST_PATH}/${id}` : `${LISTING_PATH}/${slug}`;

  return (
    <Link href={linkPath} opacity={false} key={id} fullWidth>
      <Layout display="inline-flex" width="100%" __ref={imageContainerRef} position="relative">
        <WorkspaceImg
          srcKey={image.key}
          folder={image.folder}
          alt={name}
          style={{
            borderRadius: 12,
            userSelect: 'none',
            objectFit: 'cover'
          }}
          draggable={false}
          size="lg"
          onLoad={measureImage}
        />
        {isNew && (
          <Layout position="absolute" top={12} left={12} zIndex={1}>
            <NewTag />
          </Layout>
        )}
      </Layout>
      <Layout marginTop={12}>
        <Text size="body1" color="gray-600" ellipsis scale>
          {formatPublicAddress(address)}
        </Text>
        <Text size="body1" color="gray-600" ellipsis scale>
          {name}
        </Text>
      </Layout>
      {isStb ? (
        <Text color="gray-900" size="body-sm" semibold>
          {price}/day
        </Text>
      ) : price ? (
        <TextButton
          textSize="body-sm"
          color="gray-900"
          paddingY={false}
          onClick={() => setPriceExplanationModalIsVisible(true)}
          text={<>{price}/mo</>}
          underline
          semibold
        />
      ) : (
        showSeePricing && (
          <Layout __className="WorkspaceListCard-seePrice">
            <Link href={AUTH_PATH} search={`redirect=${encodeURIComponent(linkPath)}`} semibold={false} size="body-sm">
              See pricing
            </Link>
          </Layout>
        )
      )}
    </Link>
  );
};

export default PromoCard;
