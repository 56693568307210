import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ListingWorkspace } from 'shared';

import { app, media } from 'context';
import { HEADER_Z_INDEX, Layout } from 'ds';
import { actions } from 'store/STBRequest';
import { selectStbRequest } from 'store/STBRequest/selectors';
import { selectUser } from 'store/User/selectors';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import StickyHeader from 'ux/Layouts/Shared/StickyHeader';

import STBRequestCard from './STBRequestCard';
import STBRequestForm from './STBRequestForm';
import STBRequestHeader from './STBRequestHeader';
import STBSubmitRequest from './STBSubmitRequest';
import { stbIsReadyForForm } from './utils';

interface Props {}

const STBRequest: React.FC<Props> = () => {
  const stbRequest = useAppSelector(selectStbRequest);
  const dispatch = useAppDispatch();
  const { state: { workspace } = {} } = useLocation<{ workspace?: ListingWorkspace }>();
  const { contentPaddingX } = useContext(app);
  const { xs, sm } = useContext(media);
  const [footerHeight, setFooterHeight] = useState<number>();
  const { firstname, lastname, email } = useAppSelector(selectUser) || {};

  useEffect(() => {
    if (firstname && lastname && email) {
      dispatch(actions.updateStbRequest({ first_name: firstname, last_name: lastname, email }));
    }
  }, [dispatch, firstname, lastname, email]);

  if (!stbIsReadyForForm(stbRequest) || !workspace) {
    return null;
  }

  return (
    <>
      <StickyHeader top={0} zIndex={HEADER_Z_INDEX}>
        <Layout paddingX={contentPaddingX}>
          <STBRequestHeader />
        </Layout>
      </StickyHeader>
      <Layout
        {...(xs
          ? { paddingTop: 24, paddingBottom: (footerHeight || 0) + 48 }
          : { paddingTop: sm ? 80 : 124, justify: 'center', marginX: contentPaddingX, paddingBottom: 64 })}
      >
        <Layout {...(xs ? {} : { maxWidth: 1120, justify: 'space-between', flexGrow: 1 })}>
          <Layout {...(xs ? { paddingX: contentPaddingX, flexGrow: 1 } : { maxWidth: 480, minWidth: 380 })}>
            <STBRequestForm />
          </Layout>
          <Layout {...(xs ? { paddingX: contentPaddingX, marginTop: 36 } : { maxWidth: 500, marginLeft: 40 })}>
            <STBRequestCard stbRequest={stbRequest} workspace={workspace} />
          </Layout>
        </Layout>
      </Layout>
      {xs && (
        <Layout
          paddingTop={12}
          paddingBottom={8}
          bottom={0}
          position="fixed"
          zIndex={HEADER_Z_INDEX}
          paddingX={contentPaddingX}
          color="white"
          boxShadow="2px 0px 22px rgba(87, 73, 109, 0.12)"
          onMeasure={({ height }) => setFooterHeight(height)}
        >
          <STBSubmitRequest />
        </Layout>
      )}
    </>
  );
};

export default STBRequest;
