import { OfficeLead, axios } from 'shared';

import { OfficeRequestTour } from 'store/OfficeRequest';
import { CreatableOfficeRequest } from 'ux/Public/OfficeRequest/utils';

import { CreatableOfficeLead } from './types';

export function createOfficeLead({ userId, officeLead }: { userId: number; officeLead: CreatableOfficeLead }) {
  return axios.post<OfficeLead>(`/v1/users/${userId}/office_leads`, officeLead);
}

export function createOfficeLeadTour({
  userId,
  officeLeadId,
  officeRequestTour,
  source
}: {
  userId: number;
  officeLeadId: number;
  officeRequestTour: OfficeRequestTour;
  source: 'request' | 'app';
}) {
  const { startsAt, endsAt } = officeRequestTour;
  return axios.post<OfficeLead>(`/v1/users/${userId}/office_leads/${officeLeadId}/tours`, {
    starts_at: startsAt,
    ends_at: endsAt,
    source
  });
}

export function updateOfficeLeadTour({
  userId,
  officeLeadId,
  officeRequestTour,
  source,
  tourId
}: {
  userId: number;
  tourId: number;
  officeLeadId: number;
  officeRequestTour: OfficeRequestTour;
  source: 'request' | 'app';
}) {
  const { startsAt, endsAt } = officeRequestTour;
  return axios.patch<OfficeLead>(`/v1/users/${userId}/office_leads/${officeLeadId}/tours/${tourId}`, {
    starts_at: startsAt,
    ends_at: endsAt,
    source
  });
}

export function transformOfficeRequestStateToOfficeLead({
  market,
  locations,
  headCount,
  daysPerWeek,
  maxMonthlyBudget,
  otherAmenities,
  amenities,
  howHeard,
  referralInfo,
  user: { phone, callingCode }
}: CreatableOfficeRequest): CreatableOfficeLead {
  return {
    market,
    locations,
    head_count: headCount,
    days_per_week: daysPerWeek,
    max_monthly_budget: maxMonthlyBudget,
    other_amenities: otherAmenities,
    amenities,
    phone,
    calling_code: callingCode,
    how_heard: howHeard,
    referrer_id: referralInfo?.referrer.id
  };
}
