import React, { useContext, useState } from 'react';
import { GRAY_900_HEX, Image } from 'shared';

import { app, media } from 'context';
import Button from 'ds/Button';
import Clickable from 'ds/Clickable';
import Img from 'ds/Img';
import Layout from 'ds/Layout';
import SwiperCarousel from 'ds/SwiperCarousel';
import Text from 'ds/Text';
import WorkspaceImg from 'ds/WorkspaceImg';
import useKeyDown from 'ds/helpers/useKeyDown';
import { SwiperSlide } from 'swiper/react';
import TourCTA from 'ux/Public/Listing/TourCTA';
import { ListingCTAProps } from 'ux/Public/Listing/types';

import LeftArrow from './LeftArrow';
import RightArrow from './RightArrow';
import {
  GALLERY_HEIGHT_LARGE,
  GALLERY_HEIGHT_SMALL,
  GALLERY_SM_HEIGHT,
  GALLERY_SM_WIDTH,
  GALLERY_WIDTH_LARGE,
  GALLERY_WIDTH_SMALL,
  IMAGE_HEIGHT,
  IMAGE_WIDTH
} from './constants';
import { Dimensions } from '../types';

import { ReactComponent as Cube } from '../Cube.svg';
import { ReactComponent as VideoCamera } from '../VideoCamera.svg';

type Props = {
  images: Image[];
  imageIndex: number;
  matterportId: string | null;
  tourVideoKey: string | null;
  setImageIndex: (value: number) => void;
  show3DWalkthroughTab: VoidFunction;
  showVideoTourTab: VoidFunction;
} & ListingCTAProps;

const Photos: React.FC<Props> = ({
  images,
  imageIndex,
  matterportId,
  tourVideoKey,
  setImageIndex,
  show3DWalkthroughTab,
  showVideoTourTab,
  workspace,
  officeLead,
  CTA
}) => {
  const { sm } = useContext(media);
  const { windowHeight, windowWidth } = useContext(app);
  const [activeIndex, setActiveIndex] = useState<number>(imageIndex);
  const [inputIndex, setInputIndex] = useState<number>();

  useKeyDown((e: KeyboardEvent) => {
    if (e.key === 'ArrowRight') {
      if (inputIndex === images.length - 1) setInputIndex(0);
      else setInputIndex(activeIndex + 1);
    } else if (e.key === 'ArrowLeft') {
      if (inputIndex === 0) setInputIndex(images.length - 1);
      else setInputIndex(activeIndex - 1);
    }
  });

  if (!images.length) return null;

  const imagesArray: (Image | string)[] = [...images];
  if (!!matterportId) {
    imagesArray.splice(2, 0, matterportId);
  } else if (!!tourVideoKey) {
    imagesArray.splice(2, 0, tourVideoKey);
  }

  const carouselDimensions = (): Dimensions => {
    const allowedWidthOfCarousel = windowWidth - (sm ? 308 : 450);
    const allowedHeightOfCarousel = windowHeight - 244;

    if (allowedWidthOfCarousel / allowedHeightOfCarousel > 1.57) {
      return {
        height: windowHeight - 244,
        width: (windowHeight - 244) * 1.5
      };
    }
    return {
      height: allowedWidthOfCarousel / 1.5,
      width: allowedWidthOfCarousel
    };
  };

  const { height: carouselHeight, width: carouselWidth } = carouselDimensions();

  const imageCount = images.length;

  return (
    <Layout direction="row" width="100%" position="relative" justify="flex-start" align="flex-start" color="white">
      <Layout
        marginLeft={24}
        direction="row"
        width={sm ? GALLERY_SM_WIDTH : GALLERY_WIDTH_LARGE}
        height={windowHeight - 104}
        align="flex-start"
        overflow="scroll"
        minWidth={sm ? GALLERY_SM_WIDTH : GALLERY_WIDTH_LARGE}
      >
        <Layout wrap __style={{ gap: 12 }} paddingTop={42}>
          {images.map((image, index) => {
            const isMatterportThumbnail = index === 2 && !!matterportId;
            const isTourVideoThumbnail = index === 2 && !!tourVideoKey && !isMatterportThumbnail;

            return (
              <Layout position="relative" key={image.id}>
                <Layout
                  zIndex={0}
                  overflow="hidden"
                  direction="row"
                  maxWidth={sm ? GALLERY_SM_WIDTH : index % 3 === 2 ? GALLERY_WIDTH_LARGE : GALLERY_WIDTH_SMALL}
                  maxHeight={sm ? GALLERY_SM_HEIGHT : index % 3 === 2 ? GALLERY_HEIGHT_LARGE : GALLERY_HEIGHT_SMALL}
                  borderColor="blue-500"
                  borderRadius={8}
                  color={activeIndex === index ? 'white' : undefined}
                  opacity={activeIndex === index ? 0.5 : 1}
                >
                  <Clickable
                    onClick={() => setInputIndex(index)}
                    children={
                      isMatterportThumbnail ? (
                        <>
                          <img
                            src={`https://my.matterport.com/api/v2/player/models/${matterportId}/thumb`}
                            alt="Matterport Thumbnail"
                            height={GALLERY_HEIGHT_LARGE}
                          />
                          <Layout
                            position="absolute"
                            left={12}
                            top={12}
                            borderRadius={4}
                            color="gray-900"
                            paddingY={6}
                            paddingX={10}
                            align="center"
                          >
                            <Cube />
                            <Layout marginLeft={8} />
                            <Text size="body3" color="white" semibold>
                              3D Walkthrough
                            </Text>
                          </Layout>
                        </>
                      ) : isTourVideoThumbnail ? (
                        <>
                          <Img
                            width={IMAGE_WIDTH}
                            height={IMAGE_HEIGHT}
                            alt={'video tour'}
                            srcKey={`${tourVideoKey}/ik-thumbnail.jpg`}
                          />
                          <Layout
                            position="absolute"
                            left={12}
                            top={12}
                            borderRadius={4}
                            color="gray-900"
                            paddingY={6}
                            paddingX={10}
                            align="center"
                          >
                            <VideoCamera />
                            <Layout marginLeft={8} />
                            <Text size="body3" color="white" semibold>
                              Video Tour
                            </Text>
                          </Layout>
                        </>
                      ) : (
                        <Img
                          width={IMAGE_WIDTH}
                          height={IMAGE_HEIGHT}
                          alt={`workspace image ${index}`}
                          srcKey={image.key}
                        />
                      )
                    }
                  />
                </Layout>
                {activeIndex === index && (
                  <Layout
                    top={0}
                    position="absolute"
                    zIndex={1}
                    key={image.id}
                    direction="row"
                    width={sm ? GALLERY_SM_WIDTH : index % 3 === 2 ? GALLERY_WIDTH_LARGE : GALLERY_WIDTH_SMALL}
                    height={
                      sm ? GALLERY_SM_HEIGHT - 4 : index % 3 === 2 ? GALLERY_HEIGHT_LARGE : GALLERY_HEIGHT_SMALL - 16
                    }
                    borderWidth={4}
                    borderColor="blue-500"
                    borderRadius={8}
                  ></Layout>
                )}
              </Layout>
            );
          })}
          <Layout height={42} width="100%" />
        </Layout>
      </Layout>
      <Layout width="100%" justify="center">
        <Layout
          direction="column"
          justify="center"
          align="center"
          width={carouselWidth}
          borderRadius={8}
          marginLeft={52}
          marginRight={52}
          paddingY={42}
          overflow="hidden"
        >
          <SwiperCarousel
            ignoreTouch
            config={{ md: { slidesPerView: 1, spaceBetween: 0 } }}
            controlLayoutProps={{ width: 48, height: 48, left: -246, right: -246 }}
            leftArrow={<LeftArrow />}
            rightArrow={<RightArrow />}
            height={carouselHeight}
            width={carouselWidth}
            onSlideChange={({ realIndex }) => {
              setActiveIndex(realIndex);
              setInputIndex(realIndex);
            }}
            initialSlide={imageIndex}
            inputIndex={inputIndex}
            virtual={false}
            lazy
            loop
          >
            {imagesArray.map((image, index) => {
              const isMatterportSlide = index === 2 && !!matterportId;
              const isTourVideoSlide = index === 2 && !!tourVideoKey && !isMatterportSlide;

              return (
                <SwiperSlide key={`slide${index}`}>
                  <Layout width="100%" direction="column" justify="center" align="center" position="relative">
                    {isMatterportSlide ? (
                      <Clickable
                        onClick={() => show3DWalkthroughTab()}
                        children={
                          <Layout borderRadius={8} overflow="hidden">
                            <img
                              src={`https://my.matterport.com/api/v2/player/models/${matterportId}/thumb`}
                              alt="Matterport Thumbnail"
                              style={{ objectFit: 'cover', height: carouselHeight }}
                            />
                            <Layout
                              position="absolute"
                              top={0}
                              width="100%"
                              height="100%"
                              justify="center"
                              align="center"
                              direction="column"
                              zIndex={1}
                            >
                              <Layout
                                width={90}
                                height={90}
                                borderRadius="circular"
                                justify="center"
                                align="center"
                                __style={{ backgroundColor: `${GRAY_900_HEX}B3` }}
                              >
                                <Cube width={32} height={32} />
                              </Layout>
                              <Layout marginTop={16} />
                              <Text size="h6" color="white">
                                3D Walkthrough
                              </Text>
                            </Layout>
                          </Layout>
                        }
                      />
                    ) : isTourVideoSlide ? (
                      <Clickable
                        onClick={() => showVideoTourTab()}
                        children={
                          <Layout borderRadius={8} overflow="hidden">
                            <WorkspaceImg
                              srcKey={`${tourVideoKey}/ik-thumbnail.jpg`}
                              size="lg"
                              alt={`workspace ${index + 1}`}
                              style={{
                                userSelect: 'none',
                                objectFit: 'cover',
                                borderRadius: 8,
                                width: '100%',
                                height: '100%'
                              }}
                            />
                            <Layout
                              position="absolute"
                              top={0}
                              width="100%"
                              height="100%"
                              justify="center"
                              align="center"
                              direction="column"
                              zIndex={1}
                            >
                              <Layout
                                width={90}
                                height={90}
                                borderRadius="circular"
                                justify="center"
                                align="center"
                                __style={{ backgroundColor: `${GRAY_900_HEX}B3` }}
                              >
                                <VideoCamera width={32} height={32} />
                              </Layout>
                              <Layout marginTop={16} />
                              <Text size="h6" color="white">
                                Video Tour
                              </Text>
                            </Layout>
                          </Layout>
                        }
                      />
                    ) : (
                      <WorkspaceImg
                        srcKey={(image as Image).key}
                        size="lg"
                        alt={`workspace ${index + 1}`}
                        style={{
                          userSelect: 'none',
                          objectFit: 'cover',
                          borderRadius: 8,
                          width: '100%',
                          height: '100%'
                        }}
                      />
                    )}
                  </Layout>
                </SwiperSlide>
              );
            })}
          </SwiperCarousel>
          <Layout direction="column" width="100%" height={100} justify="space-evenly" align="center">
            <Text color="gray-900" size="body1">
              {activeIndex + 1} / {imageCount}
            </Text>
            {workspace ? (
              <TourCTA workspace={workspace} officeLead={officeLead} />
            ) : CTA ? (
              <Button
                text={CTA.text}
                onClick={e => {
                  setImageIndex(-1);
                  CTA.onClick && CTA.onClick(e);
                }}
                type="primary"
                size="sm"
              />
            ) : null}
          </Layout>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default Photos;
