import React from 'react';

import Billing from './Billing';
import Consumables from './Consumables';
import OrderCleaning from './OrderCleaning';
import OrderFurniture from './OrderFurniture';
import { ChatbotIntentProps } from '../types';

interface Props extends Omit<ChatbotIntentProps, 'chatbotIntent'> {
  chatbotIntentId: number;
}

const ChatbotWidget: React.FC<Props> = ({ chatbotInteraction, threadId, chatbotIntentId }) => {
  const chatbotIntent = chatbotInteraction.chatbot_intents.find(intent => intent.id === chatbotIntentId);
  if (!chatbotIntent) return null;

  switch (chatbotIntent.type) {
    case 'OrderCleaningIntent':
      return (
        <OrderCleaning chatbotInteraction={chatbotInteraction} threadId={threadId} chatbotIntent={chatbotIntent} />
      );
    case 'OrderFurnitureIntent':
      return <OrderFurniture chatbotInteraction={chatbotInteraction} threadId={threadId} />;
    case 'ConsumablesIntent':
      return <Consumables chatbotInteraction={chatbotInteraction} threadId={threadId} chatbotIntent={chatbotIntent} />;
    case 'BillingIntent':
      return <Billing chatbotInteraction={chatbotInteraction} threadId={threadId} chatbotIntent={chatbotIntent} />;
    default:
      return null;
    // return <Conversation chatbotInteraction={chatbotInteraction} threadId={threadId} />;
  }
};

export default ChatbotWidget;
