import React, { useContext } from 'react';
import { WHITE_HEX } from 'shared';

import { media } from 'context';
import { ButterImg, Layout, Text } from 'ds';

import { ReactComponent as GradientQuote } from './gradient-quote.svg';

interface Props {
  quote: string;
  image: string;
  name: string;
  company: string;
}

const Testimonial: React.FC<Props> = ({ quote, name, company, image }) => {
  const { xs, sm } = useContext(media);

  return (
    <Layout
      color="blue-700"
      borderRadius={36}
      padding={xs ? 36 : 32}
      height="100%"
      direction="column"
      justify="space-between"
    >
      <Layout>
        <GradientQuote height={xs || sm ? 32 : 48} />
        <Layout marginTop={xs ? 24 : 32}>
          <Text color="white" size={xs || sm ? 'h6' : 'h5'} notBold>
            {quote}
          </Text>
        </Layout>
      </Layout>
      <Layout marginTop={xs ? 24 : 32} flex>
        <ButterImg
          src={image}
          width={256}
          style={{
            borderRadius: '50%',
            border: `4px solid ${WHITE_HEX}`,
            width: xs || sm ? 52 : 88,
            height: xs || sm ? 52 : 88,
            objectFit: 'cover'
          }}
          alt={name}
        />
        <Layout
          {...(xs ? { marginLeft: 12 } : { paddingY: 3.5, justify: 'center', direction: 'column', marginLeft: 19 })}
        >
          {xs ? (
            <>
              <Text color="white" size="body1">
                {name}
              </Text>
              <Text color="white" size="body1" semibold>
                {company}
              </Text>
            </>
          ) : (
            <>
              <p style={{ color: WHITE_HEX, fontSize: 22 }}>{name}</p>
              <p style={{ color: WHITE_HEX, fontSize: 22, fontWeight: 700 }}>{company}</p>
            </>
          )}
        </Layout>
      </Layout>
    </Layout>
  );
};

export default Testimonial;
