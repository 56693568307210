import React, { useEffect, useRef } from 'react';
import { GRAY_900_HEX } from 'shared';

import { Icon, Layout, Modal } from 'ds';
import { track } from 'lib/analytics';

import LocationResults from '../ux/Public/Home/LocationResults';
import { Result } from '../ux/Public/Home/types';

interface Props {
  isVisible: boolean;
  onClose: VoidFunction;
  results: Result[];
  handleChange: (value: string) => void;
  onLocationSelect: (result: Result) => void;
  searchValue?: string;
  scrimBackground?: string;
  isFullScreen?: boolean;
  animate?: boolean;
}

const LocationBottomSheet: React.FC<Props> = ({
  isVisible,
  onClose,
  results,
  handleChange,
  onLocationSelect,
  searchValue,
  scrimBackground,
  isFullScreen = false,
  animate
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const hasValue = !!searchValue;

  useEffect(() => {
    const input = inputRef.current;

    if (isVisible && hasValue && input) {
      input.select();
    }
  }, [isVisible]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Modal
      isVisible={isVisible}
      scrimBackground={scrimBackground}
      onClose={onClose}
      hasBottomSheetPaddingX={false}
      isFullScreen={isFullScreen}
      scrollable={false}
      animate={animate}
      showCloseButton={false}
      scrollShadow={false}
      handleKeyboardFocus
      header={
        <Layout flexGrow={1} paddingTop={isFullScreen ? 24 : 12} paddingX={16} position="relative" align="center">
          <button
            style={{ display: 'inline-flex', padding: '0 4px' }}
            onClick={() => {
              track('Element Interacted', { type: 'button', name: 'location input close' });
              onClose();
            }}
          >
            <Icon color="blue-500" name="leftChevron" size="md" stroke={2} />
          </button>
          <Layout height={24} marginLeft={12} flexGrow={1} display="inline-flex">
            <input
              ref={inputRef}
              key={isVisible.toString()}
              autoFocus={isVisible}
              type="text"
              autoComplete="off"
              placeholder="Enter a city or neighborhood"
              style={{
                borderStyle: 'none',
                fontSize: 16,
                lineHeight: '24px',
                outline: 'none',
                background: 'none',
                color: GRAY_900_HEX,
                zIndex: 1,
                width: '100%',
                height: '100%'
              }}
              value={searchValue}
              onChange={({ target: { value } }) => handleChange(value)}
            />
          </Layout>
          <button
            style={{
              display: 'inline-flex',
              alignItems: 'center',
              justifyContent: 'center',
              padding: 6
            }}
            onClick={() => {
              track('Element Interacted', { type: 'button', name: 'location input clear' });
              handleChange('');

              inputRef.current?.focus();
            }}
          >
            <Layout
              color="gray-300"
              align="center"
              display="inline-flex"
              padding={2}
              borderRadius="circular"
              opacity={searchValue ? 1 : 0}
              inline
            >
              <Icon color="white" name="close" size="xxs" stroke={2} />
            </Layout>
          </button>
        </Layout>
      }
    >
      <LocationResults
        key={results.length}
        size="lg"
        results={results}
        onLocationSelect={onLocationSelect}
        searchValue={searchValue}
        focusedIndex={-1}
        isBottomSheet
      />
    </Modal>
  );
};

export default LocationBottomSheet;
