import React, { useContext, useState } from 'react';

import { media } from 'context';
import { Layout, Text, Button, EmailInput, Icon } from 'ds';
import { validateEmail } from 'shared';
import { apiTrack } from 'lib/analytics';

interface Props {}

const FooterEmailSubscription: React.FC<Props> = () => {
  const { xs, sm, lg } = useContext(media);
  const [requestInProgress, setRequestInProgress] = useState<boolean>();
  const [email, setEmail] = useState<string>();
  const { valid: emailIsValid } = validateEmail(email, true);
  const [requestSubmitted, setRequestSubmitted] = useState<boolean>();

  const submitEmail = () => {
    if (!!email && !!emailIsValid) {
      setRequestInProgress(true);

      apiTrack('Email Subscribed', { email: email });

      setRequestSubmitted(true);
      setRequestInProgress(false);
    }
  };

  return (
    <Layout
      paddingBottom={44}
      marginBottom={44}
      direction={lg ? 'row' : 'column'}
      align="flex-start"
      justify={lg ? 'space-between' : undefined}
      borderBottom
      borderColor="white"
    >
      {requestSubmitted ? (
        <Layout direction="row" align="flex-start">
          <Layout
            height={xs ? 20 : 24}
            width={xs ? 20 : 24}
            minHeight={xs ? 20 : 24}
            minWidth={xs ? 20 : 24}
            color="white"
            marginRight={12}
            borderRadius="circular"
            justify="center"
            align="center"
          >
            <Icon size={xs ? 'xxs' : 'xs'} color="black" name="checkmark" />
          </Layout>
          <Text size={xs ? 'body-sm' : 'body-md'} color="white">
            Thanks! Your subscription to email updates from Codi is confirmed.
          </Text>
        </Layout>
      ) : (
        <>
          <Layout>
            <Text size="h5" color="white">
              Stay in the loop.
            </Text>
            {xs && <Layout marginTop={12} />}
            <Text size="body1" color="white">
              Interested in what we’re doing? Get updates sent straight to your inbox.
            </Text>
          </Layout>
          <Layout
            marginTop={!lg ? 24 : undefined}
            direction={xs ? 'column' : 'row'}
            flexGrow={xs || sm ? 1 : undefined}
            width={xs || sm ? '100%' : undefined}
            justify="flex-end"
            align="flex-start"
            wrap={false}
          >
            <Layout flexGrow={xs || sm ? 1 : 0} minWidth={xs || sm ? undefined : 380} width={xs ? '100%' : undefined}>
              <EmailInput
                value={email}
                disabled={requestInProgress}
                label="Email *"
                size={xs ? 'xs' : 'sm'}
                onChange={({ value }) => setEmail(value)}
                required={false}
              />
            </Layout>
            {xs ? <Layout marginTop={12} /> : <Layout marginLeft={12} />}
            <Button
              type="primary"
              size={xs ? 'xs' : 'sm'}
              onClick={submitEmail}
              disabled={requestInProgress}
              text={requestInProgress ? 'Please wait' : 'Connect with us'}
              fullWidth={xs}
            />
          </Layout>
        </>
      )}
    </Layout>
  );
};

export default FooterEmailSubscription;
