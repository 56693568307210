import React, { useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { formatAddress, formatPublicAddress } from 'shared';
import defaultImage from 'shared/assets/default-image.svg';
import classNames from 'classnames';
import pluralize from 'pluralize';

import { media } from 'context';
import { track } from 'lib/analytics';
import { ADMIN_WORKSPACES_PATH, LISTING_PATH } from 'routes';
import { SwiperSlide } from 'swiper/react';

import { AVATAR_SIZE } from './FacePile';
import Icon from './Icon';
import Layout from './Layout';
import CardCarousel from './SwiperCarousel/CardCarousel';
import Text from './Text';
import WorkspaceImg from './WorkspaceImg';
import { WORKSPACE_PHOTO_W_TO_H_RATIO } from './constants';
import { CardWorkspace } from './types';

export interface Props {
  workspace: CardWorkspace;
  onMouseEnter?: VoidFunction;
  onMouseLeave?: VoidFunction;
  disableClick?: boolean;
  hoverArrows?: boolean;
  link?: boolean;
  lazyLoad?: boolean;
  landscape?: boolean;
  width: number;
  size?: 'sm' | 'lg';
  hasShadow?: boolean;
  isAdmin?: boolean;
}

const PADDING_X = 24;
const PADDING_Y = 16;
const LANDSCAPE_HEIGHT = 200;
const SM_HEIGHT = 172;
const LG_HEIGHT = 240;

const WorkspaceCard: React.FC<Props> = ({
  workspace: { capacity, slug, images, name, short_name, address, id },
  onMouseEnter,
  onMouseLeave,
  width,
  size,
  disableClick = false,
  hoverArrows = true,
  link = true,
  landscape = false,
  hasShadow = true,
  isAdmin
}) => {
  const { xs } = useContext(media);
  const borderRadius = 16;
  const visibleHeight = size === 'sm' || xs ? SM_HEIGHT : LG_HEIGHT;
  const { width: imageWidth, height: imageHeight } = getImageDimensions({ cardWidth: width, landscape, visibleHeight });

  let Content = (
    <Layout direction={landscape ? 'row' : 'column'} inline>
      <Layout
        position="relative"
        justify="center"
        align="center"
        __style={{
          height: landscape ? LANDSCAPE_HEIGHT : visibleHeight,
          width: landscape ? imageWidth : width,
          // Hack for mobile browsers
          WebkitMaskImage: ' -webkit-radial-gradient(white, black)'
        }}
        borderTopLeftRadius={borderRadius}
        borderTopRightRadius={landscape ? undefined : borderRadius}
        borderBottomLeftRadius={landscape ? borderRadius : undefined}
        flexShrink={0}
        overflow="hidden"
        inline
      >
        <Layout color="blue-50" __style={{ width: imageWidth, height: imageHeight }} flexShrink={0} inline>
          {!!images.length ? (
            <CardCarousel arrowOffset={16}>
              {images.map(({ key }, index) => (
                <SwiperSlide key={key}>
                  <WorkspaceImg srcKey={key} size="sm" alt={index.toString()} />
                </SwiperSlide>
              ))}
            </CardCarousel>
          ) : (
            <Layout height="100%" justify="center" align="center" inline>
              <Layout width={96} display="inline-flex" inline>
                <img src={defaultImage} alt="default" />
              </Layout>
            </Layout>
          )}
        </Layout>
      </Layout>
      <Layout
        paddingX={PADDING_X}
        paddingY={PADDING_Y}
        direction={landscape ? 'column' : undefined}
        justify={landscape ? 'space-between' : undefined}
        minWidth={0}
        color="white"
      >
        <Layout>
          <Text color={name ? 'black' : 'white'} tag="h3" scale={!landscape} size="h5" maxLines={2} bold>
            {short_name || name || '|'}
          </Text>
          <Layout>
            <Text size="body2" color="gray-600" ellipsis>
              {formatPublicAddress(address)}
            </Text>
          </Layout>
        </Layout>
        <Layout marginTop={16} align="center" height={AVATAR_SIZE}>
          <Layout display="inline-flex" marginRight={8}>
            <Icon name="chair" size="xs" color="gray-600" />
          </Layout>
          <Text size="body2" color="gray-600" scale={!xs} inline>
            {capacity} {pluralize('seat', capacity)}
          </Text>
        </Layout>
      </Layout>
    </Layout>
  );

  const pathname = useHistory().location.pathname;

  if (link) {
    Content = (
      <Link
        key={slug}
        to={
          isAdmin
            ? `${ADMIN_WORKSPACES_PATH}/${id}`
            : {
                pathname: `${LISTING_PATH}/${slug || id}`,
                state: { from: pathname }
              }
        }
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onClick={e => {
          if (disableClick) {
            e.preventDefault();
          }
          track('Element Interacted', {
            type: 'link',
            context: 'Workspace map',
            name: name || formatAddress(address),
            value: id || ''
          });
        }}
        target={isAdmin ? '_blank' : undefined}
      >
        {Content}
      </Link>
    );
  }

  return (
    <Layout
      __className={classNames('WorkspaceCard', {
        'has-hover-arrows': hoverArrows
      })}
      borderRadius={16}
      overflow="hidden"
      __style={{ boxShadow: hasShadow ? '0 13px 23px rgba(0, 0, 0, 0.15)' : undefined }}
    >
      {Content}
    </Layout>
  );
};

function getImageDimensions({
  cardWidth,
  landscape,
  visibleHeight
}: {
  cardWidth: number;
  landscape: boolean;
  visibleHeight: number;
}): { width: number; height: number } {
  if (landscape) {
    return {
      width: LANDSCAPE_HEIGHT * WORKSPACE_PHOTO_W_TO_H_RATIO,
      height: LANDSCAPE_HEIGHT
    };
  }

  if (cardWidth / WORKSPACE_PHOTO_W_TO_H_RATIO < visibleHeight) {
    return {
      width: visibleHeight * WORKSPACE_PHOTO_W_TO_H_RATIO,
      height: visibleHeight
    };
  } else {
    return {
      width: cardWidth,
      height: cardWidth / WORKSPACE_PHOTO_W_TO_H_RATIO
    };
  }
}

export default WorkspaceCard;
