import { useContext, useState } from 'react';
import { PartialPick } from 'shared';

import { media } from 'context';
import IconButton from 'ds/IconButton';
import Layout from 'ds/Layout';
import { Props as TextInputProps } from 'ds/inputs/TextInput';
import { InputImageIconProps } from 'ds/inputs/types';

import MultiSelectBottomSheet from './MultiSelectBottomSheet';
import Typeahead from './Typeahead';

export type Props = Pick<
  TextInputProps,
  'label' | 'bigLabel' | 'size' | 'icon' | 'disabled' | 'onClear' | 'placeholder'
> &
  PartialPick<TextInputProps, 'onChange'> &
  Pick<InputImageIconProps, 'image'> & {
    noMatchesItem?: JSX.Element;
    values: string[];
    selections: string[];
    onSelect: (value: string) => void;
    onDelete: (value: string) => void;
    showResultsWhenEmpty?: boolean;
    maxItems?: number;
    highlightMatch?: boolean;
    required?: boolean;
    hideLabel?: boolean;
    error?: string;
    allowNewEntry?: boolean;
    onCreateNew?: VoidFunction;
    forceCapitalize?: boolean;
  };

const MultiSelect = ({
  size = 'sm',
  icon = 'search',
  label,
  onSelect,
  onDelete,
  values,
  selections,
  showResultsWhenEmpty = false,
  allowNewEntry = true,
  hideLabel = false,
  maxItems = 8,
  highlightMatch,
  disabled,
  placeholder,
  required = false,
  error,
  forceCapitalize = false,
  onChange
}: Props) => {
  const [query, setQuery] = useState<string>('');
  const { xs } = useContext(media);
  const [modalIsVisible, setModalIsVisible] = useState<boolean>(false);

  const remainingValues = values.filter(value => !selections.includes(value));
  const results = remainingValues
    .filter(value => value.toString().toLowerCase().includes(query.toLowerCase()))
    .map(value => ({ value, label: value }));
  const handleChange = (value: string) => {
    setQuery(forceCapitalize && value.length ? value.charAt(0).toUpperCase() + value.slice(1) : value);
  };
  const handleSelect = (value: string) => {
    if (selections?.includes(value)) return;

    onSelect(value);
    setQuery('');
  };

  return (
    <>
      {xs && (
        <MultiSelectBottomSheet
          onClose={() => setModalIsVisible(false)}
          isVisible={modalIsVisible}
          results={results}
          label={label}
          handleChange={handleChange}
          handleSelect={handleSelect}
          highlightMatch={highlightMatch}
          selections={selections}
          onDelete={onDelete}
          query={query}
        />
      )}
      <Layout marginTop={12}>
        <Typeahead
          size={size}
          label={label}
          icon={icon}
          onSelect={({ value }) => handleSelect(value)}
          results={results}
          onChange={params => {
            handleChange(params.value);
            onChange && onChange(params);
          }}
          query={query}
          allowNewEntry={allowNewEntry}
          hideLabel={hideLabel}
          showResultsWhenEmpty={showResultsWhenEmpty}
          maxItems={maxItems}
          highlightMatch={highlightMatch}
          disabled={disabled}
          placeholder={placeholder}
          required={required}
          error={error}
          onClear={() => setQuery('')}
          onFocus={() => setModalIsVisible(true)}
        />
      </Layout>
      <Layout paddingTop={4}>
        <Layout wrap>
          {selections?.map((name, index) => {
            return (
              <Layout
                key={name}
                paddingX={6}
                paddingY={2}
                borderRadius={4}
                align="center"
                color="gray-25"
                marginTop={12}
                marginLeft={index === 0 ? undefined : 4}
              >
                <p style={{ fontSize: 10, lineHeight: '16px' }}>{name}</p>
                <Layout marginLeft={6}>
                  <IconButton name="close" size="xs" type="noBackground" onClick={() => onDelete(name)} />
                </Layout>
              </Layout>
            );
          })}
        </Layout>
      </Layout>
    </>
  );
};

export default MultiSelect;
