import { Listing } from 'shared';

export const DEMO_SEARCH_WORKSPACE: Listing = {
  id: 1,
  capacity: 6,
  slug: 'best-codi',
  short_name: null,
  is_new: true,
  bookings: [],
  workspace_features: [],
  first_listed_at: '2022-04-28',
  monthly_price: 10000,
  square_feet: 5000,
  num_meeting_rooms: 1,
  distance: 2.4,
  listing_status: 'listed',
  is_recommended: true,
  availability_status: 'available',
  is_hot: true,
  availability_start_date: null,
  images: [
    {
      id: 1,
      key: 'ghost-workspace.jpg',
      filename: 'ghost-workspace.jpg',
      position: 0,
      published: true,
      filesize: 1250000
    }
  ],
  name: 'Bright Light Great Modern Workspace Bright Light Great Modern Workspace',
  address: {
    id: 1,
    line1: '1234 Geary Blvd',
    neighborhood: 'Japan Town',
    city: 'San Francisco',
    region: 'CA',
    postal_code: '94115',
    country: 'US',
    lat: 37.78559,
    lon: -122.43582,
    walk_score: null
  },
  daily_rate: 500,
  available_long_term: true,
  available_short_term: false,
  is_reserved: false
};
