import React from 'react';

import { Img, Layout, PillButton } from 'ds';
import { CUSTOMER_SERVICES_PATH } from 'routes';
import { actions as chatbotActions } from 'store/Chatbot';
import { selectSelectedOfficeId } from 'store/UI/selectors';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { CHATBOT_THREAD_VERTICAL_SPACING } from 'ux/Chatbot/constants';
import { ChatbotIntentProps } from 'ux/Chatbot/types';

import { LLMResponse } from '../LLMResponse';

interface Props extends Omit<ChatbotIntentProps, 'chatbotIntent'> {}

const OrderFurniture: React.FC<Props> = ({ chatbotInteraction }) => {
  const selectedOfficeId = useAppSelector(selectSelectedOfficeId);
  const dispatch = useAppDispatch();

  if (!selectedOfficeId) {
    // TODO: do something here? they might not even get here.
    return null;
  }

  const { llm_response } = chatbotInteraction;

  return (
    <Layout>
      <LLMResponse llmResponse={llm_response} />
      <Layout
        marginTop={CHATBOT_THREAD_VERTICAL_SPACING}
        color="white"
        borderRadius={16}
        direction="column"
        overflow="hidden"
      >
        <Img
          folder="ux"
          alt="Add-ons catalog"
          srcKey="furniture_widget_FX2j7nkDB.png"
          width={1026}
          style={{ objectFit: 'cover' }}
        />
        <Layout padding={16}>
          <PillButton
            type="secondary"
            size="md"
            text="View add-ons catalog"
            icon="externalLink"
            iconSize="xs"
            href={`${CUSTOMER_SERVICES_PATH}/${selectedOfficeId}/furniture`}
            onClick={() => dispatch(chatbotActions.setIsVisible(false))}
            fullWidth
          />
        </Layout>
      </Layout>
    </Layout>
  );
};

export default OrderFurniture;
