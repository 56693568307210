import React from 'react';

import { FORMATTED_TYPEAHEAD_RESULT_SIZE_CONFIG } from 'ds/ComboBox/constants';
import Layout from 'ds/Layout';
import Text from 'ds/Text';
import WorkspaceImg from 'ds/WorkspaceImg';
import { FieldSize } from 'ds/types';
import { useFieldSize } from 'hooks';

import { SearchableWorkspace } from './types';

import defaultImage from '../DefaultWorkspace.svg';
import { getHighlightedPartsOfText } from 'ds/utils';

interface Props {
  size?: FieldSize;
  query?: string;
  workspace: SearchableWorkspace;
  maxWidth: number;
}

const getHighlightedText = ({ text, query }: { text: string; query?: string }) => {
  if (!query) return text;

  const parts = getHighlightedPartsOfText({ text, query });

  return (
    <span>
      {parts.map((part, index) =>
        part.toLowerCase() === query.toLowerCase() ? <b key={`${index}${index}`}>{part}</b> : part
      )}
    </span>
  );
};

const WorkspaceResult: React.FC<Props> = ({
  maxWidth,
  query,
  workspace: { id, street_address, city_region_zip, image },
  ...props
}) => {
  const scaledSize = useFieldSize();
  const size = props.size || scaledSize;

  const highlightedStreetAddress = getHighlightedText({ text: street_address, query });
  const highlightedCityRegionZip = getHighlightedText({ text: city_region_zip, query });
  const highlightedId = getHighlightedText({ text: id.toString(), query });
  const { height, imageSize, labelFontSize } = FORMATTED_TYPEAHEAD_RESULT_SIZE_CONFIG[size];

  return (
    <Layout
      height={height}
      paddingX={16}
      paddingY={8}
      direction="row"
      justify="space-between"
      flexGrow={1}
      maxWidth={maxWidth}
    >
      <Layout
        minWidth={imageSize}
        width={imageSize}
        height={imageSize}
        borderRadius={4}
        overflow="hidden"
        marginBottom={2}
        flex
      >
        {!!image ? (
          <WorkspaceImg
            srcKey={image.key}
            alt={street_address}
            style={{ borderRadius: 4, userSelect: 'none' }}
            draggable={false}
            size="thumbnail"
          />
        ) : (
          <img src={defaultImage} alt="default" style={{ width: imageSize, height: imageSize }} />
        )}
      </Layout>
      <Layout minWidth={12} width={12} />
      <Layout direction="column" justify="center" align="flex-start" display="inline-block" flexGrow={1}>
        <Layout display="grid">
          <Text size={labelFontSize} ellipsis>
            {highlightedStreetAddress}
          </Text>
        </Layout>
        <Layout marginTop={2} />
        <Layout display="grid">
          <Text size="body3" color="gray-400" ellipsis>
            {highlightedCityRegionZip}
          </Text>
        </Layout>
      </Layout>
      <Layout minWidth={12} width={12} />
      <Text size="body3" color="gray-700">
        {highlightedId}
      </Text>
    </Layout>
  );
};

export default WorkspaceResult;
