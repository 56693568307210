import React from 'react';

import { Layout } from 'ds';

import ChatbotThreadCustomerMessage from './ChatbotThreadCustomerMessage';
import { CHATBOT_THREAD_VERTICAL_SPACING } from './constants';
import { ChatbotIntentProps } from './types';
import Conversation from './ChatbotWidgets/Conversation';

interface Props extends Omit<ChatbotIntentProps, 'chatbotIntent'> {}

const ChatbotInteractionDisplay: React.FC<Props> = ({
  chatbotInteraction,
  chatbotInteraction: { input },
  threadId
}) => {
  return (
    <Layout>
      <Layout marginLeft={24} justify="flex-end">
        <ChatbotThreadCustomerMessage>{input}</ChatbotThreadCustomerMessage>
      </Layout>
      <Layout marginTop={CHATBOT_THREAD_VERTICAL_SPACING}>
        <Conversation chatbotInteraction={chatbotInteraction} threadId={threadId} />
      </Layout>
    </Layout>
  );
};

export default ChatbotInteractionDisplay;
