import { PropsWithChildren } from 'react';
import { EditableImage } from 'shared';
import { IKImage } from 'imagekitio-react';
import { SortableContainer, SortableElement, SortableElementProps } from 'react-sortable-hoc';

import { THUMBNAIL_MARGIN_RIGHT, THUMBNAIL_RENDERED_HEIGHT, THUMBNAIL_RENDERED_WIDTH } from './constants';
import { OnPhotoChange } from './types';
import IconButton from '../IconButton';
import Layout from '../Layout';
import Text from '../Text';

const THUMBNAIL_MARGIN_TOP = 12;

interface SortablePhotoProps extends SortableElementProps {
  marginRight?: number;
}

const SortablePhoto = SortableElement<PropsWithChildren<SortablePhotoProps>>(
  ({ children, marginRight }: PropsWithChildren<SortablePhotoProps>) => (
    <li
      style={{
        listStyle: 'none',
        marginTop: THUMBNAIL_MARGIN_TOP,
        marginRight
      }}
    >
      {children}
    </li>
  )
);

interface SortablePhotoContainerProps {
  photos: EditableImage[];
  onChange: OnPhotoChange;
  thumbnailWidth: number;
  photosPerRow: number;
  thumbnailHeight: number;
  disabled: boolean;
}

const SortablePhotoContainer = SortableContainer<SortablePhotoContainerProps>(
  ({ photos, onChange, thumbnailWidth, photosPerRow, thumbnailHeight, disabled }: SortablePhotoContainerProps) => (
    <ul style={{ listStyleType: 'none', padding: 0, margin: 0, display: 'flex', flexWrap: 'wrap' }}>
      {photos.map((photo, index) => {
        return (
          <SortablePhoto
            key={photo.id}
            index={index}
            marginRight={(index + 1) % photosPerRow === 0 ? undefined : THUMBNAIL_MARGIN_RIGHT}
            disabled={disabled}
          >
            <Layout position="relative">
              <Layout position="absolute" top={4} right={4} cursor="pointer" zIndex={1}>
                <IconButton
                  name="close"
                  size="xs"
                  type="primary"
                  disabled={disabled}
                  onClick={() => {
                    const updatedPhotos = photos.filter((_, i) => i !== index);

                    if (updatedPhotos.length === 0) {
                      onChange({ images: [] });
                    } else {
                      onChange({
                        images: [...updatedPhotos]
                      });
                    }
                  }}
                />
              </Layout>
              {!!photo.key ? (
                <Layout
                  borderRadius={12}
                  overflow="hidden"
                  __style={{ width: thumbnailWidth, height: thumbnailHeight }}
                >
                  <IKImage
                    path={photo.key}
                    transformation={[
                      {
                        width: THUMBNAIL_RENDERED_WIDTH,
                        height: THUMBNAIL_RENDERED_HEIGHT
                      }
                    ]}
                    lqip={{ active: true }}
                  />
                </Layout>
              ) : (
                <img
                  style={{ borderRadius: 12, width: thumbnailWidth, height: thumbnailHeight }}
                  src={photo.url}
                  alt="workspace"
                />
              )}
              {index === 0 && (
                <Layout
                  borderRadius={12}
                  color="white"
                  position="absolute"
                  bottom={8}
                  left={4}
                  paddingX={8}
                  paddingY={4}
                >
                  <Text size="body3" scale>
                    Cover
                  </Text>
                </Layout>
              )}
            </Layout>
          </SortablePhoto>
        );
      })}
    </ul>
  )
);

export default SortablePhotoContainer;
