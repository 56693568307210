import { DateTime } from 'luxon';
import { titleize } from 'shared/strings';

export function dateToYyyyMmDd(date: Date): string {
  const offset = date.getTimezoneOffset();
  const dateWithOffset = new Date(date.getTime() - offset * 60 * 1000);
  return dateWithOffset.toISOString().split('T')[0];
}

export function yyyyMmDdToDate(date: string): Date {
  const [year, month, day] = date.split('-').map(part => parseInt(part));

  const unixTime = Date.UTC(year, month - 1, day);
  const localTzDate = new Date(unixTime);
  const offset = new Date(localTzDate.getTimezoneOffset() * 60 * 1000 + unixTime).getTimezoneOffset();

  return new Date(unixTime + offset * 60 * 1000);
}

// '2020-12-19T00:18:01.801Z' -> '2020-12-19'
export function isoDateToYyyyMmDd(date: string): string {
  return dateToYyyyMmDd(new Date(date));
  // TODO: make to local time option?
}

export function getAge(dateString: string) {
  var year = dateString.split('/')[2];
  if (year.startsWith('0')) {
    return 1000; // this should result in an invalid age error at the caller
  }
  var today = new Date();
  var birthDate = new Date(dateString);
  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
}

export function dateSort(a: string, b: string, ascending: boolean): number {
  return ascending ? a.localeCompare(b) : b.localeCompare(a);
}

// date in format YYYY-MM-DD
export function isDateInPast(date: string, includeToday: boolean = false): boolean {
  const comparison = date.localeCompare(dateToYyyyMmDd(new Date()));

  return includeToday ? comparison <= 0 : comparison < 0;
}

export function dateInRange(date: Date, startDate?: Date, endDate?: Date): boolean {
  if (startDate && endDate) {
    return date >= startDate && date <= endDate;
  } else if (startDate) {
    return date >= startDate;
  } else if (endDate) {
    return date <= endDate;
  } else {
    return false;
  }
}

export function addDays(date: Date, days: number): Date {
  const result = new Date(date);
  result.setDate(date.getDate() + days);

  return result;
}

export function daysAgo(dateInPast: Date) {
  const today = new Date();
  const difference = Math.abs(today.getTime() - dateInPast.getTime());
  const days = difference / (1000 * 3600 * 24);

  return Math.floor(days);
}

export function isToday(date: DateTime): boolean {
  return date.toISODate() === todayIsoDate();
}

export function isTomorrow(date: DateTime): boolean {
  return date.toISODate() === tomorrowIsoDate();
}

export function isYesterday(date: DateTime): boolean {
  return date.toISODate() === yesterdayIsoDate();
}

export function todayIsoDate() {
  return todayDateTime().toISODate();
}

export function tomorrowIsoDate() {
  return tomorrowDateTime().toISODate();
}

export function yesterdayIsoDate() {
  return yesterdayDateTime().toISODate();
}

export function todayDateTime() {
  return DateTime.local();
}

export function tomorrowDateTime() {
  return todayDateTime().plus({ days: 1 });
}

export function yesterdayDateTime() {
  return todayDateTime().minus({ days: 1 });
}

export const DATE_FORMATS: Record<DateTimeFormat, Intl.DateTimeFormatOptions> = {
  FULL_WITH_WEEKDAY: { weekday: 'long', month: 'long', day: 'numeric' },
  FULL_WITH_YEAR: DateTime.DATE_FULL,
  FULL_WITH_WEEKDAY_YEAR: DateTime.DATE_HUGE,
  FULL: { month: 'long', day: 'numeric' },
  MED_WITH_WEEKDAY: { weekday: 'long', month: 'short', day: 'numeric' },
  MED_WITH_SHORT_WEEKDAY: { weekday: 'short', month: 'short', day: 'numeric' },
  MED_WITH_YEAR: DateTime.DATE_MED,
  MED: { month: 'short', day: 'numeric' },
  SHORT: DateTime.DATE_SHORT,
  SHORTEST: { month: 'numeric', day: 'numeric' }
};

export type DateTimeFormat =
  | 'FULL_WITH_WEEKDAY'
  | 'FULL_WITH_YEAR'
  | 'FULL_WITH_WEEKDAY_YEAR'
  | 'MED_WITH_WEEKDAY'
  | 'MED_WITH_SHORT_WEEKDAY'
  | 'FULL'
  | 'MED'
  | 'SHORT'
  | 'SHORTEST'
  | 'MED_WITH_YEAR';

export function formatDate({
  date,
  format,
  timeZone = 'UTC'
}: {
  date: string | Date | DateTime;
  format: DateTimeFormat;
  timeZone?: string;
}): string {
  const dateTime = castToDateTime(date);
  const options = DATE_FORMATS[format];

  if (format.includes('WITH_WEEKDAY')) {
    return formatWithRelative({ dateTime, options: { ...options, timeZone } });
  } else {
    return dateTime.toLocaleString(options);
  }
}

function formatWithRelative({ dateTime, options }: { dateTime: DateTime; options: Intl.DateTimeFormatOptions }) {
  const parts = dateTime.toLocaleParts(options);
  const weekdayIndex = parts.findIndex(({ type }) => type === 'weekday');
  const weekdayPart = parts[weekdayIndex];

  if (!weekdayPart) return dateTime.toLocaleString(options);

  let relativeDate;

  if (isYesterday(dateTime)) {
    relativeDate = yesterdayDateTime();
  } else if (isToday(dateTime)) {
    relativeDate = todayDateTime();
  } else if (isTomorrow(dateTime)) {
    relativeDate = tomorrowDateTime();
  }

  if (relativeDate) {
    const relativeText = relativeDate.toRelativeCalendar();

    if (relativeText) {
      weekdayPart.value = titleize(relativeText);
    }
  }

  return parts
    .filter(p => p.value.length > 0)
    .map(p => p.value)
    .join('');
}

export function castToDateTime(date: string | Date | DateTime): DateTime {
  if (typeof date === 'string') {
    return DateTime.fromISO(date);
  } else if (date instanceof Date) {
    return DateTime.fromJSDate(date);
  } else {
    return date;
  }
}
