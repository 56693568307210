import React, { useContext, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { formatDate } from 'shared';
import qs from 'qs';
import { BreadcrumbList, CollectionPage, WithContext } from 'schema-dts';

import Head from 'components/Head';
import HeroImage from 'components/HeroImage';

import { app, media } from 'context';
import { Avatar, ButterImg, Icon, Layout, Link, Text } from 'ds';
import butter, { handleError } from 'lib/butter';

interface Props extends RouteComponentProps {}

const Landing: React.FC<Props> = ({ history }) => {
  const [blogResponse, setBlogResponse] = useState<any>();

  const { maxWidth, contentPaddingX } = useContext(app);
  const { xs } = useContext(media);
  const preview = qs.parse(history.location.search.replace('?', '').replace('/', '')).preview as string | null;

  useEffect(() => {
    butter.post
      .list({
        exclude_body: true,
        page_size: 100,
        preview: preview ? 1 : 0
      })
      .then(function (resp) {
        setBlogResponse(resp.data);
      })
      .catch(function (error) {
        handleError({ slug: 'posts', error });
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (!blogResponse) return <Layout minHeight="100vh" />;

  const getArticleLink = (slug: string) => {
    const previewSuffix = preview === '1' ? '?preview=1' : '';
    return `/blog/${slug}${previewSuffix}`;
  };

  const firstPost = blogResponse.data[0];
  const otherPosts = blogResponse.data.slice(1);

  const breadcrumbs: WithContext<BreadcrumbList> = {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: [
      {
        '@type': 'ListItem',
        position: 1,
        item: {
          '@id': `${window.location.origin}/#website`,
          name: 'Codi',
          url: window.location.origin
        }
      },
      {
        '@type': 'ListItem',
        position: 2,
        item: {
          '@id': `${window.location.origin}/#blog`,
          name: 'Codi Blog',
          url: window.location.href
        }
      }
    ]
  };

  const schema: WithContext<CollectionPage> = {
    '@context': 'https://schema.org',
    '@type': 'CollectionPage',
    breadcrumb: breadcrumbs,
    name: 'Codi Blog',
    url: window.location.href,
    description:
      'The official blog of the Codi community. Learn more about our office-as-a-service platform and commitment to positive company culture.',
    mainEntity: {
      '@type': 'ItemList',
      itemListElement: blogResponse.data.map((post: any, index: number) => ({
        '@type': 'ListItem',
        position: index + 1,
        item: {
          '@type': 'BlogPosting',
          '@id': `${window.location.origin}/blog/${post.slug}`,
          author: {
            '@type': 'Person',
            name: `${post.author.first_name} ${post.author.last_name}`,
            image: post.author.profile_image
          },
          headline: post.title,
          url: `${window.location.origin}/blog/${post.slug}`,
          datePublished: post.published,
          dateModified: post.updated,
          image: post.featured_image,
          thumbnail: post.featured_image
        }
      }))
    }
  };

  return (
    <>
      <Head
        title="Blog"
        description="The official blog of the Codi community. Learn more about our office-as-a-service platform and commitment to positive company culture."
      />
      <script type="application/ld+json">{JSON.stringify(schema)}</script>
      <HeroImage img={firstPost.featured_image} height={xs ? 408 : 500}>
        <Layout position="absolute" width="100%" height="100%" color="black" opacity={0.6} zIndex={1} />
        <Layout position="relative" width="100%" height="100%" justify="center" align="center" zIndex={2}>
          <Layout width="100%" __style={{ maxWidth }} paddingX={contentPaddingX}>
            <Text size="body2" color="white" scale>
              {formatDate({ date: new Date(firstPost.published), format: 'FULL_WITH_YEAR' })}
            </Text>
            <Layout marginTop={16}>
              <Link tag={xs ? undefined : 'h2'} color="white" href={getArticleLink(firstPost.slug)} bold>
                {firstPost.title}
              </Link>
            </Layout>
            <Layout direction="row" align="center" marginTop={16}>
              <Link color="white" href={getArticleLink(firstPost.slug)}>
                <Layout direction="row" align="center">
                  Read More{' '}
                  <Layout direction="row" align="center" marginLeft={8}>
                    <Icon name="rightArrow" color="white" size="xs" />
                  </Layout>
                </Layout>
              </Link>
            </Layout>
            <Layout direction="row" align="center" marginTop={16}>
              <Avatar size={24} avatarUrl={firstPost.author.profile_image} />
              <Layout marginLeft={8} align="center">
                <Text size="body2" color="white" scale>
                  Written by {firstPost.author.first_name} {firstPost.author.last_name}
                </Text>
              </Layout>
            </Layout>
          </Layout>
        </Layout>
      </HeroImage>
      <Layout marginTop={80} direction="column" width="100%">
        {otherPosts.map((post: any) => (
          <Layout
            key={post.slug}
            marginTop={xs ? 24 : 32}
            width="100%"
            borderRadius={20}
            borderWidth={1}
            borderColor="gray-50"
            direction={xs ? 'column' : 'row'}
            align="center"
            overflow="hidden"
          >
            <Layout
              position="relative"
              minWidth={xs ? '100%' : '40%'}
              width={xs ? '100%' : '40%'}
              height={xs ? 240 : 336}
              justify="center"
              align="center"
              color={!!post.featured_image ? undefined : 'gray-600'}
            >
              {post.featured_image && (
                <ButterImg
                  src={post.featured_image}
                  alt={post.featured_image_alt}
                  width={644}
                  style={{
                    position: 'absolute',
                    height: '100%',
                    objectFit: 'cover',
                    left: '50%',
                    transform: 'translate(-50%,0)',
                    border: 0
                  }}
                />
              )}
            </Layout>
            <Layout width="100%" paddingX={24} paddingY={16} direction="column" justify="center" align="flex-start">
              <Text size="body2" color="gray-600" scale>
                {formatDate({ date: new Date(post.published), format: 'FULL_WITH_YEAR' })}
              </Text>
              <Layout marginTop={16}>
                <Link tag={xs ? undefined : 'h3'} color="black" href={getArticleLink(post.slug)} bold>
                  {post.title}
                </Link>
              </Layout>
              <Layout direction="row" align="center" marginTop={16}>
                <Link color="blue-500" href={getArticleLink(post.slug)}>
                  <Layout direction="row" align="center" inline>
                    Read More{' '}
                    <Layout direction="row" align="center" marginLeft={8} inline>
                      <Icon name="rightArrow" color="blue-500" size="xs" />
                    </Layout>
                  </Layout>
                </Link>
              </Layout>
            </Layout>
          </Layout>
        ))}
      </Layout>
    </>
  );
};

export default Landing;
