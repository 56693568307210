import {
  OfficeLead,
  OfficeLeadLocation,
  OfficeLeadTour,
  OfficeLeadWorkspace,
  OfficeLeadWorkspaceStatus,
  User,
  Workspace,
  getDistance,
  sortByDate
} from 'shared';
import { uniq } from 'lodash';
import { DateTime } from 'luxon';

import { priceAdjustedForDaysPerWeek } from 'helpers/price';

import { headCountToSqft } from 'ds/Filters/utils';
import { CUSTOMER_OFFICE_REQUEST_PATH, SEARCH_PATH } from 'routes';

import { OfficeRequestFilters, ProspectSearchWorkspace } from './types';

export const officeLeadLocationToLocationPillLabels = ({ location }: { location: OfficeLeadLocation }) => {
  const { neighborhood, city } = location;
  const primaryLabel = neighborhood || city;
  const secondaryLabel = !!neighborhood ? city : undefined;
  return { primaryLabel, secondaryLabel };
};

export const officeLeadLocationToGeocodeQuery = ({ location }: { location: OfficeLeadLocation }) => {
  const { neighborhood, city, region, country } = location;
  const parts = [neighborhood, city, region, country].filter(part => !!part);
  return parts.join(', ');
};

export function findOfficeLeadInteraction({
  workspaceId,
  officeLeads,
  userId,
  status
}: {
  workspaceId: number;
  officeLeads: OfficeLead[];
  userId?: number;
  status: OfficeLeadWorkspaceStatus;
}) {
  for (const officeLead of officeLeads) {
    const interaction = officeLead.workspaces
      .map(ws => ws.user_interactions)
      .flat()
      .find(i => i.workspace_id === workspaceId && (userId ? i.user.id === userId : true) && i.status === status);

    if (interaction) return interaction;
  }
}

export const filterToProposedWorkspaces = ({
  workspaces,
  userId
}: {
  workspaces: OfficeLeadWorkspace[];
  userId: number;
}): OfficeLeadWorkspace[] => {
  return workspaces.filter(ws => {
    const interaction = ws.user_interactions.find(i => i.user.id === userId);

    return interaction?.status === 'proposed';
  });
};

export const getListWorkspaces = ({ officeLead: { tours, workspaces } }: { officeLead: OfficeLead }) => {
  const upcomingTourDestinationsAndRequestWorkspaceIds = tours
    .filter(t => t.status !== 'completed')
    .map(t => [...t.destinations.map(d => d.workspace.id), ...t.destination_requests.map(d => d.workspace.id)])
    .flat();
  return workspaces.filter(
    ws =>
      ws.user_interactions.some(ui => !!ui.status && ['added'].includes(ui.status)) ||
      upcomingTourDestinationsAndRequestWorkspaceIds.includes(ws.id)
  );
};

export const getMostRelevantTour = (tours: OfficeLeadTour[]): OfficeLeadTour | undefined => {
  const nextUpcomingTour = getNextUpcomingTour(tours);

  const mostRecentCompletedTour = getMostRecentCompletedTour(tours);

  return nextUpcomingTour || mostRecentCompletedTour;
};

export const getNextUpcomingTour = (tours: OfficeLeadTour[]): OfficeLeadTour | undefined => {
  return getUpcomingTours(tours)[0];
};

export const getUpcomingTours = (tours: OfficeLeadTour[]): OfficeLeadTour[] => {
  return tours
    .filter(t => ['pending', 'scheduled'].includes(t.status) && DateTime.fromISO(t.ends_at) > DateTime.now())
    .sort((t1, t2) => sortByDate(t1.starts_at, t2.starts_at));
};

export const getUpcomingTourForWorkspace = ({
  tours,
  workspaceId
}: {
  tours: OfficeLeadTour[];
  workspaceId: number;
}): OfficeLeadTour | undefined => {
  const upcomingTours = getUpcomingTours(tours);
  return (
    upcomingTours.find(t => t.destinations.map(d => d.workspace.id).includes(workspaceId)) ||
    upcomingTours.find(t => t.destination_requests.map(d => d.workspace.id).includes(workspaceId))
  );
};

export const getMostRecentCompletedTour = (tours: OfficeLeadTour[]): OfficeLeadTour | undefined => {
  return tours.filter(t => t.status === 'completed').sort((t1, t2) => sortByDate(t2.ends_at, t1.ends_at))[0];
};

export const getTourForWorkspace = ({
  tours,
  workspaceId
}: {
  tours: OfficeLeadTour[];
  workspaceId: number;
}): OfficeLeadTour | undefined => {
  const upcomingTours = tours
    .filter(t => ['pending', 'scheduled'].includes(t.status))
    .sort((t1, t2) => sortByDate(t1.starts_at, t2.starts_at));
  const upcomingTour =
    upcomingTours.find(t => t.destinations.map(d => d.workspace.id).includes(workspaceId)) ||
    upcomingTours.find(t => t.destination_requests.map(d => d.workspace.id).includes(workspaceId));

  const completedTours = tours
    .filter(t => t.status === 'completed')
    .sort((t1, t2) => sortByDate(t2.ends_at, t1.ends_at));
  const completedTour = completedTours.find(t => t.destinations.map(d => d.workspace.id).includes(workspaceId));

  return upcomingTour || completedTour;
};

export const officeSearchFilterWorkspaces = ({
  workspaces,
  filters: { minMonthlyBudget, maxMonthlyBudget, headCount, numMeetingRooms },
  daysPerWeek
}: {
  workspaces: ProspectSearchWorkspace[];
  filters: OfficeRequestFilters;
  daysPerWeek: number;
}) => {
  const minSqft = headCount ? headCountToSqft(headCount) : undefined;

  return workspaces.filter(ws => {
    const price = priceAdjustedForDaysPerWeek({ monthlyPrice: ws.monthly_price, daysPerWeek: daysPerWeek || 5 });
    if (minMonthlyBudget && price < minMonthlyBudget) return false;
    if (maxMonthlyBudget && price > maxMonthlyBudget) return false;
    if (minSqft && ws.square_feet < minSqft) return false;
    if (numMeetingRooms && ws.num_meeting_rooms < numMeetingRooms) return false;

    return true;
  });
};

export const officeSearchSortWorkspace = ({
  workspaces,
  sortValue
}: {
  workspaces: ProspectSearchWorkspace[];
  sortValue: string;
}) => {
  const sortedWorkspaces = [...workspaces];

  switch (sortValue) {
    case 'recommended':
      return sortedWorkspaces.sort((ws1, ws2) => {
        if (ws1.availability_status === 'not_available' && ws2.availability_status !== 'not_available') return 1;
        if (ws1.availability_status !== 'not_available' && ws2.availability_status === 'not_available') return -1;

        if (ws1.is_hot && !ws2.is_hot) return -1;
        if (!ws1.is_hot && ws2.is_hot) return 1;

        if (ws1.is_recommended && !ws2.is_recommended) return -1;
        if (!ws1.is_recommended && ws2.is_recommended) return 1;

        return ws1.monthly_price > ws2.monthly_price ? -1 : 1;
      });
    case 'price_asc':
      return sortedWorkspaces.sort((a, b) => a.monthly_price - b.monthly_price);
    case 'price_desc':
      return sortedWorkspaces.sort((a, b) => b.monthly_price - a.monthly_price);
    case 'popular':
      return sortedWorkspaces;
    default:
      return sortedWorkspaces;
  }
};

export const getClosestLeadInRange = async ({
  officeLeads,
  workspace: {
    monthly_price,
    address: { lat, lon, city, region, country }
  }
}: {
  officeLeads: OfficeLead[];
  workspace: Pick<ProspectSearchWorkspace, 'address' | 'monthly_price'>;
}) => {
  const matchedCityLeads = officeLeads.filter(lead =>
    lead.locations.filter(
      location => location.city === city && location.region === region && location.country === country
    )
  );
  const filteredLeads = matchedCityLeads.length ? matchedCityLeads : officeLeads;
  // const leadsWithLoc = await assignLocToLeads(filteredLeads);

  const leadsWithDistance = filteredLeads
    .filter(lead => !!lead.locations[0].loc)
    .map(lead => ({ ...lead, distance: getDistance({ lat, lng: lon }, lead.locations[0].loc || { lat: 0, lng: 0 }) }))
    .filter(lead => lead.distance < 100);
  const sortedLeads = leadsWithDistance.sort((lead1, lead2) => lead1.distance - lead2.distance);

  if (sortedLeads.length > 1) {
    const [lead1, lead2] = sortedLeads;
    // If leads are the same, return more recent lead
    if (lead1.distance === lead2.distance && lead1.max_monthly_budget === lead2.max_monthly_budget) {
      return DateTime.fromISO(lead1.created_at) > DateTime.fromISO(lead2.created_at) ? lead1 : lead2;
    } else {
      // Otherwise return lead with budget closer to monthly price
      return Math.abs(lead1.max_monthly_budget - monthly_price) < Math.abs(lead2.max_monthly_budget - monthly_price)
        ? lead1
        : lead2;
    }
  } else {
    return sortedLeads[0];
  }
};

export function getBoundsForWorkspaces(workspaces: Pick<Workspace, 'address'>[]) {
  const bounds = new google.maps.LatLngBounds();

  workspaces.forEach(({ address: { lat, lon } }) => {
    bounds.extend(new google.maps.LatLng(lat, lon));
  });

  return bounds;
}

export function getSearchLinkProps({ user }: { user: User }) {
  const officeLead = user.office_leads.length ? user.office_leads.slice(-1)[0] : null;
  const searchLinkProps = officeLead
    ? { pathname: `${CUSTOMER_OFFICE_REQUEST_PATH}/${officeLead.id}`, search: 'search=1' }
    : { pathname: SEARCH_PATH };

  return searchLinkProps;
}

export function getWorkspaceIdsForTour(tour: OfficeLeadTour) {
  return uniq([...tour.destinations, ...tour.destination_requests].map(t => t.workspace.id));
}

export function isRedirectToOfficeRequests({ user }: { user: User }) {
  return !user.offices.length && user.office_leads.length === 1;
}
