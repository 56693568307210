import React, { useState } from 'react';
import { BLUE_500_HEX, Image } from 'shared';

import Clickable from 'ds/Clickable';
import Layout from 'ds/Layout';
import Modal from 'ds/Modal';
import Text from 'ds/Text';
import { ListingCTAProps } from 'ux/Public/Listing/types';

import FloorPlans from './FloorPlans';
import Photos from './Photos';
import VideoTour from './VideoTour';
import { MEDIA_TABS, MediaTab } from '../constants';

type Props = {
  images: Image[];
  imageIndex: number;
  floorPlans: Image[];
  matterportId: string | null;
  tourVideoKey: string | null;
  onClose: VoidFunction;
  setImageIndex: (value: number) => void;
  modalZIndex?: number;
} & ListingCTAProps;

const Desktop: React.FC<Props> = ({
  images,
  imageIndex,
  floorPlans,
  matterportId,
  tourVideoKey,
  setImageIndex,
  onClose,
  modalZIndex,
  ...props
}) => {
  const [selectedTab, setSelectedTab] = useState<MediaTab>('Photos');

  const mediaTabs = [...MEDIA_TABS];
  if (!matterportId) {
    mediaTabs.splice(mediaTabs.indexOf('3D Walkthrough'), 1);
  }
  if (!tourVideoKey) {
    mediaTabs.splice(mediaTabs.indexOf('Video Tour'), 1);
  }
  if (!floorPlans.length) {
    mediaTabs.splice(mediaTabs.indexOf('Floor Plans'), 1);
  }

  return (
    <Modal
      isFullScreen
      size="lg"
      onClose={onClose}
      showCloseButton
      isVisible={imageIndex > -1}
      zIndexPosition={modalZIndex}
    >
      <Layout direction="column" width="100%" height="100%">
        <Layout width="100%" height={64}>
          {mediaTabs.map((tab, index) => {
            const selected = selectedTab === tab;

            return (
              <Clickable
                key={tab}
                onClick={() => {
                  setSelectedTab(tab);
                }}
                style={{
                  paddingTop: 24,
                  display: 'inline-block',
                  paddingBottom: 12,
                  marginLeft: index === 0 ? 24 : 36,
                  borderBottom: selected ? `4px solid ${BLUE_500_HEX}` : undefined
                }}
              >
                <Text color={selected ? 'blue-500' : 'gray-500'} semibold={selected} size="body1">
                  {tab}
                </Text>
              </Clickable>
            );
          })}
        </Layout>
        {selectedTab === 'Photos' && (
          <Photos
            images={images}
            imageIndex={imageIndex}
            matterportId={matterportId}
            tourVideoKey={tourVideoKey}
            setImageIndex={setImageIndex}
            show3DWalkthroughTab={() => setSelectedTab('3D Walkthrough')}
            showVideoTourTab={() => setSelectedTab('Video Tour')}
            {...props}
          />
        )}
        {selectedTab === '3D Walkthrough' && (
          <Layout marginTop={16} width="100%" height="100%">
            <iframe
              title="3D Walkthrough"
              width="100%"
              height="100%"
              src={`https://my.matterport.com/show/?m=${matterportId}&play=1&qs=1&tiles=1&vr=0&title=0&help=2&tourcta=2&hlr=2&rf-experience=`}
              frameBorder="0"
              allowFullScreen
              allow="xr-spatial-tracking"
            ></iframe>
          </Layout>
        )}
        {selectedTab === 'Video Tour' && !!tourVideoKey && <VideoTour tourVideoKey={tourVideoKey} />}
        {selectedTab === 'Floor Plans' && (
          <FloorPlans floorPlans={floorPlans} setImageIndex={setImageIndex} {...props} />
        )}
      </Layout>
    </Modal>
  );
};

export default Desktop;
