import React from 'react';
import { TaskStatus, titleize } from 'shared';

import Circle from 'ds/Circle';
import Icon from 'ds/Icon';
import Layout from 'ds/Layout';
import Text from 'ds/Text';
import { TASK_STATUS_TO_COLOR_PROPS } from 'ds/constants';

interface Props {
  status: TaskStatus;
}

const VendorTaskStatusDisplay: React.FC<Props> = ({ status }) => {
  const { backgroundColor, dotColor, useOutlineDot, textColor, label } = TASK_STATUS_TO_COLOR_PROPS[status];

  const formatStatus = (status: string) => {
    return titleize(status.replace(/_/g, ' '));
  };

  const isCompletable = ['overdue', 'scheduled_completable'].includes(status);

  return (
    <Layout
      color={backgroundColor}
      borderRadius={4}
      paddingLeft={14}
      paddingRight={12}
      paddingY={isCompletable ? 6 : 8}
      align="center"
      justify="center"
    >
      {useOutlineDot ? (
        <Layout width={8} height={8} borderRadius="circular" borderColor={dotColor} borderWidth={1} marginRight={6} />
      ) : (
        <Circle dimension={8} color={dotColor} marginRight={6} />
      )}
      <Text size="tag" color={textColor} bold>
        {label || formatStatus(status)}
      </Text>
      {isCompletable && (
        <Layout marginLeft={4} display="inline-flex">
          <Icon name="downCaret" color={dotColor} size={16} />
        </Layout>
      )}
    </Layout>
  );
};

export default VendorTaskStatusDisplay;
