import React, { FocusEventHandler, useState } from 'react';
import { Color, IconName, colorToRgba, validatePresence } from 'shared';
import classNames from 'classnames';

import useFieldSize from 'hooks/useFieldSize';
import { track } from 'lib/analytics';

import Icon from './Icon';
import { DS_CLASS_NAME } from './constants';
import useUniqueId from './helpers/useUniqueId';
import { ValidateProp } from './inputs/types';
import { FieldSize } from './types';

export interface Props<T extends string | number = string> extends ValidateProp {
  // value that is selected
  value?: T;
  label?: string;
  required?: boolean;
  options: Option<T>[];
  onChange: OnChange<T>;
  onFocus?: FocusEventHandler<HTMLSelectElement>;
  onBlur?: FocusEventHandler<HTMLSelectElement>;
  size?: FieldSize;
  icon?: IconName;
  iconColor?: Color;
  textColor?: 'gray-900' | 'gray-300';
  fullWidth?: boolean;
  disabled?: boolean;
  semibold?: boolean;
  autofocus?: boolean;
  name?: string;
  backgroundColor?: Color;
  backgroundOpacity?: number;
  hideCaret?: boolean;
  hideSmallLabel?: boolean;
  inputStyle?: 'inline' | 'default';
  inlineLabelWidth?: string | number;
  placeholder?: string;
}

export type OnChange<T extends string | number> = (params: OnChangeParams<T>) => void;

interface OnChangeParams<T extends string | number> {
  event: React.ChangeEvent<HTMLSelectElement>;
  value: T;
}

export interface Option<T extends string | number> {
  value: T;
  label: string;
}

const Select = <T extends string | number>({
  required,
  options,
  onChange,
  onValidate,
  value,
  label,
  icon,
  iconColor,
  fullWidth,
  semibold = false,
  disabled = false,
  autofocus = false,
  textColor = 'gray-900',
  name = '',
  backgroundColor,
  backgroundOpacity = 1,
  hideCaret,
  inputStyle = 'default',
  inlineLabelWidth,
  placeholder,
  ...props
}: Props<T>) => {
  const id = useUniqueId();
  const [isFocused, setIsFocused] = useState<boolean>(autofocus);
  const scaledSize = useFieldSize();
  const size = props.size || scaledSize;
  const isInline = inputStyle === 'inline';
  const hideSmallLabel = isInline || props.hideSmallLabel;
  const [showError, setShowError] = useState(false);
  const validate = (value?: string | number) => {
    const validationResult = validatePresence({
      fieldName: label || '',
      value: value ? value.toString() : undefined,
      required: !!required
    });
    onValidate && onValidate(validationResult);

    if (validationResult.valid) {
      setShowError(false);
    } else {
      setShowError(true);
    }

    return validationResult.error;
  };

  const firstOption = options[0];
  const isNumberValue = typeof firstOption.value === 'number';

  const onChangeWrapper = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.target;

    onChange({ event: e, value: (isNumberValue ? Number(value) : value) as T });
    validate(value);

    const option = options.find(o => o.value === value);
    track('Element Interacted', { type: 'select', value: option?.label, name, context: 'selection' });
  };

  const hasValue = value !== undefined && value !== '';

  const element = (
    <div
      style={{
        backgroundColor: !!backgroundColor
          ? disabled
            ? 'gray-25'
            : colorToRgba(backgroundColor, backgroundOpacity)
          : undefined
      }}
      className={classNames(DS_CLASS_NAME, 'Select', `is-size-${size}`, `is-color-${textColor}`, {
        'is-full-width': fullWidth,
        'has-icon': !!icon,
        'has-label': !!label && !hideSmallLabel,
        'has-value': hasValue,
        'is-semibold': semibold,
        'is-disabled': disabled,
        'is-focused': isFocused,
        'is-inline': isInline,
        'has-error': showError
      })}
    >
      {icon && iconColor && (
        <span className="Select-iconContainer">
          <Icon name={icon} color={iconColor} size={size} />
        </span>
      )}
      {!hideCaret && (
        <span className="Select-caretContainer">
          <Icon name="downCaret" color={isFocused ? 'blue-500' : 'gray-900'} size={20} />
        </span>
      )}
      {label && !hideSmallLabel && hasValue && (
        <label className={classNames('Select-label', { 'has-value': hasValue })}>
          {label} {required && ' *'}
        </label>
      )}
      <select
        className={classNames('Select-select', {
          'has-label': !!label && !hideSmallLabel,
          'has-value': hasValue,
          'is-caret-hidden': hideCaret
        })}
        onClick={e => e.stopPropagation()}
        onChange={e => {
          onChangeWrapper(e);
        }}
        value={hasValue ? value : label || undefined}
        disabled={disabled}
        onFocus={e => {
          setIsFocused(true);
          const option = options.find(o => o.value === value);

          props.onFocus && props.onFocus(e);
          track('Element Interacted', { type: 'select', value: option?.label, name, context: 'open' });
        }}
        onBlur={e => {
          setIsFocused(false);
          validate(value);

          props.onBlur && props.onBlur(e);
        }}
        autoFocus={autofocus}
      >
        {(placeholder || label) && (
          <option disabled value={label} className="Select-labelOption">
            {placeholder || label}
            {required && !placeholder ? ' *' : ''}
          </option>
        )}
        {options.map(({ value, label }) => (
          <option key={value} className="Select-option" value={value}>
            {label}
          </option>
        ))}
      </select>
    </div>
  );

  return isInline ? (
    <div className={classNames(DS_CLASS_NAME, 'Select-outerContainer', `is-size-${size}`, { 'is-inline': isInline })}>
      <span
        style={{
          width: inlineLabelWidth
        }}
        className="Select-inlineLabelContainer"
      >
        <label htmlFor={id}>
          {label}
          {required ? ' *' : ''}
        </label>
      </span>
      {element}
    </div>
  ) : (
    element
  );
};

export default Select;
