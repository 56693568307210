import React from 'react';
import { ListingWorkspace } from 'shared';

import { Layout, Text } from 'ds';

interface Props {
  workspace: ListingWorkspace;
}

const About: React.FC<Props> = ({ workspace: { description, workspace_features } }) => {
  return (
    <>
      <Text size="h5" color="gray-900" scale>
        About this office space
      </Text>
      <Layout marginTop={24} />
      <Text size="body1" color="gray-900" scale>
        {description}
      </Text>
      {!!workspace_features.length && (
        <Layout marginTop={12} flex wrap>
          {workspace_features.map(({ id, name }) => {
            return (
              <Layout
                marginTop={12}
                marginRight={12}
                key={id}
                paddingY={1}
                paddingX={6}
                color="gray-50"
                borderRadius={2}
              >
                <Text size="body2">{name}</Text>
              </Layout>
            );
          })}
        </Layout>
      )}
    </>
  );
};
export default About;
