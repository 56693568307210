import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { LatLng, Listing, searchWorkspaces } from 'shared';

import { Layout, Map as DSMap } from 'ds';
import { LIGHT_MAP_STYLES } from 'ds/map/constants';
import { SEARCH_PATH } from 'routes';
import { AppLocation } from 'store/App/types';
import { actions } from 'store/Search';
import { selectUser } from 'store/User/selectors';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { generateSearch } from 'ux/Search/utils';

interface Props {
  lat: number;
  lng: number;
  location: AppLocation;
}

const Map: React.FC<Props> = ({ lat, lng, location: { name, region } }) => {
  const [workspaces, setWorkspaces] = useState<Listing[]>([]);
  const isLoggedIn = !!useAppSelector(selectUser);
  const history = useHistory();
  const dispatch = useAppDispatch();
  const handleClick = ({
    center,
    zoom,
    selectedWorkspaceId
  }: {
    center: LatLng;
    zoom: number;
    selectedWorkspaceId?: number;
  }) => {
    dispatch(actions.updateFilters({ daysPerWeek: 5 }));

    history.push({
      pathname: SEARCH_PATH,
      search: generateSearch({
        search: '',
        center,
        location: `${name}, ${region}`,
        zoom,
        selectedWorkspaceId
      }),
      state: {
        from: { pathname: history.location.pathname }
      }
    });
  };

  return (
    <Layout width="100%" height="100%">
      <DSMap
        zoom={13}
        center={{ lat, lng }}
        options={{
          disableDoubleClickZoom: true,
          disableDefaultUI: true,
          draggable: false,
          scrollwheel: false,
          styles: LIGHT_MAP_STYLES
        }}
        onChange={({ bounds, center }) => {
          searchWorkspaces({ bounds, center }).then(({ data: workspaces }) => setWorkspaces(workspaces));
        }}
        key={isLoggedIn.toString()}
        onClick={() => handleClick({ center: { lat, lng }, zoom: 12 })}
        markerType={isLoggedIn ? 'price' : undefined}
        daysPerWeek={5}
        workspaces={workspaces}
        onMarkerClick={({
          workspace: {
            id,
            address: { lat, lon: lng }
          }
        }) => handleClick({ center: { lat, lng }, zoom: 14, selectedWorkspaceId: id })}
      />
    </Layout>
  );
};

export default Map;
