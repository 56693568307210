import React, { useState } from 'react';
import { BLUE_500_HEX, GRAY_50_HEX } from 'shared';

import { Clickable, Icon, Layout, PillButton, Text } from 'ds';
import { actions } from 'store/Chatbot';
import { selectActiveChatbotThread } from 'store/Chatbot/selectors';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { updateIntent } from 'ux/Chatbot/requests';
import { ChatbotIntent, ChatbotInteraction } from 'ux/Chatbot/types';

interface Props {
  chatbotInteraction: ChatbotInteraction;
  chatbotIntent: ChatbotIntent;
}

const SelectRecurrenceType: React.FC<Props> = ({ chatbotInteraction, chatbotIntent }) => {
  const dispatch = useAppDispatch();
  const thread = useAppSelector(selectActiveChatbotThread);

  const {
    slots,
    slots: { recurrenceType }
  } = chatbotIntent;

  const [updatedRecurrenceType, setUpdatedRecurrenceType] = useState(recurrenceType);
  const isOneOff = updatedRecurrenceType === 'one-off';
  const isRecurring = updatedRecurrenceType === 'recurring';

  return (
    <Layout marginTop={16} padding={16} color="white" borderRadius={16} flexGrow={1}>
      <Text size="body-md" semibold>
        Select cleaning type
      </Text>
      <Layout marginTop={16} />
      <Clickable
        color={isOneOff ? 'blue-50' : 'white'}
        style={{
          padding: isOneOff ? '12px 16px 12px 16px' : '13px 17px 13px 17px',
          borderWidth: isOneOff ? 2 : 1,
          borderColor: isOneOff ? BLUE_500_HEX : GRAY_50_HEX,
          borderStyle: 'solid',
          width: '100%',
          borderRadius: 8,
          display: 'flex',
          flexDirection: 'row',
          alignContent: 'flex-start',
          alignItems: 'center'
        }}
        hoverColor={isOneOff ? 'blue-50' : 'gray-50'}
        onClick={() => {
          setUpdatedRecurrenceType('one-off');
        }}
      >
        <Icon name="calendar" size="sm" />
        <Layout marginLeft={8} />
        <Text size="body-sm" align="left" semibold>
          One-off
        </Text>
      </Clickable>
      <Layout marginTop={4} />
      <Clickable
        color={isRecurring ? 'blue-50' : 'white'}
        style={{
          padding: isRecurring ? '12px 16px 12px 16px' : '13px 17px 13px 17px',
          borderWidth: isRecurring ? 2 : 1,
          borderColor: isRecurring ? BLUE_500_HEX : GRAY_50_HEX,
          borderStyle: 'solid',
          width: '100%',
          borderRadius: 8,
          display: 'flex',
          flexDirection: 'row',
          alignContent: 'flex-start',
          alignItems: 'center'
        }}
        hoverColor={isRecurring ? 'blue-50' : 'gray-50'}
        onClick={() => {
          setUpdatedRecurrenceType('recurring');
        }}
      >
        <Icon name="refresh" size="sm" />
        <Layout marginLeft={8} />
        <Text size="body-sm" align="left" semibold>
          Recurring
        </Text>
      </Clickable>
      <Layout marginTop={16} />
      <PillButton
        type="purpleGradient"
        size="md"
        text="Next"
        icon="rightArrow"
        iconPosition="right"
        iconSize="xs"
        iconStroke={2}
        disabled={!updatedRecurrenceType}
        onClick={() => {
          if (!thread) return;

          updateIntent({
            chatbot_interaction_id: chatbotInteraction.id,
            slots: { ...slots, recurrenceType: updatedRecurrenceType }
          }).then(({ data }) => {
            dispatch(actions.updateInteraction({ threadId: thread.id, interaction: data }));
          });
        }}
        fullWidth
      />
    </Layout>
  );
};

export default SelectRecurrenceType;
