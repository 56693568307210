import React from 'react';
import { Message } from 'shared';

import Layout from 'ds/Layout';

import MessageElement from './Message';
import MessageThreadInput from './MessageThreadInput';
import { CreatableMessage } from './types';

interface Props {
  messages: Message[];
  onSubmit: (message: CreatableMessage) => Promise<void>;
}

const MessageThread: React.FC<Props> = ({ messages, onSubmit }) => {
  return (
    <Layout>
      {messages.map((message, index) => (
        <Layout key={message.id} marginTop={index > 0 ? 24 : 0}>
          <MessageElement message={message} />
        </Layout>
      ))}
      <Layout marginTop={8}>
        <MessageThreadInput onSubmit={onSubmit} />
      </Layout>
    </Layout>
  );
};

export default MessageThread;
