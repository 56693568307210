import { LatLng } from './types';
import axios from 'axios';

const GOOGLE_API_URL = 'https://maps.google.com/maps/api';

let googleApiKey: null | string = null;

export function setGoogleApiKey(key: string) {
  googleApiKey = key;
}

export interface TimeZone {
  timeZoneId: string;
  timeZoneName: string;
  dstOffset: number;
  rawOffset: number;
}

export async function getTimeZone(lat: number, lon: number): Promise<TimeZone | undefined> {
  apiKeyCheck();

  const timeZoneUrl = `${GOOGLE_API_URL}/timezone/json?location=${lat},${lon}&timestamp=0&key=${googleApiKey}`;
  try {
    const data = await axios.get(timeZoneUrl);
    return data.data;
  } catch (data) {}
}

const ADDRESS_ATTR = {
  administrative_area_level_1: ['short_name', 'region'],
  locality: ['long_name', 'city'],
  postal_code: ['long_name', 'postal_code'],
  route: ['short_name', 'route'],
  street_number: ['long_name', 'street_number'],
  neighborhood: ['long_name', 'neighborhood'],
  country: ['short_name', 'country']
};

const FALLBACK_ADDRESS_ATTR = {
  sublocality_level_1: ['long_name', 'city'],
  postal_town: ['long_name', 'city'],
  postal_code_prefix: ['long_name', 'postal_code']
};

type AddressAttrKey = keyof typeof ADDRESS_ATTR;
type FallbackAttrKey = keyof typeof FALLBACK_ADDRESS_ATTR;

export async function geocodeToAddress(addr: string) {
  apiKeyCheck();

  let url = `${GOOGLE_API_URL}/geocode/json?address=${encodeURI(`${addr}`)}&key=${googleApiKey}`;
  const result = await axios.get<{ results: google.maps.GeocoderResult[] }>(url);
  const geocoderResult = result.data.results[0];

  const address = googleResultToCodiAddress(geocoderResult);

  try {
    return Promise.resolve(address);
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function geocode(query: string) {
  apiKeyCheck();

  let url = `${GOOGLE_API_URL}/geocode/json?address=${encodeURI(`${query}`)}&key=${googleApiKey}`;
  const result = await axios.get<{ results: google.maps.GeocoderResult[] }>(url);
  const geocoderResult = result.data.results[0];

  try {
    return Promise.resolve(geocoderResult);
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function reverseGeocode({ latLng: { lat, lng } }: { latLng: LatLng }) {
  apiKeyCheck();

  let url = `${GOOGLE_API_URL}/geocode/json?latlng=${lat},${lng}&key=${googleApiKey}`;
  const results = await axios.get<{ results: google.maps.GeocoderResult[] }>(url);
  const result = results.data.results[0];

  return result;
}

function apiKeyCheck() {
  if (!googleApiKey) throw new Error('Google Maps API key is not set. Set with setGoogleApiKey function.');
}

export function locToPoint({ lat, lon }: { lat: number; lon: number }): string {
  return `POINT (${lon} ${lat})`;
}

export interface GeocodeResult {
  place_id: string;
  postal_code: string;
  city: string;
  region: string;
  country: string;
  neighborhood: string | null;
  line1: string;
  lat: number;
  lon: number;
  loc: string;
  time_zone_id: string;
  formatted_address: string;
}

export async function googleResultToCodiAddress(
  result: google.maps.places.PlaceResult | google.maps.GeocoderResult
): Promise<GeocodeResult> {
  const place_id = result.place_id!;
  const addressComponents = result.address_components!;
  const addressResult: any = {};
  addressComponents.forEach(ac => {
    const componentType = ac.types[0] as AddressAttrKey;
    if (ADDRESS_ATTR[componentType]) {
      addressResult[ADDRESS_ATTR[componentType][1]] =
        ac[ADDRESS_ATTR[componentType][0] as keyof google.maps.GeocoderAddressComponent];
    }
  });
  if (!addressResult.city || !addressResult.postal_code) {
    for (let attr of addressComponents) {
      for (let type of attr.types) {
        if (FALLBACK_ADDRESS_ATTR[type as FallbackAttrKey]) {
          addressResult[FALLBACK_ADDRESS_ATTR[type as FallbackAttrKey][1]] =
            attr[FALLBACK_ADDRESS_ATTR[type as FallbackAttrKey][0] as keyof google.maps.GeocoderAddressComponent];
        }
      }
    }
  }
  let timeZoneId;
  const location: google.maps.LatLng | google.maps.LatLngBoundsLiteral = result!.geometry!.location!;
  let lat, lon;

  if (isLatLngLiteral(location)) {
    lat = location.lat;
    lon = location.lng;
  } else {
    lat = location.lat();
    lon = location.lng();
  }

  const timezoneObj = await getTimeZone(lat, lon);
  timeZoneId = timezoneObj?.timeZoneId;

  const { postal_code = '', city = '', region = '', country = '', street_number, route } = addressResult;
  let neighborhood = addressResult.neighborhood || null;

  if (!neighborhood) {
    await new google.maps.Geocoder().geocode({ location }, results => {
      if (!results) return;

      for (let i = 0; i < results.length; i++) {
        const result = results[i];
        for (let j = 0; j < result.address_components.length; j++) {
          const component = result.address_components[j];
          if (component.types.includes('neighborhood')) {
            neighborhood = component.long_name;
            i = results.length;
          }
        }
      }
    });
  }

  return {
    place_id,
    postal_code,
    city,
    region,
    country,
    neighborhood: neighborhood || null,
    line1: street_number && route ? `${street_number} ${route}` : '',
    lat,
    lon,
    time_zone_id: timeZoneId || '',
    loc: locToPoint({ lat, lon }),
    formatted_address: result.formatted_address || ''
  };
}

function isLatLngLiteral(
  location: google.maps.LatLng | google.maps.LatLngLiteral
): location is google.maps.LatLngLiteral {
  return typeof location.lat === 'number' && typeof location.lng === 'number';
}
