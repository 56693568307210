import React, { ChangeEvent, FocusEventHandler, useState } from 'react';
import { Validator, validatePresence } from 'shared';
import classNames from 'classnames';

import useFieldSize from 'hooks/useFieldSize';

import { BaseProps, InputProps, ValidateProp } from './types';
import useValidate from './useValidate';
import Layout from '../Layout';
import Text from '../Text';
import { DS_CLASS_NAME } from '../constants';
import useUniqueId from '../helpers/useUniqueId';

export interface Props extends Omit<BaseProps<HTMLTextAreaElement>, 'onFocus'>, InputProps, ValidateProp {
  onFocus?: FocusEventHandler<HTMLTextAreaElement>;
  label: string;
  maxLength?: number;
  minHeight?: number;
  height?: number;
  validate?: Validator;
}

const TextArea: React.FC<Props> = ({
  value,
  label,
  required = false,
  name,
  onChange,
  onBlur,
  onFocus,
  onValidate,
  placeholder,
  maxLength,
  minHeight,
  height,
  disabled,
  autoFocus,
  ...props
}) => {
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const enforceMaxLength = typeof maxLength === 'number';

  const onChangeWrapper = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const value = handleValue(e.target.value);

    const validationResult = validate(value);

    if (validationResult.valid) {
      setErrorMessage(undefined);
    }

    onChange({ event: e, value });
    onValidate && onValidate(validationResult);
  };

  const handleValue = (value: string) => {
    return enforceMaxLength ? (value = value.substr(0, maxLength)) : value;
  };

  const validate = (value?: string) => {
    return props.validate
      ? props.validate({ fieldName: label, value, required })
      : validatePresence({ fieldName: label, value, required });
  };

  useValidate({ value: value || undefined, onValidate, validate, setErrorMessage });

  const id = useUniqueId();
  const hasValue = !!value && value.length > 0;
  const scaledSize = useFieldSize();
  const size = props.size || scaledSize;
  const error = props.error || errorMessage;
  const hasError = !!error;
  const hideLabel = props.hideLabel || size === 'xxs';

  return (
    <div>
      <div
        className={classNames(
          'TextArea',
          `is-size-${size}`,
          { 'has-error': hasError, 'is-disabled': disabled },
          DS_CLASS_NAME
        )}
      >
        <textarea
          onFocus={onFocus}
          onBlur={event => {
            const value = handleValue(event.target.value);
            setErrorMessage(validate(value).error);

            onBlur && onBlur({ event, value });
          }}
          id={id}
          name={name}
          className={classNames(DS_CLASS_NAME, 'TextArea-textarea', {
            'has-error': hasError,
            'has-value': hasValue,
            'is-disabled': disabled,
            'has-max-height': height && height < 500,
            'has-small-label': !hideLabel
          })}
          aria-label={hideLabel ? label : undefined}
          value={value || ''}
          onChange={onChangeWrapper}
          placeholder={placeholder}
          style={{ minHeight: !height ? minHeight : undefined, height }}
          autoFocus={autoFocus}
          disabled={disabled}
        />
        <div className={classNames('TextArea-labelBackground')}></div>
        <label className={classNames('TextArea-label')} htmlFor={id}>
          {label}
          {required ? ' *' : ''}
        </label>
      </div>
      {enforceMaxLength && (
        <Layout marginTop={8} justify="flex-end">
          <Text color="gray-400" size="tag" scale>
            {(value || '').length}/{maxLength}
          </Text>
        </Layout>
      )}
      {hasError && error && (
        <Layout marginTop={12}>
          <Text size="body2" color="red-700" scale>
            {error}
          </Text>
        </Layout>
      )}
    </div>
  );
};

export default TextArea;
