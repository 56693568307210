import { useRef, useState } from 'react';
import { IconName } from 'shared';
import classNames from 'classnames';

import { track } from 'lib/analytics';

import Clickable from './Clickable';
import Icon from './Icon';
import Layout from './Layout';
import Text from './Text';
import { useOnClickOutside } from './helpers';

export interface Props<T extends string | number = string> {
  // value that is selected
  value?: T;
  options: SelectMenuOption<T>[];
  onSelect: (params: { value: T }) => void;
  name?: string;
  placeholder?: string;
}

export interface SelectMenuOption<T extends string | number> {
  value: T;
  label: string;
  icon?: IconName;
}

const SelectMenu = <T extends string | number>({ options, onSelect, value, name = '', placeholder }: Props<T>) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const selectedOption = options.find(option => option.value === value);
  const containerRef = useRef<HTMLDivElement>(null);
  const [menuWidth, setMenuWidth] = useState<number>();
  const menuOptions = options.filter(o => o.value !== value);

  useOnClickOutside(containerRef, () => {
    setIsOpen(false);
  });

  const handleSelect = (value: T) => {
    onSelect({ value });
    setIsOpen(false);
    const option = options.find(option => option.value === value);
    track('Element Interacted', { type: 'select', value: option?.label, name, context: 'selection' });
  };

  return (
    <Layout
      __ref={containerRef}
      position="relative"
      width="100%"
      onMeasure={({ width }) => setMenuWidth(width)}
      zIndex={2}
    >
      <Clickable onClick={() => setIsOpen(!isOpen)} style={{ display: 'block' }} fullWidth>
        <Layout align="center" paddingX={12}>
          <Text size="body-sm">{selectedOption?.label || placeholder}</Text>
          {selectedOption?.icon && (
            <Layout display="inline-flex" marginLeft={8}>
              <Icon name={selectedOption.icon} size="sm" />
            </Layout>
          )}
        </Layout>
      </Clickable>
      {menuWidth && (
        <Layout
          __className={classNames('SelectMenu-menu', { 'is-open': isOpen })}
          color="white"
          paddingY={8}
          boxShadow="0px 8px 24px 0px rgba(0, 0, 0, 0.12)"
          borderRadius={8}
          width={menuWidth}
        >
          {menuOptions.map(({ value, label }) => {
            return (
              <Clickable onClick={() => handleSelect(value)} className="SelectMenu-option" key={value} fullWidth>
                <Layout padding={12} justify="flex-start">
                  <Text size="body-sm">{label}</Text>
                </Layout>
              </Clickable>
            );
          })}
        </Layout>
      )}
    </Layout>
  );
};

export default SelectMenu;
