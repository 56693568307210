import React, { useRef, useState } from 'react';
import { Color } from 'shared';

import Clickable from 'ds/Clickable';
import Layout from 'ds/Layout';
import Link from 'ds/Link';
import Popover from 'ds/Popover';
import Text from 'ds/Text';
import { useOnClickOutside } from 'ds/helpers';

import { BreadCrumb as BreadCrumbType } from './types';

type CommonProps = {
  index: number;
  textColor?: Color;
};

type ItemProps = { items: BreadCrumbType[]; href?: never; label?: never; search?: never };
type CrumbProps = BreadCrumbType & { items?: never };

type Props = CommonProps & (ItemProps | CrumbProps);

const BreadCrumb: React.FC<Props> = ({ index, href, search, label, textColor, items = [] }) => {
  let element: JSX.Element;
  const ref = useRef<HTMLDivElement>(null);

  const [menuOpen, setMenuOpen] = useState<boolean>(false);

  useOnClickOutside(ref, event => {
    if (!items.length) return;

    if (ref && ref.current && ref.current.contains(event.target as HTMLElement)) {
      return;
    }

    setMenuOpen(false);
  });

  if (items.length) {
    return (
      <Layout marginX={8} flex>
        <Text size="body2">/</Text>
        <Layout marginLeft={8}>
          <Popover
            containerStyle={{ display: 'inline-flex' }}
            placement="bottom-start"
            color="white"
            isVisible={menuOpen}
            content={
              <Layout minWidth={173} __ref={ref}>
                {items.map(({ label, href }) => {
                  return (
                    <Link
                      href={href}
                      display="block"
                      color={textColor || 'gray-900'}
                      opacity={false}
                      key={label}
                      search={search}
                    >
                      <Layout paddingY={8} paddingX={16} align="center" hoverColor="blue-50">
                        <Text size="body3">{label}</Text>
                      </Layout>
                    </Link>
                  );
                })}
              </Layout>
            }
          >
            <Clickable onClick={() => setMenuOpen(true)}>
              <Text size="body2" color={textColor || 'blue-500'}>
                ...
              </Text>
            </Clickable>
          </Popover>
        </Layout>
      </Layout>
    );
  }

  if (href) {
    element = (
      <Link href={href} key={label} search={search}>
        <Text color={textColor || 'blue-500'} size="body2" maxLines={1}>
          {label}
        </Text>
      </Link>
    );
  } else {
    element = (
      <Text size="body2" key={label} maxLines={1} color={textColor || 'gray-900'} semibold>
        {label}
      </Text>
    );
  }

  return index === 0 ? (
    element
  ) : (
    <React.Fragment key={label}>
      <Layout marginX={8}>
        <Text size="body2" color={textColor || 'gray-900'}>
          /
        </Text>
      </Layout>
      {element}
    </React.Fragment>
  );
};

export default BreadCrumb;
