import { User } from 'shared';

import { getItem, setItem } from './storage';
import { FeatureName } from './types';

export function featureEnabled({ name, user }: { name: FeatureName; user?: User | null }): boolean {
  const features = getItem('features');
  const enabledForUser = user?.active_features?.includes(name);
  if (enabledForUser) return true;

  if (!features) return false;

  const feature = features[name];
  return feature ? true : false;
}

export function enableFeature(name: FeatureName) {
  const features = getItem('features') || {};
  features[name] = true;

  setItem('features', features);
}

export function disableFeature(name: FeatureName) {
  const features = getItem('features') || {};
  features[name] = false;

  setItem('features', features);
}
