import React from 'react';
import { Image, ListingWorkspace } from 'shared';
import { ListItem, Offer, RealEstateListing, WithContext } from 'schema-dts';

import { getCityString } from 'helpers/bookings';
import { generateImgSrc, imageKitUrl } from 'helpers/images';

interface Props {
  workspace: ListingWorkspace;
}

function numberWithCommas(x: number) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

const RealEstateListingSchema: React.FC<Props> = ({
  workspace: {
    location_info: { neighborhood, submarket, market },
    address,
    name,
    description,
    square_feet,
    monthly_price,
    images,
    slug
  }
}) => {
  let breadcrumbs: ListItem[] = [];
  let position = 1;

  breadcrumbs.push({
    '@type': 'ListItem',
    position: position,
    item: {
      '@id': `${window.location.origin}/#website`,
      name: 'Codi',
      url: window.location.origin
    }
  });
  position++;

  if (!!market && !!market.url) {
    breadcrumbs.push({
      '@type': 'ListItem',
      position: position,
      item: {
        '@id': market.url,
        name: market.name,
        url: market.url!
      }
    });
    position++;
  }
  if (!!submarket && !!submarket.url) {
    breadcrumbs.push({
      '@type': 'ListItem',
      position: position,
      item: {
        '@id': submarket.url!,
        name: submarket.name,
        url: submarket.url!
      }
    });
    position++;
  }
  if (!!neighborhood && !!neighborhood.url) {
    breadcrumbs.push({
      '@type': 'ListItem',
      position: position,
      item: {
        '@id': neighborhood.url!,
        name: neighborhood.name,
        url: neighborhood.url!
      }
    });
    position++;
  }
  breadcrumbs.push({
    '@type': 'ListItem',
    position: position,
    item: {
      '@id': window.location.href,
      name: name,
      url: window.location.href
    }
  });

  const imageKey = images[0]?.key;
  const imgWidth = 1920;
  const imgHeight = 1280;
  const thumbnailWidth = 800;
  const thumbnailHeight = 600;

  const primaryImageOfPage = imageKitUrl({
    key: imageKey,
    transformationChain: [`w-${imgWidth},h-${imgHeight}`]
  });
  const thumbnailImageOfPage = imageKitUrl({
    key: imageKey,
    transformationChain: [`w-${thumbnailWidth},h-${thumbnailHeight}`]
  });

  const schema: WithContext<RealEstateListing> = {
    '@context': 'https://schema.org',
    '@type': 'RealEstateListing',
    description: `Office space for rent in ${getCityString(address)}. ${description}`,
    speakable: { '@type': 'SpeakableSpecification', xpath: ["/html/head/meta[@name='description']/@content"] },
    breadcrumb: {
      '@type': 'BreadcrumbList',
      itemListElement: breadcrumbs
    },
    primaryImageOfPage: { '@id': primaryImageOfPage },
    image: { '@id': primaryImageOfPage },
    thumbnailUrl: thumbnailImageOfPage,
    about: {
      '@type': 'Offer',
      name: name,
      description: `${numberWithCommas(square_feet)} sq. ft. office space offered at $${numberWithCommas(
        monthly_price!
      )} per month in ${address.city}, ${address.region}`,
      category: 'Office',
      url: `${window.location.origin}/listings/${slug}`,
      price: monthly_price ? `$${numberWithCommas(monthly_price)} per month` : undefined,
      priceCurrency: 'USD',
      image: generateImgSrc({ srcKey: (images[0] as Image).key, width: 800, height: 600 }),
      availableAtOrFrom: {
        '@type': 'Place',
        address: {
          '@type': 'PostalAddress',
          streetAddress: address.line1,
          addressLocality: address.city,
          addressRegion: address.region,
          postalCode: address.postal_code,
          addressCountry: address.country
        }
      }
    } as Offer
  };

  return <script type="application/ld+json">{JSON.stringify(schema)}</script>;
};

export default RealEstateListingSchema;
