import React from 'react';
import { IconName } from 'shared';

import { Clickable, Icon, Layout, Text } from 'ds';
import { actions } from 'store/Chatbot';
import { useAppDispatch } from 'store/hooks';

import { CHATBOT_BOTTOM_NAVIGATION_HEIGHT } from './constants';

interface Props {
  screenValue: string;
  isActive: boolean;
  iconName: IconName;
  label: string;
}

const ChatbotBottomNavigationButton: React.FC<Props> = ({ screenValue, isActive, iconName, label }) => {
  const dispatch = useAppDispatch();

  return (
    <Clickable
      onClick={() => dispatch(actions.pushScreen({ value: screenValue }))}
      style={{ height: CHATBOT_BOTTOM_NAVIGATION_HEIGHT }}
      fullWidth
    >
      <Layout direction="column" align="center">
        <Icon name={iconName} color={isActive ? 'blue-500' : 'gray-300'} size={28} />
        <Layout marginTop={4}>
          <Text size="tag" color={isActive ? 'blue-500' : 'gray-300'}>
            {label}
          </Text>
        </Layout>
      </Layout>
    </Clickable>
  );
};

export default ChatbotBottomNavigationButton;
