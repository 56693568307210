import React, { useContext, useEffect, useState } from 'react';
import { RouteComponentProps, useLocation } from 'react-router-dom';
import { DEFAULT_LAT_LNG, nearestLoc } from 'shared';

import Head from 'components/Head';

import { app, media } from 'context';
import { Layout } from 'ds';
import { RequestState } from 'ds/types';
import { getLocations, getPlaceDetails } from 'lib/location';
import { ROOT_PATH, SEARCH_PATH, SEARCH_PATH_DEPRECATED } from 'routes';
import { SEARCH_META_DESCRIPTION, SEARCH_META_TITLE } from 'routes/constants';
import { selectCities } from 'store/App/selectors';
import { selectUser } from 'store/User/selectors';
import { useAppSelector } from 'store/hooks';

import MobileUI from './MobileUI';
import UI from './UI';
import { SORT_LABELS } from './constants';
import { generateSearch, parseSearch } from './utils';

interface Props extends RouteComponentProps<{}, any, RouteState> {}

interface RouteState {
  from?: { pathname: string };
}

const Search: React.FC<Props> = ({ history }) => {
  const { ipLocation, navBarHeight } = useContext(app);
  const { xs } = useContext(media);
  const user = useAppSelector(selectUser);
  const {
    bounds,
    center: qsCenter,
    zoom,
    location: qsLocation,
    map,
    selectedWorkspaceId,
    daysPerWeek,
    minPrice,
    maxPrice,
    minSqft,
    maxSqft,
    numMeetingRooms,
    offsitesOnly,
    sortLabel = SORT_LABELS[0]
  } = parseSearch(history.location.search);
  const [backPathname, _setBackPathname] = useState<string>(history.location.state?.from?.pathname || ROOT_PATH);
  const cities = useAppSelector(selectCities);
  const closestCity = ipLocation && cities ? nearestLoc({ lat: ipLocation.lat, lng: ipLocation.lng }, cities) : null;
  const [requestState, setRequestState] = useState<RequestState>('ready');
  const citiesLoaded = cities !== undefined;
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === SEARCH_PATH_DEPRECATED) {
      history.replace(`${SEARCH_PATH}${location.search}`);
    }
  }, [history, location.pathname, location.search]);

  useEffect(() => {
    if (requestState === 'in_progress' || !citiesLoaded) return;

    if (qsCenter && qsLocation) return;

    if (qsLocation && !qsCenter && !bounds) {
      setRequestState('in_progress');
      getLocations({
        input: qsLocation,
        callback: results => {
          const result = results[0];

          if (!result) return;

          getPlaceDetails({
            place_id: result.place_id,
            callback: details => {
              const viewPort = details?.geometry?.viewport;
              if (!viewPort) return null;

              const ne = viewPort.getNorthEast();
              const sw = viewPort.getSouthWest();
              const bounds = ne && sw ? { ne: ne.toJSON(), sw: sw.toJSON() } : undefined;

              history.replace({
                search: generateSearch({
                  search: history.location.search,
                  location: result.description,
                  bounds,
                  center: viewPort.getCenter().toJSON()
                })
              });
              setRequestState('ready');
            }
          });
        }
      });

      return;
    } else if (closestCity) {
      const { name, region, country } = closestCity;

      history.replace({
        search: generateSearch({
          search: history.location.search,
          location: `${name}, ${region}, ${country}`
        })
      });
    } else {
      history.replace({
        search: generateSearch({
          search: history.location.search,
          location: 'San Francisco, CA, US',
          center: DEFAULT_LAT_LNG
        })
      });
    }
  }, [ipLocation, user?.id, closestCity?.lat, closestCity?.lng, citiesLoaded, qsLocation]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!qsLocation || !qsCenter) {
    return <Layout height={`calc(100vh - ${navBarHeight}px)`} />;
  }

  const props = {
    location: qsLocation,
    center: qsCenter,
    zoom: zoom || 12,
    bounds,
    selectedWorkspaceId: selectedWorkspaceId || null,
    daysPerWeek,
    minPrice,
    maxPrice,
    minSqft,
    maxSqft,
    numMeetingRooms,
    offsitesOnly,
    sortLabel
  };

  return (
    <>
      <Head title={SEARCH_META_TITLE} description={SEARCH_META_DESCRIPTION} />
      {xs ? <MobileUI {...props} backPathname={backPathname} /> : <UI {...props} showMap={map !== 'hide'} />}
    </>
  );
};

export default Search;
