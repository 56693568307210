import {
  Community,
  CreateUserInput,
  CreateUserResponse,
  GoogleProfileData,
  NotificationPreferencesAttributes,
  PartialPick,
  Reminders,
  Support,
  User,
  UserAction,
  UserCalendar,
  UserInteraction
} from 'shared/types';

import axios from 'shared/lib/axios';
import { getBlob } from 'shared/file';

interface PathParams {
  id: number;
  startDate: string;
  endDate: string;
  // TODO: include lat/lng?
}

type UserIdParam = Pick<PathParams, 'id'>;

type UserCalendarParams = Pick<PathParams, 'id' | 'startDate' | 'endDate'>;

function userPath({ id }: UserIdParam) {
  return `/v1/users/${id}`;
}

function userCalendarPath({ id, startDate, endDate }: UserCalendarParams) {
  return `/v1/users/${id}/calendar?start_date=${startDate}&end_date=${endDate}`;
}

function updateUserPath({ id }: UserIdParam) {
  return `/v1/users/${id}`;
}

function createUserPath() {
  return 'v1/users';
}

function uploadAvatarPath({ id }: UserIdParam) {
  return `/v1/users/${id}/avatar`;
}

function updateUserInteractionPath({ userId }: { userId: number }) {
  return `/v1/users/${userId}/user_interaction`;
}

export function getUser({ id }: UserIdParam) {
  return axios.get<User>(userPath({ id }));
}

export function getUserCalendar({ id, startDate, endDate }: UserCalendarParams) {
  return axios.get<UserCalendar>(userCalendarPath({ id, startDate, endDate }));
}

type NotificationResponse = {
  email_encoded: string;
  email: string;
  phone_last_4: string;
  reminders: Reminders;
  support: Support;
  community: Community;
};

export function updateNotificationPreferences({
  token,
  ...params
}: {
  token: string;
  reminders?: Reminders;
  support?: Support;
  community?: Community;
}) {
  return axios.post<NotificationResponse>(`v1/notification_preferences/${token}`, { ...params, email: token });
}

export function getNotificationPreferences({ token }: { token: string }) {
  return axios.get<NotificationResponse>(`v1/notification_preferences/${token}`);
}

interface UpdateUserData
  extends PartialPick<
    User,
    'firstname' | 'lastname' | 'email' | 'calling_code' | 'phone' | 'google_id' | 'is_workspace_creator'
  > {
  notification_preferences_attributes?: UpdatableNotificationPreferences;
  user_actions_to_delete?: string[];
  google_data?: GoogleProfileData | {};
}

interface UpdatableNotificationPreferences extends NotificationPreferencesAttributes {
  id: number;
}

export interface UpdateUserParams extends Pick<User, 'id'>, UpdateUserData {}

export function updateUser({ id, user_actions_to_delete, ...params }: UpdateUserParams) {
  return axios.patch<User>(updateUserPath({ id }), { user_actions_to_delete, user: { id, ...params } });
}

export interface CreateUserParams {
  image_url?: string;
  user: CreateUserInput;
  pathname?: string;
  is_mobile_app?: boolean;
}

export function createUser(params: CreateUserParams) {
  return axios.post<CreateUserResponse>(createUserPath(), params);
}

interface UploadAvatarResponse {
  avatar_url: string;
}

export async function uploadAvatar({ url, id }: { url: string; id: number }) {
  const blob = await getBlob(url);
  const formData = new FormData();
  formData.append('avatar', blob);

  return axios.post<UploadAvatarResponse>(uploadAvatarPath({ id }), formData);
}

interface UpdateUserInteractionParams extends Partial<UserInteraction> {
  userId: number;
}

export async function updateUserInteraction({ userId, ...params }: UpdateUserInteractionParams) {
  return axios.post<UserInteraction>(updateUserInteractionPath({ userId }), params);
}

export async function createUserAction({ userId, value }: { userId: number; value: string }) {
  return axios.post<UserAction[]>(`/v1/users/${userId}/user_actions`, { value });
}
