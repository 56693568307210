import React, { PropsWithChildren } from 'react';
import { Color } from 'shared';

import Layout, { Props as LayoutProps } from 'ds/Layout';

interface Props {
  color?: Color;
  background?: string;
  paddingY?: number;
  paddingTop?: number;
  paddingBottom?: number;
  containerProps?: LayoutProps;
}

const FullWidthBackground: React.FC<PropsWithChildren<Props>> = ({
  color,
  background,
  paddingY,
  paddingTop,
  paddingBottom,
  children,
  containerProps
}) => {
  return (
    <Layout position="relative" zIndex={0} {...containerProps}>
      <Layout
        color={color}
        background={background}
        position="absolute"
        left="-100vw"
        right="-100vw"
        height="100%"
        zIndex={-1}
      ></Layout>
      <Layout {...(paddingTop || paddingBottom ? { paddingTop, paddingBottom } : { paddingY })}>{children}</Layout>
    </Layout>
  );
};

export default FullWidthBackground;
