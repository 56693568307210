import { useHistory } from 'react-router-dom';
import { JWT, refreshToken, setAuthorizationHeader } from 'shared';
import decode from 'jwt-decode';

import { resetAnalytics } from 'lib/analytics';
import { LOGOUT } from 'store/Auth';
import { store } from 'store/store';

import { LOCAL_STORAGE_NAMESPACE } from './constants';
import { getItem, removeItem, setItem } from './storage';

const NAMESPACE = `${LOCAL_STORAGE_NAMESPACE}.auth` as const;
const TOKEN_KEY = `${NAMESPACE}.token` as const;

export function getToken(): string | null {
  return localStorage.getItem(TOKEN_KEY);
}

export function getDefaultToken(): string {
  return process.env.REACT_APP_API_GENERIC_TOKEN;
}

export function setToken(token: string) {
  // this __should__ never happen
  if (!token) return;

  localStorage.setItem(TOKEN_KEY, token);
  setAuthorizationHeader(token);
}

export function unsetToken() {
  localStorage.removeItem(TOKEN_KEY);
  setAuthorizationHeader(getDefaultToken());
}

export function decodeJwt(token: string) {
  const decoded = decode<JWT>(token);

  const impId = getItem('impId');

  if (impId) {
    decoded.data.user_id = impId;
  }

  return decoded;
}

export function resetHubSpotChat() {
  if (!window.HubSpotConversations) {
    return;
  }
  window.HubSpotConversations.clear({ resetWidget: true });
}

export function logout({ history }: { history: ReturnType<typeof useHistory> | undefined }) {
  if (history) {
    addLogoutSearchParam({ history });
  }

  unsetToken();
  resetAnalytics();
  resetHubSpotChat();
  store.dispatch({ type: LOGOUT, payload: null });
}

function addLogoutSearchParam({ history }: { history: ReturnType<typeof useHistory> }) {
  const searchParams = new URLSearchParams(history.location.search);
  searchParams.append('logout', '1');

  history.replace({ search: searchParams.toString() });
}

const ONE_DAY_IN_MS = 86400000;

export function startTokenRefreshCheck() {
  setInterval(refreshTokenIfExpirationSoon, ONE_DAY_IN_MS);
}

export async function refreshTokenIfExpirationSoon() {
  const token = getToken();

  if (!token) {
    return;
  }

  const {
    exp,
    data: { user_id }
  } = decodeJwt(token);

  if (!user_id || exp * 1000 > Date.now()) {
    return;
  }

  if (exp * 1000 < Date.now() + ONE_DAY_IN_MS * 2) {
    refreshToken()
      .then(({ data }) => setToken(data.token))
      .catch(() => unsetToken());
  }
}

function setFunctionsOnGlobalCodi() {
  window.codi = window.codi || {};
  window.codi.setItem = setItem;
  window.codi.getItem = getItem;
  window.codi.removeItem = removeItem;
}

setFunctionsOnGlobalCodi();
