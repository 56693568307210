import React, { useContext } from 'react';
import { BLUE_100_HEX } from 'shared';

import { app, media } from 'context';
import { Button, Img, Layout, Text } from 'ds';

import { ReactComponent as Graphic } from './nyc-tech-week-hero-graphic.svg';
import { ReactComponent as MobileGraphic } from './nyc-tech-week-mobile-hero-graphic.svg';

interface Props {}

const Hero: React.FC<Props> = () => {
  const { width, contentPaddingX } = useContext(app);

  const { xs, sm, md, xl } = useContext(media);

  if (xs || sm) {
    return (
      <Layout paddingY={80} color="blue-700" paddingX={contentPaddingX} position="relative">
        <MobileGraphic
          style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', zIndex: 0 }}
          preserveAspectRatio="none"
        />
        <Layout zIndex={1} position="relative">
          <Text size="body1" color="white">
            Thursday, June 6
            <br />
            Codi x NYC #TechWeek 2024
          </Text>
          <Layout marginTop={24}>
            <Text size="h3" color="white" scale>
              Scaling Your Marketplace
            </Text>
          </Layout>
          <Layout marginTop={24}>
            <Text size="body1" color="white" semibold>
              Brunch + Chat w/ FJ Labs, Pickle, and Codi
            </Text>
          </Layout>
          <Layout marginTop={48} justify="center">
            <Button
              text="Register now"
              size="md"
              type="primary"
              href="https://lu.ma/lc9s9rgu"
              iconPosition="right"
              icon="rightArrow"
              iconStroke={2}
            />
          </Layout>
          <Layout marginTop={12} justify="center">
            <Text size="body2" color="white" italic>
              Seating is limited.
            </Text>
          </Layout>
          <Layout marginTop={112} paddingBottom={34} justify="center" flex>
            <Layout position="relative" flex>
              <Layout direction="column">
                <Layout direction="row">
                  <Layout position="relative">
                    <Img
                      srcKey="christelle__mJRaRlIV.jpeg"
                      folder="public"
                      alt="Christelle Rohaut"
                      width={600}
                      style={{
                        width: 150,
                        height: 150,
                        borderRadius: '50%',
                        border: `4px solid ${BLUE_100_HEX}`,
                        objectFit: 'cover'
                      }}
                    />
                    <Layout
                      color="blue-800"
                      width={170}
                      padding={6}
                      position="absolute"
                      left={-10}
                      top={130}
                      zIndex={2}
                    >
                      <Text align="right" size="headline-xs" color="white">
                        Christelle Rohaut
                      </Text>
                      <Text align="right" size="body-xs" color="white">
                        CEO @ Codi
                      </Text>
                    </Layout>
                  </Layout>
                  <Layout position="relative" marginLeft={28} zIndex={0} height="fit-content">
                    <Img
                      srcKey="arne_0gKbtO5dC.jpeg"
                      folder="public"
                      alt="Arne Halleraker"
                      width={600}
                      style={{
                        width: 150,
                        height: 150,
                        borderRadius: '50%',
                        border: `4px solid ${BLUE_100_HEX}`,
                        objectFit: 'cover',
                        position: 'relative'
                      }}
                    />
                    <Layout
                      color="blue-500"
                      width={170}
                      padding={6}
                      position="absolute"
                      left={-10}
                      top={130}
                      zIndex={2}
                    >
                      <Text align="right" size="headline-xs" color="white">
                        Arne Halleraker
                      </Text>
                      <Text align="right" size="body-xs" color="white">
                        Partner @ FJ Labs
                      </Text>
                    </Layout>
                  </Layout>
                </Layout>
                <Layout direction="row" marginTop={64}>
                  <Layout position="relative">
                    <Img
                      srcKey="brian2_z0faSRBQmE.png"
                      folder="public"
                      alt="Brian McMahon"
                      width={600}
                      style={{
                        width: 150,
                        height: 150,
                        borderRadius: '50%',
                        border: `4px solid ${BLUE_100_HEX}`,
                        objectFit: 'cover'
                      }}
                    />
                    <Layout
                      color="blue-500"
                      width={170}
                      padding={6}
                      position="absolute"
                      left={-10}
                      top={130}
                      zIndex={2}
                    >
                      <Text align="right" size="headline-xs" color="white">
                        Brian McMahon
                      </Text>
                      <Text align="right" size="body-xs" color="white">
                        CEO @ Pickle
                      </Text>
                    </Layout>
                  </Layout>
                  <Layout position="relative" marginLeft={28} zIndex={0} height="fit-content">
                    <Img
                      srcKey="derek_qIZY1G7Fv.jpeg"
                      folder="public"
                      alt="Derek Chu"
                      width={600}
                      style={{
                        width: 150,
                        height: 150,
                        borderRadius: '50%',
                        border: `4px solid ${BLUE_100_HEX}`,
                        objectFit: 'cover',
                        position: 'relative'
                      }}
                    />
                    <Layout
                      color="blue-800"
                      width={170}
                      padding={6}
                      position="absolute"
                      left={-10}
                      top={130}
                      zIndex={2}
                    >
                      <Text align="right" size="headline-xs" color="white">
                        Derek Chu
                      </Text>
                      <Text align="right" size="body-xs" color="white">
                        Consumer VC @ Firstmark
                      </Text>
                    </Layout>
                  </Layout>
                </Layout>
              </Layout>
            </Layout>
          </Layout>
        </Layout>
      </Layout>
    );
  }

  return (
    <Layout height={620} position="relative" color="blue-700">
      <Graphic
        style={{ position: 'absolute', top: 0, left: 0, width, height: 620, zIndex: 0 }}
        preserveAspectRatio="none"
      />
      <Layout zIndex={2} position="relative" height="100%" align="center" justify="center" flex>
        <Layout maxWidth={1200} align="center">
          <Layout borderRadius={24} padding={md ? 24 : 40} color="white" maxWidth={md ? 360 : !xl ? 400 : 544}>
            <Text size="h6" color="blue-700">
              Thursday, June 6 | Codi x NYC #TechWeek 2024
            </Text>
            <Layout marginTop={16}>
              <Text size="h3" color="blue-500">
                Scaling Your Marketplace
              </Text>
            </Layout>
            <Layout marginTop={16}>
              <Text size="h6" color="blue-700">
                Brunch + Chat w/ FJ Labs, Pickle, and Codi
              </Text>
            </Layout>
            <Layout marginTop={40} align="center">
              <Button
                text="Register now"
                size="md"
                type="primary"
                href="https://lu.ma/lc9s9rgu"
                iconPosition="right"
                icon="rightArrow"
                iconStroke={2}
              />
              <Layout marginLeft={12}>
                <Text size="body2" color="blue-700" italic>
                  Seating is limited.
                </Text>
              </Layout>
            </Layout>
          </Layout>
          <Layout justify="center" marginLeft={md ? 0 : 44} width={md ? 480 : 648} position="relative" flex>
            <Layout direction="column">
              <Layout direction="row">
                <Layout position="relative">
                  <Img
                    srcKey="christelle__mJRaRlIV.jpeg"
                    folder="public"
                    alt="Christelle Rohaut"
                    width={600}
                    style={{
                      width: md ? 160 : 200,
                      height: md ? 160 : 200,
                      borderRadius: '50%',
                      border: `4px solid ${BLUE_100_HEX}`,
                      objectFit: 'cover'
                    }}
                  />
                  <Layout
                    color="blue-800"
                    width={md ? 180 : 220}
                    paddingX={md ? 12 : 16}
                    paddingY={md ? 8 : 12}
                    position="absolute"
                    left={-15}
                    top={md ? 140 : 180}
                    zIndex={2}
                  >
                    <Text align="right" size={md ? 'headline-xs' : 'headline-sm'} color="white">
                      Christelle Rohaut
                    </Text>
                    <Text align="right" size="body-xs" color="white">
                      CEO @ Codi
                    </Text>
                  </Layout>
                </Layout>
                <Layout position="relative" marginLeft={64} zIndex={0} height="fit-content">
                  <Img
                    srcKey="arne_0gKbtO5dC.jpeg"
                    folder="public"
                    alt="Arne Halleraker"
                    width={600}
                    style={{
                      width: md ? 160 : 200,
                      height: md ? 160 : 200,
                      borderRadius: '50%',
                      border: `4px solid ${BLUE_100_HEX}`,
                      objectFit: 'cover',
                      position: 'relative'
                    }}
                  />
                  <Layout
                    color="blue-500"
                    width={md ? 180 : 220}
                    paddingX={md ? 12 : 16}
                    paddingY={md ? 8 : 12}
                    position="absolute"
                    left={-10}
                    top={md ? 140 : 180}
                    zIndex={2}
                  >
                    <Text align="right" size={md ? 'headline-xs' : 'headline-sm'} color="white">
                      Arne Halleraker
                    </Text>
                    <Text align="right" size="body-xs" color="white">
                      Partner @ FJ Labs
                    </Text>
                  </Layout>
                </Layout>
              </Layout>
              <Layout direction="row" marginTop={64}>
                <Layout position="relative">
                  <Img
                    srcKey="brian2_z0faSRBQmE.png"
                    folder="public"
                    alt="Brian McMahon"
                    width={600}
                    style={{
                      width: md ? 160 : 200,
                      height: md ? 160 : 200,
                      borderRadius: '50%',
                      border: `4px solid ${BLUE_100_HEX}`,
                      objectFit: 'cover'
                    }}
                  />
                  <Layout
                    color="blue-500"
                    width={md ? 180 : 220}
                    paddingX={md ? 12 : 16}
                    paddingY={md ? 8 : 12}
                    position="absolute"
                    left={-10}
                    top={md ? 140 : 180}
                    zIndex={2}
                  >
                    <Text align="right" size={md ? 'headline-xs' : 'headline-sm'} color="white">
                      Brian McMahon
                    </Text>
                    <Text align="right" size="body-xs" color="white">
                      CEO @ Pickle
                    </Text>
                  </Layout>
                </Layout>
                <Layout position="relative" marginLeft={64} zIndex={0} height="fit-content">
                  <Img
                    srcKey="derek_qIZY1G7Fv.jpeg"
                    folder="public"
                    alt="Derek Chu"
                    width={600}
                    style={{
                      width: md ? 160 : 200,
                      height: md ? 160 : 200,
                      borderRadius: '50%',
                      border: `4px solid ${BLUE_100_HEX}`,
                      objectFit: 'cover',
                      position: 'relative'
                    }}
                  />
                  <Layout
                    color="blue-800"
                    width={md ? 180 : 220}
                    paddingX={md ? 12 : 16}
                    paddingY={md ? 8 : 12}
                    position="absolute"
                    left={-10}
                    top={md ? 140 : 180}
                    zIndex={2}
                  >
                    <Text align="right" size={md ? 'headline-xs' : 'headline-sm'} color="white">
                      Derek Chu
                    </Text>
                    <Text align="right" size="body-xs" color="white">
                      Consumer VC @ Firstmark
                    </Text>
                  </Layout>
                </Layout>
              </Layout>
            </Layout>
          </Layout>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default Hero;
