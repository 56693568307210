import { AuthResponseData } from 'shared/types';
import axios from 'shared/lib/axios';

function validatePath() {
  return '/v1/auth/validate';
}

function authenticateGooglePath() {
  return '/v1/auth/googleauth';
}

function authenticateApplePath() {
  return '/v1/auth/appleauth';
}

function sendCodePath() {
  return '/v1/auth/challenge';
}

function refreshTokenPath() {
  return '/v1/auth/refresh';
}

export function validate({
  token,
  email,
  isMobileApp,
  updateEmail
}: {
  token: string;
  email: string;
  isMobileApp?: boolean;
  updateEmail?: boolean;
}) {
  return axios.post<AuthResponseData>(validatePath(), {
    token,
    email,
    is_mobile_app: isMobileApp,
    update_email: updateEmail
  });
}

export function authenticateGoogle({
  code,
  userId,
  isMobileApp
}: {
  code: string;
  userId?: number;
  isMobileApp?: boolean;
}) {
  return axios.post<AuthResponseData>(authenticateGooglePath(), {
    code,
    user_id: userId,
    is_mobile_app: isMobileApp
  });
}

export interface AppleAuthPayload {
  firstname?: string;
  lastname?: string;
  userIdentity: string;
  identityToken: string;
}

export function authenticateApple({ firstname, lastname, userIdentity, identityToken }: AppleAuthPayload) {
  return axios.post<AuthResponseData>(authenticateApplePath(), { firstname, lastname, userIdentity, identityToken });
}

interface SendCodeResponse {
  message: 'token sent';
}

export function sendCode({ email }: { email: string }) {
  return axios.post<SendCodeResponse>(sendCodePath(), { email });
}

interface RefreshTokenResponse {
  token: string;
}
export function refreshToken() {
  return axios.post<RefreshTokenResponse>(refreshTokenPath());
}
