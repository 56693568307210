import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { ListingWorkspace, OfficeLead, formatDate, geocode } from 'shared';
import qs from 'qs';

import { Button, Icon, Layout, RequestState, Text } from 'ds';
import { apiTrack } from 'lib/analytics';
import { AUTH_PATH, CUSTOMER_OFFICE_REQUEST_PATH } from 'routes';
import { selectMarkets } from 'store/App/selectors';
import { actions as officeRequestActions } from 'store/OfficeRequest';
import { actions as userActions } from 'store/User';
import { selectOfficeLeads, selectUser, selectUserLikedWorkspaceIds } from 'store/User/selectors';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import TourStatusLarge from 'ux/Customer/OfficeRequest/TourStatusLarge';
import { addOfficeLeadWorkspace, createTourDestinationRequest } from 'ux/Customer/OfficeRequest/requests';
import { getClosestLeadInRange, getNextUpcomingTour, getTourForWorkspace } from 'ux/Customer/OfficeRequest/utils';

import { generateStepPath, marketNameFromLatLng } from '../OfficeRequest/utils';

interface Props {
  workspace: Pick<
    ListingWorkspace,
    'id' | 'monthly_price' | 'address' | 'availability_status' | 'availability_start_date'
  >;
  officeLead?: OfficeLead;
  textColor?: 'white';
  showAvailabilityStartDate?: boolean;
}

const TourCTA: React.FC<Props> = ({
  workspace,
  workspace: {
    id,
    monthly_price,
    availability_status,
    availability_start_date,
    address: { city, region, country }
  },
  textColor,
  showAvailabilityStartDate = true,
  ...props
}) => {
  const location = useLocation();
  const { days_per_week: qsDaysPerWeek } = qs.parse(location.search, { ignoreQueryPrefix: true });

  const history = useHistory();
  const user = useAppSelector(selectUser);
  const isLoggedIn = !!user;
  const markets = useAppSelector(selectMarkets);
  const officeLeads = useAppSelector(selectOfficeLeads);
  const [requestState, setRequestState] = useState<RequestState>('ready');
  const requestInProgress = requestState === 'in_progress';
  const [fetchedOfficeLeadId, setFetchedOfficeLeadId] = useState<number | null | undefined>(undefined);
  const officeLead = props.officeLead || officeLeads.find(ol => ol.id === fetchedOfficeLeadId);
  const userLikedWorkspaceIds = useAppSelector(state => selectUserLikedWorkspaceIds(state, officeLead?.id || 0));

  const upcomingTour = officeLead ? getNextUpcomingTour(officeLead.tours) : null;
  const dispatch = useAppDispatch();
  const leadIds = user?.office_leads.map(l => l.id).join(',');
  const daysPerWeek =
    typeof qsDaysPerWeek === 'string' && qsDaysPerWeek.length
      ? parseInt(qsDaysPerWeek)
      : officeLead?.days_per_week || 5;

  const handleSetOfficeLead = async () => {
    if (props.officeLead) return;

    if (!user) return;

    if (!user.office_leads.length) return;

    const officeLead = await getClosestLeadInRange({
      officeLeads: user.office_leads,
      // @ts-expect-error
      workspace: workspace
    });

    setFetchedOfficeLeadId(officeLead.id);
  };

  useEffect(() => {
    handleSetOfficeLead();
  }, [workspace.id, leadIds]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleRequestTour = async () => {
    if (user && officeLead) {
      setRequestState('in_progress');

      const upcomingTour = getNextUpcomingTour(officeLead.tours);
      apiTrack('Tour Requested', { workspaceId: workspace.id });

      if (!userLikedWorkspaceIds.includes(id)) {
        await addOfficeLeadWorkspace({
          officeLeadId: officeLead.id,
          workspaceId: id,
          userId: user.id,
          source: 'listing'
        });
      }

      if (upcomingTour) {
        const { data } = await createTourDestinationRequest({
          officeLeadId: officeLead.id,
          workspaceId: workspace.id,
          userId: user.id,
          tourId: upcomingTour.id
        });
        dispatch(userActions.updateOfficeLead(data));
      } else {
        history.push({ pathname: `${CUSTOMER_OFFICE_REQUEST_PATH}/${officeLead.id}`, search: 'schedule=1' });
      }

      setRequestState('ready');
    } else {
      dispatch(officeRequestActions.setMaxMonthlyBudget(Math.ceil((monthly_price || 0) / 1000) * 1000));
      dispatch(officeRequestActions.setTourRequestWorkspaceId(workspace.id));
      dispatch(officeRequestActions.setDaysPerWeek(daysPerWeek));
      dispatch(officeRequestActions.setLaunchPath(location.pathname));

      geocode(`${city}, ${region}, ${country}`).then(response => {
        const lat = (response.geometry.location.lat as unknown) as number;
        const lng = (response.geometry.location.lng as unknown) as number;
        const loc = { lat, lng };
        const place_id = response.place_id;
        const market = marketNameFromLatLng(loc, markets);
        dispatch(officeRequestActions.setMarket(market));
        dispatch(officeRequestActions.addLocation({ city, region, country, place_id, loc }));
        history.push(generateStepPath({ step: !!market ? 1 : 2 }));
      });
    }
  };

  const tour = officeLead ? getTourForWorkspace({ tours: officeLead.tours, workspaceId: id }) : undefined;

  if (tour && !!officeLead) {
    return <TourStatusLarge tours={officeLead.tours} workspaceId={workspace.id} textColor={textColor} />;
  }

  if (availability_status === 'not_available') {
    return (
      <Layout color="gray-25" borderRadius={8} align="center" justify="center" paddingX={24} paddingY={16}>
        <Icon name="lock" size="sm" stroke={2} />
        <Layout marginLeft={4} display="inline-flex">
          <Text size="body-sm" semibold>
            Not currently available
          </Text>
        </Layout>
      </Layout>
    );
  }

  const availableStartDateDisplay = showAvailabilityStartDate &&
    availability_status === 'available_soon' &&
    availability_start_date && (
      <Layout marginTop={12}>
        <Text size="body-xs" color="gray-700" align="center" italic>
          Available starting {formatDate({ date: availability_start_date, format: 'MED' })}.
        </Text>
      </Layout>
    );

  if (upcomingTour && officeLead && user) {
    return (
      <>
        <Button
          size="md"
          type="primary"
          onClick={handleRequestTour}
          icon="plus"
          iconPosition="left"
          text="Request to tour"
          context="CtaBox"
          showSpinner={requestInProgress}
          fullWidth
        />
        {availableStartDateDisplay}
      </>
    );
  } else if (officeLead) {
    return (
      <>
        <Button
          size="md"
          href={`${CUSTOMER_OFFICE_REQUEST_PATH}/${officeLead.id}`}
          onClick={async () => {
            if (!user) return;

            if (!userLikedWorkspaceIds.includes(id)) {
              const { data } = await addOfficeLeadWorkspace({
                officeLeadId: officeLead.id,
                workspaceId: id,
                userId: user.id,
                source: 'listing'
              });

              dispatch(userActions.updateOfficeLead(data));
            }
          }}
          search={`?schedule=1&td_ws_id=${workspace.id}`}
          type="primary"
          text="Request to tour"
          icon="plus"
          iconPosition="left"
          context="CtaBox"
          showSpinner={requestInProgress}
          fullWidth
        />
        {availableStartDateDisplay}
      </>
    );
  } else {
    return (
      <>
        <Button
          size="md"
          type="primary"
          {...(isLoggedIn
            ? { text: 'Request tour', onClick: handleRequestTour }
            : { text: 'Log in to see pricing', href: AUTH_PATH })}
          context="CtaBox"
          showSpinner={requestInProgress}
          search={`redirect=${encodeURIComponent(location.pathname + location.search)}`}
          fullWidth
        />
        {availableStartDateDisplay}
      </>
    );
  }
};

export default TourCTA;
