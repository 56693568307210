import { geocodeToAddress } from './geocode';

export type ValidationResult = { valid: false; error: string } | { valid: true; error?: never };

export const EMAIL_REGEXP = /^\w+([+.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,7})+$/;
export const validateEmail = (
  email: string | undefined,
  required: boolean,
  { excludedDomains }: { excludedDomains: string[] } = { excludedDomains: [] }
): ValidationResult => {
  if (email === undefined) {
    return required ? { error: 'Email address is required.', valid: false } : { valid: true };
  } else if (email === '') {
    return required ? { error: 'Email address is required.', valid: false } : { valid: true };
  } else if (email !== undefined && (!email.includes('@') || !EMAIL_REGEXP.test(email))) {
    return { error: 'Please enter a valid email address.', valid: false };
  }

  const domain = email.split('@')[1];

  for (const excludedDomain of excludedDomains) {
    if (domain === excludedDomain) {
      return { valid: false, error: `Domain cannot be ${excludedDomain}` };
    }
  }

  return {
    valid: true
  };
};

export const isEmail = (email?: string): boolean => {
  if (!email) return false;

  return EMAIL_REGEXP.test(email);
};

const DOMAIN_REGEXP = /^\w+([.-]?\w+)*(\.\w{2,7})+$/;
export const validateDomain = (domain: string | undefined, required: boolean): ValidationResult => {
  if (domain === undefined) {
    return required ? { error: 'Domain is required.', valid: false } : { valid: true };
  } else if (domain === '') {
    return required ? { error: 'Domain is required.', valid: false } : { valid: true };
  } else if (domain !== undefined && !DOMAIN_REGEXP.test(domain)) {
    return { error: 'Please enter a valid domain.', valid: false };
  }

  return {
    valid: true
  };
};

export interface ValidatorParams {
  fieldName?: string;
  value?: string;
  required: boolean;
}
export type Validator = (params: ValidatorParams) => ValidationResult;

export const validatePresence: Validator = ({ fieldName, value, required }): ValidationResult => {
  if (value === undefined) {
    return required ? { error: `${fieldName} is required.`, valid: false } : { valid: true };
  } else if (value === '' && required) {
    return { error: `${fieldName} is required.`, valid: false };
  }

  return { valid: true };
};

export const validatePhone = (phone: string | undefined, required: boolean): ValidationResult => {
  if (phone === undefined) {
    return required ? { error: 'Phone number is required.', valid: false } : { valid: true };
  } else if (phone === '' && required) {
    return { error: 'Phone number is required.', valid: false };
  } else if (phone.length > 0 && phone.length < 10) {
    return { error: 'Please enter a valid phone number.', valid: false };
  }

  return { valid: true };
};

export const validatePostalCode = (
  postalCode: string | undefined,
  required: boolean
): ValidationResult | Promise<ValidationResult> => {
  if (postalCode === undefined || postalCode === '') {
    return required ? { error: 'Postal code is required.', valid: false } : { valid: true };
  }

  if (postalCode.length < 5) {
    return { error: 'Postal code is required.', valid: false };
  } else {
    // validate zip
    // return geocode(zip + ',United States')
    // TODO: in the future, we may want to validate against specific countries
    return geocodeToAddress(postalCode)
      .then<ValidationResult>(address => {
        if (!address.postal_code) {
          return { error: 'Please enter a valid postal code.', valid: false };
        } else {
          return { valid: true };
        }
      })
      .catch(() => {
        return { error: 'Please enter a valid postal code.', valid: false };
      });
  }
};

export const validateUrl: Validator = ({ value, required }): ValidationResult => {
  if (value === undefined) {
    return required ? { error: 'URL is required.', valid: false } : { valid: true };
  } else if (value === '' && !required) {
    return { valid: true };
  }

  return isValidHttpUrl(value) ? { valid: true } : { valid: false, error: 'Please enter a valid URL.' };
};

export function isValidHttpUrl(url: string) {
  let testUrl;

  try {
    testUrl = new URL(url);
  } catch {
    return false;
  }

  return testUrl.protocol === 'http:' || testUrl.protocol === 'https:';
}

export const validateNumber = ({
  num,
  min,
  max,
  currency = false
}: {
  num: number;
  min: number;
  max?: number;
  currency?: boolean;
}): ValidationResult => {
  let error;
  const formattedMin = min.toLocaleString();
  const formattedMax = max?.toLocaleString();

  if (num < min) {
    error = currency
      ? `Please enter an amount greater than $${formattedMin}.`
      : `Please enter a number greater than ${formattedMin}.`;
  } else if (!!max && max > -1 && num > max) {
    error = currency
      ? `Please enter an amount between $${formattedMin} and $${formattedMax}.`
      : `Please enter a number between ${formattedMin} and ${formattedMax}.`;
  }

  if (!!error) {
    return { valid: false, error };
  }

  return { valid: true };
};

export const validateCreditCardNumber = (ccNum: string | undefined): ValidationResult => {
  if (!ccNum || !ccNum.length) {
    return { error: 'Card number is required.', valid: false };
  }

  return { valid: true };
};

export const validateCreditCardExpiration = (ccExp: string | undefined): ValidationResult => {
  if (!ccExp || !ccExp.length) {
    return { error: 'Expiration date is required.', valid: false };
  }

  if (!ccExp || ccExp.length < 4) {
    return { error: 'Please enter a valid expiration.', valid: false };
  }

  const today = new Date(); // gets the current date
  let today_mm = (today.getMonth() + 1).toString(); // extracts the month portion
  let today_yy = (today.getFullYear() % 100).toString(); // extracts the year portion and changes it from yyyy to yy format

  if (parseInt(today_mm) < 10) {
    // if today's month is less than 10
    today_mm = '0' + today_mm; // prefix it with a '0' to make it 2 digits
  }

  var mm = ccExp.substr(0, 2); // get the mm portion of the expiryDate (first two characters)
  var yy = ccExp.substr(2); // get the yy portion of the expiryDate (from index 3 to end)

  const isInPast = !(yy > today_yy || (yy === today_yy && mm >= today_mm));

  if (isInPast) {
    return { error: 'Expiration date cannot be in the past.', valid: false };
  }

  const isInvalidMonth = !parseInt(ccExp.substr(0, 2)) || parseInt(ccExp.substr(0, 2)) > 12;
  if (isInvalidMonth) {
    return { error: 'Expiration month must be a valid month.', valid: false };
  }

  return { valid: true };
};

export const validateCvcCode = (cvc: string | undefined): ValidationResult => {
  if (!cvc || !cvc.length) {
    return { error: 'CVC code is required.', valid: false };
  }

  if (!cvc || cvc.length < 3) {
    return { error: 'Please enter a valid CVC code.', valid: false };
  }

  return { valid: true };
};
