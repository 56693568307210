import React, { useContext, useState } from 'react';
import { BLUE_500_HEX } from 'shared';

import { media } from 'context';
import {
  ANIMATION_DURATION,
  Button,
  EmailInput,
  Grid,
  Icon,
  Layout,
  Link,
  LocationInput,
  Modal,
  PhoneInput,
  RequestState,
  Text,
  TextInput
} from 'ds';

import { createBrokerForm } from './requests';
import { CreatableBrokerForm } from './types';

interface Props {}

interface EditableBrokerForm extends Partial<CreatableBrokerForm> {
  callingCode?: string;
}

const BrokerReachOutForm: React.FC<Props> = () => {
  const { xs, isMobile } = useContext(media);
  const [requestState, setRequestState] = useState<RequestState>('ready');
  const [attributes, setAttributes] = useState<EditableBrokerForm>({});
  const { firstName, lastName, email, phone, brokerage, callingCode = '1' } = attributes;
  const [isPhoneValidFormat, setIsPhoneValidFormat] = useState<boolean>(false);
  const [isValidEmail, setIsValidEmail] = useState<boolean>(false);
  const [locationInputValue, setLocationInputValue] = useState<string>();
  const [submissionScreenIsVisible, setSubmissionScreenIsVisible] = useState<boolean>(false);

  const isValid = isCreatableBrokerForm(attributes) && isPhoneValidFormat && isValidEmail;

  const handleCreateBrokerForm = async () => {
    if (!isCreatableBrokerForm(attributes)) return;

    setRequestState('in_progress');

    try {
      await createBrokerForm({ ...attributes });
      setAttributes({});
      setLocationInputValue(undefined);
      setSubmissionScreenIsVisible(true);
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, ANIMATION_DURATION);
    } catch {}

    setRequestState('ready');
  };

  return (
    <Layout __id="broker-form">
      <Modal isVisible={submissionScreenIsVisible} isFullScreen onClose={() => setSubmissionScreenIsVisible(false)}>
        <Layout justify="center" align="center" height="100vh">
          <Layout direction="column" align="center">
            <Layout
              borderRadius="circular"
              height={132}
              width={132}
              __style={{ border: `6px solid ${BLUE_500_HEX}` }}
              align="center"
              justify="center"
            >
              <Icon size={64} name="checkmark" color="blue-500" stroke={2} />
            </Layout>
            <Layout marginTop={32}>
              <Text size="display-sm" color="blue-600">
                Thank you!
              </Text>
            </Layout>
            <Layout marginTop={32}>
              <Text size="headline-xs" bold={false} color="gray-400">
                Your form has been successfully submitted.
              </Text>
            </Layout>
          </Layout>
        </Layout>
      </Modal>
      <Layout>
        <Text size={isMobile ? 'headline-lg' : 'display-sm'} color="blue-600">
          Reach out to Codi’s team for more info
        </Text>
      </Layout>
      <Layout marginTop={32}>
        <Grid gapY={32} gapX={32} itemsPerRow={xs ? 1 : 2}>
          <TextInput
            label="First name"
            onChange={({ value }) => setAttributes({ ...attributes, firstName: value })}
            value={firstName}
            size="sm"
            required
          />
          <TextInput
            label="Last name"
            onChange={({ value }) => setAttributes({ ...attributes, lastName: value })}
            value={lastName}
            size="sm"
            required
          />
          <EmailInput
            label="Email"
            onChange={({ value }) => setAttributes({ ...attributes, email: value })}
            value={email}
            onValidate={({ valid }) => {
              setIsValidEmail(valid);
            }}
            size="sm"
            required
          />
          <PhoneInput
            label="Phone"
            value={phone}
            size="sm"
            callingCode={callingCode}
            onCallingCodeChange={code => setAttributes({ ...attributes, callingCode: code })}
            onChange={({ value }) => setAttributes({ ...attributes, phone: value })}
            onValidate={({ valid }) => {
              setIsPhoneValidFormat(valid);
            }}
            required
          />
          <TextInput
            label="Brokerage name"
            onChange={({ value }) => setAttributes({ ...attributes, brokerage: value })}
            value={brokerage}
            size="sm"
            required
          />
          <LocationInput
            label="City"
            onLocationChange={({ location }) => {
              setAttributes({ ...attributes, city: location });
              setLocationInputValue(location);
            }}
            onChange={({ value }) => {
              setLocationInputValue(value);
              setAttributes({ ...attributes, city: undefined });
            }}
            value={locationInputValue}
            types={['(cities)']}
            size="sm"
            required
          />
        </Grid>
      </Layout>
      <Layout marginTop={32}>
        <Text size="body-xs" color="gray-500">
          By continuing, you agree to Codi’s{' '}
          <Link href="/terms">
            <Text color="gray-500" size="body-xs" underline inline>
              Terms of Service
            </Text>
          </Link>{' '}
          and{' '}
          <Link href="/privacy">
            <Text color="gray-500" size="body-xs" underline inline>
              Privacy Policy
            </Text>
          </Link>
          .
        </Text>
      </Layout>
      <Layout marginTop={32}>
        <Button
          text="Submit"
          size="md"
          type="primary"
          disabled={requestState === 'in_progress' || !isValid}
          onClick={handleCreateBrokerForm}
          fullWidth={xs}
        />
      </Layout>
    </Layout>
  );
};

function isCreatableBrokerForm(attributes: EditableBrokerForm): attributes is CreatableBrokerForm {
  return (
    !!attributes.firstName &&
    !!attributes.lastName &&
    !!attributes.email &&
    !!attributes.phone &&
    !!attributes.brokerage &&
    !!attributes.city
  );
}

export default BrokerReachOutForm;
