import { CreateableWorkspace } from 'shared';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { DateTime } from 'luxon';

import { CreatablePropertyUser } from './types';

export type AddPropertyState = {
  exitModalIsVisible: boolean;
  workspace: Partial<CreateableWorkspace>;
  user: Partial<CreatablePropertyUser>;
};

const initialState: AddPropertyState = {
  exitModalIsVisible: false,
  workspace: { first_hosting_availability: DateTime.now().toISODate() },
  user: { calling_code: '1' }
};

const AddPropertySlice = createSlice({
  name: 'AddProperty',
  initialState,
  reducers: {
    setExitModalIsVisible: (state, { payload }: PayloadAction<boolean>) => {
      state.exitModalIsVisible = payload;
    },
    updateWorkspace: (state, { payload }: PayloadAction<Partial<CreateableWorkspace>>) => {
      state.workspace = { ...state.workspace, ...payload };
    },
    setWorkspace: (state, { payload }: PayloadAction<Partial<CreateableWorkspace>>) => {
      state.workspace = payload;
    },
    updateUser: (state, { payload }: PayloadAction<Partial<CreatablePropertyUser>>) => {
      state.user = { ...state.user, ...payload };
    },
    reset: state => ({ ...initialState, user: state.user })
  }
});

export const { actions } = AddPropertySlice;

export default AddPropertySlice.reducer;
