import React from 'react';

import { FORMATTED_TYPEAHEAD_RESULT_SIZE_CONFIG } from 'ds/ComboBox/constants';
import Icon from 'ds/Icon';
import Img from 'ds/Img';
import Layout from 'ds/Layout';
import Text from 'ds/Text';
import { FieldSize } from 'ds/types';
import { useFieldSize } from 'hooks';

import { SearchableUser } from './types';
import { getHighlightedPartsOfText } from 'ds/utils';

interface Props {
  size?: FieldSize;
  query?: string;
  user: SearchableUser;
  maxWidth: number;
  rightElement?: JSX.Element | null;
}

const getHighlightedText = ({ text, query }: { text: string; query?: string }) => {
  if (!query) return text;

  const parts = getHighlightedPartsOfText({ text, query });

  return (
    <span>
      {parts.map((part, index) =>
        part.toLowerCase() === query.toLowerCase() ? <b key={part + index}>{part}</b> : part
      )}
    </span>
  );
};

const UserResult: React.FC<Props> = ({
  maxWidth,
  query,
  user: { id, email, first, last, image, avatar_url },
  rightElement,
  ...props
}) => {
  const scaledSize = useFieldSize();
  const size = props.size || scaledSize;
  const name = `${first} ${last}`;

  const highlightedName = getHighlightedText({ text: name, query });
  const highlightedEmail = getHighlightedText({ text: email, query });
  const highlightedId = getHighlightedText({ text: id.toString(), query });
  const { height, imageSize, labelFontSize } = FORMATTED_TYPEAHEAD_RESULT_SIZE_CONFIG[size];
  const firstLastDisplay = !!first && !!last;

  return (
    <Layout
      height={height}
      paddingX={16}
      paddingY={8}
      direction="row"
      justify="space-between"
      flexGrow={1}
      maxWidth={maxWidth}
    >
      <Layout
        minWidth={imageSize}
        width={imageSize}
        height={imageSize}
        borderRadius={4}
        overflow="hidden"
        marginBottom={2}
        flex
      >
        {!!image ? (
          <Img
            srcKey={image.key}
            folder={image.folder}
            alt={name}
            style={{ borderRadius: '50%', userSelect: 'none' }}
            draggable={false}
            width={imageSize}
            height={imageSize}
          />
        ) : avatar_url && first && last ? (
          <img src={avatar_url} style={{ width: imageSize, height: imageSize, borderRadius: '50%' }} alt={name} />
        ) : (
          <Layout
            color="gray-50"
            borderRadius="circular"
            width={imageSize}
            height={imageSize}
            align="center"
            justify="center"
          >
            <Icon name="avatar" color="gray-400" size="md" />
          </Layout>
        )}
      </Layout>
      <Layout minWidth={12} width={12} />

      {firstLastDisplay ? (
        <Layout
          direction="column"
          justify="center"
          align={firstLastDisplay ? 'flex-start' : 'center'}
          display="inline-block"
          flexGrow={1}
        >
          <Layout display="grid">
            <Text size={labelFontSize} ellipsis>
              {highlightedName}
            </Text>
          </Layout>
          <Layout marginTop={2} />
          <Layout display="grid">
            <Text size="body3" color="gray-400" ellipsis>
              {highlightedEmail}
            </Text>
          </Layout>
        </Layout>
      ) : (
        <Layout align="center" justify="flex-start" flexGrow={1}>
          <Text size={labelFontSize} ellipsis>
            {highlightedEmail}
          </Text>
        </Layout>
      )}

      <Layout minWidth={12} width={12} />
      {rightElement === undefined ? (
        <Text size="body-xs" color="gray-700">
          {highlightedId}
        </Text>
      ) : (
        rightElement
      )}
    </Layout>
  );
};

export default UserResult;
