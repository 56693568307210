import { Updatable } from 'shared/types';

export function updateInList<T extends { id: string | number }>(list: Updatable<T>[], update: Updatable<T>) {
  const item = list.find(o => o.id === update.id);

  if (!item) return;

  safeAssign(item, update);
}

export function safeAssign<T>(object: T | undefined, update: Partial<T>) {
  if (!object) return;

  Object.assign(object, update);
}
