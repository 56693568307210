import { axios, Listing } from 'shared';

export function findWorkspaces({ workspaceIds }: { workspaceIds: number[] }) {
  const searchParams = new URLSearchParams();

  for (const id of workspaceIds) {
    searchParams.append('workspace_ids[]', id.toString());
  }

  return axios.get<Listing[]>('/v1/listings/find', { params: searchParams });
}
