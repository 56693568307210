import React, { useEffect } from 'react';
import poweredByGoogle from 'shared/assets/powered-by-google-on-white-hdpi.png';

import { Icon, Layout, Text } from 'ds';
import { highlightMatches } from 'ds/utils';

import { Result } from './types';

interface ResultProps {
  size: 'sm' | 'lg';
  results: Result[];
  onLocationSelect: (result: Result) => void;
  isBottomSheet?: boolean;
  setSelectedResult?: (result: Result) => void;
  searchValue?: string;
  focusedIndex: number;
}

const LocationResults: React.FC<ResultProps> = ({
  results,
  size,
  onLocationSelect,
  isBottomSheet,
  setSelectedResult,
  searchValue,
  focusedIndex
}) => {
  const itemsVisibleCount = isBottomSheet ? 5 : 3;

  const result = results[focusedIndex];

  useEffect(() => {
    if (!result || !setSelectedResult) return;

    setSelectedResult(result);
  }, [result, setSelectedResult]);

  return (
    <Layout position="relative" {...(results.length ? { paddingBottom: 16 } : { height: 0 })}>
      {!searchValue && (
        <Layout paddingX={24} {...(isBottomSheet ? { paddingTop: 32 } : { paddingY: 16 })}>
          <Text size="body3" color="gray-600" bold>
            SUGGESTED
          </Text>
        </Layout>
      )}
      <ul
        style={{
          listStyleType: 'none',
          padding: searchValue ? '16px 0 0 0' : 0,
          margin: 0
        }}
      >
        {results.slice(0, itemsVisibleCount).map(({ neighborhood, city, region, country, place_id }, index) => (
          <ResultItem
            key={neighborhood + city + region + country}
            neighborhood={neighborhood}
            city={city}
            region={region}
            country={country}
            place_id={place_id}
            focused={isBottomSheet ? false : index === focusedIndex}
            onLocationSelect={onLocationSelect}
            size={size}
            isBottomSheet={isBottomSheet}
            searchValue={searchValue}
          />
        ))}
      </ul>
      {searchValue && results.length > 0 && (
        <Layout justify="flex-end" marginTop={16} paddingRight={isBottomSheet ? undefined : 8}>
          <Layout width={120}>
            <img src={poweredByGoogle} alt="Powered by Google" />
          </Layout>
        </Layout>
      )}
    </Layout>
  );
};

interface ResultItemProps extends Result {
  focused: boolean;
  size: 'sm' | 'lg';
  onLocationSelect: (result: Result) => void;
  searchValue?: string;
  isBottomSheet?: boolean;
}

const ResultItem: React.FC<ResultItemProps> = ({
  neighborhood,
  city,
  region,
  country,
  place_id,
  focused,
  onLocationSelect,
  searchValue,
  isBottomSheet
}) => {
  const label = `${!!neighborhood ? `${neighborhood}, ` : ''}${city}, ${region}${country ? `, ${country}` : ''}`;

  const display = highlightMatches({
    label,
    query: searchValue,
    MatchedWrapper: ({ children }) => (
      <Text size={isBottomSheet ? 'body1' : 'h6'} scale={isBottomSheet} bold inline>
        {children}
      </Text>
    )
  });

  return (
    <li
      onClick={() => {
        onLocationSelect({ neighborhood, city, region, country, place_id });
      }}
    >
      <Layout
        paddingX={24}
        paddingY={isBottomSheet ? 20 : 16}
        align="center"
        cursor="pointer"
        color={focused ? 'blue-50' : undefined}
        hoverColor="blue-50"
      >
        <Layout marginRight={16} display="inline-flex">
          <Icon name="addressPinOutline" size={isBottomSheet ? 'sm' : 'md'} color="black" />
        </Layout>
        <Text size={isBottomSheet ? 'body1' : 'h6'} scale={isBottomSheet} bold={false} inline>
          {display}
        </Text>
      </Layout>
    </li>
  );
};

export default LocationResults;
