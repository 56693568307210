import React, { useContext } from 'react';
import { GRAY_400_HEX } from 'shared';

import { media } from 'context';
import { IconButton, Layout, Text } from 'ds';
import { formatUsd } from 'helpers';
import { track } from 'lib/analytics';

interface Props {
  placeholder?: string;
  value?: number;
  onChange: (value?: number) => void;
  label: string;
  max: number;
  isCurrency?: boolean;
  stepValue?: number;
}

const NUMBER_REGEX = /\D/g;

const HomePageNumberStepper: React.FC<Props> = ({
  placeholder,
  value,
  onChange,
  label,
  max,
  isCurrency = false,
  stepValue = 1
}) => {
  const { xs, md } = useContext(media);

  return (
    <Layout display="inline-flex" direction="column">
      <Layout align="center">
        <IconButton
          context="home headcount minus"
          value={value}
          size="md"
          onClick={() => {
            if (!value) return;

            onChange(Math.max(0, value - stepValue));
          }}
          type="blueLight"
          name="minus"
          disabled={!value}
        />
        <Layout {...(xs ? {} : { width: md ? 180 : 238 })} marginX={12}>
          <input
            autoComplete="off"
            placeholder={placeholder}
            inputMode="numeric"
            style={{
              borderStyle: 'none',
              fontSize: xs ? 20 : 32,
              lineHeight: xs ? '30px' : '42px',
              outline: 'none',
              background: 'none',
              color: GRAY_400_HEX,
              zIndex: 1,
              textAlign: 'center',
              width: '100%'
            }}
            value={value === undefined ? '' : isCurrency ? formatUsd(value, false) : value.toLocaleString()}
            onChange={({ target: { value } }) => {
              const number = parseInt(value.replaceAll(NUMBER_REGEX, ''));

              if (isNaN(number)) {
                onChange(undefined);
              } else if (number > max) {
                return;
              } else {
                onChange(number);
              }
            }}
            onBlur={e => {
              track('Element Interacted', {
                type: 'input',
                name: 'home headcount',
                value: e.target.value || undefined,
                action: 'onBlur'
              });
            }}
            onFocus={e => {
              track('Element Interacted', {
                type: 'input',
                name: 'home headcount',
                value: e.target.value || undefined,
                action: 'onFocus'
              });
            }}
          />
          <Layout color="blue-300" height={2} marginTop={12} />
        </Layout>
        <IconButton
          size="md"
          context="home headcount plus"
          value={value}
          onClick={() => {
            onChange(Math.min(max, (value || 0) + stepValue));
          }}
          type="blueLight"
          name="plus"
          disabled={(value || 0) >= max}
        />
      </Layout>
      <Layout marginTop={24} justify="center">
        <Text size="body-md" color="gray-400">
          {label}
        </Text>
      </Layout>
    </Layout>
  );
};

export default HomePageNumberStepper;
