export const ICON_NAME_TO_ROTATION = {
  ellipsisH: 90,
  leftChevron: 270,
  downChevron: 180,
  rightChevron: 90,
  leftArrow: 270,
  downArrow: 180,
  rightArrow: 90,
  upCaret: 180,
  leftCaret: 90,
  rightCaret: 270
};

export const ICON_NAMES = [
  'addReaction',
  'addressPin',
  'addressPinOutline',
  'archive',
  'attachment',
  'avatar',
  'beverage',
  'beverageSupplies',
  'bike',
  'building',
  'buildingNew',
  'buildingRetail',
  'calendar',
  'callout',
  'camera',
  'centerMap',
  'chair',
  'check',
  'checkmark',
  'cleaning',
  'cleaningDeep',
  'cleaningLight',
  'clear',
  'clipboard',
  'clipboardNoPageFold',
  'clock',
  'close',
  'coffee_tea',
  'coffee',
  'comfortable_chairs',
  'comment',
  'copy',
  'creditCard',
  'dashboard',
  'desk',
  'detector',
  'dollarSign',
  'downArrow',
  'downCaret',
  'downChevron',
  'download',
  'editPen',
  'ellipsisH',
  'ellipsisV',
  'envelope',
  'exclamation',
  'externalLink',
  'fan',
  'featherDuster',
  'filter',
  'flag',
  'flame',
  'flameOutline',
  'flipchart',
  'gift',
  'hamburger',
  'handle',
  'heart',
  'heartOutline',
  'house',
  'image',
  'images',
  'info',
  'keyCard',
  'kitchen',
  'leftArrow',
  'leftCaret',
  'leftChevron',
  'list',
  'locationArrow',
  'locationArrowOutline',
  'lock',
  'map',
  'meeting_room',
  'meetingRoom',
  'membership',
  'membership2',
  'microwave',
  'minus',
  'mobilePhone',
  'monitor',
  'music',
  'newspaper',
  'noise',
  'notification',
  'outdoors',
  'outdoors2',
  'outdoorsFlower',
  'page',
  'pageBlank',
  'parking',
  'payout',
  'pets',
  'phone_call_area',
  'phone',
  'plus',
  'printer',
  'privacy',
  'private_parking',
  'projector',
  'questionCircle',
  'questionMark',
  'refresh',
  'reservation',
  'rightArrow',
  'rightCaret',
  'rightChevron',
  'ruler',
  'scale',
  'search',
  'settings',
  'share',
  'smart_lock',
  'snack',
  'sort',
  'sortControl',
  'sortAsc',
  'sortDesc',
  'sparkle',
  'sparklingWater',
  'standing_desk',
  'star',
  'starOutline',
  'support',
  'surveillance',
  'tableLarge',
  'tableSmall',
  'thumbsDown',
  'thumbsDownFilled',
  'thumbsUp',
  'thumbsUpFilled',
  'ticket',
  'transit',
  'transportation',
  'trashcan',
  'tv',
  'upArrow',
  'upCaret',
  'upChevron',
  'upload',
  'uploadCloud',
  'users',
  'userStar',
  'walk',
  'whiteboard',
  'wifi',
  'shoppingCart',
  'miscellaneous',
  'noSymbol',
  'calendarEdit',
  'watch',
  'watching'
] as const;

export type IconNames = typeof ICON_NAMES;
export type IconName = typeof ICON_NAMES[number];
export type IconStroke = 1.5 | 2;
export type IconSize = 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl' | 'xxxl';
