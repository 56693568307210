import { BOUNDS, COORDS } from 'shared/constants';
import { Bounds, EditableImage, Listing, ListingStatus, ListingWorkspace, Workspace } from 'shared/types';
import {
  CreateableSpace,
  CreateableWorkspace,
  CreateableWorkstation,
  EditableAccessInfo,
  SaveableSpace,
  SaveableWorkspace,
  SaveableWorkstation,
  SearchWorkspacesParams
} from './types';

import axios from 'shared/lib/axios';
import { getBlob } from 'shared/file';

interface PathParams {
  workspaceId: number;
}

function createWorkspacePath() {
  return '/v1/workspaces';
}

function saveWorkspacePath({ workspaceId }: PathParams) {
  return `/v1/workspaces/${workspaceId}`;
}

function workspacePath({ workspaceId }: PathParams) {
  return `/v1/workspaces/${workspaceId}`;
}

function userWorkspacesPath({ userId }: { userId: number }) {
  return `/v1/users/${userId}/workspaces`;
}

function createSpacePath({ workspaceId }: PathParams) {
  return `${workspacePath({ workspaceId })}/spaces`;
}

interface SpacePathParams extends PathParams {
  spaceId: number;
}

function saveOrDeleteSpacePath({ workspaceId, spaceId }: SpacePathParams) {
  return `${workspacePath({ workspaceId })}/spaces/${spaceId}`;
}

function createWorkstationPath({ workspaceId, spaceId }: SpacePathParams) {
  return `${saveOrDeleteSpacePath({ workspaceId, spaceId })}/workstations`;
}

interface WorkstationPathParams extends SpacePathParams {
  workstationId: number;
}

function saveOrDeleteWorkstationPath({ workspaceId, spaceId, workstationId }: WorkstationPathParams) {
  return `${saveOrDeleteSpacePath({ workspaceId, spaceId })}/workstations/${workstationId}`;
}

function listingPath({ slug }: { slug: number | string }) {
  return `/v1/listings/${slug}`;
}

function saveAccessInfoPath({ workspaceId }: PathParams) {
  return `/v1/workspaces/${workspaceId}/update_access_info`;
}

function saveListingStatusPath({ workspaceId }: PathParams) {
  return `/v1/workspaces/${workspaceId}/listing_status`;
}

function searchWorkspacesPath() {
  return '/v1/listings';
}

export function saveWorkspace({ workspace }: { workspace: SaveableWorkspace }) {
  const {
    address: address_attributes,
    workspace_price: workspace_price_attributes,
    workspace_features: workspace_features_attributes,
    wifis: workspace_wifis_attributes,
    instructions: workspace_instructions_attributes,
    ...workspaceAttributes
  } = workspace;

  return axios.patch<Workspace>(saveWorkspacePath({ workspaceId: workspace.id }), {
    ...workspaceAttributes,
    address_attributes,
    workspace_price_attributes,
    workspace_features_attributes,
    workspace_wifis_attributes,
    workspace_instructions_attributes
  });
}

export function createWorkspace({ ...params }: { workspace: CreateableWorkspace }) {
  const { images, ...workspace } = params.workspace;

  const {
    address: address_attributes,
    workspace_price: workspace_price_attributes,
    workspace_users: workspace_users_attributes,
    licensor_id,
    square_feet,
    building_class,
    external_listing_url,
    first_hosting_availability,
    notes,
    user_type
  } = workspace;

  return axios
    .post<Workspace>(createWorkspacePath(), {
      licensor_id,
      address_attributes,
      workspace_price_attributes,
      workspace_users_attributes,
      square_feet,
      building_class,
      external_listing_url,
      first_hosting_availability,
      notes,
      user_type
    })
    .then(response => {
      if (images && images.length) {
        const image = images[0];
        return uploadImage({ image, workspaceId: response.data.id });
      } else {
        return response;
      }
    });
}

function uploadImage({ image, workspaceId }: { image: EditableImage; workspaceId: number }) {
  const formData = new FormData();

  // TODO: this only uploads one image
  formData.append('image', image.file!);

  return axios.post<Workspace>(`/v1/workspaces/${workspaceId}/images`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
}

export function createSpace({ workspaceId, space }: { workspaceId: number; space: CreateableSpace }) {
  return axios.post<Workspace>(createSpacePath({ workspaceId }), {
    space
  });
}

export function saveSpace({ workspaceId, space }: { workspaceId: number; space: SaveableSpace }) {
  return axios.patch<Workspace>(saveOrDeleteSpacePath({ workspaceId, spaceId: space.id }), {
    space
  });
}

export function deleteSpace({ workspaceId, spaceId }: { workspaceId: number; spaceId: number }) {
  return axios.delete<Workspace>(saveOrDeleteSpacePath({ workspaceId, spaceId }));
}

export function createWorkstation({
  workspaceId,
  spaceId,
  workstation
}: {
  workspaceId: number;
  spaceId: number;
  workstation: CreateableWorkstation;
}) {
  return axios.post<Workspace>(createWorkstationPath({ workspaceId, spaceId }), {
    workstation
  });
}

export function saveWorkstation({
  workspaceId,
  spaceId,
  workstation
}: {
  workspaceId: number;
  spaceId: number;
  workstation: SaveableWorkstation;
}) {
  return axios.patch<Workspace>(saveOrDeleteWorkstationPath({ workspaceId, spaceId, workstationId: workstation.id }), {
    workstation
  });
}

export function deleteWorkstation({
  workspaceId,
  spaceId,
  workstationId
}: {
  workspaceId: number;
  spaceId: number;
  workstationId: number;
}) {
  return axios.delete<Workspace>(saveOrDeleteWorkstationPath({ workspaceId, spaceId, workstationId }));
}

export function getUserWorkspaces({ userId }: { userId: number }) {
  return axios.get<Workspace[]>(userWorkspacesPath({ userId }));
}

export function getWorkspace(params: PathParams) {
  return axios.get<Workspace>(workspacePath(params));
}

export function getListing({ slug, available_short_term }: { slug: number | string; available_short_term?: boolean }) {
  return axios.get<ListingWorkspace>(listingPath({ slug }), { params: { available_short_term } });
}

export interface SaveableCheckinInstruction {
  description: string;
  sequence: number;
  photo_url: string;
}

interface SaveCheckinInstructionParams extends PathParams {
  checkinInstructions: SaveableCheckinInstruction[];
  vendorCheckinInstructions: SaveableCheckinInstruction[];
}

export async function saveCheckinInstructions({
  workspaceId,
  checkinInstructions,
  vendorCheckinInstructions
}: SaveCheckinInstructionParams) {
  const formData = new FormData();

  for (const index of [0, 1, 2]) {
    const { description, photo_url } = checkinInstructions[index] || {};
    formData.append(`checkin_instructions_${index + 1}`, description || '');

    if (photo_url && photo_url.startsWith('blob')) {
      const blob = await getBlob(photo_url);

      formData.append(`checkin_photo_${index + 1}`, blob);
    } else {
      formData.append(`checkin_photo_${index + 1}`, photo_url || '');
    }
  }

  for (const index of [0, 1, 2]) {
    const { description, photo_url } = vendorCheckinInstructions[index] || {};
    formData.append(`vendor_checkin_instructions_${index + 1}`, description || '');

    if (photo_url && photo_url.startsWith('blob')) {
      const blob = await getBlob(photo_url);

      formData.append(`vendor_checkin_photo_${index + 1}`, blob);
    } else {
      formData.append(`vendor_checkin_photo_${index + 1}`, photo_url || '');
    }
  }

  return axios.put<Workspace>(saveAccessInfoPath({ workspaceId }), formData);
}

interface SaveAccessInfoParams extends PathParams {
  access_info: Partial<EditableAccessInfo>;
}

export function saveAccessInfo({ workspaceId, access_info: { wifi_name, wifi_password } }: SaveAccessInfoParams) {
  return axios.put<Workspace>(saveAccessInfoPath({ workspaceId }), { wifi_name, wifi_password });
}

interface SaveListingStatusParams extends PathParams {
  listing_status: ListingStatus;
}
export function saveListingStatus({ workspaceId, listing_status }: SaveListingStatusParams) {
  return axios.post<Workspace>(saveListingStatusPath({ workspaceId }), {
    listing_status
  });
}

export function searchWorkspaces({ dist, bounds, date, center }: SearchWorkspacesParams) {
  const params = {
    ...(bounds ? transformBounds(bounds) : {}),
    dist,
    search_latlon: `${center.lat},${center.lng}`,
    date
  };
  return axios.get<Listing[]>(searchWorkspacesPath(), { params });
}

interface BoundsParam {
  nw_lat: number;
  nw_lng: number;
  ne_lat: number;
  ne_lng: number;
  sw_lat: number;
  sw_lng: number;
  se_lat: number;
  se_lng: number;
}

export function transformBounds(bounds: Bounds): BoundsParam {
  const transformed: Partial<BoundsParam> = {};

  BOUNDS.forEach(bound => {
    COORDS.forEach(coord => {
      transformed[`${bound}_${coord}` as const] = bounds[bound][coord];
    });
  });

  return transformed as BoundsParam;
}
