import React, { useContext, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { User } from 'shared';
import { usePreviousValue } from 'beautiful-react-hooks';

import { app } from 'context';
import { Button, HEADER_MAX_WIDTH, HEADER_PORTAL_ID, Icon, Layout, Link, Logo, TextButton } from 'ds';
import { useOnClickOutside } from 'ds/helpers';
import { EnterpriseNavigationPage } from 'lib/butter/types';
import { OFFICE_REQUEST_PATH, ROOT_PATH } from 'routes';
import { selectCmsPage } from 'store/App/selectors';
import { actions as authActions } from 'store/Auth';
import { actions as officeRequestActions } from 'store/OfficeRequest';
import { useAppSelector } from 'store/hooks';

import Menu from './Menu';
import { MENU_PADDING_Y } from './constants';
import NavItems from '../NavItems';
import { PUBLIC_NAV_HEIGHT } from '../constants';

interface Props {
  showCta?: boolean;
  isSearchPage?: boolean;
  user?: User | null;
  isTransparentMode?: boolean;
  textOpacity?: number;
  openMenuIndex: number;
  setOpenMenuIndex: (index: number) => void;
}

const TRACK_CONTEXT = 'nav bar';

const Navigation: React.FC<Props> = ({
  showCta,
  isSearchPage,
  user,
  isTransparentMode,
  textOpacity,
  openMenuIndex,
  setOpenMenuIndex
}) => {
  const { contentPaddingX, navBarHeight } = useContext(app);
  const { pathname } = useLocation();
  const isWorkspacesOrSearchPage = ['workspaces'].includes(pathname.split('/')[1]) || isSearchPage;
  const page = useAppSelector(s => selectCmsPage(s, 'enterprise-navigation'));

  const [isHidingMenu, setIsHidingMenu] = useState<boolean>(false);
  const [isOpeningMenu, setIsOpeningMenu] = useState<boolean>(false);
  const [resizeOrdinal, setResizeOrdinal] = useState<number>(0);
  const previousOpenMenuIndex = usePreviousValue(openMenuIndex);
  const menuHeightsRef = useRef<(number | undefined)[]>([]);
  const headerRef = useRef<HTMLDivElement>(null);
  const closeMenu = () => setOpenMenuIndex(-1);
  const dispatch = useDispatch();
  const openMenuHeight = menuHeightsRef.current[openMenuIndex];
  const openHeight = openMenuHeight ? openMenuHeight + MENU_PADDING_Y * 2 : undefined;
  const showMenus = !isSearchPage;

  useOnClickOutside(headerRef, () => {
    if (openMenuIndex === -1) return;

    setIsHidingMenu(true);
    setOpenMenuIndex(-1);

    setTimeout(() => {
      setIsHidingMenu(false);
    }, 400);
  });

  if (!page) return <Layout height={PUBLIC_NAV_HEIGHT} />;

  const { menus } = (page as EnterpriseNavigationPage).fields;
  const useOpenMenuStyle = openMenuIndex > -1 || isHidingMenu;

  return (
    <>
      <Layout
        justify="center"
        __ref={headerRef}
        color={isTransparentMode && openMenuIndex === -1 ? undefined : 'white'}
      >
        <Layout
          maxWidth={isWorkspacesOrSearchPage ? undefined : HEADER_MAX_WIDTH}
          justify="space-between"
          align="center"
          marginX={contentPaddingX}
          height={navBarHeight}
          flexGrow={1}
          color={isTransparentMode ? undefined : 'white'}
          zIndex={1}
        >
          <Layout align="center" flexGrow={1} opacity={textOpacity ? textOpacity : undefined}>
            <Link href={ROOT_PATH} context={TRACK_CONTEXT} name="logo">
              <Logo color={isTransparentMode && !textOpacity ? 'white' : 'blue-500'} />
            </Link>
            <Layout __id={HEADER_PORTAL_ID} />
            {showMenus && (
              <Layout marginLeft={88} flex>
                {menus.map(({ name, path }, index) => {
                  const isOpen = openMenuIndex === index;
                  const color = 'gray-900';

                  return (
                    <Layout key={name} marginRight={40} align="center">
                      {path ? (
                        <Link href={path} size="body2" color={isTransparentMode && !textOpacity ? 'white' : 'gray-900'}>
                          {name}
                        </Link>
                      ) : (
                        <TextButton
                          name={name}
                          onClick={() => {
                            if (index === openMenuIndex) {
                              setIsHidingMenu(true);

                              setTimeout(() => {
                                setIsHidingMenu(false);
                              }, 400);

                              setOpenMenuIndex(-1);
                            } else {
                              setOpenMenuIndex(index);
                              setIsOpeningMenu(true);
                              setTimeout(() => {
                                setIsOpeningMenu(false);
                              }, 200);
                            }
                          }}
                          textSize="body2"
                          semibold={true}
                          hoverColor="black"
                          hoverOpacity={true}
                          text={
                            <Layout align="center" inline>
                              {name}
                              <Layout
                                inline
                                display="inline-flex"
                                marginLeft={8}
                                __style={{
                                  transform: isOpen ? 'rotate(0deg)' : 'rotate(180deg)',
                                  transition: 'transform 200ms cubic-bezier(0.4, 0, 0.2, 1)'
                                }}
                              >
                                <Icon
                                  name="upChevron"
                                  size="xs"
                                  color={isTransparentMode && !textOpacity ? 'white' : 'gray-900'}
                                />
                              </Layout>
                            </Layout>
                          }
                          color={isTransparentMode && !textOpacity ? 'white' : color}
                        />
                      )}
                    </Layout>
                  );
                })}
              </Layout>
            )}
          </Layout>
          <Layout align="center">
            {user === undefined ? null : (
              <>
                {!isSearchPage && showCta && (
                  <Layout marginRight={16}>
                    <Button
                      size="sm"
                      text="Get started"
                      type="primary"
                      href={OFFICE_REQUEST_PATH}
                      onClick={() => {
                        dispatch(officeRequestActions.setEntry('home-page-get-started'));
                      }}
                    />
                  </Layout>
                )}
                {user ? (
                  <Layout marginLeft={16}>
                    <NavItems isTransparentMode={isTransparentMode && openMenuIndex === -1} opacity={textOpacity} />
                  </Layout>
                ) : (
                  <Layout marginLeft={24} opacity={textOpacity ? textOpacity : undefined}>
                    <Link
                      href="/sign-in"
                      onClick={() => dispatch(authActions.setAuthFlowType('default'))}
                      color={isTransparentMode && !textOpacity ? 'white' : 'black'}
                      size="body2"
                      semibold
                    >
                      Sign in
                    </Link>
                  </Layout>
                )}
              </>
            )}
          </Layout>
        </Layout>
        <Layout
          __className="NavigationMenu-container"
          position="absolute"
          color="white"
          opacity={isTransparentMode && openMenuIndex === -1 ? 0 : useOpenMenuStyle ? 1 : 0.2}
          boxShadow={useOpenMenuStyle ? '0px 13px 23px rgba(0, 0, 0, 0.15)' : undefined}
          top={navBarHeight}
          paddingY={openMenuIndex > -1 ? 32 : 0}
          justify="center"
          borderColor={openMenuIndex > -1 ? 'gray-50' : undefined}
          borderTop
          zIndex={-1}
          __style={{ height: openMenuIndex > -1 && openHeight ? openHeight : 0, width: document.body.clientWidth }}
          flex
        >
          {menus.map((menu, index) => {
            const isHiding = isHidingMenu && index === previousOpenMenuIndex;
            const height = menuHeightsRef.current[index];
            const isOpen = index === openMenuIndex;

            return (
              <Layout
                top={openMenuIndex === -1 ? -(height || 2000) : undefined}
                key={menu.name}
                position="absolute"
                zIndex={isOpen ? 1 : -1}
                height={isOpen ? undefined : 0}
              >
                <Menu
                  closeMenu={closeMenu}
                  setHeight={height => {
                    const heights = [...menuHeightsRef.current];
                    heights[index] = height;
                    menuHeightsRef.current = heights;
                    setResizeOrdinal(resizeOrdinal + 1);
                  }}
                  menu={menu}
                  openMenuIndex={openMenuIndex}
                  index={index}
                  isHiding={isHiding}
                  isOpening={isOpeningMenu && isOpen}
                  resizeOrdinal={resizeOrdinal}
                />
              </Layout>
            );
          })}
        </Layout>
      </Layout>
    </>
  );
};

export default Navigation;
