import axios from 'shared/lib/axios';

import { SitemapResponse } from './types';

function sitemapPath() {
  return '/v1/sitemaps/html';
}

export function getSitemap() {
  return axios.get<SitemapResponse>(sitemapPath());
}
