import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { CreateUserParams, CreateUserResponse, createUser, isApiErrorResponse, uploadAvatar } from 'shared';

import { setToken } from 'helpers/auth';
import { ResizedImg, resizeImg } from 'helpers/images';

import AvatarUpdate from 'components/AvatarUpdate';

import { media } from 'context';
import { Button, Layout, Text, TextInput } from 'ds';
import { useKeyPress } from 'ds/helpers';

export interface Props {
  email: string;
  firstname: string | undefined;
  setFirstname: (firstname: string) => void;
  lastname: string | undefined;
  setLastname: (lastname: string) => void;
  onSignupSuccess: (data: CreateUserResponse) => void;
  submitting: boolean;
}

const SignUp: React.FC<Props> = ({
  email,
  firstname,
  setFirstname,
  lastname,
  setLastname,
  onSignupSuccess,
  submitting
}) => {
  const { xs } = useContext(media);
  const [avatar, setAvatar] = useState<File>();
  const [avatarUrl, setAvatarUrl] = useState<string>();

  const [requestInProgress, setRequestInProgress] = useState<boolean>(false);
  const [error, setError] = useState<string>();
  const [firstnameError, setFirstnameError] = useState<string>();
  const [lastnameError, setLastnameError] = useState<string>();

  const { pathname } = useLocation();

  useEffect(() => {
    async function resizeAvatar() {
      if (!avatar) return;

      const { url } = (await resizeImg({ file: avatar, width: 400, height: 400 })) as ResizedImg;
      setAvatarUrl(url);
    }

    if (!!avatar) {
      resizeAvatar();
    }
  }, [avatar]);

  useKeyPress((e: KeyboardEvent) => {
    if (e.key === 'Enter' && firstname && lastname && email) {
      handleSubmit();
    }
  });

  const handleSubmit = async () => {
    if (!firstname) {
      setFirstnameError('First name is required.');
    }
    if (!lastname) {
      setLastnameError('Last name is required.');
    }
    if (!firstname || !lastname) return;

    setRequestInProgress(true);

    const userParams: CreateUserParams = {
      user: {
        firstname,
        lastname,
        email
      },
      pathname
    };

    createUser(userParams)
      .then(({ data }) => {
        if (!!avatarUrl) {
          setToken(data.token);
          uploadAvatar({ url: avatarUrl, id: data.user.id }).then(response => {
            setRequestInProgress(false);
            onSignupSuccess({ user: { ...data.user, avatar_url: response.data.avatar_url }, token: data.token });
          });
        } else {
          setRequestInProgress(false);
          onSignupSuccess(data);
        }
      })
      .catch(error => {
        if (isApiErrorResponse(error) && error.type === 'HivenException::JwtTokenNotAuthorized') {
          setToken(process.env.REACT_APP_API_GENERIC_TOKEN);
          setError('The signup token has expired. Please go back and request a new verification code.');
        }
        setRequestInProgress(false);
      });
  };

  return (
    <Layout direction="column" align={xs ? 'center' : undefined} width="100%">
      <Layout marginTop={xs ? 32 : 24} />
      <Text size={xs ? 'h6' : 'h5'} align={xs ? 'center' : undefined}>
        Finish your profile.
      </Text>
      <Layout marginTop={24} />
      <AvatarUpdate
        onChange={(file: File) => {
          setAvatar(file);
        }}
        avatarUrl={avatarUrl}
        avatarSize={136}
      />
      <Layout marginTop={24} />
      <Text color="gray-900" semibold size={xs ? 'body2' : 'h6'}>
        {email}
      </Text>
      <Layout marginTop={24} />
      <Layout width="100%">
        <TextInput
          value={firstname}
          onChange={({ value }) => {
            setFirstnameError('');
            setFirstname(value);
          }}
          label="First name"
          autoFocus
          size={xs ? 'sm' : 'md'}
          error={firstnameError}
          required
        />
      </Layout>
      <Layout marginTop={12} />
      <Layout width="100%">
        <TextInput
          value={lastname}
          onChange={({ value }) => {
            setLastnameError('');
            setLastname(value);
          }}
          label="Last name"
          size={xs ? 'sm' : 'md'}
          error={lastnameError}
          required
        />
      </Layout>
      <Layout marginTop={24} />
      <Button
        type="primary"
        onClick={() => handleSubmit()}
        size={xs ? 'sm' : 'md'}
        text="Finish"
        disabled={requestInProgress || submitting}
        fullWidth={true}
        showSpinner={requestInProgress || submitting}
      />
      {!!error && (
        <Layout marginTop={4}>
          <Text size="body2" color="red-700">
            {error}
          </Text>
        </Layout>
      )}
    </Layout>
  );
};

export default SignUp;
