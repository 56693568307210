// TODO: is there a more centralized place where this belongs?
import { User, UserCalendar, UserCalendarDate, addDays, dateToYyyyMmDd, getUserCalendar } from 'shared';
import { DateTime } from 'luxon';

import { actions as userActions } from 'store/User';
import { store } from 'store/store';

import { UpcomingSessionData } from './types';

export const calendarHasNoAvailability = (calendar: UserCalendar) => {
  return !calendarHasAvailability(calendar);
};

export const calendarHasAvailability = (calendar: UserCalendar) => {
  return !!calendar.dates.find(date => date.workspaces.length > 0);
};

export const workspacesAreComingSoon = ({ user, calendar }: { user?: User; calendar?: UserCalendar }) => {
  return !!user?.customers?.length && calendar && calendarHasNoAvailability(calendar);
};

export const findFirstOpenDate = ({
  dates,
  userId
}: {
  dates: UserCalendarDate[];
  userId: number;
}): string | undefined => {
  return dates.find(({ bookings, workspaces }) => {
    const userHasBooking = bookings.find(b => b.user.id === userId);

    if (userHasBooking) return false;

    return workspaces.some(ws => ws.capacity > bookings.filter(b => b.workspace_id === ws.id).length);
  })?.date;
};

export const loadUserCalendar = (userId: number) => {
  getUserCalendarData(userId).then(data => {
    store.dispatch(userActions.setUserCalendar(data));
  });
};

export const getUserCalendarData = (userId: number) => {
  const startDate = dateToYyyyMmDd(new Date());

  const endDate = dateToYyyyMmDd(addDays(new Date(), 29));
  // TODO: also send lat/lng?
  return getUserCalendar({ id: userId, startDate, endDate }).then(({ data }) => {
    return data;
  });
};

export const sessionFromCalendar = ({
  calendar,
  bookingId
}: {
  calendar: UserCalendar;
  bookingId: number;
}): UpcomingSessionData | null => {
  const calendarDate = calendar.dates.filter(cd => !!cd.bookings.find(b => b.id === bookingId))[0];
  if (!calendarDate) return null;

  const userBooking = calendarDate.bookings.find(b => b.id === bookingId);
  if (!userBooking) return null;

  const workspace = calendarDate.workspaces.find(workspace => userBooking.workspace_id === workspace.id);
  if (!workspace) return null;

  return {
    booking: userBooking,
    date: calendarDate.date,
    workspace: workspace,
    otherGuests: calendarDate.bookings
      .filter(b => b.user.id !== userBooking.user.id)
      .map(({ user: { firstname, avatar_url } }) => ({
        name: firstname,
        avatarUrl: avatar_url
      }))
  };
};

export const upcomingSessionFromCalendar = ({
  userId,
  calendar
}: {
  userId: number;
  calendar: UserCalendar;
}): UpcomingSessionData | null => {
  const calendarDate = calendar.dates.find(d => !!d.bookings.find(b => b.user.id === userId));
  if (!calendarDate) return null;

  const userBooking = calendarDate.bookings.find(b => b.user.id === userId);
  if (!userBooking) return null;

  const workspace = calendarDate.workspaces.find(workspace => userBooking.workspace_id === workspace.id);
  if (!workspace) return null;

  return {
    booking: userBooking,
    date: calendarDate.date,
    workspace: workspace,
    otherGuests: calendarDate.bookings
      .filter(b => b.user.id !== userBooking.user.id)
      .map(({ user: { firstname, avatar_url } }) => ({
        name: firstname,
        avatarUrl: avatar_url
      }))
  };
};

export const showCalendarNavItem = ({ user }: { user?: User | null }) => {
  if (!user) return false;

  const { customers } = user;

  if (!customers.length) return false;

  const now = DateTime.now();

  return customers.some(({ plan_start_date }) => {
    if (!plan_start_date) return false;

    const startDate = DateTime.fromISO(plan_start_date, { zone: 'utc' });

    return startDate.minus({ days: 30 }) < now;
  });
};
