import { STORAGE_NAMESPACE } from 'shared';

import { FeatureState, IpLocation } from './types';

export type StorageState = {
  token: null | string;
  lastAuthMode: 'sso' | 'email' | null;
  ipLocation: IpLocation | null;
  searchPriceExplanationDismissed: boolean | null;
  lastUserId: number;
  lastCustomerId: number;
  impId?: number | null;
} & {
  features: FeatureState | null;
};

type Key = keyof StorageState;

function keyTransform(key: Key) {
  return STORAGE_NAMESPACE + '.' + key;
}

export const getItem = <T extends Key>(key: T): StorageState[T] | null => {
  // Guard against function call in (build) environments without window.
  if (typeof window === 'undefined') return null;

  const value = localStorage.getItem(keyTransform(key));

  return value === null ? null : JSON.parse(value);
};

export const setItem = <T extends Key>(key: T, value: StorageState[T]) => {
  const storageValue = JSON.stringify(value);

  if (storageValue) {
    localStorage.setItem(keyTransform(key), storageValue);
    window.dispatchEvent(new Event(key));
  }
};

export const removeItem = <T extends Key>(key: T) => {
  localStorage.removeItem(keyTransform(key));
};
