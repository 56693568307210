import React, { RefObject, useContext, useState } from 'react';
import { BLUE_50_HEX, WHITE_HEX } from 'shared';

import { createTransformations, imageKitUrl } from 'helpers/images';

import { media } from 'context';
import { Clickable, Icon, Img, Layout } from 'ds';

import { ReactComponent as PlayButton } from './play-button.svg';

interface Props {
  srcKey: string;
  title: string;
  subtitle: string;
  location: string;
  avatarSrcKey: string;
  posterSrcKey: string;
  videoRef: RefObject<HTMLVideoElement>;
  onClick: (id: number) => void;
  id: 1 | 2 | 3 | 4;
}

const Video: React.FC<Props> = ({
  srcKey,
  title,
  subtitle,
  location,
  avatarSrcKey,
  posterSrcKey,
  videoRef,
  id,
  onClick
}) => {
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const { xs } = useContext(media);
  const playButtonEnabled = videoRef.current?.currentTime === 0;

  return (
    <Layout direction="column" flexGrow={1}>
      <Layout position="relative" __className="Testimonials-video">
        <Clickable
          onClick={e => {
            e.preventDefault();

            if (!playButtonEnabled) return;

            setIsPlaying(true);
            onClick(id);
          }}
          style={{ display: 'block' }}
          fullWidth
        >
          <video
            controlsList="nodownload"
            controls={!playButtonEnabled || isPlaying}
            style={{
              width: '100%',
              position: 'relative',
              objectFit: 'cover',
              display: 'block',
              borderTopLeftRadius: 24,
              borderTopRightRadius: 24
            }}
            poster={imageKitUrl({
              key: posterSrcKey,
              folder: 'public',
              transformationChain: ['w-1000,h-562']
            })}
            ref={videoRef}
            onPause={() => setIsPlaying(false)}
            onPlay={() => onClick(id)}
          >
            <source
              src={imageKitUrl({
                key: srcKey,
                folder: 'public',
                transformationChain: createTransformations({
                  width: 1920
                })
              })}
              type="video/mp4"
            />
          </video>
          {playButtonEnabled && !isPlaying && (
            <Layout position="absolute" left="50%" top="50%" transform="translateX(-50%) translateY(-50%)" zIndex={2}>
              <PlayButton width={xs ? 33 : 54} height={xs ? 38 : 64} />
            </Layout>
          )}
        </Clickable>
      </Layout>
      <Layout
        color="blue-700"
        flexGrow={1}
        paddingX={32}
        paddingY={20}
        align="center"
        borderBottomLeftRadius={24}
        borderBottomRightRadius={24}
      >
        <Img
          srcKey={avatarSrcKey}
          alt={title}
          width={176}
          style={{
            width: xs ? 52 : 88,
            height: xs ? 52 : 88,
            objectFit: 'cover',
            border: `${xs ? 2 : 5}px solid ${BLUE_50_HEX}`,
            borderRadius: '50%'
          }}
          folder="public"
        />
        <Layout marginLeft={24}>
          <p style={{ fontSize: xs ? 14 : 20, lineHeight: '26px', color: WHITE_HEX, fontWeight: 700 }}>{title}</p>
          <Layout marginTop={xs ? 4 : 2}>
            <p style={{ fontSize: xs ? 10 : 20, lineHeight: xs ? '12px' : '26px', color: WHITE_HEX }}>{subtitle}</p>
          </Layout>
          <Layout marginTop={xs ? 4 : 2} align="center">
            <Icon name="addressPinOutline" size={xs ? 12 : 'sm'} color="white" />
            <p style={{ fontSize: xs ? 10 : 20, lineHeight: xs ? '12px' : '26px', color: WHITE_HEX, marginLeft: 8 }}>
              {location}
            </p>
          </Layout>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default Video;
