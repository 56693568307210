import { BOUNDS, Bounds, COORDS, LatLng, Listing, SearchWorkspacesParams, sortByDate } from 'shared';
import qs from 'qs';

import { parseBounds, parseCenter, roundBounds, validBounds } from 'helpers/map';
import { priceAdjustedForDaysPerWeek } from 'helpers/price';

import { Media } from 'context';

import { SearchFilters, SearchSortLabel } from './types';

export function generateSearch({
  search,
  bounds,
  center,
  zoom,
  selectedWorkspaceId,
  location,
  map,
  daysPerWeek,
  minPrice,
  maxPrice,
  minSqft,
  maxSqft,
  numMeetingRooms,
  offsitesOnly,
  sortLabel
}: {
  search: string;
  bounds?: Bounds | null;
  center?: LatLng | null;
  zoom?: number | null;
  location?: string | null;
  map?: MapState;
  selectedWorkspaceId?: number | null;
  daysPerWeek?: number | null;
  minPrice?: number | null;
  maxPrice?: number | null;
  minSqft?: number | null;
  maxSqft?: number | null;
  numMeetingRooms?: number | null;
  offsitesOnly?: number | null;
  sortLabel?: string;
}): string {
  const searchParams = new URLSearchParams(search);

  if (bounds && validBounds(bounds)) {
    appendBoundsToSearch(searchParams, bounds);
  } else if (bounds === null) {
    deleteBounds(searchParams);
  }

  if (center) {
    COORDS.forEach(coord => {
      searchParams.set(`center_${coord}`, center[coord].toString());
    });
  } else if (center === null) {
    COORDS.forEach(coord => {
      searchParams.delete(`center_${coord}`);
    });
  }

  if (zoom) {
    searchParams.set('zoom', zoom.toString());
  } else if (zoom === null) {
    searchParams.delete('zoom');
  }

  if (selectedWorkspaceId) {
    searchParams.set('selected_workspace_id', selectedWorkspaceId.toString());
  } else if (selectedWorkspaceId === null) {
    searchParams.delete('selected_workspace_id');
  }

  if (typeof location === 'string') {
    searchParams.set('location', location);
  } else if (location === null) {
    searchParams.delete('location');
  }

  if (typeof map === 'string') {
    searchParams.set('map', map);
  }

  const filterParams = [
    { name: 'days_per_week', value: daysPerWeek },
    { name: 'min_price', value: minPrice },
    { name: 'max_price', value: maxPrice },
    { name: 'min_sqft', value: minSqft },
    { name: 'max_sqft', value: maxSqft },
    { name: 'num_meeting_rooms', value: numMeetingRooms },
    { name: 'offsites_only', value: offsitesOnly }
  ] as const;

  filterParams.forEach(({ name, value }) => {
    if (typeof value === 'number') {
      searchParams.set(name, value.toString());
    } else if (value === null) {
      searchParams.delete(name);
    }
  });

  if (typeof sortLabel === 'string') {
    searchParams.set('sortLabel', sortLabel);
  }

  return searchParams.toString();
}

const MAP_STATES = ['show', 'hide'] as const;
type MapState = typeof MAP_STATES[number];

export function parseSearch(
  search: string
): {
  bounds?: Bounds;
  center?: LatLng;
  zoom?: number;
  location?: string;
  map?: MapState;
  selectedWorkspaceId?: number | null;
  daysPerWeek?: number;
  minPrice?: number;
  maxPrice?: number;
  minSqft?: number;
  maxSqft?: number;
  numMeetingRooms?: number;
  offsitesOnly?: number;
  sortLabel?: SearchSortLabel;
} {
  const {
    zoom,
    selected_workspace_id,
    location,
    map,
    days_per_week,
    min_price,
    max_price,
    min_sqft,
    max_sqft,
    offsites_only,
    sortLabel,
    ...params
  } = qs.parse(search, {
    ignoreQueryPrefix: true
  });
  const bounds = parseBounds(params);
  const center = parseCenter(params);

  return {
    location: typeof location === 'string' ? location : undefined,
    zoom: typeof zoom === 'string' ? parseFloat(zoom) : undefined,
    selectedWorkspaceId: typeof selected_workspace_id === 'string' ? parseInt(selected_workspace_id) : undefined,
    map: MAP_STATES.includes(map as MapState) ? (map as MapState) : undefined,
    daysPerWeek:
      typeof days_per_week === 'string' || typeof days_per_week === 'number' ? parseInt(days_per_week) : undefined,
    minPrice: typeof min_price === 'string' ? parseInt(min_price) : undefined,
    maxPrice: typeof max_price === 'string' ? parseInt(max_price) : undefined,
    minSqft: typeof min_sqft === 'string' ? parseInt(min_sqft) : undefined,
    maxSqft: typeof max_sqft === 'string' ? parseInt(max_sqft) : undefined,
    numMeetingRooms: typeof params.num_meeting_rooms === 'string' ? parseInt(params.num_meeting_rooms) : undefined,
    offsitesOnly: typeof offsites_only === 'string' ? parseInt(offsites_only) : undefined,
    bounds,
    center,
    sortLabel: typeof sortLabel === 'string' ? (sortLabel as SearchSortLabel) : undefined
  };
}

interface SearchKeyParams extends Partial<SearchWorkspacesParams> {
  userId?: number;
  companyName?: string;
}
export function generateSearchKey({ bounds, date, userId, companyName }: SearchKeyParams): string {
  return [JSON.stringify(roundBounds(bounds)), date, userId, companyName].join();
}

export function appendBoundsToSearch(searchParams: URLSearchParams, bounds: Bounds) {
  BOUNDS.forEach(bound => {
    COORDS.forEach(coord => {
      searchParams.set(`${bound}_${coord}`, bounds[bound][coord].toString());
    });
  });
}

export function deleteBounds(searchParams: URLSearchParams) {
  BOUNDS.forEach(bound => {
    COORDS.forEach(coord => {
      searchParams.delete(`${bound}_${coord}`);
    });
  });
}

export const getWorkspaceListWidth = ({ xl }: Media) => {
  if (xl) return 748;
  else return 580;
};

export interface FilterableWorkspace
  extends Pick<
    Listing,
    'monthly_price' | 'square_feet' | 'available_short_term' | 'daily_rate' | 'is_reserved' | 'num_meeting_rooms'
  > {}

export function filterWorkspaces<T extends FilterableWorkspace>({
  workspaces,
  filters: { daysPerWeek, minPrice, maxPrice, minSqft, maxSqft, offsitesOnly, numMeetingRooms }
}: {
  workspaces: T[];
  filters: SearchFilters;
}) {
  const hasMinPriceFilter = typeof minPrice === 'number';
  const hasMaxPriceFilter = typeof maxPrice === 'number';
  const hasMinSqftFilter = typeof minSqft === 'number';
  const hasMaxSqftFilter = typeof maxSqft === 'number';
  const hasNumMeetingRoomsFilter = typeof numMeetingRooms === 'number';

  const adjustedPrice = (price: number) => {
    return priceAdjustedForDaysPerWeek({ monthlyPrice: price, daysPerWeek });
  };

  return workspaces.filter(ws => {
    // TODO: can remove check for typeof ws.square_feet === 'number' once API type has required square_feet property.
    let minSqftMatch =
      !hasMinSqftFilter ||
      (typeof ws.square_feet === 'number' && ws.square_feet >= minSqft) ||
      (hasMinSqftFilter &&
        hasMaxSqftFilter &&
        minSqft > maxSqft &&
        typeof ws.square_feet === 'number' &&
        ws.square_feet <= maxSqft);
    let maxSqftMatch =
      !hasMaxSqftFilter ||
      (typeof ws.square_feet === 'number' && ws.square_feet <= maxSqft) ||
      (hasMinSqftFilter &&
        hasMaxSqftFilter &&
        maxSqft < minSqft &&
        typeof ws.square_feet === 'number' &&
        ws.square_feet >= minSqft);
    const numMeetingRoomsMatch = !hasNumMeetingRoomsFilter || ws.num_meeting_rooms || 0 >= numMeetingRooms;

    if (!!offsitesOnly) {
      const price = ws.daily_rate;

      let minPriceMatch =
        !hasMinPriceFilter ||
        price >= minPrice ||
        (hasMinPriceFilter && hasMaxPriceFilter && minPrice > maxPrice && price <= maxPrice);
      let maxPricematch =
        !hasMaxPriceFilter ||
        price <= maxPrice ||
        (hasMinPriceFilter && hasMaxPriceFilter && maxPrice < minPrice && price >= minPrice);

      return (
        ws.available_short_term &&
        !!price &&
        !ws.is_reserved &&
        minPriceMatch &&
        maxPricematch &&
        minSqftMatch &&
        maxSqftMatch &&
        numMeetingRoomsMatch
      );
    }

    const price = ws.monthly_price ? adjustedPrice(ws.monthly_price) : null;

    let minPriceMatch = true;
    let maxPriceMatch = true;
    if (price) {
      minPriceMatch =
        !hasMinPriceFilter ||
        price >= minPrice ||
        (hasMinPriceFilter && hasMaxPriceFilter && minPrice > maxPrice && price <= maxPrice);
      maxPriceMatch =
        !hasMaxPriceFilter ||
        price <= maxPrice ||
        (hasMinPriceFilter && hasMaxPriceFilter && maxPrice < minPrice && price >= minPrice);
    }

    return minPriceMatch && maxPriceMatch && minSqftMatch && maxSqftMatch && numMeetingRoomsMatch;
  });
}

export function sortWorkspaces({ sortLabel, ...args }: { workspaces: Listing[]; sortLabel: SearchSortLabel }) {
  const workspaces = [...args.workspaces];

  switch (sortLabel) {
    case 'Recommended':
      return workspaces.sort((ws1, ws2) => {
        if (ws1.availability_status === 'not_available' && ws2.availability_status !== 'not_available') return 1;
        if (ws1.availability_status !== 'not_available' && ws2.availability_status === 'not_available') return -1;

        if (ws1.is_hot && !ws2.is_hot) {
          return -1;
        }
        if (!ws1.is_hot && ws2.is_hot) return 1;

        if (ws1.is_recommended && !ws2.is_recommended) return -1;
        if (!ws1.is_recommended && ws2.is_recommended) return 1;

        if (!ws1.monthly_price) return 1;
        if (!ws2.monthly_price) return -1;

        return ws1.monthly_price > ws2.monthly_price ? -1 : 1;
      });
    case 'Newest':
      return workspaces.sort((ws1, ws2) => sortByDate(ws1.first_listed_at, ws2.first_listed_at)).reverse();
    case 'Price (high to low)':
      return workspaces.sort((ws1, ws2) => (ws2.monthly_price || 0) - (ws1.monthly_price || 0));
    case 'Price (low to high)':
      return workspaces.sort((ws1, ws2) => (ws1.monthly_price || 0) - (ws2.monthly_price || 0));
    case 'Size (high to low)':
      return workspaces.sort((ws1, ws2) => (ws2.square_feet || 0) - (ws1.square_feet || 0));
    case 'Size (low to high)':
      return workspaces.sort((ws1, ws2) => (ws1.square_feet || 0) - (ws2.square_feet || 0));
  }
}
