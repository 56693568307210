import React from 'react';

import Avatar from 'ds/Avatar';
import { FORMATTED_TYPEAHEAD_RESULT_SIZE_CONFIG } from 'ds/ComboBox/constants';
import Layout from 'ds/Layout';
import Text from 'ds/Text';
import { FieldSize } from 'ds/types';

interface Props {
  size?: FieldSize;
  query?: string;
  label: string;
  subLabel?: string;
  img_url?: string;
  id?: string | number;
}

const getHighlightedText = ({ text, query }: { text: string; query?: string }) => {
  if (!query) return text;

  // Escape special regex characters in the query
  const escapedQuery = query.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  const parts = text.split(new RegExp(`(${escapedQuery})`, 'gi'));

  return (
    <span>
      {parts.map((part, index) =>
        part.toLowerCase() === query.toLowerCase() ? <b key={part + index}>{part}</b> : part
      )}
    </span>
  );
};

const ComboBoxResult: React.FC<Props> = ({ size = 'sm', query, label, img_url, id, subLabel }) => {
  const { imageSize, labelFontSize } = FORMATTED_TYPEAHEAD_RESULT_SIZE_CONFIG[size];

  const highlightedName = getHighlightedText({ text: label, query });

  return (
    <Layout direction="row" align="center" width="100%" paddingX={12} paddingY={8}>
      {img_url && (
        <>
          <Layout width={imageSize} height={imageSize} borderRadius={4} overflow="hidden" marginBottom={2} flex>
            <Avatar size={imageSize} avatarUrl={img_url} />
          </Layout>
          <Layout minWidth={12} width={12} />
        </>
      )}
      <Layout direction="column" justify="center" align="flex-start" display="inline-block" flexGrow={1}>
        <Layout display="grid" minHeight={24} align="center">
          <Text size={labelFontSize} ellipsis>
            {highlightedName}
          </Text>
        </Layout>
        {subLabel && (
          <>
            <Layout marginTop={2} />
            <Layout display="grid">
              <Text size="body3" color="gray-400" ellipsis>
                {subLabel}
              </Text>
            </Layout>
          </>
        )}
      </Layout>
      {id && (
        <>
          <Layout minWidth={12} width={12} />
          <Text size="body3" color="gray-700">
            {id}
          </Text>
        </>
      )}
    </Layout>
  );
};

export default ComboBoxResult;
